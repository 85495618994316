import { Button } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TeamsItemComponent = ({ teamsTotal = 0, membersTotal = 0, pushHandler = undefined }) => {
  TeamsItemComponent.propTypes = {
    pushHandler: PropTypes.func,
    teamsTotal: PropTypes.number,
    membersTotal: PropTypes.number,
  };
  const b = bem('TeamsItemComponent');
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div>
          <h3 className={b('title')}>{t('teams.title')}</h3>
          <p className={b('description')}>{t('teams.create_teams_for_hr_challenges')}</p>
        </div>
        <div>
          <Button
            onClick={pushHandler}
            style={{
              width: '200px',
              height: '44px',
              fontSize: 14,
              fontWeight: 600,
              borderRadius: 8,
            }}
            type='primary'
          >
            {t('teams.create_team')}
          </Button>
        </div>
      </div>
      <div className={b('number_info')}>
        <div>
          <h5 className='title'>{t('teams.number_of_teams')}</h5>
          <span className='number'>{teamsTotal}</span>
        </div>
        <div>
          <h5 className='title'>{t('teams.number_of_participants')}</h5>
          <span className='number'>{membersTotal}</span>
        </div>
      </div>
    </div>
  );
};

export default TeamsItemComponent;
