import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';

const BreadcrumbsComponent = ({
  linkProps = false,
  homeLink = '',
  textEnd = '',
  componentName = '',
  linkTobe = '',
  pushHandler = '',
  linkPush = '',
}) => {
  BreadcrumbsComponent.propTypes = {
    linkProps: PropTypes.bool,
    homeLink: PropTypes.string,
    textEnd: PropTypes.string,
    componentName: PropTypes.string,
    linkTobe: PropTypes.string,
    pushHandler: PropTypes.string,
    linkPush: PropTypes.string,
  };
  const { t } = useTranslation();
  const push = useNavigate();
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const dispatch = useDispatch();

  const breadHandler = () => {
    if (errorsValidate) {
      dispatch(modalExitReducer(true));
      dispatch(linksAddReducer(pushHandler));
    } else {
      push(pushHandler);
    }
  };
  return (
    <Breadcrumb separator='|'>
      {homeLink ? (
        <Breadcrumb.Item onClick={() => push('/admin')}>
          <span className='text_page2'>{t(`challenges.${homeLink}`)}</span>
        </Breadcrumb.Item>
      ) : null}
      {linkProps ? (
        <Breadcrumb.Item onClick={breadHandler}>
          <span className='text_page1'>{t(`${componentName}`)}</span>
        </Breadcrumb.Item>
      ) : null}
      {linkTobe ? (
        <Breadcrumb.Item className='text_page1' onClick={() => push(linkPush)}>
          {linkTobe}
        </Breadcrumb.Item>
      ) : null}

      <Breadcrumb.Item>
        <span className='text_page3'>{textEnd}</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default BreadcrumbsComponent;
