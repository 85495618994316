import { Skeleton } from 'antd';
import bem from 'easy-bem';
import React from 'react';

import SkeletonButton from 'components/SkeletonBlock/FieldsSkeleton/SkeletonButton';
import SkeletonImage from 'components/SkeletonBlock/FieldsSkeleton/SkeletonImage';

const SkeletonCategory = () => {
  const b = bem('SkeletonCategory');
  return (
    <div data-testid='skeleton_category' className='SkeletonCategory_sections'>
      <div className={b()}>
        <div className={b('block')}>
          <div className='margin_bottom'>
            <SkeletonButton height={16} num={1} />
          </div>
          <div className='margin_bottom'>
            <SkeletonButton height={30} num={1} />
          </div>
          <SkeletonButton height={30} num={1} />
        </div>
        <div className={b('block_2')}>
          <SkeletonButton height={40} width='511px' borderRadius={8} num={1} />
          <div className={b('block_3')}>
            <SkeletonButton height={44} width='129px' borderRadius={8} num={3} />
          </div>
        </div>
      </div>
      <div className={b('block_4')}>
        <SkeletonImage width={246} height={148} borderRadius={8} num={1} />
        <div className='progress_text'>
          <Skeleton active />
        </div>
      </div>
    </div>
  );
};

export default SkeletonCategory;
