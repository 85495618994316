import bem from 'easy-bem';
import React from 'react';

import plugForTable from 'assets/images/plug_for_table.svg';
import localesUtils from 'utils/localesUtils/localesUtils';

const CustomizeRenderEmpty = () => {
  const b = bem('CustomizeRenderEmpty');

  return (
    <div className={b()}>
      <div>
        <img src={plugForTable} alt='plug_for_table' />
      </div>
      <div>
        <h1 className={b('title')}>
          {localesUtils('Извините, пока никто не подал заявку.', 'Sorry, no one has applied yet.')}
        </h1>
        <p>
          {localesUtils(
            'Участники появятся как только подадут заявки на участие в мероприятиях.\n',
            'Participants appear as soon as they send requests for events participation.',
          )}
        </p>
      </div>
    </div>
  );
};

export default CustomizeRenderEmpty;
