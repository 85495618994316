import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Status = ({ items = [], title = '', onClick = undefined, defaultSelectedKeys = [] }) => {
  Status.propTypes = {
    items: PropTypes.instanceOf(Array),
    title: PropTypes.string,
    onClick: PropTypes.func,
    defaultSelectedKeys: PropTypes.instanceOf(Array),
  };
  const b = bem('Status');
  const { t } = useTranslation();
  return (
    <Dropdown menu={{ items, onClick, defaultSelectedKeys }}>
      <span>
        <Space className={b('title')}>
          {t(title)}
          <DownOutlined />
        </Space>
      </span>
    </Dropdown>
  );
};

export default Status;
