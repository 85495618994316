import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import logoutIcons from 'assets/images/iconsMenu/logout.svg';
import logo from 'assets/images/logo/Logo.svg';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalExit from 'components/UI/ModalExit/ModalExit';
import { clearResultsChallenges } from 'redux/challengesSlice/challengesSlice';
import {
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import { clearResultsPromotions } from 'redux/promotions/promotionsSlice';
import {
  searchReducer,
  searchSelector,
  searchTermPromoReducer,
  searchTermReducer,
  searchTermStartReducer,
} from 'redux/search/searchSlice';
import { clearStarts } from 'redux/startsSlice/startsSlice';
import { menuText } from 'utils/constants';

const Navigation = ({
  logOutHandler = undefined,
  isModalVisible = undefined,
  yesDeleteHandler = undefined,
  noneDeleteHandler = undefined,
}) => {
  Navigation.propTypes = {
    logOutHandler: PropTypes.func,
    isModalVisible: PropTypes.bool,
    yesDeleteHandler: PropTypes.func,
    noneDeleteHandler: PropTypes.func,
  };
  const b = bem('MenuNavigation');
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const { searchTerm, searchTermPromo, searchTermStart } = useSelector(searchSelector);
  const push = useNavigate();
  const link = pathname.split('/').filter((i) => i);
  const dispatch = useDispatch();

  const menuHandler = (value) => {
    if (errorsValidate) {
      dispatch(modalExitReducer(true));
      dispatch(linksAddReducer(value));
    } else {
      push(value);
      if (value.includes('/challenges') && searchTermPromo.length) {
        dispatch(clearResultsPromotions());
        dispatch(searchTermPromoReducer(''));
      } else if (value.includes('/starts') && searchTermStart.length) {
        dispatch(clearStarts());
        dispatch(searchTermStartReducer(''));
      } else if (value.includes('/promotions') && searchTerm.length) {
        dispatch(clearResultsChallenges());
        dispatch(searchTermReducer(''));
      } else if (
        value.includes('/admin') &&
        value.length === 1 &&
        (searchTerm.length || searchTermPromo.length)
      ) {
        dispatch(clearResultsChallenges());
        dispatch(searchTermReducer(''));
        dispatch(clearResultsPromotions());
        dispatch(searchTermPromoReducer(''));
      }
    }
    dispatch(searchReducer(''));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const menuLink = menuText.map((item) => (
    <button
      type='button'
      className='btn_none_style'
      onClick={() => menuHandler(item.link)}
      key={item.name}
    >
      <div className={`/${link[0]}` === item.link || pathname === item.link ? 'activeLink' : ''}>
        <div className='line' />
      </div>
      <div>
        <img src={item.icons} alt={item.name} />
      </div>
      <p>{t(`navigation.${item.name}`)}</p>
    </button>
  ));

  return (
    <div className={b()}>
      <div className={b('logo_nomad')}>
        <Link to='/admin'>
          <img src={logo} alt='logo_nomad' />
        </Link>
      </div>
      <div className={b('navigation_menu')}>
        <p>{t('navigation.menu')}</p>
        <div className={b('link_navigation')}>{menuLink}</div>
      </div>
      <button type='button' onClick={logOutHandler} className={b('logout')}>
        <div>
          <img src={logoutIcons} alt='logout-icons' />
        </div>
        <p>{t('navigation.log_out')}</p>
      </button>
      <ModalComponent open={isModalVisible} width={440} height='100%'>
        <ModalExit yesDeleteHandler={yesDeleteHandler} noneDeleteHandler={noneDeleteHandler} />
      </ModalComponent>
    </div>
  );
};

export default Navigation;
