import { ConfigProvider, DatePicker, Form } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import ruRU from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import i18n from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

dayjs.extend(customParseFormat);

const DatePickerField = ({
  label = '',
  marginTop = '',
  creatObj = {},
  dateChangeHandler = undefined,
  dateFormat = '',
  width = '',
  height = '',
  borderRadius = 0,
  disabled = false,
  disableDate = true,
}) => {
  DatePickerField.propTypes = {
    label: PropTypes.string,
    marginTop: PropTypes.string,
    creatObj: PropTypes.instanceOf(Object),
    dateChangeHandler: PropTypes.func,
    dateHandler: PropTypes.func,
    dateFormat: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    borderRadius: PropTypes.number,
    disabled: PropTypes.bool,
    disableDate: PropTypes.bool,
  };
  const { t } = useTranslation();

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  return (
    <ConfigProvider data-testid='date_picker_elem' locale={i18n?.language === 'ru' ? ruRU : enUS}>
      <Form.Item
        label={t(label)}
        style={{
          marginTop,
        }}
      >
        <DatePicker
          disabled={disabled}
          disabledDate={disableDate ? disabledDate : false}
          onChange={dateChangeHandler}
          format={dateFormat}
          defaultValue={dayjs(moment(creatObj.date, dateFormat))}
          style={{
            width,
            height,
            borderRadius,
          }}
        />
      </Form.Item>
    </ConfigProvider>
  );
};

export default DatePickerField;
