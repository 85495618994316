import { CloseOutlined } from '@ant-design/icons';
import { Form, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const InputInterest = ({
  label = '',
  deleteCard = undefined,
  onChangeInterest = undefined,
  min = 0,
  max = 0,
  onChangeNumber = undefined,
  status = '',
  statusInterest = 0,
  placeholder = '',
  input = '',
  onChangeNumberKm = undefined,
  onClick = undefined,
  statusDefaultKm = '',
  nameLocales = '',
}) => {
  InputInterest.propTypes = {
    label: PropTypes.string,
    deleteCard: PropTypes.func,
    onChangeInterest: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    onChangeNumber: PropTypes.func,
    status: PropTypes.string,
    statusInterest: PropTypes.number,
    placeholder: PropTypes.string,
    input: PropTypes.string,
    onChangeNumberKm: PropTypes.func,
    onClick: PropTypes.func,
    statusDefaultKm: PropTypes.string,
    nameLocales: PropTypes.string,
  };
  const { t } = useTranslation();

  return (
    <div className='block_form_percent'>
      <Form.Item
        label={
          <div className='form_block'>
            <p>{label}</p>
            <div>
              <CloseOutlined
                onClick={deleteCard}
                style={{
                  fontSize: 14,
                }}
              />
            </div>
          </div>
        }
        style={{
          backgroundColor: '#ffffff',
          width: 512,
          height: 112,
          marginTop: 16,
          padding: '15px 22px 0px 22px',
          borderRadius: 8,
        }}
      >
        <div className='block_form_percent'>
          <InputNumber
            status={+status ? '' : 'error'}
            min={min}
            max={max}
            onClick={onClick}
            onChange={input === 'km' ? onChangeNumberKm : onChangeNumber}
            style={{
              width: 291,
              height: 40,
              borderRadius: 8,
            }}
            defaultValue={statusDefaultKm}
            placeholder={t(placeholder)}
            formatter={(value) => `${value}${nameLocales}`}
            parser={(value) => value.replace(`${nameLocales}`, '')}
          />
          <InputNumber
            status={statusInterest ? '' : 'error'}
            onChange={onChangeInterest}
            onClick={onClick}
            style={{
              width: 164,
              height: 40,
              borderRadius: 8,
            }}
            defaultValue={statusInterest || 0}
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            placeholder={t('challenges.percent')}
          />
        </div>
      </Form.Item>
    </div>
  );
};

export default InputInterest;
