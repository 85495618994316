import errors from 'assets/images/icons/errors.svg';
import icons from 'assets/images/icons/messageIcons.svg';
import success from 'assets/images/icons/success.svg';

export const messagesError = (text) => (
  <div className='errorPage'>
    <div className='icons'>
      <img src={icons} alt='icons' />
    </div>
    <div>{text}</div>
  </div>
);

export const messagesErrorFetch = (text) => (
  <div className='errorPage'>
    <div className='icons'>
      <img src={errors} alt='errors' />
    </div>
    <div>{text}</div>
  </div>
);

export const messagesSuccess = (text) => (
  <div className='successPage'>
    <div className='icons'>
      <img src={success} alt='success' />
    </div>
    <div>{text}</div>
  </div>
);
