import { Button, Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const CustomButton = ({
  formStyle = {},
  type = '',
  text = '',
  style = {},
  icon = undefined,
  className = '',
  onClick = undefined,
  loading = undefined,
}) => {
  CustomButton.propTypes = {
    formStyle: PropTypes.instanceOf(Object),
    type: PropTypes.string,
    text: PropTypes.string,
    style: PropTypes.instanceOf(Object),
    icon: PropTypes.element,
    className: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
  };

  return (
    <Form.Item style={formStyle}>
      <Button
        loading={loading}
        onClick={onClick}
        type={type}
        className={className}
        htmlType='submit'
        style={style}
        icon={icon}
      >
        {text}
      </Button>
    </Form.Item>
  );
};

export default CustomButton;
