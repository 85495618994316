import { RightOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import Errors from 'components/Errors/Errors';
import PlugForLists from 'components/NoChallenge/plugForLists';
import ShowAllCard from 'components/ShowAllCard/ShowAllCard';
import SkeletonBlock from 'components/SkeletonBlock/SkeletonBlock';
import TableComponent from 'components/TableComponent/TableComponent';
import StatisticItemComponent from 'containers/Home/StatisticItemComponent/StatisticItemComponent';
import {
  addResultsChallenges,
  addResultsInfo,
  challengesSelector,
  creatingNullReducer,
} from 'redux/challengesSlice/challengesSlice';
import {
  challengesHomeFetch,
  homeSelector,
  promotionsHomeFetch,
  startsHomeFetch,
} from 'redux/homeSlice/homeSlice';
import { addResultsInfoPromotions, addResultsPromotions } from 'redux/promotions/promotionsSlice';
import { searchReducer } from 'redux/search/searchSlice';
import { usersFetch, usersSelector } from 'redux/users/usersSlice';
import { statisticItems, statusAccount } from 'utils/constants';

import { commonStatisticInfoFetch, commonStatisticsSelector } from '../../redux/common/commonSlice';

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const push = useNavigate();
  const b = bem('Home');
  const { users, usersLoading } = useSelector(usersSelector);
  const { common, loading } = useSelector(commonStatisticsSelector);
  const { challengesHome, promotionsHome, startsHome } = useSelector(homeSelector);
  const { sort } = useSelector(challengesSelector);
  const sortPromotions = useSelector((state) => state.promotions.sort);

  useEffect(() => {
    if (!Object.keys(common).length) {
      dispatch(commonStatisticInfoFetch());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!challengesHome.results.length) {
      dispatch(challengesHomeFetch());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!startsHome.results.length) {
      dispatch(startsHomeFetch());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!promotionsHome.results.length) {
      dispatch(promotionsHomeFetch());
    }
  }, [dispatch]);

  useEffect(() => {
    if (challengesHome.results.length && !sort.status.url && !sort.categories.url) {
      dispatch(addResultsChallenges(challengesHome));
    }
  }, [challengesHome, challengesHome.results, dispatch, sort.categories.url, sort.status.url]);

  useEffect(() => {
    if (promotionsHome.results.length && !sortPromotions.status.url) {
      dispatch(addResultsPromotions(promotionsHome));
    }
  }, [dispatch, promotionsHome, promotionsHome.results, sortPromotions?.status?.url]);
  const challengesList = challengesHome.results?.filter((item, i) => i < 4);
  const promotionsList = promotionsHome.results?.filter((item, i) => i < 4);
  const startsList = startsHome.results?.filter((item, i) => i < 4);
  const pushHandlerChallenges = () => {
    dispatch(creatingNullReducer());
    push('/challenges/creatingChallenges');
  };

  const pushHandlerPromotions = () => {
    push('/promotions/create-promotion/');
  };

  const pushHandlerStarts = () => {
    push('/starts/create');
  };

  const nextBrowserChallengesHandler = (id) => {
    push(`/challenges/challenge-info/${id}`);
    const findHome = challengesList.find((item) => item.id === Number(id));
    if (findHome) {
      dispatch(addResultsInfo(findHome));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(searchReducer(''));
  };

  const nextBrowserPromotionsHandler = (id) => {
    push(`/promotions/promotion-info/${id}/`);
    const findHome = promotionsList.find((item) => item.id === Number(id));
    if (findHome) {
      dispatch(addResultsInfoPromotions(findHome));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(searchReducer(''));
  };

  const nextBrowserStartsHandler = (id) => {
    push(`/starts/starts-info/${id}/`);
  };

  const resultsChallenges = () => {
    if (challengesHome.loading) {
      return <SkeletonBlock active={challengesHome.loading} num={4} />;
    }
    if (challengesList.length) {
      return (
        <ShowAllCard
          fileLoading={false}
          results={challengesList}
          options={{}}
          onChange={() => {}}
          category
          requirements
          nextBrowserHandler={nextBrowserChallengesHandler}
        />
      );
    }
    return (
      <PlugForLists
        pushHandler={pushHandlerChallenges}
        subMainText={t('challenges.creating_challenges_photo')}
        buttonText={t('challenges.creating_challenges')}
      />
    );
  };

  const resultsPromotions = () => {
    if (promotionsHome.loading) {
      return <SkeletonBlock active={promotionsList.loading} num={4} />;
    }
    if (promotionsList.length) {
      return (
        <ShowAllCard
          fileLoading={false}
          results={promotionsList}
          options={{}}
          onChange={() => {}}
          nextBrowserHandler={nextBrowserPromotionsHandler}
        />
      );
    }
    return (
      <PlugForLists
        pushHandler={pushHandlerPromotions}
        subMainText={t('promotions.creating_your_promotions')}
        buttonText={t('promotions.create_your_promotion')}
      />
    );
  };

  const resultsStarts = () => {
    if (startsHome.loading) {
      return <SkeletonBlock active={startsHome.loading} num={4} />;
    }
    if (startsList.length) {
      return (
        <ShowAllCard
          fileLoading={false}
          results={startsList}
          options={{}}
          onChange={() => {}}
          category
          nextBrowserHandler={nextBrowserStartsHandler}
        />
      );
    }
    return (
      <PlugForLists
        pushHandler={pushHandlerStarts}
        subMainText={t('starts.starts_starts_photo')}
        buttonText={t('starts.creating_starts')}
      />
    );
  };

  if (challengesHome.errors || promotionsHome.errors) {
    return <Errors status={challengesHome.errors || promotionsHome.errors} />;
  }

  return (
    <div className={b()}>
      <div>
        <h2 className={b('title')}>{t('home.statistics')}</h2>
        <StatisticItemComponent loading={loading} items={statisticItems} commonInfo={common} />
      </div>

      <div className={b('Last_updated')}>
        <div className='block_card'>
          <h2 className={b('title')}>{t('home.last_updated_challenges')}</h2>
          <Link to='/challenges'>
            {t('home.watch_all')}
            <RightOutlined
              style={{
                color: '#007AFF',
              }}
            />
          </Link>
        </div>

        <div className='block_challenges_list'>{resultsChallenges()}</div>
      </div>

      <div className={b('Last_updated')}>
        <div className='block_card'>
          <h2 className={b('title')}>{t('promotions.promotions')}</h2>
          <Link to='/promotions/'>
            {t('home.watch_all')}
            <RightOutlined
              style={{
                color: '#007AFF',
              }}
            />
          </Link>
        </div>

        <div className='block_challenges_list'>{resultsPromotions()}</div>
      </div>

      <div className={b('Last_updated')}>
        <div className='block_card'>
          <h2 className={b('title')}>{t('starts.name')}</h2>
          <Link to='/starts/'>
            {t('home.watch_all')}
            <RightOutlined
              style={{
                color: '#007AFF',
              }}
            />
          </Link>
        </div>

        <div className='block_challenges_list'>{resultsStarts()}</div>
      </div>

      <div className={b('Last_updated')}>
        <div className='block_card'>
          <h2 className={b('title')}>{t('home.recent_participants_added')}</h2>
        </div>
        <TableComponent
          usersParticipantsSearchFetch={usersFetch}
          usersParticipantsList={users}
          loadingIndicator={usersLoading}
          users={users?.items}
          filterData={users?.items}
          usersList={users}
          searchPlaceholder={t('home.search_users')}
          placeholderForStatusName={t('home.account_status')}
          statusFilterMap={statusAccount}
        />
      </div>
    </div>
  );
};

export default Home;
