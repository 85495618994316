import localesUtils from 'utils/localesUtils/localesUtils';

export const errorsHandler = (numberError = 0) => {
  const errorsArray = [
    {
      ru: 'К сожалению, мы не можем обработать ваш запрос. Пожалуйста, проверьте, что вы правильно заполнили все поля и повторите запрос еще раз.',
      en: 'Unfortunately, we are unable to process your request. Please check that you have filled in all fields correctly and try your request again.',
    },
    {
      ru: 'К сожалению, у вас нет прав для доступа к этому ресурсу, так как ваш токен доступа недействителен. Пожалуйста, авторизуйтесь заново.',
      en: 'Unfortunately, you do not have access to this resource because your access token is invalid. Please reauthorize to regain access.',
    },
    {
      ru: 'Извините, на сервере что-то пошло не так, и мы не можем обработать ваш запрос в данный момент. Пожалуйста, повторите попытку позже.',
      en: 'Sorry, something went wrong on the server and we are unable to process your request at the moment. Please try again later.',
    },
    {
      ru: 'К сожалению, запрашиваемая вами страница не найдена.',
      en: 'Unfortunately, the page you requested was not found.',
    },
  ];
  if (Number(numberError) === 401) {
    return localesUtils(errorsArray[1].ru, errorsArray[1].en);
  }
  if (Number(numberError) === 404) {
    return localesUtils(errorsArray[3].ru, errorsArray[3].en);
  }
  if (Number(numberError) < 500) {
    return localesUtils(errorsArray[0].ru, errorsArray[0].en);
  }
  if (errorsArray >= 500) {
    return localesUtils(errorsArray[2].ru, errorsArray[2].en);
  }
  return localesUtils(
    'Извините, на сервере что-то пошло не так, и мы не можем обработать ваш запрос в данный момент. Пожалуйста, повторите попытку позже.',
    'Sorry, something went wrong on the server and we are unable to process your request at the moment. Please try again later.',
  );
};

export const successText = (text = '') => {
  const pushText = {
    successAdd: {
      ru: 'Успешно создано!',
      en: 'Successfully add!',
    },
    successDelete: {
      ru: 'Успешно удалено!',
      en: 'Successfully remove!',
    },
    updatePromotions: {
      ru: 'Акция успешно отредактирована!',
      en: 'Successfully updated!',
    },
    updateStarts: {
      ru: 'Старт успешно отредактирован!',
      en: 'Successfully updated!',
    },
    updateTeams: {
      ru: 'Команда успешно отредактирована!',
      en: 'Successfully updated!',
    },
    updateNews: {
      ru: 'Новость успешно отредактирована!',
      en: 'Successfully updated!',
    },
    updateComment: {
      ru: 'Комментарий успешно отредактирован!',
      en: 'Successfully updated!',
    },
    updateCaching: {
      ru: 'Данные успешно обновлены!',
      en: 'Successfully updated!',
    },
  };

  if (text) {
    return localesUtils(pushText[text].ru, pushText[text].en);
  }
  return localesUtils('Нет текста к уведомлению', 'No notification text');
};

export const linkSearch = (link) => {
  if (link[0] === 'challenges' && link.length === 1) {
    return {
      id: 1,
      placeholder: localesUtils('Поиск по челленджу', 'Search by challenge'),
    };
  }

  if (link[0] === 'promotions' && link.length === 1) {
    return {
      id: 2,
      placeholder: localesUtils('Поиск по Акции', 'Search by Promotion'),
    };
  }

  if (link[0] === 'starts' && link.length === 1) {
    return {
      id: 2,
      placeholder: localesUtils('Поиск по Стартам', 'Search by Starts'),
    };
  }

  return {
    id: 0,
    placeholder: localesUtils('Поиск', 'Search'),
  };
};
