import { Button } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ModalDelete = ({
  noneDeleteHandler = undefined,
  yesDeleteHandler = undefined,
  loading = false,
  title = '',
  text = '',
}) => {
  ModalDelete.propTypes = {
    noneDeleteHandler: PropTypes.func,
    yesDeleteHandler: PropTypes.func,
    loading: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
  };
  const { t } = useTranslation();
  const b = bem('modalEventsDelete');

  return (
    <div data-testid='elem-modal-delete' className={b()}>
      <div>
        <h1 data-testid='title_delete_modal'>{title}</h1>
        <p>{text}</p>
        <div className='btn_delete'>
          <Button
            data-testid='elem-not'
            onClick={noneDeleteHandler}
            style={{
              backgroundColor: '#F4F6FF',
              color: '#007AFF',
              marginRight: '12px',
            }}
          >
            {t('challenges.leave')}
          </Button>
          <Button
            loading={loading}
            data-testid='yes-elem'
            onClick={yesDeleteHandler}
            style={{
              backgroundColor: '#007AFF',
              color: '#fff',
            }}
          >
            {t('challenges.remove')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalDelete;
