import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, message } from 'antd';
import bem from 'easy-bem';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import Errors from 'components/Errors/Errors';
import FormField from 'components/FormField/FormField';
import { messagesErrorFetch } from 'components/Messages/Messagetext';
import ProgressComponents from 'components/ProgressComponents/ProgressComponents';
import SkeletonCategory from 'components/SkeletonBlock/SkeletonCategory/SkeletonCategory';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalFormConditions from 'components/UI/ModalFormConditions/ModalFormConditions';
import {
  challengesCategoriesFetch,
  challengesCategoriesSelector,
  challengesChoseReducer,
  challengesCreateDataSelector,
  challengesSelector,
  creatChallengesReducer,
  requirementAdd,
  requirementInterestChange,
  requirementRemove,
  requirementUnitChangeKm,
  validateUnitAmountReducer,
  validateUnitAmountReducerDelete,
  validateUnitArrayNull,
} from 'redux/challengesSlice/challengesSlice';
import {
  errorsValidateReducer,
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import nameLocales from 'utils/namelocales/nameLocales';
import { numberKmFunction, numberMetersFunction } from 'utils/resultsKmMeters/resultsKmMeters';

const Conditions = () => {
  const b = bem('Conditions');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { creatChallenges, summaRequirement, validateUnitAmount } = useSelector(challengesSelector);
  const { loading, errors, results, categoriesChose } = useSelector(challengesCategoriesSelector);
  const { requirements } = useSelector(challengesCreateDataSelector);
  const push = useNavigate();

  useEffect(() => {
    if (errorsValidate) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.onpopstate = () => {};
        dispatch(modalExitReducer(true));
        dispatch(linksAddReducer(`/challenges`));
      };
    }
  }, [errorsValidate]);

  useEffect(() => {
    if (!results.length) {
      dispatch(challengesCategoriesFetch());
    }
    dispatch(validateUnitArrayNull());
  }, []);

  useEffect(() => {
    if (requirements.length > 3) {
      message.error(messagesErrorFetch(t('challenges.only_3_challenge')));
    }
  }, [requirements]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const modalHandler = () => {
    setIsModalVisible(true);
  };
  const onChangeChoose = (value) => {
    const resultsCategories = results?.find((item) => item.id === value);
    dispatch(creatChallengesReducer({ category: value }));
    dispatch(challengesChoseReducer({ data: resultsCategories }));
    dispatch(errorsValidateReducer(true));
  };

  const formHandler = (e) => {
    const { target } = e;
    const dataName = target.dataset.name;
    if (dataName) {
      dispatch(creatChallengesReducer({ [dataName]: target.value }));
    }
  };

  const cardDelete = (id) => {
    setIsModalVisible(false);
    dispatch(requirementRemove({ id }));
  };

  const onChangeInterest = (id, interest) => {
    if (interest === null) {
      dispatch(requirementInterestChange({ id, interest: 0 }));
    } else if (interest.toString().includes('.')) {
      message.error(messagesErrorFetch(t('challenges.interest_errors')));
      dispatch(requirementInterestChange({ id, interest }));
    } else {
      dispatch(requirementInterestChange({ id, interest }));
    }
  };

  const onChangeNumber = (id, unitAmount) => {
    const unitMeters = numberMetersFunction(id, unitAmount);
    if (unitMeters.unit_amount.toString().length <= 8) {
      dispatch(requirementUnitChangeKm(numberMetersFunction(id, unitAmount)));
      dispatch(validateUnitAmountReducerDelete(id));
    } else {
      message.error(messagesErrorFetch(t('challenges.input_unit_errors')));
      dispatch(validateUnitAmountReducer(id));
    }
  };

  const deleteCard = (id) => {
    dispatch(requirementRemove({ id }));
  };
  const onChangeNumberKm = (id, unitAmount, measurementUnit) => {
    const unitKm = unitAmount * 1000;
    const unitAmountKm = numberKmFunction(measurementUnit, unitKm, id);
    if (unitAmountKm.unitKmDefault === '0.00') {
      message.error(messagesErrorFetch(t('challenges.push_errors_validate')));
      dispatch(requirementUnitChangeKm(numberKmFunction(measurementUnit, 1000, id)));
      dispatch(validateUnitAmountReducer(id));
    } else if (unitAmountKm.unit_amount.toString().length <= 8) {
      dispatch(requirementUnitChangeKm(numberKmFunction(measurementUnit, unitKm, id)));
      dispatch(validateUnitAmountReducerDelete(id));
    } else {
      message.error(messagesErrorFetch(t('challenges.input_unit_errors')));
      dispatch(validateUnitAmountReducer(id));
    }
  };

  const kmHandler = (card) => {
    dispatch(
      requirementAdd({
        card: {
          ...card,
          input: 'km',
        },
      }),
    );

    setIsModalVisible(false);
  };

  const meterHandler = (card) => {
    dispatch(
      requirementAdd({
        card: {
          ...card,
          input: 'meter',
        },
      }),
    );

    setIsModalVisible(false);
  };
  const examination = () => {
    const keys = Object.keys(creatChallenges);
    const res = requirements.filter((item) => item.unit_amount);
    const percentage = requirements.filter((item) => item.percentage);
    const validateInterest = requirements.filter((item) =>
      item.percentage.toString().includes('.'),
    );
    const exc = () => {
      for (const item of keys) {
        if (!creatChallenges[item]) {
          return false;
        }
      }
    };
    if (validateUnitAmount.length) {
      return false;
    }
    if (validateInterest.length) {
      return false;
    }
    if (exc()) {
      return false;
    }

    if (requirements.length > 3) {
      return false;
    }
    if (
      exc() === undefined &&
      res.length === requirements.length &&
      percentage.length === requirements.length
    ) {
      return true;
    }
  };

  const nextHandler = () => {
    push('/challenges/creatingChallenges/challengeConditions/UploadPhoto');
  };

  const onClick = (e) => {
    e.target.select();
  };

  const examinationCategory = () => {
    if (categoriesChose?.applicable_reqs.length === requirements.length) {
      return true;
    }
    return !categoriesChose;
  };

  if (loading) {
    return <SkeletonCategory />;
  }
  if (errors) {
    return <Errors status={errors} />;
  }

  return (
    <div>
      <div className='block_card_flex'>
        <div>
          <Form name='basic' layout='vertical' onChange={formHandler}>
            <div className={b()}>
              <BreadcrumbsComponent
                linkTobe={t('challenges.creating_challenges')}
                linkPush='/challenges/creatingChallenges'
                textEnd={t('challenges.challenge_conditions')}
              />
              <p className={b('title')}>{t('challenges.challenge_conditions')}</p>
              <p className={b('text')}>
                {t('challenges.create_your_challenges')} {t('challenges.interest')}
              </p>
              <div className='ConditionsForm'>
                <FormField
                  type='showSearchSelect'
                  label={t('challenges.category')}
                  name='category'
                  message='Please input your username!'
                  placeholder={t('challenges.category')}
                  onChangeChoose={onChangeChoose}
                  chooseCategory={creatChallenges.category}
                  disabled={loading}
                  options={results}
                />
              </div>
            </div>

            {requirements?.map((item) => {
              return (
                <FormField
                  input={item.input}
                  key={item.id}
                  type='inputInterest'
                  label={i18n?.language === 'ru' ? item?.name_ru : item?.name_en}
                  deleteCard={() => deleteCard(item.id)}
                  placeholder='challenges.field_placeholder'
                  min={item.input === 'meter' ? 0 : 1}
                  max={item.measurement_unit === 2 && item.input === 'meter' ? 99 : 80000}
                  onChangeNumber={(num) => onChangeNumber(item.id, num, item.measurement_unit)}
                  onChangeNumberKm={(num) => onChangeNumberKm(item.id, num, item.measurement_unit)}
                  onChangeInterest={(num) => onChangeInterest(item.id, num)}
                  onClick={onClick}
                  status={`${item.unit_amount}`}
                  statusInterest={item.percentage}
                  statusDefaultKm={item.unitKmDefault ? `${item.unitKmDefault}` : `${0}`}
                  nameLocales={nameLocales(item.measurement_unit, item.input)}
                  data={item}
                />
              );
            })}
            <Button
              onClick={modalHandler}
              disabled={examinationCategory()}
              style={{
                width: '512px',
                height: 44,
                fontWeight: 600,
                backgroundColor: examinationCategory() ? '#89BAEF' : '#DEEBFF',
                border: 'none',
                color: examinationCategory() ? '#ffffff' : '#007AFF',
                marginTop: 10,
                borderRadius: 8,
              }}
            >
              <span
                style={{
                  paddingRight: 10,
                }}
              >
                <PlusOutlined
                  style={{
                    fontSize: 16,
                  }}
                />
              </span>
              {t('challenges.add_conditions')}
            </Button>
            <div className={b('block_btn')}>
              <Button
                onClick={() => push('/challenges/creatingChallenges')}
                style={{
                  width: 129,
                  height: 44,
                  backgroundColor: 'rgba(0, 122, 255, 0.1)',
                  border: 'none',
                  color: '#200E32',
                  borderRadius: 8,
                }}
              >
                {t('promotions.back')}
              </Button>
              <div className={b('text_page')}>
                <span>
                  <span className='text_page_title'>{t('promotions.page')} 2</span>
                  <br />
                  <span className='text_page_text'>2 {t('challenges.from')} 3</span>
                </span>
              </div>
              <Button
                disabled={!(summaRequirement === 100 && creatChallenges?.category && examination())}
                onClick={nextHandler}
                style={{
                  width: 129,
                  height: 44,
                  backgroundColor:
                    summaRequirement === 100 && creatChallenges?.category && examination()
                      ? '#007AFF'
                      : '#89BAEF',
                  border: 'none',
                  color: '#ffffff',
                  borderRadius: 8,
                }}
                htmlType='submit'
              >
                {t('promotions.next')}
              </Button>
            </div>
          </Form>
        </div>
        <div>
          <ProgressComponents
            requirement={requirements}
            summaRequirement={summaRequirement}
            chooseCategory={creatChallenges.category}
          />
        </div>
      </div>
      <ModalComponent open={isModalVisible} onCancel={handleCancel} width={423} height='100%'>
        <ModalFormConditions
          meterHandler={meterHandler}
          kmHandler={kmHandler}
          results={categoriesChose}
          requirement={requirements}
          cardDelete={cardDelete}
        />
      </ModalComponent>
    </div>
  );
};

export default Conditions;
