import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';

const nameSpace = 'user';

const INITIAL_STATE = {
  user: null,
  tokens: null,
  success: null,
  loading: false,
  errors: null,
};

export const createUser = createAsyncThunk(
  `${nameSpace}/createUser`,
  async (userData, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.post('/accounts/registration/', userData);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  reducers: {
    clearUserState: (state) => {
      state.loading = false;
      state.success = false;
      state.commonError = null;
      state.errors = null;
      state.isPasswordUpdated = false;
    },
    clearUserFromUserState: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUser.pending, (state) => {
      state.user = null;
      state.tokens = null;
      state.loading = true;
      state.errors = null;
      state.success = false;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.user = payload.user;
      state.tokens = payload.tokens;
      state.loading = false;
      state.success = true;
      state.errors = null;
    });
    builder.addCase(createUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.commonError = payload?.detail;
      state.errors = payload;
    });
  },
});

export const userSelector = (state) => state.user;
export default userSlice.reducer;
