import { PlusOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import ResultsCard from 'components/ShowAllCard/ResultsCard/ResultsCard';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

const ShowAllCard = ({
  onChange = undefined,
  results = [],
  fileLoading = false,
  options = {},
  fileHandler = undefined,
  link = '',
  nextBrowserHandler = false,
  status = false,
  category = false,
  dataDisableEnd = false,
  requirements = false,
  textFile = '',
  titleFile = '',
  isNews = false,
}) => {
  ShowAllCard.propTypes = {
    onChange: PropTypes.func,
    results: PropTypes.instanceOf(Array),
    fileLoading: PropTypes.bool,
    options: PropTypes.instanceOf(Object),
    fileHandler: PropTypes.func,
    link: PropTypes.string,
    nextBrowserHandler: PropTypes.func,
    status: PropTypes.bool,
    category: PropTypes.bool,
    dataDisableEnd: PropTypes.bool,
    requirements: PropTypes.bool,
    textFile: PropTypes.string,
    titleFile: PropTypes.string,
    isNews: PropTypes.bool,
  };
  const b = bem('ShowAllCard');

  return (
    <div className={b()}>
      {fileLoading ? (
        <Link
          onClick={fileHandler}
          to={link}
          data-testid='create_file'
          className={
            requirements ? b('file_loading') : `${b('file_loading')} fileLoadingPromotions`
          }
        >
          <div className='block_loading'>
            <div className='create_file'>
              <div>
                <PlusOutlined
                  style={{
                    fontSize: 24,
                    color: '#007AFF',
                    fontWeight: 700,
                  }}
                />
              </div>
            </div>
            <div className='block_text'>
              <div>
                <p className='title_card'>{titleFile}</p>
                <p
                  className='text_card'
                  style={{
                    paddingTop: 10,
                  }}
                >
                  {textFile}
                </p>
              </div>
            </div>
          </div>
        </Link>
      ) : null}
      <ResultsCard
        isNews={isNews}
        results={results}
        options={options}
        onChange={onChange}
        nextBrowserHandler={nextBrowserHandler}
        category={category}
        dataDisableEnd={dataDisableEnd}
        requirements={requirements}
        status={status}
      />
    </div>
  );
};

export default ShowAllCard;
