import { Card } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';

const CardComponent = ({ cover = {}, children = [], className = '' }) => {
  CardComponent.propTypes = {
    cover: PropTypes.instanceOf(Object),
    children: PropTypes.instanceOf(Array),
    className: PropTypes.string,
  };
  const b = bem('CardComponent');

  return (
    <Card hoverable className={`${b('card-styles')} ${className}`} cover={cover}>
      {children}
    </Card>
  );
};

export default CardComponent;
