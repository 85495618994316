import bem from 'easy-bem';
import React from 'react';

import SkeletonButton from 'components/SkeletonBlock/FieldsSkeleton/SkeletonButton';
import SkeletonImage from 'components/SkeletonBlock/FieldsSkeleton/SkeletonImage';

const SkeletonUpdate = () => {
  const b = bem('SkeletonUpdate');
  return (
    <div data-testid='loading-update' className={b()}>
      <div>
        <SkeletonButton num={1} height={16} />
        <SkeletonButton num={2} height={30} marginTop={12} />
      </div>
      <div className={b('group')}>
        <SkeletonImage
          width={142}
          height={88}
          className={b('skeleton_img')}
          num={6}
          borderRadius={8}
          marginBottom={12}
        />
      </div>
      <SkeletonButton height={40} marginTop={20} borderRadius={8} num={1} />
      <SkeletonButton height={200} marginTop={20} borderRadius={8} num={1} />
      <SkeletonButton height={40} marginTop={20} borderRadius={8} num={1} />
      <div className={b('btn')}>
        <SkeletonButton width='134px' height={44} marginTop={20} borderRadius={8} num={2} />
      </div>
    </div>
  );
};

export default SkeletonUpdate;
