import PropTypes from 'prop-types';

const ShowAllCardStatus = ({ icon = '', name = '' }) => {
  ShowAllCardStatus.propTypes = {
    icon: PropTypes.string,
    name: PropTypes.string,
  };
  return (
    <div className='flex_block'>
      <div>
        <img src={icon} width={24} height={24} alt={name} />
      </div>

      <p className='text_icons_category'>{name}</p>
    </div>
  );
};

export default ShowAllCardStatus;
