import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { message } from 'antd';

import { messagesErrorFetch, messagesSuccess } from 'components/Messages/Messagetext';
import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';
import { errorsHandler, successText } from 'utils/errorsHandler/errorsHandler';
import toQueryParams from 'utils/toQueryParams';

const nameSpace = 'users';

const INITIAL_STATE = {
  users: null,
  usersLoading: false,
  usersError: null,
  participants: null,
  participantsLoading: null,
  participantsError: null,
  exportLoading: false,
  exportError: null,
  exportFile: null,
  comments: {
    createLoading: false,
    createError: null,
    editLoading: false,
    editError: null,
    deleteLoading: false,
    deleteError: null,
    success: false,
  },
  userComments: [],
};

export const usersFetch = createAsyncThunk(
  `${nameSpace}/users-fetch`,
  async ({ data }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/users/${query}`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const participantsFetch = createAsyncThunk(
  `${nameSpace}/participants-fetch`,
  async ({ data }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/challenges/participants${query}`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const exportParticipantsToCsv = createAsyncThunk(
  `${nameSpace}/export-participants-to-csv`,
  async ({ data }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/challenges/participants/csv/${query}`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const createUserComment = createAsyncThunk(
  `${nameSpace}/create-user-comment`,
  async (data, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.post(`/users/comments/`, data);
      message.success(messagesSuccess(successText('successAdd')));
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const editUserComment = createAsyncThunk(
  `${nameSpace}/edit-user-comment`,
  async (data, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.put(`/users/comments/${data.id}/`, { comment: data.comment });
      message.success(messagesSuccess(successText('updateComment')));
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const deleteUserComment = createAsyncThunk(
  `${nameSpace}/delete-user-comment`,
  async ({ id }, { rejectWithValue }) => {
    try {
      await axiosApi.delete(`/users/comments/${id}/`);
      message.success(messagesSuccess(successText('successDelete')));
      return {
        id,
      };
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

const usersSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  reducers: {
    exportNull: (state) => {
      state.exportLoading = false;
      state.exportError = null;
      state.exportFile = null;
    },
    commentsSuccessNull: (state) => {
      state.comments.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(usersFetch.pending, (state) => {
      state.usersLoading = true;
      state.usersError = null;
    });
    builder.addCase(usersFetch.fulfilled, (state, { payload }) => {
      state.usersLoading = false;
      state.users = payload;
      state.userComments = payload.comments;
      state.usersError = null;
    });
    builder.addCase(usersFetch.rejected, (state, { payload }) => {
      state.usersLoading = false;
      state.usersError = payload;
    });
    builder.addCase(participantsFetch.pending, (state) => {
      state.participantsLoading = true;
      state.participantsError = null;
    });
    builder.addCase(participantsFetch.fulfilled, (state, { payload }) => {
      state.participantsLoading = false;
      state.participants = payload;
      state.participantsError = null;
    });
    builder.addCase(participantsFetch.rejected, (state, { payload }) => {
      state.participantsLoading = false;
      state.participantsError = payload;
    });
    builder.addCase(exportParticipantsToCsv.pending, (state) => {
      state.exportLoading = true;
      state.exportError = null;
    });
    builder.addCase(exportParticipantsToCsv.fulfilled, (state, { payload }) => {
      state.exportLoading = false;
      state.exportFile = payload;
      state.exportError = null;
    });
    builder.addCase(exportParticipantsToCsv.rejected, (state, { payload }) => {
      state.exportLoading = false;
      state.exportError = payload;
    });
    builder.addCase(createUserComment.pending, (state) => {
      state.comments.createLoading = true;
      state.comments.createError = null;
    });
    builder.addCase(createUserComment.fulfilled, (state, { payload }) => {
      state.comments.createLoading = false;
      state.comments.createError = null;
      state.userComments = {
        ...state.userComments,
        [payload.user_id]: payload.comment,
      };
      state.comments.success = true;
    });
    builder.addCase(createUserComment.rejected, (state, { payload }) => {
      state.comments.createLoading = false;
      state.comments.createError = payload;
    });
    builder.addCase(editUserComment.pending, (state) => {
      state.comments.editLoading = true;
      state.comments.editError = null;
    });
    builder.addCase(editUserComment.fulfilled, (state, { payload }) => {
      state.comments.editLoading = false;
      state.comments.editError = null;
      state.userComments[payload.user_id] = payload.comment;
      state.comments.success = true;
    });
    builder.addCase(editUserComment.rejected, (state, { payload }) => {
      state.comments.editLoading = false;
      state.comments.editError = payload;
    });
    builder.addCase(deleteUserComment.pending, (state) => {
      state.comments.deleteLoading = true;
      state.comments.deleteError = null;
    });
    builder.addCase(deleteUserComment.fulfilled, (state, { payload }) => {
      state.comments.deleteLoading = false;
      state.comments.deleteError = null;
      const comments = state.userComments;
      delete comments[payload.id];
      state.userComments = comments;
      state.comments.success = true;
    });
    builder.addCase(deleteUserComment.rejected, (state, { payload }) => {
      state.comments.deleteLoading = false;
      state.comments.deleteError = payload;
    });
  },
});

export const { exportNull, commentsSuccessNull } = usersSlice.actions;
export const usersSelector = (state) => state.users;
export const commentsSelector = (state) => state.users.comments;
export default usersSlice.reducer;
