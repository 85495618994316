export const searchValidateBool = (pathname, searchTerm, link, num, name) =>
  pathname.includes(name) && searchTerm && link.length === num;

export const dispatchFetch = (dispatch, sort, searchTerm, action) => {
  const data = {
    query: { page: 1 },
  };
  return dispatch(
    action({
      data,
      sort,
      name: `&name=${searchTerm}`,
    }),
  );
};
