import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import localesUtils from 'utils/localesUtils/localesUtils';
import 'components/Fields/NumberField/_numberField.scss';

const NumberField = ({
  id = '',
  type = '',
  label = '',
  name = '',
  dataName = '',
  placeholder = '',
  width = '',
  max = 0,
  disabled = false,
}) => {
  NumberField.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    dataName: PropTypes.string,
    width: PropTypes.string,
    placeholder: PropTypes.string,
    max: PropTypes.number,
    disabled: PropTypes.bool,
  };

  const validateNumber = (_, value) => {
    if (value && (isNaN(value) || (value && !/^\d+$/.test(value)))) {
      return Promise.reject(
        new Error(localesUtils(`Введите действительное целое число`, `Enter a valid integer`)),
      );
    }

    if (value && (value < 1 || value > max)) {
      return Promise.reject(
        new Error(
          localesUtils(`Введите число от 1 до ${max}`, `Enter a number between 1 and ${max}`),
        ),
      );
    }
    return Promise.resolve();
  };

  return (
    <Form.Item
      type={type}
      id={id}
      label={label}
      name={name}
      style={{ lineHeight: 'normal' }}
      rules={[
        {
          validator: validateNumber,
        },
      ]}
    >
      <Input
        style={{ width, paddingBottom: '7px', ':disabled': { color: 'red' } }}
        data-name={dataName}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default NumberField;
