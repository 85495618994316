import { PlusOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import blockIcons from 'assets/images/icons/blockIcons.svg';
import dragSvg from 'assets/images/icons/drag.svg';
import PhotosAdd from 'components/DownloadPhoto/PhotosAdd/PhotosAdd';

const DownloadPhotosPromotion = ({
  photos = [],
  drag = false,
  dragHandler = undefined,
  deletePhotoHttps = undefined,
  dragLeaveHandler = undefined,
  onDropHandler = undefined,
  photosSaveHandler = undefined,
  deletePhotoHandler = undefined,
  title = true,
  maxPhotos = 5,
  isNews = false,
}) => {
  DownloadPhotosPromotion.propTypes = {
    photos: PropTypes.instanceOf(Array),
    drag: PropTypes.bool,
    dragHandler: PropTypes.func,
    deletePhotoHttps: PropTypes.func,
    dragLeaveHandler: PropTypes.func,
    onDropHandler: PropTypes.func,
    photosSaveHandler: PropTypes.func,
    deletePhotoHandler: PropTypes.func,
    title: PropTypes.bool,
    maxPhotos: PropTypes.number,
    isNews: PropTypes.bool,
  };
  const { t } = useTranslation();
  const b = bem('photos_download_events');
  if (drag && !isNews) {
    return (
      <div
        data-testid='elem-drag'
        className='flex_drag'
        onDragStart={(e) => dragHandler(e)}
        onDragLeave={(e) => dragLeaveHandler(e)}
        onDragOver={(e) => dragHandler(e)}
        onDrop={(e) => onDropHandler(e)}
      >
        <div>
          <div>
            <img src={dragSvg} alt='dragSvg' />
          </div>
          <h2>{t('promotions.drag_your_image')}</h2>
          <p>{t('promotions.up_time')}</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={b()}
      onDragStart={(e) => dragHandler(e)}
      onDragLeave={(e) => dragLeaveHandler(e)}
      onDragOver={(e) => dragHandler(e)}
      onDrop={(e) => onDropHandler(e)}
    >
      {photos?.length && title ? (
        <p className={b('text_edit')}>{t('promotions.editing_promotion')}</p>
      ) : null}

      {photos?.length || isNews ? (
        <div className={b('save_photos_down2')}>
          {photos?.length > maxPhotos ? null : (
            <div>
              <label className='btn_file' htmlFor='upload-photo'>
                <input
                  min={0}
                  onChange={photosSaveHandler}
                  type='file'
                  name='photo'
                  id='upload-photo'
                  accept='image/x-png,image/gif,image/jpeg'
                  multiple
                />
                <div>
                  <div className='icons_file'>
                    <PlusOutlined
                      style={{
                        color: '#007AFF',
                        fontSize: 16,
                        fontWeight: 700,
                      }}
                    />
                  </div>

                  <p className='text_select_none'>{t('promotions.add_photo')}</p>
                </div>
              </label>
            </div>
          )}

          {photos?.map((item, i) => {
            return (
              <PhotosAdd
                key={item.id ? item.id : i}
                deletePhotoHttps={deletePhotoHttps}
                deletePhoto={deletePhotoHandler}
                photosHttp={item.image ? item.image : ''}
                photosCreate={!item.image ? item : {}}
                id={item.id ? item.id : i}
              />
            );
          })}
        </div>
      ) : (
        <div className={b('DragYourPhoto')}>
          <div>
            <div className='block-icons'>
              <img src={blockIcons} alt='blockIcons' className='block_photos' />
            </div>
            <h2 className='text_select_none'>{t('promotions.drag_your_image')}</h2>
            <div>
              <label className='btn_file text_select_none' htmlFor='upload-photo'>
                <input
                  onChange={photosSaveHandler}
                  type='file'
                  name='photo'
                  id='upload-photo'
                  accept='image/x-png,image/gif,image/jpeg'
                  multiple
                />
                {t('promotions.download_photo')}
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadPhotosPromotion;
