import { Button } from 'antd';
import bem from 'easy-bem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import pageNotFoundIcons from 'assets/images/404Page_not_found.svg';

const PageNotFound = () => {
  const b = bem('PageNotFound');
  const push = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <div className={b('block_pageNotFound')}>
        <div>
          <div>
            <img src={pageNotFoundIcons} alt='PageNotFound' width={328} height={328} />
          </div>
          <p className='title_PageNotFound'>{t('errors.not_found_page')}</p>
          <p className='text_PageNotFound'>{t('errors.text_not_found_page')}</p>
          <div className={b('button')}>
            <Button onClick={() => push('/admin')} type='primary'>
              {t('errors.to_main')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
