import { Button, Form, message } from 'antd';
import dayjs from 'dayjs';
import bem from 'easy-bem';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import DownloadPhoto from 'components/DownloadPhoto/DownloadPhoto';
import Errors from 'components/Errors/Errors';
import FormField from 'components/FormField/FormField';
import Loading from 'components/Loading/Loading';
import {
  messagesError,
  messagesErrorFetch,
  messagesSuccess,
} from 'components/Messages/Messagetext';
import {
  errorsValidateNullReducer,
  errorsValidateReducer,
} from 'redux/historyNotifications/historyNotifications';
import {
  dateValidateReducer,
  deleteImagesNull,
  deletePhotosHttpsStarts,
  deleteUpdateStartsPhoto,
  editStartsAddReducer,
  editStartsReducer,
  startsEditSelector,
  startsInfoFetch,
  startsResultsInfoSelector,
  startsSelector,
  startsUpdatePhotoDownload,
  startUpdate,
  updateDate,
  updateStartsDate,
} from 'redux/startsSlice/startsSlice';
import { checkHandlerFor, filesValidateImg } from 'utils/constants';
import {
  dateMomentFormat,
  datePickerFormat,
  sundayDate,
  sundayEnd,
} from 'utils/dateFormat/dateFormat';
import deleteTagHtml from 'utils/deleteTagHtml/deleteTagHtml';
import { successText } from 'utils/errorsHandler/errorsHandler';
import filesValidate from 'utils/filesValidate/filesValidate';
import localesUtils from 'utils/localesUtils/localesUtils';
import localesValidateInput from 'utils/localiesValidateInput/localiesValidateInput';
import { updateStartsValidate } from 'utils/updateValidate/updateValidate';

const Update = () => {
  const { t } = useTranslation();
  const b = bem('UpdateStarts');
  const push = useNavigate();
  const { id } = useParams();
  const history = useNavigate();
  const [form] = Form.useForm();
  const {
    updateStartsData,
    editStarts,
    results,
    dateValidate,
    updateStartsNavigate,
    updatePutStartLoading,
  } = useSelector(startsSelector);
  const { resultsInfoStarts } = useSelector(startsResultsInfoSelector);
  const resultsObj = results.find((item) => item.id === +id) || resultsInfoStarts;
  const { loading, errors } = useSelector(startsResultsInfoSelector);
  const { images } = useSelector(startsEditSelector);
  const [drag, setDrag] = useState(false);
  const [validateForm, setValidateForm] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!results.length) {
      dispatch(startsInfoFetch({ id }));
    }
  }, [dispatch, results.length, id]);

  useEffect(() => {
    dispatch(deleteImagesNull());
  }, []);

  useEffect(() => {
    if (resultsObj) {
      dispatch(editStartsAddReducer(resultsObj));
    }
  }, [dispatch, resultsObj]);

  useEffect(() => {
    if (resultsObj) {
      form.setFieldsValue({
        name: resultsObj?.name,
        description: resultsObj?.description,
        url: resultsObj?.url,
        button_text: resultsObj?.button_text,
      });
    }
  }, [form, resultsObj]);

  useEffect(() => {
    if (updateStartsNavigate) {
      history(`/starts/starts-info/${id}/`);
      message.success(messagesSuccess(successText('updateStarts')));
    }
  }, [updateStartsNavigate]);

  useEffect(() => {
    const resultsUpdate = resultsObj;
    if (resultsUpdate) {
      const validate = updateStartsValidate(resultsUpdate, editStarts, editStarts.deleted_images);
      setValidateForm(validate);
      if (validate) {
        dispatch(errorsValidateNullReducer());
      } else {
        dispatch(errorsValidateReducer(true));
      }
    }
  }, [editStarts, updateStartsData]);

  const handleDateChange = (date) => {
    if (date) {
      const datePicker = {
        date: datePickerFormat(date),
      };
      dispatch(
        updateDate({
          data: datePicker,
        }),
      );

      if (date && dayjs(date).isBefore(editStarts?.registration_ends)) {
        dispatch(dateValidateReducer(true));

        return message.error(messagesError(t('errors.date_should_be_after_register_end')));
      }

      dispatch(dateValidateReducer(false));
      dispatch(errorsValidateReducer(true));
    } else if (date === null) {
      message.error(messagesErrorFetch(t('errors.add_date')));
      dispatch(dateValidateReducer(true));
    } else {
      const datePicker = {
        date: sundayDate(),
      };
      updateDate(
        date({
          data: datePicker,
        }),
      );

      dispatch(dateValidateReducer(false));
    }
  };

  const dateHandler = (date) => {
    if (date) {
      const datePicker = {
        registration_starts: datePickerFormat(date[0]),
        registration_ends: datePickerFormat(date[1]),
      };
      dispatch(
        updateStartsDate({
          data: datePicker,
        }),
      );

      if (date && dayjs(date[1]).isAfter(editStarts?.date)) {
        dispatch(dateValidateReducer(true));

        return message.error(messagesError(t('errors.date_should_be_after_register_end')));
      }

      dispatch(dateValidateReducer(false));
    } else if (date === null) {
      message.error(messagesErrorFetch(i18n?.language === 'ru' ? 'Выберите дату' : 'Add date'));
      dispatch(dateValidateReducer(true));
    } else {
      const datePicker = {
        registration_starts: sundayDate(),
        registration_ends: sundayEnd(),
      };
      dispatch(
        updateStartsDate({
          data: datePicker,
        }),
      );

      dispatch(dateValidateReducer(false));
    }
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];
    const ret = filesValidate(files, updateStartsData.images, images);
    checkHandlerFor(startsUpdatePhotoDownload, ret);
    setDrag(false);
  };

  const deletePhotoHandler = (id) => {
    dispatch(deleteUpdateStartsPhoto(id));
  };

  const deletePhotoHttps = (id) => {
    dispatch(deletePhotosHttpsStarts(id));
  };

  const dragHandler = (e) => {
    e.preventDefault();
    if (images.length <= 5) {
      setDrag(true);
    }
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const photosSaveHandler = (e) => {
    const { files } = e.target;
    if (filesValidateImg(files).length) {
      const ret = filesValidate(files, updateStartsData.images, images);
      checkHandlerFor(startsUpdatePhotoDownload, ret);
    }
  };

  const formHandler = (e) => {
    const { target } = e;
    const dataName = target.dataset.name;
    dispatch(editStartsReducer({ [dataName]: target.value }));
    if (target.value) {
      dispatch(errorsValidateReducer(true));
    }
  };

  const handleChange = (content) => {
    if (!content.slice(0, 11).includes('<p><br></p>')) {
      dispatch(errorsValidateReducer(true));
      dispatch(editStartsReducer({ description: content }));
    } else {
      dispatch(editStartsReducer({ description: '' }));
    }
  };

  const onFinish = () => {
    if ([...images, ...updateStartsData.images].length < 1) {
      message.error(messagesError(t('errors.download_no_more_than_two_photos')));
    } else {
      const editObj = {
        ...editStarts,
      };
      delete editObj.images;

      if (editStarts?.images.length) {
        editStarts.images.forEach((item, i) => {
          editObj[`images[${i}]image`] = item;
        });
      }

      if (editStarts.deleted_images?.length) {
        editStarts.deleted_images.forEach((item, i) => {
          editObj[`deleted_images[${i}]`] = item;
        });
      }

      const formData = new FormData();
      for (const name in editObj) {
        if (name) {
          formData.append(name, editObj[name]);
        }
      }

      const data = {
        startId: id,
        newData: formData,
      };

      dispatch(startUpdate(data));
    }
  };

  const cancelHandler = () => {
    push(`/starts/starts-info/${id}`);
  };

  if (loading) {
    return <Loading />;
  }

  if (errors) {
    return <Errors status={errors} />;
  }

  return (
    <div className={b()}>
      <div className={b('block')}>
        <BreadcrumbsComponent
          linkProps
          textEnd={t('starts.edit')}
          componentName='starts.name'
          pushHandler='/starts'
        />
        <div className={b('text')}>
          <h4 data-testid='update-starts-form-title'>{t('starts.edit')}</h4>
          <p>{t('starts.edit_start')}</p>
        </div>

        <DownloadPhoto
          photos={[...updateStartsData.images, ...images]}
          drag={drag}
          onDropHandler={onDropHandler}
          deletePhotoHandler={deletePhotoHandler}
          deletePhotoHttps={deletePhotoHttps}
          dragHandler={dragHandler}
          dragLeaveHandler={dragLeaveHandler}
          photosSaveHandler={photosSaveHandler}
          title={false}
        />
        <Form form={form} name='basic' layout='vertical' onChange={formHandler} onFinish={onFinish}>
          <FormField
            type='modificationInput'
            label='starts.title_label'
            name='name'
            message={localesUtils(
              'Пожалуйста, введите название старта',
              'Please input your starts title',
            )}
            dataName='name'
            placeholder='starts.title_label'
          />
          <FormField
            id='description_id'
            rules={[
              {
                required: true,
                message: localesValidateInput(
                  <span id='promotionsDescription'>{t('errors.enter_description')}</span>,
                  'promotionsDescription',
                  t('errors.enter_description'),
                ),
              },
              {
                max: 1200,
                message: localesValidateInput(
                  <span id='promotionsDescriptionSecond'>
                    {t('errors.description_should_be_less_than_1200_character')}
                  </span>,
                  'promotionsDescriptionSecond',
                  t('errors.description_should_be_less_than_1200_character'),
                ),
              },
            ]}
            label={t('starts.description_label')}
            placeholder={t('starts.description_label')}
            type='editor'
            name='description'
            onChange={(content) => handleChange(content)}
          />
          <FormField
            type='datePicker'
            label='starts.select_date'
            marginTop='-10px'
            disableDate={false}
            creatObj={resultsObj || editStarts}
            message='Please input your date picker!'
            dateChangeHandler={handleDateChange}
            dateFormat={dateMomentFormat}
            width='100%'
            height='34px'
            borderRadius={8}
          />
          <FormField
            type='dateFormRangePicker'
            label='starts.register_dates'
            marginTop='-10px'
            disableDate={false}
            creatObj={resultsObj || editStarts}
            message='Please input your date picker!'
            dateHandler={dateHandler}
            dateFormat={dateMomentFormat}
            width='100%'
            height='34px'
            borderRadius={8}
            startLabel='registration_starts'
            endLabel='registration_ends'
          />
          <FormField
            type='text'
            id='url_id'
            dataName='url'
            className={b('input-form-style input-text-form-style')}
            inputClassName={b('border-input-style')}
            name='url'
            label={t('starts.url_label')}
            placeholder={t('starts.url_label')}
            dependencies={['button_text']}
            textErrorsRu='Поле `Текст для кнопки` должно быть заполнено'
            textErrorsEn='Field `Button text` must be field'
            rules={[
              {
                required: true,
                type: 'url',
                message: localesValidateInput(
                  <span id='promotionsButtonText'>
                    {t('errors.this_field_must_be_a_valid_url')}
                  </span>,
                  'promotionsButtonText',
                  t('errors.this_field_must_be_a_valid_url'),
                ),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const buttonText = getFieldValue(['button_text']);
                  if (value !== '' && buttonText === '') {
                    return Promise.reject(new Error(t('errors.button_link_text')));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          />

          <FormField
            type='text'
            dataName='button_text'
            id='button_text_id'
            className={b('input-form-style')}
            inputClassName={b('border-input-style')}
            label={t('promotions.button_text')}
            placeholder={t('promotions.button_text')}
            name='button_text'
            dependencies={['url']}
            textErrorsRu='Поле `Добавить ссылку` должно быть заполнено'
            textErrorsEn='Field `Add link` must be field'
            rules={[
              {
                required: true,
                max: 20,
                message: localesValidateInput(
                  <span id='promotionsButtonText2'>{t('errors.button_error_max_text')}</span>,
                  'promotionsButtonText2',
                  t('errors.button_error_max_text'),
                ),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const urlLink = getFieldValue(['url']);
                  if (value !== '' && urlLink === '') {
                    return Promise.reject(new Error(t('errors.button_error_text')));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          />
          <div className={b('btn')}>
            <Button
              onClick={cancelHandler}
              style={{
                width: 129,
                height: 44,
                backgroundColor: 'rgba(0, 122, 255, 0.1)',
                borderRadius: 8,
                color: '#200E32',
                marginRight: 16,
                border: 'none',
              }}
            >
              {t('promotions.cancel')}
            </Button>
            <Form.Item
              data-testid='edit-submit'
              className={b('btn_form')}
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button
                loading={updatePutStartLoading}
                disabled={
                  ![...updateStartsData.images, ...images].length ||
                  validateForm ||
                  dateValidate ||
                  !deleteTagHtml(editStarts.description)
                }
                style={{
                  width: 129,
                  height: 44,
                  backgroundColor:
                    ![...updateStartsData.images, ...images].length ||
                    validateForm ||
                    dateValidate ||
                    !deleteTagHtml(editStarts.description)
                      ? 'rgb(137, 186, 239)'
                      : '#007AFF',
                  borderRadius: 8,
                  color: 'white',
                  border: 'none',
                }}
                type='primary'
                htmlType='submit'
              >
                {t('promotions.save')}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Update;
