import { LoadingOutlined } from '@ant-design/icons';
import { Drawer, Form, Input } from 'antd';
import bem from 'easy-bem';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import closeIcons from 'assets/images/icons/close.svg';
import noAvatar from 'assets/images/photos/no_avatar.png';
import {
  commentsSelector,
  commentsSuccessNull,
  createUserComment,
  deleteUserComment,
  editUserComment,
  usersSelector,
} from 'redux/users/usersSlice';
import { dateTimeFormat, dateTimeToFormat } from 'utils/dateFormat/dateFormat';

const DrawerProfile = ({ onClose = undefined, open = false, recordData = {} }) => {
  DrawerProfile.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    recordData: PropTypes.instanceOf(Object),
  };
  const b = bem('DrawerProfile');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createLoading, editLoading, deleteLoading, success } = useSelector(commentsSelector);
  const [comment, setComment] = useState('');
  const { userComments } = useSelector(usersSelector);

  useEffect(() => {
    if (recordData?.id) {
      Object.keys(userComments).includes(recordData?.id.toString())
        ? setComment(userComments[recordData?.id])
        : setComment('');
    }
  }, [recordData, userComments]);

  useEffect(() => {
    if (success) {
      onClose();
      dispatch(commentsSuccessNull());
    }
  }, [success]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const userHasComment = Object.keys(userComments).includes(recordData?.id.toString());
      e.preventDefault();
      if (comment && !userHasComment) {
        dispatch(createUserComment({ user_id: recordData?.id, comment: e.target.value }));
      } else if (comment && userHasComment) {
        dispatch(editUserComment({ id: recordData?.id, comment: e.target.value }));
      } else if (!comment && userHasComment) {
        dispatch(deleteUserComment({ id: recordData?.id }));
      }
    }
  };

  return (
    <Drawer placement='right' onClose={onClose} open={open} closable={false}>
      <div className={b()}>
        <div className={b('avatar_block')}>
          <div className='avatar_photos'>
            <img
              src={recordData?.avatar_filename ? recordData.avatar_filename : noAvatar}
              alt='avatar'
            />
            <p>
              {recordData?.first_name} {recordData?.last_name}
            </p>
          </div>
          <button type='button' className='block_close' onClick={onClose}>
            <img src={closeIcons} alt='close' />
          </button>
        </div>
        <div
          className={b('form_input')}
          style={{
            marginTop: 20,
          }}
        >
          <Form layout='vertical'>
            <Form.Item label={t('home.phone_number')}>
              <Input
                disabled
                placeholder={t('home.phone_number')}
                value={recordData?.phone}
                style={{
                  backgroundColor: '#FAFAFF',
                  border: 'none',
                }}
              />
            </Form.Item>
            <Form.Item label={t('challenges.email')}>
              <Input
                disabled
                placeholder={t('challenges.email')}
                value={recordData?.email}
                style={{
                  backgroundColor: '#FAFAFF',
                  border: 'none',
                }}
              />
            </Form.Item>
          </Form>
        </div>
        <div
          className={b('form_input')}
          style={{
            marginTop: 24,
          }}
        >
          <Form layout='vertical'>
            <Form.Item label={t('challenges.date_of_birth')}>
              <Input
                disabled
                placeholder={t('challenges.date_of_birth')}
                value={dateTimeToFormat(recordData?.date_of_birth, dateTimeFormat)}
                style={{
                  backgroundColor: '#FAFAFF',
                  border: 'none',
                }}
              />
            </Form.Item>
          </Form>
        </div>
        <div
          className={b('form_input')}
          style={{
            marginTop: 24,
          }}
        >
          <Form layout='vertical'>
            <Form.Item label={t('challenges.date_of_registration')}>
              <Input
                disabled
                placeholder={t('challenges.date_of_registration')}
                value={dateTimeToFormat(recordData?.registered_on, dateTimeFormat)}
                style={{
                  backgroundColor: '#FAFAFF',
                  border: 'none',
                }}
              />
            </Form.Item>
          </Form>
        </div>
        <div
          className={b('form_input')}
          style={{
            marginTop: 24,
          }}
        >
          <Form layout='vertical'>
            <Form.Item label='Strava'>
              <Input
                disabled
                placeholder='Strava'
                value={recordData?.strv_integration_allowed ? 'Привязан' : 'Не привязан'}
                style={{
                  backgroundColor: '#FAFAFF',
                  border: 'none',
                }}
              />
            </Form.Item>
          </Form>
        </div>
        <div
          className={b('form_input')}
          style={{
            marginTop: 24,
          }}
        >
          <Form layout='vertical'>
            <Form.Item label={t('challenges.comment')}>
              <Input
                showCount
                prefix={createLoading || editLoading || deleteLoading ? <LoadingOutlined /> : null}
                disabled={createLoading || editLoading || deleteLoading}
                allowClear
                maxLength={50}
                placeholder={t('challenges.comment')}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                style={{
                  backgroundColor: '#FAFAFF',
                  border: 'none',
                }}
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerProfile;
