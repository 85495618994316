import { message } from 'antd';

import { messagesError } from 'components/Messages/Messagetext';
import localesUtils from 'utils/localesUtils/localesUtils';

const filesValidate = (files = [], imagesHttp = [], update = []) => {
  const filesCopyID = [...files];
  const filesCopy = filesCopyID.map((item) => {
    const file = item;
    file.id = Math.floor(new Date().valueOf() * Math.random());
    return file;
  });
  const filesImages = [...files, ...imagesHttp, ...update];
  const fileSize = filesCopy.filter(
    (item) =>
      +item.size.toString() <= 5000000 &&
      (item.type.includes('image/jpeg') || item.type.includes('image/png')),
  );
  const fileError = filesCopy.filter((item) => +item.size.toString() >= 5000000);
  const fileErrorType = filesCopy.filter(
    (item) => item.type.includes('image/jpeg') || item.type.includes('image/png'),
  );
  if (fileError.length) {
    message.error(
      messagesError(localesUtils('Размер изображения слишком большой', 'Picture size is too big')),
    );
  }
  if (!fileErrorType.length) {
    message.error(
      messagesError(localesUtils('Неверный формат изображение', 'Invalid image format')),
    );
  }
  if (filesImages.length < 7) {
    return fileSize;
  }
  const mas = [...imagesHttp, ...update];
  const filterFiles = [...fileSize];
  const num = 6 - mas.length;
  const f = filterFiles.filter((item, i) => i <= num - 1);
  message.error(
    messagesError(
      localesUtils(
        'Вы не можете загрузить больше 6 фотографий',
        'You cannot upload more than 6 photos',
      ),
    ),
  );
  return f;
};

export default filesValidate;
