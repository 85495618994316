import { ConfigProvider, Form, Radio, Table } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router';

import CustomizeRenderEmpty from 'components/CustomizeRenderEmpty/CustomizeRenderEmpty';
import PaginationComponents from 'components/PaginationComponents/PaginationComponents';
import SearchTeams from 'components/TeamsTable/SearchTeams/SearchTeams';
import { setSearchReducer, teamInfoNull, teamsSelector } from 'redux/teams/teamsSlice';

import { deleteEmptyQueryStrings, iconsTable } from 'utils/constants';
import { datePickerFormat, dateTimeFormat, dateTimeToFormat } from 'utils/dateFormat/dateFormat';

const TeamsTable = ({ teamsSearchFetch = undefined, teamsList = undefined, hasSearch = true }) => {
  TeamsTable.propTypes = {
    teamsList: PropTypes.instanceOf(Array),
    teamsSearchFetch: PropTypes.func,
    hasSearch: PropTypes.bool,
  };
  const b = bem('TableList');
  const push = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { teamsLoading, results, search } = useSelector(teamsSelector);
  const [size, setSize] = useState('large');
  const totalPages = results && Math.ceil(results.total / results.size);

  const [filters, setFilters] = useState({
    page: 1,
    size: 10,
    created_at_after: '',
    created_at_before: '',
  });

  const [sort, setSort] = useState('');

  useEffect(() => {
    const queryObj = {
      page: filters.page,
      size: filters.size,
      created_at_after: filters.created_at_after,
      created_at_before: filters.created_at_before,
      name: search.name,
    };

    if (sort) {
      queryObj.sort = sort;
    }

    const validateQuery = deleteEmptyQueryStrings(queryObj);
    const data = {
      query: validateQuery,
    };
    const delay = setTimeout(() => {
      dispatch(teamsSearchFetch({ data }));
    }, 500);
    return () => clearTimeout(delay);
  }, [
    dispatch,
    filters.page,
    filters.size,
    filters.created_at_after,
    filters.created_at_before,
    search.name,
    sort,
  ]);

  const columns = [
    {
      key: 'team',
      title: <div className='block_text_table'>{t('teams.team_name')}</div>,
      dataIndex: 'name',
      fixed: 'left',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'organization',
      title: <div className='block_text_table'>{t('teams.organization_name')}</div>,
      dataIndex: 'organization',
      fixed: 'left',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <span>{record.organization_name}</span>,
    },
    {
      key: 'registered_on',
      title: <div className='block_text_table'>{t('teams.date_of_registration')}</div>,
      dataIndex: 'registered_on',
      fixed: 'left',
      render: (text, record) => <span>{dateTimeToFormat(record.created_at, dateTimeFormat)}</span>,
    },
    {
      key: 'members_count',
      title: <div className='block_text_table'>{t('teams.number_of_participants')}</div>,
      dataIndex: 'members_count',
      fixed: 'left',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <span>{record.members_count}</span>,
    },
  ];

  const onChangeRegisterDateHandler = (date) => {
    if (date) {
      const after = datePickerFormat(date[0]);
      const before = datePickerFormat(date[1]);
      setFilters({ ...filters, page: 1, created_at_after: after, created_at_before: before });
    } else {
      setFilters({ ...filters, page: 1, created_at_after: '', created_at_before: '' });
    }
  };

  const searchHandler = (e) => {
    const queryObj = {
      name: '',
    };
    const validateQuery = deleteEmptyQueryStrings(queryObj);
    const data = {
      query: validateQuery,
    };
    dispatch(setSearchReducer(e?.toLowerCase()));

    if (!e?.length && e !== undefined) {
      dispatch(teamsSearchFetch({ data }));
    }
  };

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const handleChange = (value) => {
    setFilters({ ...filters, page: 1, size: value });
  };

  const pagePrevHandler = () => {
    setFilters({ ...filters, page: filters.page - 1 });
  };

  const pageNextHandler = () => {
    setFilters({ ...filters, page: filters.page + 1 });
  };

  const tableProps = {
    size,
  };

  const handleRowClick = (record) => {
    dispatch(teamInfoNull());
    push(`/teams/teams-info/${record.id}`);
  };

  const handleTableSortChange = (pagination, filters, sorter) => {
    setSort(`${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`);
  };

  return (
    <div className='container_block'>
      {hasSearch ? (
        <SearchTeams
          onChangeRegisterDateHandler={onChangeRegisterDateHandler}
          searchHandler={searchHandler}
          value={search.name}
        />
      ) : null}

      <div className={b()} data-testid='teams_list'>
        <div className={b('title')}>
          <p>{t('teams.list_of_teams')}</p>
          <div>
            <Form.Item>
              <Radio.Group value={size} onChange={handleSizeChange}>
                <Radio.Button
                  style={{
                    border: 'none',
                  }}
                  value='large'
                >
                  <span className={size}>{iconsTable.large(size)}</span>
                </Radio.Button>
                <Radio.Button
                  style={{
                    border: 'none',
                  }}
                  value='middle'
                >
                  <span className={size}>{iconsTable.middle(size)}</span>
                </Radio.Button>
                <Radio.Button
                  style={{
                    border: 'none',
                  }}
                  value='small'
                >
                  <span className={size}>{iconsTable.small(size)}</span>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
        <ConfigProvider renderEmpty={() => CustomizeRenderEmpty}>
          <Table
            loading={teamsLoading}
            {...tableProps}
            style={{ cursor: 'pointer' }}
            bordered
            columns={columns}
            pagination={false}
            dataSource={teamsList}
            rowKey={(record) => record.id}
            scroll={{
              x: 950,
            }}
            onChange={handleTableSortChange}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record),
              };
            }}
          />
        </ConfigProvider>
        <PaginationComponents
          usersList={results}
          usersParams={filters}
          numUsersPagination={filters}
          inputOnChangeHandler={(e) => setFilters({ ...filters, page: Number(e.target.value) })}
          handleChange={handleChange}
          pagePrevHandler={pagePrevHandler}
          pageNextHandler={pageNextHandler}
          totalUsersPages={totalPages}
        />
      </div>
    </div>
  );
};

export default TeamsTable;
