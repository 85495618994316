import { HolderOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Status from 'components/Status/Status';

const SortBy = ({ statusHandler = undefined, titleObject = {} }) => {
  SortBy.propTypes = {
    statusHandler: PropTypes.func,
    titleObject: PropTypes.instanceOf(Object),
  };
  const b = bem('Challenges');
  const { t } = useTranslation();

  const items = [
    {
      key: '2',
      label: (
        <button type='button' className='Status__block_status'>
          {t('challenges.all')}
        </button>
      ),
      icon: <HolderOutlined />,
    },
    {
      key: '0',
      label: (
        <button type='button' className='Status__block_status'>
          {t('challenges.active')}
        </button>
      ),
      icon: <PlusCircleOutlined />,
    },
    {
      key: '1',
      label: (
        <button type='button' className='Status__block_status'>
          {t('challenges.inactive')}
        </button>
      ),
      icon: <MinusCircleOutlined />,
    },
  ];
  return (
    <div className={b('text_challenges')}>
      <div className={b('block_category')}>
        <div className='status'>
          <span>{t('challenges.filter')}</span>
          <Status
            items={items}
            title='challenges.status'
            onClick={statusHandler}
            defaultSelectedKeys={[titleObject.status.id || '2']}
          />
        </div>
      </div>
    </div>
  );
};

export default SortBy;
