import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Divider, Space } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';

import { useTranslation } from 'react-i18next';

import FormField from 'components/FormField/FormField';

const SearchTeams = ({
  onChangeRegisterDateHandler = undefined,
  searchHandler = undefined,
  value = '',
}) => {
  SearchTeams.propTypes = {
    onChangeRegisterDateHandler: PropTypes.func,
    searchHandler: PropTypes.func || undefined,
    value: PropTypes.string,
  };
  const b = bem('search_table');
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <div className={b('search_input')}>
        <div className='block_flex_search'>
          <AutoComplete
            allowClear
            className='bg_input'
            size='large'
            placeholder={t('search.search_teams')}
            prefix={<SearchOutlined />}
            onChange={searchHandler}
            value={value}
            style={{
              borderRadius: 8,
              width: '100%',
              height: 40,
              backgroundColor: '#F4F6FF',
              border: 'none',
            }}
          />
          <Divider />
          <div className={b('date_table')}>
            <div>
              <div className='label_text'>{t('challenges.date_of_registration')}</div>
              <div className='date_input'>
                <Space direction='vertical' size={12}>
                  <FormField
                    type='rangePicker'
                    format='DD-MM-YYYY'
                    onChange={onChangeRegisterDateHandler}
                  />
                </Space>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTeams;
