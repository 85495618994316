import { Button, Checkbox, message } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import DownloadPhoto from 'components/DownloadPhoto/DownloadPhoto';
import { messagesError } from 'components/Messages/Messagetext';
import { authSelector } from 'redux/auth/authSlice';
import {
  challengesCreateDataSelector,
  challengesDragHandler,
  challengesPhotoDownload,
  challengesSelector,
  clearResultsChallenges,
  createChallengesData,
  createChallengesFetch,
  deleteChallengesPhoto,
  historyNull,
  nullSort,
} from 'redux/challengesSlice/challengesSlice';
import {
  errorsValidateReducer,
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import {
  clearPromotionsResultsSort,
  deletePromotionPhoto,
  promotionCreateFetch,
  promotionDragHandler,
  promotionPhotoDownload,
  promotionsCreateDataSelector,
  promotionsSelector,
} from 'redux/promotions/promotionsSlice';
import {
  searchTermPromoReducer,
  searchTermReducer,
  searchTermStartReducer,
} from 'redux/search/searchSlice';
import {
  deleteStartsPhoto,
  startsCreateFetch,
  startsCreateSelector,
  startsDragHandler,
  startsPhotoDownload,
  startsSelector,
} from 'redux/startsSlice/startsSlice';
import { checkHandlerFor, filesValidateImg, typeValidate } from 'utils/constants';
import filesValidate from 'utils/filesValidate/filesValidate';

const UploadPhotoComponents = ({ type = '', linkTobe = '', linkPush = '', text = '' }) => {
  UploadPhotoComponents.propTypes = {
    type: PropTypes.string,
    linkTobe: PropTypes.string,
    linkPush: PropTypes.string,
    text: PropTypes.string,
  };
  const b = bem('UploadPhoto');
  const { user } = useSelector(authSelector);

  const { images } = useSelector(challengesCreateDataSelector);
  const { creatChallenges, resultsChallengesInfo } = useSelector(challengesSelector);
  const { createPromotionData, promotionSuccess, promotionInfo } = useSelector(promotionsSelector);
  const { creatStarts, startsSuccess, startsInfo } = useSelector(startsSelector);
  const promotionsImages = useSelector(promotionsCreateDataSelector);
  const startsImages = useSelector(startsCreateSelector);
  const { loading, history } = useSelector(createChallengesData);
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const [drag, setDrag] = useState(false);
  const [pushRequired, setPushRequired] = useState(true);
  const dispatch = useDispatch();
  const push = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (errorsValidate) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.onpopstate = () => {};
        dispatch(modalExitReducer(true));
        dispatch(linksAddReducer(typeValidate(type)));
      };
    }
  }, [errorsValidate]);

  useEffect(() => {
    if (type === 'challenges') {
      if (history === 'success') {
        if (resultsChallengesInfo.resultsInfo) {
          push(`/challenges/challenge-info/${resultsChallengesInfo.resultsInfo?.id}`);
        }
        dispatch(nullSort());
        dispatch(clearResultsChallenges());
        dispatch(searchTermReducer(''));
      }
      dispatch(historyNull());
    }

    if (type === 'promotions') {
      if (promotionSuccess.success !== 'not') {
        if (promotionInfo) {
          push(`/promotions/promotion-info/${promotionInfo?.id}/`);
        }
        dispatch(clearPromotionsResultsSort());
        dispatch(searchTermPromoReducer(''));
      }
    }

    if (type === 'starts') {
      if (startsSuccess.success !== 'not') {
        if (startsInfo) {
          push(`/starts/starts-info/${startsInfo?.id}/`);
        }
        dispatch(searchTermStartReducer(''));
      }
    }
  }, [history, promotionSuccess, startsSuccess]);

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];
    const ret = filesValidate(files, images);
    setDrag(false);
    dispatch(errorsValidateReducer(true));
    if (type === 'challenges') {
      checkHandlerFor(challengesDragHandler, ret);
    } else if (type === 'promotions') {
      checkHandlerFor(promotionDragHandler, ret);
    } else if (type === 'starts') {
      checkHandlerFor(startsDragHandler, ret);
    }
  };

  const deletePhotoHandler = (id) => {
    if (type === 'challenges') {
      dispatch(deleteChallengesPhoto(id));
    }

    if (type === 'promotions') {
      dispatch(deletePromotionPhoto(id));
    }

    if (type === 'starts') {
      dispatch(deleteStartsPhoto(id));
    }
  };

  const dragHandler = (e) => {
    e.preventDefault();
    if (images.length <= 5) {
      setDrag(true);
    }
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const photosSaveHandler = (e) => {
    const { files } = e.target;
    dispatch(errorsValidateReducer(true));
    if (type === 'challenges') {
      if (filesValidateImg(files).length) {
        const ret = filesValidate(files, images);
        checkHandlerFor(challengesPhotoDownload, ret);
      }
    }

    if (type === 'promotions') {
      if (filesValidateImg(files).length) {
        const ret = filesValidate(files, images);
        checkHandlerFor(promotionPhotoDownload, ret);
      }
    }
    if (type === 'starts') {
      if (filesValidateImg(files).length) {
        const ret = filesValidate(files, images);
        checkHandlerFor(startsPhotoDownload, ret);
      }
    }
  };

  const pushHandler = () => {
    if (type === 'challenges') {
      push('/challenges/creatingChallenges/challengeConditions');
    }

    if (type === 'promotions') {
      push(`/promotions/create-promotion`);
    }

    if (type === 'starts') {
      push('/starts/create');
    }
  };

  const nextHandler = () => {
    if (type === 'challenges') {
      if (images.length < 1) {
        message.error(messagesError(t('errors.photos')));
      } else {
        const obj = {
          ...creatChallenges,
          push_required: pushRequired,
        };
        delete obj.images;
        delete obj.requirements;
        delete obj.category;
        delete obj.team_name;
        obj[`category.id`] = creatChallenges.category;
        creatChallenges.images.forEach((item, i) => {
          obj[`images[${i}]image`] = item;
        });
        creatChallenges.requirements.forEach((item, i) => {
          obj[`requirements[${i}]requirement.id`] = item.requirement.id;
          obj[`requirements[${i}]percentage`] = item.percentage;
          obj[`requirements[${i}]unit_amount`] = item.unit_amount;
        });
        if (user?.organization?.id) {
          obj.organization = user?.organization.id;
        } else if (!creatChallenges.organization) {
          delete obj.organization;
        }

        if (!creatChallenges.team) {
          delete obj.team;
        }

        const formData = new FormData();
        for (const name in obj) {
          if (name) {
            formData.append(name, obj[name]);
          }
        }
        dispatch(createChallengesFetch({ data: formData }));
      }
    }

    if (type === 'promotions') {
      if (promotionsImages.images.length < 1) {
        message.error(messagesError(t('errors.photos')));
      } else if (promotionsImages.images.length) {
        const obj = {
          ...createPromotionData,
          push_required: pushRequired,
        };
        delete obj.images;

        createPromotionData.images.forEach((item, i) => {
          obj[`images[${i}]image`] = item;
        });

        if (!createPromotionData.url) {
          delete obj.url;
        }

        if (!createPromotionData.button_text) {
          delete obj.button_text;
        }

        const formData = new FormData();
        for (const name in obj) {
          if (name) {
            formData.append(name, obj[name]);
          }
        }
        dispatch(promotionCreateFetch({ data: formData }));
      }
    }

    if (type === 'starts') {
      if (startsImages.images.length < 1) {
        message.error(messagesError(t('errors.photos')));
      } else if (startsImages.images.length) {
        const obj = {
          ...creatStarts,
          push_required: pushRequired,
        };
        delete obj.category;
        delete obj.images;
        obj[`category.id`] = creatStarts.category;

        creatStarts.images.forEach((item, i) => {
          obj[`images[${i}]image`] = item;
        });

        const formData = new FormData();

        for (const name in obj) {
          if (name) {
            formData.append(name, obj[name]);
          }
        }
        dispatch(startsCreateFetch({ data: formData }));
      }
    }
  };

  const imagesTypesDownload = () => {
    if (type === 'challenges') {
      return images;
    }

    if (type === 'promotions') {
      return promotionsImages.images;
    }

    if (type === 'starts') {
      return startsImages.images;
    }

    return [];
  };

  return (
    <div className={b()} data-testid='stage-two'>
      <BreadcrumbsComponent
        linkTobe={t(linkTobe)}
        linkPush={linkPush}
        textEnd={t('uploadPhoto.upload_photo')}
      />
      <p className={b('title')}>{t('uploadPhoto.upload_photo')}</p>
      {images.length ? (
        <p className={b('text')}>{t('uploadPhoto.photos_length')}</p>
      ) : (
        <p className={b('text')}>{t(text)}</p>
      )}
      <DownloadPhoto
        photos={imagesTypesDownload()}
        drag={drag}
        onDropHandler={onDropHandler}
        deletePhotoHandler={deletePhotoHandler}
        dragHandler={dragHandler}
        dragLeaveHandler={dragLeaveHandler}
        photosSaveHandler={photosSaveHandler}
        title={false}
      />
      <Checkbox
        role='checkbox'
        defaultChecked={pushRequired}
        style={{ color: '#67697F', marginBottom: 12 }}
        onClick={() => setPushRequired(!pushRequired)}
      >
        {t('uploadPhoto.send_push')}
      </Checkbox>
      <div className={b('block_btn')}>
        <Button
          onClick={pushHandler}
          style={{
            width: 129,
            height: 44,
            backgroundColor: 'rgba(0, 122, 255, 0.1)',
            border: 'none',
            color: '#200E32',
            borderRadius: 8,
          }}
        >
          {t('uploadPhoto.back')}
        </Button>
        <div className={b('text_page')}>
          <span>
            <span className='text_page_title'>
              {t('uploadPhoto.page')} {type === 'challenges' ? 3 : 2}
            </span>

            <br />
            <span className='text_page_text'>
              {type === 'challenges' ? 3 : 2} {t('uploadPhoto.from')}{' '}
              {type === 'challenges' ? 3 : 2}
            </span>
          </span>
        </div>
        <Button
          disabled={!imagesTypesDownload().length}
          loading={loading || promotionSuccess.loading || startsSuccess.loading}
          onClick={nextHandler}
          style={{
            width: 129,
            height: 44,
            backgroundColor: !imagesTypesDownload().length ? '#89BAEF' : '#007AFF',
            border: 'none',
            color: '#ffffff',
            borderRadius: 8,
          }}
          htmlType='submit'
        >
          {t('uploadPhoto.finish')}
        </Button>
      </div>
    </div>
  );
};

export default UploadPhotoComponents;
