import { Skeleton } from 'antd';
import bem from 'easy-bem';
import React from 'react';

import SkeletonButton from 'components/SkeletonBlock/FieldsSkeleton/SkeletonButton';
import SkeletonImage from 'components/SkeletonBlock/FieldsSkeleton/SkeletonImage';

const SkeletonInfo = () => {
  const b = bem('SkeletonInfo');
  return (
    <div data-testid='info-loader' className={b()}>
      <div className={b('fons')}>
        <SkeletonButton height={16} num={1} />
        <SkeletonButton height={30} num={1} marginTop={12} />
        <div className={b('group_images')}>
          <div>
            <SkeletonImage
              width={416}
              num={1}
              height={267}
              className={b('skeleton_img')}
              borderRadius={8}
            />
          </div>
          <div className={b('group_images_sk')}>
            <SkeletonImage
              width={128}
              height={81}
              borderRadius={8}
              num={3}
              className={b('skeleton_img')}
              marginBottom={12}
            />
          </div>
        </div>
        <div className={b('text')}>
          <Skeleton active />
        </div>
      </div>
      <div className={b('btn')}>
        <SkeletonButton height={44} num={3} borderRadius={0} />
      </div>
    </div>
  );
};

export default SkeletonInfo;
