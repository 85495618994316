import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { addCookies } from 'utils/addCookies/addCookies';
import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';

const nameSpace = 'auth';
const INITIAL_STATE = {
  user: null,
  tokens: {
    access: '',
    refresh: '',
  },
  errors: null,
  commonError: null,
  success: null,
  loading: null,
};

export const loginUser = createAsyncThunk(
  `${nameSpace}/loginUser`,
  async (loginData, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.post('/accounts/login/', loginData);
      addCookies('refresh', resp.data.tokens.refresh);
      localStorage.setItem(
        'users',
        JSON.stringify({
          user: resp.data.user,
          token: {
            access: resp.data.tokens.access,
          },
        }),
      );
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const refreshToken = createAsyncThunk(
  `${nameSpace}/refreshToken`,
  async (data = null, { getState }) => {
    const { tokens } = getState().auth;
    const asd = { refresh: tokens.refresh };
    const resp = await axiosApi.post('/accounts/refresh/', asd);
    return resp.data;
  },
);

export const resetUserPasswordSendEmail = createAsyncThunk(
  `${nameSpace}/resetUserPasswordSendEmail`,
  async (data, { rejectWithValue }) => {
    try {
      await axiosApi.post('/accounts/password-reset/', data);
    } catch (e) {
      let error = e?.response?.data;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const resetUserPassword = createAsyncThunk(
  `${nameSpace}/resetUserPassword`,
  async (data, { rejectWithValue }) => {
    try {
      await axiosApi.post(`/accounts/password-reset/confirm/`, data);
    } catch (e) {
      let error = e?.response?.data;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

const authSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  reducers: {
    clearAuthState: (state) => {
      state.loading = false;
      state.success = false;
      state.commonError = null;
      state.errors = null;
    },
    logoutUser: () => INITIAL_STATE,
    refreshAccessToken: (state, { payload }) => {
      state.tokens = { ...state.tokens, ...payload };
    },
    checkForTokens: (state, payload) => {
      state.user = payload.payload?.user;
      state.tokens.access = payload.payload?.token?.access;
      state.tokens.refresh = payload.payload?.token?.refresh;
      state.success = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.commonError = null;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.user = payload.user;
      state.tokens = payload.tokens;
      state.loading = false;
      state.success = true;
      state.errors = null;
      state.commonError = null;
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.commonError = payload?.detail;
    });
    builder.addCase(resetUserPasswordSendEmail.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(resetUserPasswordSendEmail.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(resetUserPasswordSendEmail.rejected, (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.commonError = payload?.detail;
      state.errors = payload?.detail ? payload?.detail : payload;
    });
    builder.addCase(resetUserPassword.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(resetUserPassword.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(resetUserPassword.rejected, (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
      state.success = false;
    });
    builder.addCase(refreshToken.fulfilled, (state, { payload }) => {
      state.tokens = { ...state.tokens, ...payload };
    });
  },
});

export const { logoutUser, checkForTokens } = authSlice.actions;
export const authSelector = (state) => state.auth;
export default authSlice.reducer;
