import { CloseOutlined, LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Form, message, Spin, Tooltip } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import csvIcon from 'assets/images/icons/csv.svg';
import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import FormField from 'components/FormField/FormField';
import {
  messagesError,
  messagesErrorFetch,
  messagesSuccess,
} from 'components/Messages/Messagetext';
import {
  errorsValidateNullReducer,
  errorsValidateReducer,
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import {
  deleteInvalidMember,
  editAddMember,
  editRemoveMember,
  editTeamAddReducer,
  editTeamInfoReducer,
  emailsSelector,
  fetchMembersEmails,
  teamInfoFetch,
  teamsInfoSelector,
  teamsPutSelector,
  teamsSelector,
  teamsUpdate,
} from 'redux/teams/teamsSlice';

import { usersFetch, usersSelector } from 'redux/users/usersSlice';
import { deleteEmptyQueryStrings } from 'utils/constants';
import { successText } from 'utils/errorsHandler/errorsHandler';
import localesUtils from 'utils/localesUtils/localesUtils';
import { updateTeamsValidate } from 'utils/updateValidate/updateValidate';

const Update = () => {
  const b = bem('Update');
  const { t } = useTranslation();
  const { id } = useParams();
  const push = useNavigate();
  const dispatch = useDispatch();
  const { teamsInfo, editTeam, updateTeamData } = useSelector(teamsSelector);
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const { loading: teamsInfoLoading } = useSelector(teamsInfoSelector);
  const { loading, history } = useSelector(teamsPutSelector);
  const { users, usersLoading } = useSelector(usersSelector);
  const {
    loading: emailsLoading,
    success: emailsSuccess,
    invalidMembers,
  } = useSelector(emailsSelector);
  const [validateForm, setValidateForm] = useState(false);
  const [usersSearch, setUsersSearch] = useState({ search: '' });
  const [value, setValue] = useState(null);
  const [filename, setFilename] = useState(null);
  const [query, setQuery] = useState({
    page: 1,
    size: 20,
  });

  useEffect(() => {
    if (teamsInfo?.results) {
      dispatch(editTeamAddReducer(teamsInfo.results));
    }
  }, [dispatch, teamsInfo.results]);

  useEffect(() => {
    if (history === 'success') {
      push(`/teams/teams-info/${id}`);
      message.success(messagesSuccess(successText('updateTeams')));
    }
  }, [history]);

  useEffect(() => {
    dispatch(teamInfoFetch({ id, data: { query } }));
    setValidateForm(false);
  }, [dispatch, query]);

  useEffect(() => {
    const resultsUpdate = teamsInfo.results;
    if (resultsUpdate) {
      const validate = updateTeamsValidate(resultsUpdate, editTeam, editTeam.deleted_members);
      setValidateForm(validate);
      if (validate) {
        dispatch(errorsValidateNullReducer());
      } else {
        dispatch(errorsValidateReducer(true));
      }
    }
  }, [editTeam, updateTeamData]);

  useEffect(() => {
    const queryObj = {
      search: usersSearch.search,
    };

    const validateQuery = deleteEmptyQueryStrings(queryObj);
    const data = {
      query: validateQuery,
    };
    if (usersSearch?.search) {
      dispatch(usersFetch({ data }));
    }
  }, [dispatch, usersSearch.search]);

  const handleSearch = (e) => {
    setValue(e);
    setUsersSearch({ ...usersSearch, search: e?.toLowerCase() });
  };

  const formHandler = (e) => {
    const { target } = e;
    const dataName = target.dataset.name;
    if (dataName) {
      dispatch(editTeamInfoReducer({ [dataName]: target.value }));
    }
    if (target.value) {
      dispatch(errorsValidateReducer(true));
    }
  };

  const handleMemberSelect = (user) => {
    if (updateTeamData.members_data.find((e) => e.id === user.id)) {
      return message.error(messagesErrorFetch(t('errors.this_user_already_added')));
    }
    setValue(null);
    dispatch(editAddMember(user));
    dispatch(errorsValidateReducer(true));
  };

  const deleteMemberHandler = (member) => {
    dispatch(editRemoveMember({ member }));
  };

  const cancelHandler = () => {
    if (errorsValidate) {
      dispatch(modalExitReducer(true));
      dispatch(linksAddReducer(`/teams/teams-info/${id}`));
    } else {
      push(`/teams/teams-info/${id}`);
    }
  };

  const onFinish = () => {
    if ([...editTeam.members, ...updateTeamData.members].length < 1) {
      return message.error(messagesError(t('errors.add_at_least_one_member')));
    }

    if (invalidMembers.length > 0) {
      return message.error(messagesError(t('teams.delete_unregistered_users')));
    }
    const data = {
      teamId: id,
      newData: { ...editTeam },
    };

    dispatch(teamsUpdate(data));
  };

  const fileSaveHandler = (e) => {
    const { files } = e.target;
    setFilename(files[0].name);

    const obj = {
      user_emails: files[0],
    };

    const formData = new FormData();
    for (const name in obj) {
      if (name) {
        formData.append(name, obj[name]);
      }
    }
    dispatch(fetchMembersEmails({ data: formData }));
  };

  const scrollHandler = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const atBottom = scrollHeight - scrollTop === clientHeight;
    const pastBottom = scrollHeight - scrollTop <= clientHeight;
    if (atBottom || pastBottom) {
      if (teamsInfo?.results.members_data.next) {
        setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
      }
    }
  };

  return (
    <div className={b()} data-testid='teams--update-element'>
      <BreadcrumbsComponent
        linkProps
        textEnd={t('teams.title_update')}
        componentName='teams.title'
        pushHandler='/teams'
      />
      <p className={b('title')}>{t('teams.title_update')}</p>
      <div className={b('upload-file')}>
        <label className='btn_file text_select_none' htmlFor='upload-file'>
          <img src={csvIcon} alt={t('teams.import_csv')} />
          <input
            onChange={fileSaveHandler}
            type='file'
            name='file'
            id='upload-file'
            multiple
            accept='text/csv'
          />
          {t('teams.import_csv')}
        </label>
      </div>
      {filename ? (
        <div className={b('clipped-file')}>
          {emailsLoading ? <LoadingOutlined /> : <PaperClipOutlined />}
          <span
            className={b(
              `filename ${emailsSuccess === 'not' && !emailsLoading ? 'invalid-file' : ''}`,
            )}
          >
            {filename}
          </span>
        </div>
      ) : null}
      <Form
        fields={[
          {
            name: 'name',
            value: editTeam?.name,
          },
        ]}
        onFinish={onFinish}
        name='basic'
        layout='vertical'
        onChange={formHandler}
        style={{ marginTop: '15px' }}
      >
        <FormField
          type='modificationInput'
          label='teams.title_label'
          name='name'
          message={localesUtils(
            'Пожалуйста, введите название команды',
            'Please input your team title',
          )}
          dataName='name'
          placeholder='teams.title_label'
        />
        <Form.Item label={t('teams.add_participant')}>
          <AutoComplete
            data-testid='autocomplete-users'
            loading={usersLoading}
            value={value}
            style={{ width: '100%' }}
            options={users?.items?.map((user) => ({ key: user.id, value: user.email, user }))}
            filterOption={(inputValue, option) =>
              option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }
            onSearch={handleSearch}
            onSelect={(e, option) => handleMemberSelect(option.user)}
            placeholder={t('teams.input_email')}
          />
        </Form.Item>
        {teamsInfoLoading || emailsLoading ? (
          <div className={b('participants_loading')}>
            <Spin size='large' />
          </div>
        ) : (
          <div className={b('participants_block')} onScroll={scrollHandler}>
            {updateTeamData?.members_data?.map((member) => (
              <p className={b('participant')} key={member.id} data-testid='added-member'>
                {member.email}
                <CloseOutlined
                  style={{ cursor: 'pointer', fontSize: '14px' }}
                  onClick={() => deleteMemberHandler(member)}
                />
              </p>
            ))}
            {invalidMembers?.map((member) => (
              <Tooltip
                title='Не найден в списке Nomad пользователей'
                key={member.id}
                placement='bottom'
                overlayInnerStyle={{ fontSize: '12px', width: '255px' }}
              >
                <p className={b('participant invalid')}>
                  {member.email}
                  <CloseOutlined
                    style={{ cursor: 'pointer', fontSize: '14px', color: '#FF4B55' }}
                    onClick={() => dispatch(deleteInvalidMember({ id: member.id }))}
                  />
                </p>
              </Tooltip>
            ))}
          </div>
        )}
        <div className={b('btn_edit')}>
          <Button
            data-testid='button-cancel'
            style={{
              width: 129,
              height: 44,
              backgroundColor: 'rgba(0, 122, 255, 0.1)',
              borderRadius: 8,
              color: '#200E32',
              marginRight: 16,
              border: 'none',
            }}
            onClick={cancelHandler}
          >
            {t('challenges.cancel')}
          </Button>
          <Button
            data-testid='button-submit'
            disabled={
              ![...updateTeamData.members_data, ...editTeam.members].length ||
              validateForm ||
              !editTeam.name ||
              teamsInfoLoading
            }
            loading={loading}
            style={{
              width: 129,
              height: 44,
              backgroundColor:
                ![...updateTeamData.members_data, ...editTeam.members].length ||
                validateForm ||
                !editTeam.name ||
                teamsInfoLoading
                  ? 'rgb(137, 186, 239)'
                  : '#007AFF',
              borderRadius: 8,
              color: 'white',
              border: 'none',
            }}
            onClick={onFinish}
          >
            {t('challenges.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Update;
