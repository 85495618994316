const obj = {
  create: 'creatingChallenges',
  challengeConditions: 'challengeConditions',
  updatePhotos: 'UploadPhoto',
  updateChallenges: 'update-challenge',
  createPromotion: 'create-promotion',
  updatePromotion: 'update-promotion',
  updateStarts: 'update-starts',
};

const errorsPush = (pathname) => {
  return !!(
    pathname.includes(obj.create) ||
    pathname.includes(obj.challengeConditions) ||
    pathname.includes(obj.updatePhotos || pathname.includes(obj.updateChallenges)) ||
    pathname.includes(obj.createPromotion) ||
    pathname.includes(obj.updatePromotion) ||
    pathname.includes(obj.updateStarts)
  );
};

export default errorsPush;
