import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Select } from 'antd';
import bem from 'easy-bem';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalUpdateCache from 'components/UI/ModalUpdateCache/ModalUpdateCache';
import SearchCard from 'containers/SearchResults/SearchCard';
import { cachingFetch, cachingSelector } from 'redux/cache/cacheSlice';
import {
  addChallengesSearch,
  challengesFetch,
  challengesSelector,
  searchTxtReducer,
} from 'redux/challengesSlice/challengesSlice';
import {
  addPromotionsSearch,
  promotionsFetch,
  promotionsSelector,
  searchTxtPromoReducer,
} from 'redux/promotions/promotionsSlice';
import {
  challengesSearchFetch,
  promotionsSearchFetch,
  searchReducer,
  searchSelector,
  searchTermPromoReducer,
  searchTermReducer,
  searchTermStartReducer,
  searchTextNotReducer,
  startsSearchFetch,
  teamsSearchFetch,
} from 'redux/search/searchSlice';
import {
  addStartsSearch,
  searchTxtStartsReducer,
  startsFetch,
  startsSelector,
} from 'redux/startsSlice/startsSlice';
import {
  checkSpaces,
  languages,
  optionsChallenges,
  optionsPromotions,
  optionsStarts,
  optionsTeams,
} from 'utils/constants';
import { linkSearch } from 'utils/errorsHandler/errorsHandler';
import { dispatchFetch, searchValidateBool } from 'utils/searchValidate/searchValidate';

const { Option } = Select;

const Header = () => {
  const b = bem('Header');
  const { t } = useTranslation();
  const [lang, setLang] = useState('ru');
  const {
    searchTerm,
    search,
    challengesSearch,
    promotionsSearch,
    searchTermPromo,
    searchTermStart,
    startsSearch,
    teamsSearch,
  } = useSelector(searchSelector);
  const { getPromotionsError } = useSelector(promotionsSelector);
  const { updateLoading } = useSelector(cachingSelector);
  const { errors, sort, loadingSearch } = useSelector(challengesSelector);
  const promotions = useSelector(promotionsSelector);
  const {
    loadingSearch: loadingSearchStart,
    sort: sortStarts,
    errors: errorsStarts,
  } = useSelector(startsSelector);
  const { pathname } = useLocation();
  const push = useNavigate();
  const dispatch = useDispatch();
  const link = pathname.split('/').filter((i) => i);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion.winWidth]);

  useEffect(() => {
    const Debounce = setTimeout(() => {
      if (searchValidateBool(pathname, searchTerm, link, 1, '/challenges')) {
        dispatchFetch(
          dispatch,
          `&sort=status,-id${sort.status.url}${sort.categories.url}`,
          searchTerm,
          challengesFetch,
        );
      }
    }, 300);
    return () => clearTimeout(Debounce);
  }, [searchTerm, pathname]);

  useEffect(() => {
    const Debounce = setTimeout(() => {
      if (searchValidateBool(pathname, searchTermPromo, link, 1, '/promotions')) {
        dispatchFetch(
          dispatch,
          `&sort=status,-id${promotions.sort.status.url}`,
          searchTermPromo,
          promotionsFetch,
        );
      }
    }, 300);
    return () => clearTimeout(Debounce);
  }, [searchTermPromo, pathname]);

  useEffect(() => {
    const Debounce = setTimeout(() => {
      if (searchValidateBool(pathname, searchTermStart, link, 1, '/starts')) {
        dispatchFetch(
          dispatch,
          `&sort=status,-id${sortStarts.status.url}`,
          searchTermStart,
          startsFetch,
        );
      }
    }, 300);
    return () => clearTimeout(Debounce);
  }, [searchTermStart, pathname]);

  useEffect(() => {
    const Debounce = setTimeout(() => {
      if (search) {
        dispatch(teamsSearchFetch({ name: search }));
        dispatch(challengesSearchFetch({ name: search }));
        dispatch(promotionsSearchFetch({ name: search }));
        dispatch(startsSearchFetch({ name: search }));
      }
    }, 500);

    return () => clearTimeout(Debounce);
  }, [search]);

  const handleChange = (value) => {
    i18n.changeLanguage(value);
    setLang(value);
  };

  const searchChallenges = [
    {
      label: t('challenges.name'),
      options: challengesSearch.results?.map((item) => {
        return {
          key: item.id,
          nameId: 1,
          value: <SearchCard isChallenge item={item} />,
        };
      }),
    },
  ];

  const searchPromotions = [
    {
      label: t('promotions.name'),
      options: promotionsSearch.results?.map((item) => {
        return {
          key: item.id,
          nameId: 2,
          value: <SearchCard item={item} />,
        };
      }),
    },
  ];

  const searchStarts = [
    {
      label: t('starts.name'),
      options: startsSearch.results?.map((item) => {
        return {
          key: item.id,
          nameId: 3,
          value: <SearchCard item={item} />,
        };
      }),
    },
  ];

  const searchTeams = [
    {
      label: t('teams.title'),
      options: teamsSearch.results?.map((item) => {
        return {
          key: item.id,
          nameId: 5,
          value: <SearchCard item={item} />,
        };
      }),
    },
  ];

  const renderSearchResults = () => {
    if (challengesSearch.count + promotionsSearch.count + startsSearch.count + teamsSearch.count) {
      return (
        <>
          {t('search.watch_all_result')} (
          {challengesSearch.count + promotionsSearch.count + startsSearch.count + teamsSearch.count}
          )
        </>
      );
    }
    if (
      challengesSearch.loading ||
      promotionsSearch.loading ||
      startsSearch.loading ||
      teamsSearch.loading
    ) {
      return t('search.searchHome');
    }

    return t('search.not_found');
  };

  const searchResults = {
    nameId: 4,
    value: <div className='block_search_results'>{renderSearchResults()}</div>,
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const modalUpdateCacheHandler = async () => {
    if (link.length) {
      const data = {
        query: { update: link },
      };

      await dispatch(cachingFetch({ data }));
    } else {
      await dispatch(
        cachingFetch({
          challenges: '?update=challenges',
          promotions: '&update=promotions',
          starts: '&update=starts',
        }),
      );
    }
    await setIsModalVisible(false);
  };

  const searchValidate = () => {
    if (search) {
      if (
        challengesSearch.results.length &&
        promotionsSearch.results.length &&
        startsSearch.results.length
      ) {
        return [
          ...optionsChallenges(1, searchChallenges),
          ...optionsPromotions(1, searchPromotions),
          ...optionsStarts(1, searchStarts),
          ...optionsTeams(1, searchTeams),
          searchResults,
        ];
      }

      if (teamsSearch.count) {
        return [...optionsTeams(2, searchTeams), searchResults];
      }

      if (challengesSearch.count) {
        return [...optionsChallenges(2, searchChallenges), searchResults];
      }
      if (promotionsSearch.count) {
        return [...optionsPromotions(2, searchPromotions), searchResults];
      }
      if (startsSearch.count) {
        return [...optionsStarts(2, searchStarts), searchResults];
      }
      return [searchResults];
    }
  };

  const pushHandler = (_, key) => {
    const findChallenges = challengesSearch.results.find((item) => item.id === key.key);
    const findPromotions = promotionsSearch.results.find((item) => item.id === key.key);
    const findStarts = startsSearch.results.find((item) => item.id === key.key);
    const findTeams = teamsSearch.results.find((item) => item.id === key.key);
    if (key.nameId === 1 && findChallenges) {
      push(`/challenges/challenge-info/${key.key}`);
      dispatch(addChallengesSearch(findChallenges));
      dispatch(searchTermReducer(''));
      dispatch(searchReducer(''));
    } else if (key.nameId === 2 && findPromotions) {
      push(`/promotions/promotion-info/${key.key}`);
      dispatch(addPromotionsSearch(findPromotions));
      dispatch(searchTermReducer(''));
      dispatch(searchReducer(''));
    } else if (key.nameId === 3 && findStarts) {
      push(`/starts/starts-info/${key.key}`);
      dispatch(addStartsSearch(findStarts));
      dispatch(searchTermReducer(''));
      dispatch(searchReducer(''));
    } else if (key.nameId === 5 && findTeams) {
      push(`/teams/teams-info/${key.key}`);
      dispatch(searchReducer(''));
      dispatch(searchTermReducer(''));
    } else if (
      key.nameId === 4 &&
      (challengesSearch.results.length ||
        promotionsSearch.results.length ||
        startsSearch.results.length)
    ) {
      if (checkSpaces(search)) {
        push(`/search-results`);
      }
    }
  };

  const searchTermHandler = (value) => {
    dispatch(searchTermReducer(value));
    dispatch(searchTxtReducer());
    if (!checkSpaces(value)) {
      dispatchFetch(
        dispatch,
        `&sort=status,-id${sort.status.url}${sort.categories.url}`,
        '',
        challengesFetch,
      );
    }
  };

  const searchTermPromotionsHandler = (value) => {
    dispatch(searchTermPromoReducer(value));
    dispatch(searchTxtPromoReducer());
    if (!checkSpaces(value)) {
      dispatchFetch(dispatch, `&sort=status,-id${promotions.sort.status.url}`, '', promotionsFetch);
    }
  };

  const searchTermStartsHandler = (value) => {
    dispatch(searchTermStartReducer(value));
    dispatch(searchTxtStartsReducer());
    if (!checkSpaces(value)) {
      dispatchFetch(dispatch, `&sort=status,-id${sortStarts.status.url}`, '', startsFetch);
    }
  };

  const searchHandlerGlob = (value) => {
    dispatch(searchReducer(value));
    if (!value.length) {
      dispatch(searchTextNotReducer());
    }
  };

  const searchHandler = (value) => {
    dispatch(searchReducer(value));
    if (!value.length) {
      dispatch(searchTextNotReducer());
    }
  };

  const renderResults = () => {
    if (pathname.includes('/challenges') && link.length === 1) {
      return (
        <>
          <div className='img_search'>
            {loadingSearch ? <LoadingOutlined /> : <SearchOutlined />}
          </div>
          <AutoComplete
            allowClear
            disabled={!!(errors || getPromotionsError)}
            value={searchTerm}
            autoFocus={false}
            style={{ width: 370 }}
            onSearch={searchTermHandler}
            placeholder={linkSearch(link).placeholder}
          />
        </>
      );
    }
    if (pathname.includes('/promotions') && link.length === 1) {
      return (
        <>
          <div className='img_search'>
            {promotions.promotionsLoadingSearch ? <LoadingOutlined /> : <SearchOutlined />}
          </div>
          <AutoComplete
            allowClear
            disabled={!!(errors || getPromotionsError)}
            value={searchTermPromo}
            autoFocus={false}
            style={{ width: 370 }}
            onSearch={searchTermPromotionsHandler}
            placeholder={linkSearch(link).placeholder}
          />
        </>
      );
    }
    if (pathname.includes('/starts') && link.length === 1) {
      return (
        <>
          <div className='img_search'>
            {loadingSearchStart ? <LoadingOutlined /> : <SearchOutlined />}
          </div>
          <AutoComplete
            allowClear
            disabled={!!(errors || errorsStarts)}
            value={searchTermStart}
            autoFocus={false}
            style={{ width: 370 }}
            onSearch={searchTermStartsHandler}
            placeholder={linkSearch(link).placeholder}
          />
        </>
      );
    }
    if (pathname.includes('/search-results')) {
      return (
        <>
          <div className='img_search'>
            {challengesSearch.loading ||
            promotionsSearch.loading ||
            startsSearch.loading ||
            teamsSearch.loading ? (
              <LoadingOutlined />
            ) : (
              <SearchOutlined />
            )}
          </div>
          <AutoComplete
            allowClear
            disabled={
              !!(
                challengesSearch.errors ||
                promotionsSearch.errors ||
                startsSearch.errors ||
                teamsSearch.errors
              )
            }
            open={false}
            value={search}
            autoFocus={false}
            style={{ width: 370 }}
            options={searchValidate()}
            onSearch={(value) => searchHandlerGlob(value)}
            placeholder={linkSearch(link).placeholder}
            onSelect={pushHandler}
          />
        </>
      );
    }

    return (
      <>
        <div className='img_search'>
          {challengesSearch.loading ||
          promotionsSearch.loading ||
          startsSearch.loading ||
          teamsSearch.loading ? (
            <LoadingOutlined />
          ) : (
            <SearchOutlined />
          )}
        </div>
        <AutoComplete
          allowClear
          disabled={
            !!(
              challengesSearch.errors ||
              promotionsSearch.errors ||
              startsSearch.errors ||
              teamsSearch.errors
            )
          }
          value={search}
          autoFocus={false}
          style={{ width: 370 }}
          options={searchValidate()}
          onSearch={(value) => searchHandler(value)}
          placeholder={linkSearch(link).placeholder}
          onSelect={pushHandler}
        />
      </>
    );
  };
  return (
    <div className={b()}>
      <div className={b('search_block')}>{renderResults()}</div>
      <div>
        <Select
          className='select_lan'
          autoFocus={false}
          value={lang}
          style={{
            width: 120,
            height: 40,
            backgroundColor: '#F4F6FF',
            borderRadius: 8,
          }}
          onChange={handleChange}
        >
          {languages?.map((option) => {
            return windowDimenion.winWidth >= 700 ? (
              <Option key={option.code} value={option.code}>
                <img
                  style={{
                    paddingRight: '5px',
                    width: '32px',
                    height: '25px',
                  }}
                  alt={option.name}
                  src={option.img}
                />
                <span className='text_lan_option'>{option.name_en}</span>

                <p className='text_lang'>{option.lang}</p>
              </Option>
            ) : (
              <Option key={option.code} value={option.code}>
                <img
                  alt={option.name}
                  style={{
                    paddingRight: '5px',
                    width: '30px',
                    height: '25px',
                  }}
                  src={option.img}
                />
                {option.name}
                <p className='text_lang'>{option.lang}</p>
              </Option>
            );
          })}
        </Select>
      </div>
      {link.length === 0 ||
      (link.includes('challenges') && link.length === 1) ||
      (link.includes('promotions') && link.length === 1) ||
      (link.includes('starts') && link.length === 1) ? (
        <div className={b('update_cache_button')}>
          <Button onClick={() => setIsModalVisible(true)}>
            {t('cache.update_cache')} {link.length ? t(`cache.${link}`) : null}
          </Button>
        </div>
      ) : null}

      <ModalComponent open={isModalVisible} onCancel={handleCancel} width={443} height='100%'>
        <ModalUpdateCache
          title={
            link.length
              ? t('cache.now_you_will_update_all_the_information') + t(`update_cache.${link}`)
              : t(`cache.now_you_will_update_all_the_information_about`)
          }
          text={t('cache.data_will_be_updated_in_the_mobile')}
          noneDeleteHandler={handleCancel}
          yesDeleteHandler={modalUpdateCacheHandler}
          loading={updateLoading}
        />
      </ModalComponent>
    </div>
  );
};

export default Header;
