import { ConfigProvider, DatePicker, Form } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import ruRU from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import i18n from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { sundayDate, sundayEnd } from 'utils/dateFormat/dateFormat';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const DateFormRangePicker = ({
  label = '',
  marginTop = '',
  creatObj = {},
  dateHandler = undefined,
  dateFormat = '',
  width = '',
  height = '',
  borderRadius = 0,
  disabled = false,
  disableDate = true,
  startLabel = 'starts_at',
  endLabel = 'ends_at',
}) => {
  DateFormRangePicker.propTypes = {
    label: PropTypes.string,
    marginTop: PropTypes.string,
    creatObj: PropTypes.instanceOf(Object),
    dateHandler: PropTypes.func,
    dateFormat: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    borderRadius: PropTypes.number,
    disabled: PropTypes.bool,
    disableDate: PropTypes.bool,
    startLabel: PropTypes.string,
    endLabel: PropTypes.string,
  };
  const { t } = useTranslation();

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const dateForm = () => {
    if (creatObj[startLabel] !== sundayDate() || creatObj[endLabel] !== sundayEnd()) {
      return (
        <RangePicker
          disabled={disabled}
          disabledDate={disableDate ? disabledDate : false}
          defaultValue={[
            dayjs(moment(creatObj[startLabel], dateFormat)),
            dayjs(moment(creatObj[endLabel], dateFormat)),
          ]}
          onChange={dateHandler}
          format={dateFormat}
          style={{
            width,
            height,
            borderRadius,
          }}
        />
      );
    }

    return (
      <RangePicker
        disabled={disabled}
        disabledDate={disableDate ? disabledDate : false}
        defaultValue={[
          dayjs(moment(creatObj[startLabel], dateFormat)),
          dayjs(moment(creatObj[endLabel], dateFormat)),
        ]}
        format={dateFormat}
        onChange={dateHandler}
        style={{
          width: '100%',
          height: '44px',
          borderRadius: 8,
        }}
      />
    );
  };

  return (
    <ConfigProvider data-testid='date_picker_elem' locale={i18n?.language === 'ru' ? ruRU : enUS}>
      <Form.Item
        label={t(label)}
        style={{
          marginTop,
        }}
      >
        {dateForm()}
      </Form.Item>
    </ConfigProvider>
  );
};

export default DateFormRangePicker;
