import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const optionsAge = [];
for (let i = 3; i < 91; i += 1) {
  optionsAge.push(i);
}

const SelectTags = ({
  onChange = undefined,
  placeholder = '',
  value = null,
  ageFrom = null,
  allowClear = false,
}) => {
  SelectTags.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.number,
    ageFrom: PropTypes.number,
    allowClear: PropTypes.bool,
  };

  return (
    <Select
      bordered={false}
      style={{
        width: '100%',
      }}
      showSearch
      allowClear={allowClear}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      options={optionsAge
        .map((item) => {
          return {
            label: item,
            value: item,
          };
        })
        .filter((age) => age.value > ageFrom)}
    />
  );
};

export default SelectTags;
