import { createSlice } from '@reduxjs/toolkit';

const nameSpace = 'historyNotifications';

const INITIAL_STATE = {
  modalExitBoll: false,
  links: '',
  errorsValidate: false,
};

const historyNotifications = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  reducers: {
    errorsValidateReducer: (state, action) => {
      state.errorsValidate = action.payload;
      window.onbeforeunload = () => {
        return true;
      };
    },
    errorsValidateNullReducer: (state) => {
      state.errorsValidate = false;
      window.onpopstate = () => {
        window.history.go(10);
      };
    },
    modalExitReducer: (state, action) => {
      state.modalExitBoll = action.payload;
    },
    linksAddReducer: (state, action) => {
      state.links = action.payload;
    },
  },
});

export const {
  errorsValidateReducer,
  errorsValidateNullReducer,
  modalExitReducer,
  linksAddReducer,
} = historyNotifications.actions;
export const historyNotificationsSelector = (state) => state.historyNotifications;
export default historyNotifications.reducer;
