import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import localesValidateInput from 'utils/localiesValidateInput/localiesValidateInput';

const EmailField = ({
  id = '',
  type = '',
  label = '',
  name = '',
  className = '',
  inputClassName = '',
  placeholder = '',
}) => {
  EmailField.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    placeholder: PropTypes.string,
  };
  const { t } = useTranslation();

  return (
    <Form.Item
      type={type}
      id={id}
      className={className}
      label={label}
      name={name}
      rules={[
        {
          type: 'email',
          message: localesValidateInput(
            <span id='the_input_is_not_valid_email'>
              {t('errors.the_input_is_not_valid_email')}
            </span>,
            'the_input_is_not_valid_email',
            t('errors.the_input_is_not_valid_email'),
          ),
        },
        {
          required: true,
          message: localesValidateInput(
            <span id='input_your_email'>{t('errors.input_your_email')}</span>,
            'input_your_email',
            t('errors.input_your_email'),
          ),
        },
      ]}
    >
      <Input className={inputClassName} placeholder={placeholder} />
    </Form.Item>
  );
};

export default EmailField;
