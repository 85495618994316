import { Form, Input } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';

const TextareaField = ({
  id = '',
  rules = [],
  type = '',
  className = '',
  label = '',
  name = '',
  placeholder = '',
  onChange = undefined,
  onClick = undefined,
}) => {
  TextareaField.propTypes = {
    id: PropTypes.string,
    rules: PropTypes.instanceOf(Array),
    type: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
  };
  const b = bem('TextareaField');

  return (
    <Form.Item id={id} rules={rules} type={type} className={className} label={label} name={name}>
      <Input.TextArea
        name={name}
        onChange={onChange}
        onClick={onClick}
        rows={4}
        placeholder={placeholder}
        className={b('textarea-style')}
      />
    </Form.Item>
  );
};

export default TextareaField;
