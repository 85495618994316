import { AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import logoutIcons from 'assets/images/iconsMenu/logout.svg';
import logo from 'assets/images/logo/Logo.svg';
import mobileLogo from 'assets/images/logo/mobile_logo.svg';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalExit from 'components/UI/ModalExit/ModalExit';
import { nullSort } from 'redux/challengesSlice/challengesSlice';
import {
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import { nullSortPromotions } from 'redux/promotions/promotionsSlice';
import { menuText } from 'utils/constants';

const MobileNavigation = ({
  logOutHandler = undefined,
  isModalVisible = undefined,
  yesDeleteHandler = undefined,
  noneDeleteHandler = undefined,
}) => {
  MobileNavigation.propTypes = {
    logOutHandler: PropTypes.func,
    isModalVisible: PropTypes.bool,
    yesDeleteHandler: PropTypes.func,
    noneDeleteHandler: PropTypes.func,
  };
  const b = bem('MobileNavigation');
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const [open, setOpen] = useState(false);
  const push = useNavigate();
  const link = pathname.split('/').filter((i) => i);

  const menuHandler = (value) => {
    if (errorsValidate) {
      dispatch(modalExitReducer(true));
      dispatch(linksAddReducer(value));
    } else {
      push(value);
      if (value === '/challenges') {
        dispatch(nullSort());
      } else {
        dispatch(nullSortPromotions());
      }
    }
  };

  const menuLink = menuText.map((item) => (
    <button
      type='button'
      className='btn_none_style'
      onClick={() => menuHandler(item.link)}
      key={item.name}
    >
      <div className={`/${link[0]}` === item.link || pathname === item.link ? 'activeLink' : ''}>
        <div className='line' />
      </div>
      <div>
        <img src={item.icons} alt={item.name} />
      </div>
      <p className={`subtext-navigation ${open ? 'active-links' : ''}`}>
        {t(`navigation.${item.name}`)}
      </p>
    </button>
  ));

  return (
    <div className={`${b()} ${open ? 'active_menu_button' : 'inactive_menu_button'}`}>
      <div className={b('logo_nomad')}>
        <Link to='/admin'>
          {open ? (
            <img className='logo-open' src={logo} alt='logo_nomad' />
          ) : (
            <img src={mobileLogo} alt='logo_nomad' />
          )}
        </Link>
      </div>
      <button className='menu_button' type='button' onClick={() => setOpen(!open)}>
        {open ? (
          <AlignRightOutlined style={{ color: '#fff', fontSize: '18px' }} />
        ) : (
          <AlignLeftOutlined style={{ color: '#fff', fontSize: '18px' }} />
        )}
      </button>
      <div className={b('navigation_menu')}>
        <p>{t('navigation.menu')}</p>
        <div className={b('link_navigation')}>{menuLink}</div>
      </div>
      <button type='button' onClick={logOutHandler} className={b('logout')}>
        <div>
          <img src={logoutIcons} alt='logout-icons' />
        </div>
        <p className={`${open ? 'active-links' : ''}`}>{t('navigation.log_out')}</p>
      </button>
      <ModalComponent open={isModalVisible} width={440} height='100%'>
        <ModalExit yesDeleteHandler={yesDeleteHandler} noneDeleteHandler={noneDeleteHandler} />
      </ModalComponent>
    </div>
  );
};

export default MobileNavigation;
