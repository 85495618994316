import { Form, Select } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import localesUtils from 'utils/localesUtils/localesUtils';

const ShowSearchSelect = ({
  label = '',
  message = '',
  placeholder = '',
  onChangeChoose = undefined,
  chooseCategory = 0,
  disabled = false,
  options = [],
}) => {
  ShowSearchSelect.propTypes = {
    label: PropTypes.string,
    message: PropTypes.string,
    placeholder: PropTypes.string,
    onChangeChoose: PropTypes.func,
    chooseCategory: PropTypes.number,
    disabled: PropTypes.bool,
    options: PropTypes.instanceOf(Array),
  };

  useEffect(() => {
    const elemTitle = document.querySelectorAll('.ant-select-selection-item');
    if (elemTitle.length && options.length) {
      elemTitle.forEach((elem) => {
        options.forEach((text) => {
          if (elem.textContent === text.name_ru) {
            elem.textContent = localesUtils(text.name_ru, text.name_en);
          } else if (elem.textContent === text.name_en) {
            elem.textContent = localesUtils(text.name_ru, text.name_en);
          }
        });
      });
    }
  }, [i18next?.language]);
  return (
    <Form.Item
      label={label}
      rules={[
        {
          required: true,
          message,
        },
      ]}
    >
      <Select
        disabled={disabled}
        defaultValue={chooseCategory || null}
        showSearch
        placeholder={placeholder}
        optionFilterProp='children'
        onChange={onChangeChoose}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={options.map((item) => {
          return {
            value: item.id,
            label: localesUtils(item.name_ru, item.name_en),
          };
        })}
      />
    </Form.Item>
  );
};

export default ShowSearchSelect;
