import { Button, Select } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import nextIcons from 'assets/images/icons/white-arrow.svg';

const { Option } = Select;

const PaginationComponents = ({
  usersList = undefined,
  numUsersPagination = undefined,
  usersParams = undefined,
  inputOnChangeHandler = undefined,
  handleChange = undefined,
  pagePrevHandler = undefined,
  pageNextHandler = undefined,
  totalUsersPages = undefined,
}) => {
  PaginationComponents.propTypes = {
    usersList: PropTypes.instanceOf(Object) || undefined,
    numUsersPagination: PropTypes.instanceOf(Object) || undefined,
    usersParams: PropTypes.instanceOf(Object) || undefined,
    inputOnChangeHandler: PropTypes.func,
    handleChange: PropTypes.func,
    pagePrevHandler: PropTypes.func,
    pageNextHandler: PropTypes.func,
    totalUsersPages: PropTypes.number,
  };
  const { t } = useTranslation();
  const b = bem('pagination');

  return (
    <div className={b()}>
      <div className={b('pagination_show')}>
        <div className='glav_pagination'>
          <div className='pagintion_str'>
            <span className='str'>{t('challenges.page')}</span>
            <span className='text_input'>
              <input onChange={inputOnChangeHandler} value={usersParams?.page} />
            </span>
            <span className='str'>
              {t('challenges.from')} {totalUsersPages || 0}
            </span>
          </div>
          <div className='next_table'>
            <Button
              disabled={usersParams?.page <= 1}
              onClick={pagePrevHandler}
              icon={<img src={nextIcons} alt='backIcons' />}
              className='pagination_buttons pagination_buttons-prev'
            />
            <Button
              role='button'
              disabled={usersParams?.page >= totalUsersPages}
              onClick={pageNextHandler}
              icon={<img src={nextIcons} alt='nextIcons' />}
              className='pagination_buttons pagination_buttons-next'
            />
          </div>
        </div>
        <div className='pagination_total'>
          <div>
            <Select
              value={numUsersPagination?.size}
              style={{
                marginTop: '-8px',
              }}
              onChange={handleChange}
            >
              <Option value='10'>10</Option>
              <Option value='20'>20</Option>
              <Option value='50'>50</Option>
              <Option value='100'>100</Option>
            </Select>
          </div>
          <div className='text_span'>
            {t('challenges.shown_with')} 1-
            {numUsersPagination?.size > usersList?.total
              ? usersList?.total
              : numUsersPagination?.size}{' '}
            {t('challenges.from')} {usersList?.total}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaginationComponents;
