import { Button, Form, message } from 'antd';
import bem from 'easy-bem';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import FormField from 'components/FormField/FormField';
import { messagesErrorFetch } from 'components/Messages/Messagetext';
import {
  errorsValidateReducer,
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import {
  organizationsListFetch,
  organizationsSelector,
} from 'redux/organizations/organizationsSlice';
import { createTeamReducer, organizationChoseReducer, teamsSelector } from 'redux/teams/teamsSlice';
import localesUtils from 'utils/localesUtils/localesUtils';

const Creating = () => {
  const b = bem('Creating');
  const { t } = useTranslation();
  const push = useNavigate();
  const { createTeam } = useSelector(teamsSelector);
  const { organizations, organizationsLoading } = useSelector(organizationsSelector);
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizationsListFetch());
  }, [dispatch]);

  useEffect(() => {
    if (
      !errorsValidate &&
      (createTeam.name || createTeam.organization || createTeam.members.length)
    ) {
      dispatch(errorsValidateReducer(true));
    }

    if (errorsValidate) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.onpopstate = () => {};
        dispatch(modalExitReducer(true));
        dispatch(linksAddReducer(`/teams`));
      };
    }
  }, [errorsValidate]);

  const formHandler = (e) => {
    const { target } = e;
    const dataName = target.dataset.name;
    dispatch(createTeamReducer({ [dataName]: target.value }));
    if (target.value) {
      dispatch(errorsValidateReducer(true));
    }
  };

  const organizationChose = (id) => {
    dispatch(organizationChoseReducer({ id }));
    if (id) {
      dispatch(errorsValidateReducer(true));
    }
  };

  const pushHandler = () => {
    if (!createTeam.organization) {
      return message.error(messagesErrorFetch(t('errors.enter_organization')));
    }

    push('/teams/create/teamMembers');
  };

  return (
    <div className={b()} data-testid='teams--form-element'>
      <div className='breadcrumbs'>
        <BreadcrumbsComponent textEnd={t('teams.creating_team')} />
        <p className={b('title')}>{t('teams.creating_team')}</p>
        <p className={b('text')}>{t('teams.create_your_teams')}</p>
        <Form
          fields={[
            {
              name: 'name',
              value: createTeam.name,
            },
          ]}
          name='basic'
          layout='vertical'
          onChange={formHandler}
        >
          <FormField
            type='modificationInput'
            label='teams.title_label'
            name='name'
            message={localesUtils(
              'Пожалуйста, введите название команды',
              'Please input your team title',
            )}
            dataName='name'
            placeholder='teams.title_label'
          />
          <FormField
            type='showSearchSelect'
            label={t('teams.organization')}
            name='category'
            message='Please input organization!'
            placeholder={t('teams.choose_organization')}
            onChangeChoose={organizationChose}
            chooseCategory={parseInt(createTeam.organization)}
            disabled={organizationsLoading}
            options={organizations?.results}
          />
          <Form.Item
            className={b('btn_form')}
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button
              disabled={!createTeam.organization || !createTeam.name}
              data-testid='button-submit'
              style={{
                width: 129,
                height: 44,
                borderRadius: 8,
              }}
              type='primary'
              htmlType='submit'
              onClick={pushHandler}
            >
              {t('promotions.next')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Creating;
