import bem from 'easy-bem';
import React from 'react';

import SkeletonButton from 'components/SkeletonBlock/FieldsSkeleton/SkeletonButton';

const SkeletonTeamInfo = () => {
  const b = bem('SkeletonTeamInfo');

  return (
    <div className={b()} data-testid='team-info-loader'>
      <SkeletonButton width='830px' height={115} borderRadius={16} num={1} />
      <div className={b('btn')}>
        <SkeletonButton width='330px' height={46} num={2} borderRadius={5} marginBottom={10} />
      </div>
    </div>
  );
};

export default SkeletonTeamInfo;
