import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TagInput = ({
  label = '',
  name = '',
  dataName = '',
  placeholder = '',
  message = '',
  messageFormat = '',
}) => {
  TagInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    dataName: PropTypes.string,
    placeholder: PropTypes.string,
    message: PropTypes.string,
    messageFormat: PropTypes.string,
  };
  const { t } = useTranslation();

  useEffect(() => {
    const tagDom = document.querySelector('#tag');
    const tagFormat = document.querySelector('#tagFormat');
    if (tagDom && messageFormat) {
      tagDom.innerHTML = message;
    } else if (tagFormat) {
      tagFormat.innerHTML = messageFormat;
    }
  }, [message, messageFormat]);

  return (
    <Form.Item
      label={t(label)}
      name={name}
      rules={[
        {
          required: true,
          message: <span id='tag'>{message}</span>,
        },
        {
          pattern: /^[a-zA-Z0-9]*$/,
          message: <span id='tagFormat'>{messageFormat}</span>,
        },
      ]}
    >
      <Input maxLength={20} data-name={dataName} placeholder={t(placeholder)} />
    </Form.Item>
  );
};

export default TagInput;
