import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { sentryDSN } from 'utils/config';

const initSentry = () => {
  if (sentryDSN) {
    Sentry.init({
      dsn: sentryDSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
};

export default initSentry;
