import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';

const nameSpace = 'user';

const INITIAL_STATE = {
  searchTerm: '',
  search: '',
  searchTermPromo: '',
  searchTermStart: '',
  challengesSearch: {
    results: [],
    loading: false,
    errors: null,
    count: 0,
  },
  challengesPagination: {
    count: 0,
    previous: null,
    next: '',
  },
  promotionsPagination: {
    count: 0,
    previous: null,
    next: '',
  },
  promotionsSearch: {
    results: [],
    loading: false,
    errors: null,
    count: 0,
  },
  startsSearch: {
    results: [],
    loading: false,
    errors: null,
    count: 0,
  },
  startsPagination: {
    count: 0,
    previous: null,
    next: '',
  },
  teamsSearch: {
    results: [],
    loading: false,
    errors: null,
    count: 0,
  },
  teamsSearchPagination: {
    count: 0,
  },
};

export const challengesSearchFetch = createAsyncThunk(
  `${nameSpace}/challengesSearchFetch`,
  async ({ name }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/challenges/?sort=status,-id&name=${name}`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const promotionsSearchFetch = createAsyncThunk(
  `${nameSpace}/promotionsSearchFetch`,
  async ({ name }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/promotions/?sort=status,-id&name=${name}`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const startsSearchFetch = createAsyncThunk(
  `${nameSpace}/startsSearchFetch`,
  async ({ name }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/starts/?sort=status,-id&name=${name}`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const teamsSearchFetch = createAsyncThunk(
  `${nameSpace}/teamsSearchFetch`,
  async ({ name }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/teams/?name=${name}`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

const searchSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  reducers: {
    searchTermReducer: (state, action) => {
      state.searchTerm = action.payload;
    },
    searchTermPromoReducer: (state, action) => {
      state.searchTermPromo = action.payload;
    },
    searchTermStartReducer: (state, action) => {
      state.searchTermStart = action.payload;
    },
    searchReducer: (state, action) => {
      state.search = action.payload;
    },
    searchTextNotReducer: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(challengesSearchFetch.pending, (state) => {
      state.challengesSearch = {
        ...state.challengesSearch,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(challengesSearchFetch.fulfilled, (state, { payload }) => {
      state.challengesSearch = {
        ...state.challengesSearch,
        loading: false,
        results: payload.results,
        errors: null,
        count: payload.count,
      };
      state.challengesPagination = {
        ...state.challengesPagination,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    });
    builder.addCase(challengesSearchFetch.rejected, (state, { payload }) => {
      state.challengesSearch = {
        ...state.challengesSearch,
        loading: false,
        errors: payload,
        results: [],
      };
    });

    builder.addCase(promotionsSearchFetch.pending, (state) => {
      state.promotionsSearch = {
        ...state.promotionsSearch,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(promotionsSearchFetch.fulfilled, (state, { payload }) => {
      state.promotionsSearch = {
        ...state.promotionsSearch,
        loading: false,
        results: payload.results,
        errors: null,
        count: payload.count,
      };
      state.promotionsPagination = {
        ...state.promotionsPagination,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    });
    builder.addCase(promotionsSearchFetch.rejected, (state, { payload }) => {
      state.promotionsSearch = {
        ...state.promotionsSearch,
        loading: false,
        errors: payload,
        results: [],
      };
    });

    builder.addCase(startsSearchFetch.pending, (state) => {
      state.startsSearch = {
        ...state.startsSearch,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(startsSearchFetch.fulfilled, (state, { payload }) => {
      state.startsSearch = {
        ...state.startsSearch,
        loading: false,
        results: payload.results,
        errors: null,
        count: payload.count,
      };
      state.startsPagination = {
        ...state.startsPagination,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    });
    builder.addCase(startsSearchFetch.rejected, (state, { payload }) => {
      state.startsSearch = {
        ...state.startsSearch,
        loading: false,
        errors: payload,
        results: [],
      };
    });
    builder.addCase(teamsSearchFetch.pending, (state) => {
      state.teamsSearch = {
        ...state.teamsSearch,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(teamsSearchFetch.fulfilled, (state, { payload }) => {
      state.teamsSearch = {
        ...state.teamsSearch,
        loading: false,
        results: payload.items,
        errors: null,
        count: payload.total,
      };
      state.teamsSearchPagination = {
        count: payload.total,
      };
    });
    builder.addCase(teamsSearchFetch.rejected, (state, { payload }) => {
      state.teamsSearch = {
        ...state.teamsSearch,
        loading: false,
        errors: payload,
        results: [],
      };
    });
  },
});
export const searchSelector = (state) => state.search;
export const {
  searchTermReducer,
  searchReducer,
  searchTermPromoReducer,
  searchTextNotReducer,
  searchTermStartReducer,
} = searchSlice.actions;
export default searchSlice.reducer;
