import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { message } from 'antd';

import { messagesErrorFetch, messagesSuccess } from 'components/Messages/Messagetext';
import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';
import { errorsHandler, successText } from 'utils/errorsHandler/errorsHandler';
import toQueryParams from 'utils/toQueryParams';

const nameSpace = 'teams';

const INITIAL_STATE = {
  results: null,
  teamsLoading: false,
  teamsError: null,
  search: {
    name: '',
  },
  teamsInfo: {
    loading: false,
    error: null,
    results: null,
  },
  teamChallenges: {
    results: null,
    loading: false,
    error: null,
  },
  createTeam: {
    name: '',
    organization: '',
    members: [],
  },
  editTeam: {
    members: [],
    deleted_members: [],
    name: '',
  },
  updateTeamData: {
    members: [],
    members_data: [],
  },
  teamsSuccess: {
    loading: false,
    error: null,
    success: 'not',
  },
  organizations: {
    loading: false,
    error: null,
    results: null,
  },
  deleteTeam: {
    errorsDelete: null,
    loadingDelete: false,
    history: '',
  },
  updatePutTeam: {
    loading: false,
    error: null,
    history: '',
  },
  getMembersEmails: {
    loading: false,
    error: null,
    success: 'not',
    invalidMembers: [],
  },
};

export const teamChallengesFetch = createAsyncThunk(
  `${nameSpace}/challengesFetch`,
  async ({ data, sort }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/challenges/${query}${sort}`);
      return {
        ...resp.data,
      };
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const teamsFetch = createAsyncThunk(
  `${nameSpace}/teamsFetch`,
  async ({ data }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/teams/${query}`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const teamInfoFetch = createAsyncThunk(
  `${nameSpace}/teamInfoFetch`,
  async ({ data, id }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/teams/${id}/${query}`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const createTeamFetch = createAsyncThunk(
  `${nameSpace}/createTeamFetch`,
  async ({ data }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.post(`/teams/`, data);
      message.success(messagesSuccess(successText('successAdd')));
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));

      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const fetchMembersEmails = createAsyncThunk(
  `${nameSpace}/fetchMembersEmails`,
  async ({ data }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.post(`/users/emails/`, data);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      if (e?.response?.status === 400) {
        message.error(messagesErrorFetch(e.response.data.user_emails));
      } else {
        message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      }

      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const teamsUpdate = createAsyncThunk(
  `${nameSpace}/teamsUpdate`,
  async ({ teamId, newData }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.put(`/teams/${teamId}/`, newData);
      return resp.data;
    } catch (e) {
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      return rejectWithValue(e?.response?.data);
    }
  },
);

export const teamsDeleteFetch = createAsyncThunk(
  `${nameSpace}/teamsDeleteFetch`,
  async ({ id }, { rejectWithValue }) => {
    try {
      await axiosApi.delete(`/teams/${id}/`);
      message.success(messagesSuccess(successText('successDelete')));
      return {
        id,
      };
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

const teamsSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  reducers: {
    setSearchReducer: (state, { payload }) => {
      state.search.name = payload;
    },
    creatingNullReducer: (state) => {
      state.createTeam = INITIAL_STATE.createTeam;
      state.teamsSuccess = {
        loading: false,
        error: null,
        success: 'not',
      };
      state.getMembersEmails = INITIAL_STATE.getMembersEmails;
    },
    createTeamReducer: (state, action) => {
      const keys = Object.keys(action.payload);
      state.createTeam[keys[0]] = action.payload[keys[0]];
    },
    organizationChoseReducer: (state, { payload }) => {
      state.createTeam.organization = payload.id;
    },
    addMemberReducer: (state, { payload }) => {
      state.createTeam.members = [
        ...state.createTeam.members,
        { member_id: payload.id, email: payload.email },
      ];
    },
    removeMemberReducer: (state, { payload }) => {
      state.createTeam.members = state.createTeam.members.filter(
        (item) => item.member_id !== payload.id,
      );
    },
    editTeamAddReducer: (state, { payload }) => {
      state.editTeam.name = payload?.name;
      state.updateTeamData.members = payload?.members.map((item) => ({ ...item, isHttps: true }));
      state.updateTeamData.members_data = [
        ...state.updateTeamData.members_data,
        ...payload.members_data.items.map((item) => ({
          ...item,
          isHttps: true,
        })),
      ];
    },
    editTeamInfoReducer: (state, { payload }) => {
      const keys = Object.keys(payload);
      state.editTeam[keys[0]] = payload[keys[0]];
    },
    editAddMember: (state, { payload }) => {
      state.editTeam.members = [...state.editTeam.members, { member_id: payload.id }];
      state.updateTeamData.members_data = [...state.updateTeamData.members_data, { ...payload }];
    },
    editRemoveMember: (state, { payload }) => {
      state.updateTeamData.members_data = state.updateTeamData.members_data.filter(
        (item) => item.id !== payload.member.id,
      );
      if (payload.member.isHttps) {
        state.editTeam.deleted_members = [...state.editTeam.deleted_members, payload.member.id];
      } else {
        state.editTeam.members = state.editTeam.members.filter(
          (item) => item.member_id !== payload.member.id,
        );
      }
    },
    teamUpdateNull: (state) => {
      state.updatePutTeam.history = '';
      state.updateTeamData = {
        members: [],
        members_data: [],
      };
      state.editTeam = {
        members: [],
        deleted_members: [],
        name: '',
      };
      state.getMembersEmails = INITIAL_STATE.getMembersEmails;
    },
    historyTeamsNull: (state) => {
      state.deleteTeam.history = '';
    },
    teamInfoNull: (state) => {
      state.teamsInfo = {
        results: null,
        loading: false,
        error: null,
      };
    },
    deleteInvalidMember: (state, { payload }) => {
      state.getMembersEmails.invalidMembers = state.getMembersEmails.invalidMembers.filter(
        (member) => member.id !== payload.id,
      );
    },
    clearTeams: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(teamsFetch.pending, (state) => {
      state.teamsLoading = true;
      state.teamsError = null;
    });
    builder.addCase(teamsFetch.fulfilled, (state, { payload }) => {
      state.teamsLoading = false;
      state.results = payload;
      state.teamsError = null;
    });
    builder.addCase(teamsFetch.rejected, (state, { payload }) => {
      state.teamsLoading = false;
      state.teamsError = payload;
    });
    builder.addCase(teamInfoFetch.pending, (state) => {
      state.teamsInfo.loading = true;
      state.teamsInfo.error = null;
    });
    builder.addCase(teamInfoFetch.fulfilled, (state, { payload }) => {
      state.teamsInfo.loading = false;
      state.teamsInfo.results = payload;
      state.teamsInfo.error = null;
    });
    builder.addCase(teamInfoFetch.rejected, (state, { payload }) => {
      state.teamsInfo.loading = false;
      state.teamsInfo.error = payload;
    });
    builder.addCase(createTeamFetch.pending, (state) => {
      state.teamsSuccess.loading = true;
      state.teamsSuccess.error = null;
    });
    builder.addCase(createTeamFetch.fulfilled, (state, { payload }) => {
      state.teamsSuccess.loading = false;
      state.teamsSuccess.error = null;
      state.teamsSuccess.success = 'ok';
      state.teamsInfo.results = payload;
    });
    builder.addCase(createTeamFetch.rejected, (state, { payload }) => {
      state.teamsSuccess.loading = false;
      state.teamsSuccess.error = payload;
    });
    builder.addCase(fetchMembersEmails.pending, (state) => {
      state.getMembersEmails.loading = true;
      state.getMembersEmails.error = null;
      state.getMembersEmails.success = 'not';
    });
    builder.addCase(fetchMembersEmails.fulfilled, (state, { payload }) => {
      const validMembers = [];
      const invalidMembers = [];

      Object.keys(payload).forEach((key, index) => {
        payload[key]
          ? validMembers.push({ email: key, member_id: payload[key], id: payload[key] })
          : invalidMembers.push({ email: key, id: index });
      });
      state.createTeam.members = validMembers;
      state.updateTeamData.members_data = [
        ...new Map(
          [...state.updateTeamData.members_data, ...validMembers].map((item) => [item.id, item]),
        ).values(),
      ];

      const newMembers = validMembers
        .filter(
          (member) =>
            !state.updateTeamData.members.some((data) => data.member_id === member.member_id),
        )
        .map((item) => ({
          member_id: item.member_id,
        }));

      state.editTeam.members = [...state.editTeam.members, ...newMembers];
      state.getMembersEmails = { loading: false, error: null, success: 'success', invalidMembers };
    });
    builder.addCase(fetchMembersEmails.rejected, (state, { payload }) => {
      state.getMembersEmails.loading = false;
      state.getMembersEmails.error = payload;
      state.getMembersEmails.success = 'not';
    });
    builder.addCase(teamsDeleteFetch.pending, (state) => {
      state.deleteTeam = {
        ...state.deleteTeam,
        loadingDelete: true,
        errorDelete: null,
      };
    });
    builder.addCase(teamsDeleteFetch.fulfilled, (state) => {
      state.deleteTeam = {
        ...state.deleteTeam,
        loadingDelete: false,
        history: 'success',
      };
    });
    builder.addCase(teamsDeleteFetch.rejected, (state, { payload }) => {
      state.deleteTeam = {
        ...state.deleteTeam,
        errorDelete: payload,
        loadingDelete: false,
      };
    });
    builder.addCase(teamsUpdate.pending, (state) => {
      state.updatePutTeam = {
        ...state.updatePutTeam,
        loading: true,
        error: null,
      };
    });
    builder.addCase(teamsUpdate.fulfilled, (state) => {
      state.updatePutTeam = {
        ...state.updatePutTeam,
        loading: false,
        error: null,
        history: 'success',
      };
    });
    builder.addCase(teamsUpdate.rejected, (state, { payload }) => {
      state.updatePutTeam = {
        ...state.updatePutTeam,
        loading: false,
        error: payload,
      };
    });
    builder.addCase(teamChallengesFetch.pending, (state) => {
      state.teamChallenges = {
        ...state.teamChallenges,
        loading: true,
        error: null,
      };
    });
    builder.addCase(teamChallengesFetch.fulfilled, (state, { payload }) => {
      state.teamChallenges = {
        ...state.teamChallenges,
        loading: false,
        results: payload,
      };
    });
    builder.addCase(teamChallengesFetch.rejected, (state, { payload }) => {
      state.teamChallenges = {
        ...state.teamChallenges,
        loading: false,
        error: payload,
      };
    });
  },
});

export const {
  setSearchReducer,
  creatingNullReducer,
  createTeamReducer,
  organizationChoseReducer,
  addMemberReducer,
  removeMemberReducer,
  editTeamAddReducer,
  editTeamInfoReducer,
  editAddMember,
  editRemoveMember,
  historyTeamsNull,
  teamUpdateNull,
  teamInfoNull,
  deleteInvalidMember,
  clearTeams,
} = teamsSlice.actions;
export const teamsSelector = (state) => state.teams;
export const teamsInfoSelector = (state) => state.teams.teamsInfo;
export const teamsDeleteSelector = (state) => state.teams.deleteTeam;
export const teamsPutSelector = (state) => state.teams.updatePutTeam;
export const teamsChallengesSelector = (state) => state.teams.teamChallenges;
export const emailsSelector = (state) => state.teams.getMembersEmails;
export default teamsSlice.reducer;
