import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { messagesErrorFetch, messagesSuccess } from 'components/Messages/Messagetext';
import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';
import { sundayDate, sundayEnd } from 'utils/dateFormat/dateFormat';
import { errorsHandler, successText } from 'utils/errorsHandler/errorsHandler';
import toQueryParams from 'utils/toQueryParams';

const nameSpace = 'promotions';

const INITIAL_STATE = {
  promotions: [],
  pagination: {
    count: 0,
    previous: null,
    next: '',
  },
  params: {
    page: 1,
  },
  searchTxtPromo: '',
  promotionsLoadingSearch: false,
  promotionInfo: null,
  getPromotionsSuccess: false,
  getPromotionsLoading: false,
  getPromotionsError: null,
  getPromotionFetchError: null,
  getPromotionFetchLoading: false,
  updatePromotionLoading: false,
  updatePromotionSuccess: false,
  updatePromotionError: null,
  updatePutPromotionLoading: false,
  updatePutPromotionError: null,
  historyEdit: '',
  deletePromotionLoading: false,
  deletePromotionError: null,
  deletePromotionSuccess: '',
  updatePromotionsNavigate: false,
  results: [],
  createPromotionData: {
    name: '',
    description: '',
    starts_at: sundayDate(),
    ends_at: sundayEnd(),
    url: '',
    button_text: '',
    images: [],
  },
  updatePromotionData: {
    name: '',
    description: '',
    starts_at: '',
    ends_at: '',
    url: '',
    button_text: '',
    images: [],
  },
  editPromotion: {
    images: [],
    deleted_images: [],
    loadingEdit: false,
    errors: null,
    successEdit: 'not',
  },
  promotionSuccess: {
    loading: false,
    errors: null,
    success: 'not',
  },
  dateValidate: false,
  sort: {
    status: {
      id: null,
      title: '',
      url: '',
    },
  },
  activeInactive: {
    loading: false,
    errors: null,
  },
};

export const promotionCreateFetch = createAsyncThunk(
  `${nameSpace}/promotionCreateFetch`,
  async ({ data }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.post('/promotions/', data);
      message.success(messagesSuccess(successText('successAdd')));
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const promotionsFetch = createAsyncThunk(
  `${nameSpace}/promotionsFetch`,
  async ({ data, sort, name = '' }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/promotions/${query}${sort}${name}`);
      return {
        ...resp.data,
        name,
      };
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const promotionFetchInfo = createAsyncThunk(
  `${nameSpace}/promotionFetchInfo`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/promotions/${id}/`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const promotionDelete = createAsyncThunk(
  `${nameSpace}/promotionDelete`,
  async (id, { rejectWithValue }) => {
    try {
      await axiosApi.delete(`/promotions/${id}/`);
      message.success(messagesSuccess(successText('successDelete')));
      return {
        id,
      };
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const promotionsActiveInactive = createAsyncThunk(
  `${nameSpace}/promotionsActiveInactive`,
  async ({ name, promotionId, newData }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.put(`/promotions/${promotionId}/`, newData);
      return resp.data;
    } catch (e) {
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      return rejectWithValue(e?.response?.data);
    }
  },
);

export const promotionUpdate = createAsyncThunk(
  `${nameSpace}/promotionUpdate`,
  async ({ promotionId, newData }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.put(`/promotions/${promotionId}/`, newData);
      return resp.data;
    } catch (e) {
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      return rejectWithValue(e?.response?.data);
    }
  },
);

const promotionsSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  reducers: {
    setPromotionData: (state, action) => {
      state.createPromotionData = { ...state.createPromotionData, ...action.payload };
    },
    promotionDragHandler: (state, action) => {
      state.createPromotionData.images = [...state.createPromotionData.images, action.payload];
    },
    promotionPhotoDownload: (state, action) => {
      state.createPromotionData.images = [...state.createPromotionData.images, action.payload];
    },
    deletePromotionPhoto: (state, action) => {
      state.createPromotionData.images = state.createPromotionData.images.filter(
        (item) => item.id !== action.payload,
      );
    },
    editAddPromotion: (state, action) => {
      state.updatePromotionData.name = action.payload.name;
      state.updatePromotionData.description = action.payload.description;
      state.editPromotion.images = action.payload.images;
      state.updatePromotionData.starts_at = action.payload.starts_at;
      state.updatePromotionData.ends_at = action.payload.ends_at;
      state.updatePromotionData.url = action.payload.url;
      state.updatePromotionData.button_text = action.payload.button_text;
    },
    promotionsGetHandler: (state, action) => {
      const keys = Object.keys(action.payload);
      state.updatePromotionData[keys[0]] = action.payload[keys[0]];
      if (keys[1]) {
        state.updatePromotionData[keys[1]] = action.payload[keys[1]];
      }
    },
    promotionsCheckboxHandler: (state) => {
      state.updatePromotionData.url = '';
      state.updatePromotionData.button_text = '';
    },
    promotionUpdatePhotoDownload: (state, action) => {
      state.updatePromotionData.images = [...state.updatePromotionData.images, action.payload];
    },
    deleteUpdatePromotionPhoto: (state, action) => {
      state.updatePromotionData.images = state.updatePromotionData.images.filter(
        (item) => item.id !== action.payload,
      );
    },
    deletePhotosHttps: (state, action) => {
      const photosEdit = state.editPromotion.images.filter((item) => item.id !== action.payload);
      state.editPromotion.deleted_images.push(action.payload);
      state.editPromotion.images = photosEdit;
    },
    clearState: () => INITIAL_STATE,
    imagesNPromotionClear: (state) => {
      state.updatePromotionData.images = [];
    },
    dateAddReducerPromotions: (state, action) => {
      state.createPromotionData.starts_at = action.payload.data.starts_at;
      state.createPromotionData.ends_at = action.payload.data.ends_at;
    },
    updatePromotionsDate: (state, action) => {
      state.updatePromotionData.starts_at = action.payload.data.starts_at;
      state.updatePromotionData.ends_at = action.payload.data.ends_at;
    },
    dateValidateReducer: (state, action) => {
      state.dateValidate = action.payload;
    },
    sortHandlerPromotionReducer: (state, action) => {
      state.sort = {
        ...state.sort,
        ...action.payload,
      };
    },
    nullSortPromotions: (state) => {
      state.sort = {
        status: {
          id: null,
          title: '',
          url: '',
        },
      };
    },
    deleteHistoryPromotion: (state) => {
      state.deletePromotionSuccess = '';
    },
    promotionsInfoErrorsNull: (state) => {
      state.getPromotionFetchError = null;
    },
    updatePromotionsNavigateNull: (state) => {
      state.updatePromotionsNavigate = false;
    },
    loadingActiveInactive: (state, action) => {
      const findPromotion = state.promotions.find((item) => item.id === action.payload.id);
      findPromotion.loading = true;
    },
    deleteImagesNull: (state) => {
      state.editPromotion.deleted_images = [];
    },
    addPromotionsSearch: (state, action) => {
      state.promotions = [...state.promotions, action.payload];
    },
    searchLoadingPromotions: (state) => {
      state.getPromotionsLoading = true;
    },
    addResultsPromotions: (state, action) => {
      state.promotions = action.payload.results;
      state.pagination = {
        ...state.pagination,
        count: action.payload.pagination.count,
        next: action.payload.pagination.next,
        previous: action.payload.pagination.previous,
      };
      state.sort = {
        status: {
          id: null,
          title: '',
          url: '',
        },
      };
    },
    clearResultsPromotions: (state) => {
      state.promotions = [];
    },
    addResultsInfoPromotions: (state, action) => {
      state.promotionInfo = action.payload;
    },
    clearPromotionsResultsSort: (state) => {
      state.promotions = [];
      state.sort = {
        status: {
          id: null,
          title: '',
          url: '',
        },
      };
    },
    createButtonUrlNull: (state) => {
      state.createPromotionData.url = '';
      state.createPromotionData.button_text = '';
    },
    addPageParamsPromotions: (state) => {
      state.params.page += 1;
    },
    minusPageParamsPromotions: (state) => {
      state.params.page -= 1;
    },
    nullPageParamsPromotions: (state) => {
      state.params.page = 1;
    },
    searchTxtPromoReducer: (state) => {
      state.searchTxtPromo = 'success';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(promotionCreateFetch.pending, ({ promotionSuccess }) => {
      promotionSuccess.loading = true;
      promotionSuccess.errors = null;
    });
    builder.addCase(promotionCreateFetch.fulfilled, (state, { payload }) => {
      state.results = [payload, ...state.results];
      state.promotionInfo = payload;
      state.promotionSuccess = {
        ...state.promotionSuccess,
        loading: false,
        errors: null,
        success: 'ok',
      };
    });
    builder.addCase(promotionCreateFetch.rejected, (state) => {
      state.promotionSuccess = {
        ...state.promotionSuccess,
        errors: null,
        loading: false,
      };
    });
    builder.addCase(promotionsFetch.pending, (state, action) => {
      state.getPromotionsError = null;
      state.getPromotionsLoading = true;
      state.getPromotionsSuccess = false;
      if (action?.meta.arg.name) {
        state.promotionsLoadingSearch = true;
      }
    });
    builder.addCase(promotionsFetch.fulfilled, (state, { payload }) => {
      state.getPromotionsError = null;
      state.getPromotionsLoading = false;
      state.getPromotionsSuccess = true;
      state.promotions = payload.results;
      state.searchTxtPromo = '';
      state.pagination = {
        ...state.pagination,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
      if (payload.name) {
        state.promotionsLoadingSearch = false;
      }
    });
    builder.addCase(promotionsFetch.rejected, (state, { payload }) => {
      state.getPromotionsLoading = false;
      state.getPromotionsSuccess = false;
      state.getPromotionsError = payload;
      state.promotionsLoadingSearch = false;
    });
    builder.addCase(promotionFetchInfo.pending, (state) => {
      state.getPromotionFetchLoading = true;
      state.getPromotionFetchError = null;
    });
    builder.addCase(promotionFetchInfo.fulfilled, (state, { payload }) => {
      state.promotionInfo = payload;
      state.getPromotionFetchLoading = false;
      state.getPromotionFetchError = null;
    });
    builder.addCase(promotionFetchInfo.rejected, (state, { payload }) => {
      state.getPromotionFetchLoading = false;
      state.getPromotionFetchError = payload;
    });
    builder.addCase(promotionDelete.pending, (state) => {
      state.deletePromotionLoading = true;
    });
    builder.addCase(promotionDelete.fulfilled, (state, { payload }) => {
      state.promotions = state.promotions.filter((item) => item.id !== Number(payload.id));
      state.deletePromotionLoading = false;
      state.deletePromotionSuccess = 'push';
    });
    builder.addCase(promotionDelete.rejected, (state, { payload }) => {
      state.deletePromotionLoading = false;
      state.deletePromotionSuccess = '';
      state.deletePromotionError = payload;
    });
    builder.addCase(promotionUpdate.pending, (state) => {
      state.updatePutPromotionLoading = true;
      state.updatePutPromotionError = null;
    });
    builder.addCase(promotionUpdate.fulfilled, (state, { payload }) => {
      const findUpdate =
        state?.promotions?.find((item) => item.id === payload.id) || state.promotionInfo;
      findUpdate.images = payload.images;
      findUpdate.button_text = payload.button_text;
      findUpdate.starts_at = payload.starts_at;
      findUpdate.ends_at = payload.ends_at;
      findUpdate.name = payload.name;
      findUpdate.description = payload.description;
      findUpdate.url = payload.url;
      findUpdate.status = payload.status;
      findUpdate.loading = false;
      state.promotionInfo = payload;
      state.promotionInfo.images = payload.images;
      state.updatePutPromotionLoading = false;
      state.updatePutPromotionError = null;
      state.updatePromotionsNavigate = true;
    });
    builder.addCase(promotionUpdate.rejected, (state, { payload }) => {
      state.updatePutPromotionError = payload;
      state.updatePutPromotionLoading = false;
    });
    builder.addCase(promotionsActiveInactive.pending, (state) => {
      state.activeInactive = {
        ...state.activeInactive,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(promotionsActiveInactive.fulfilled, (state, { payload }) => {
      const findUpdate =
        state?.promotions?.find((item) => item.id === payload.id) || state.promotionInfo;
      findUpdate.status = payload.status;
      findUpdate.loading = false;
      state.promotionInfo = payload;
      state.activeInactive = {
        ...state.activeInactive,
        loading: false,
        errors: null,
      };
    });
    builder.addCase(promotionsActiveInactive.rejected, (state, { payload }) => {
      state.activeInactive = {
        ...state.activeInactive,
        errors: payload,
        loading: false,
      };
    });
  },
});

export const {
  setPromotionData,
  promotionDragHandler,
  promotionPhotoDownload,
  deletePromotionPhoto,
  clearState,
  editAddPromotion,
  deletePhotosHttps,
  promotionsGetHandler,
  promotionUpdatePhotoDownload,
  deleteUpdatePromotionPhoto,
  imagesNPromotionClear,
  dateAddReducerPromotions,
  updatePromotionsDate,
  dateValidateReducer,
  sortHandlerPromotionReducer,
  nullSortPromotions,
  deleteHistoryPromotion,
  promotionsInfoErrorsNull,
  updatePromotionsNavigateNull,
  loadingActiveInactive,
  promotionsCheckboxHandler,
  deleteImagesNull,
  addPromotionsSearch,
  searchLoadingPromotions,
  addResultsPromotions,
  clearResultsPromotions,
  addResultsInfoPromotions,
  clearPromotionsResultsSort,
  createButtonUrlNull,
  addPageParamsPromotions,
  minusPageParamsPromotions,
  nullPageParamsPromotions,
  searchTxtPromoReducer,
} = promotionsSlice.actions;
export const promotionsSelector = (state) => state.promotions;
export const paginationPromotionsSelector = (state) => state.promotions.pagination;
export const promotionsCreateDataSelector = (state) => state.promotions.createPromotionData;
export const promotionsEditDataSelector = (state) => state.promotions.editPromotion;
export default promotionsSlice.reducer;
