import { Form, Input } from 'antd';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import localesUtils from 'utils/localesUtils/localesUtils';

const DefaultField = ({
  id = '',
  type = '',
  label = '',
  name = '',
  className = '',
  inputClassName = '',
  placeholder = '',
  dependencies = [],
  rules = [],
  onChange = undefined,
  onClick = undefined,
  textErrorsRu = '',
  textErrorsEn = '',
  dataName = '',
}) => {
  DefaultField.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    placeholder: PropTypes.string,
    dependencies: PropTypes.instanceOf(Array),
    rules: PropTypes.instanceOf(Array),
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    textErrorsRu: PropTypes.string,
    textErrorsEn: PropTypes.string,
    dataName: PropTypes.string,
  };

  useEffect(() => {
    const dom = document.querySelectorAll('.ant-form-item-explain-error');
    if (dom.length && textErrorsRu && textErrorsEn) {
      dom.forEach((item) => {
        if (item.textContent === textErrorsRu) {
          item.innerHTML = localesUtils(textErrorsRu, textErrorsEn);
        } else if (item.textContent === textErrorsEn) {
          item.innerHTML = localesUtils(textErrorsRu, textErrorsEn);
        }
      });
    }
  }, [i18n?.language]);

  return (
    <Form.Item
      type={type || 'text'}
      id={id}
      className={className}
      label={label}
      name={name}
      dependencies={dependencies}
      rules={rules}
    >
      <Input
        data-name={dataName}
        onChange={onChange}
        onClick={onClick}
        name={name}
        className={inputClassName}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default DefaultField;
