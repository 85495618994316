import { Button, Tabs } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import deleteIcons from 'assets/images/icons/Delete.svg';
import editIcons from 'assets/images/icons/Edit.svg';
import Errors from 'components/Errors/Errors';
import PlugForLists from 'components/NoChallenge/plugForLists';
import ShowAllCard from 'components/ShowAllCard/ShowAllCard';
import SkeletonBlock from 'components/SkeletonBlock/SkeletonBlock';
import SkeletonTeamInfo from 'components/SkeletonBlock/SkeletonTeamInfo/SkeletonTeamInfo';
import TableComponent from 'components/TableComponent/TableComponent';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalDelete from 'components/UI/ModalDelete/ModalDelete';
import { creatingNullReducer } from 'redux/challengesSlice/challengesSlice';
import { searchReducer } from 'redux/search/searchSlice';
import {
  historyTeamsNull,
  teamChallengesFetch,
  teamInfoFetch,
  teamInfoNull,
  teamsChallengesSelector,
  teamsDeleteFetch,
  teamsDeleteSelector,
  teamsFetch,
  teamsInfoSelector,
  teamsSelector,
  teamUpdateNull,
} from 'redux/teams/teamsSlice';
import { statusAccount, stravaStatus } from 'utils/constants';
import { dateTimeFormat, dateTimeToFormat } from 'utils/dateFormat/dateFormat';

const Info = () => {
  const b = bem('TeamsInfo');
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const push = useNavigate();
  const { loading, error } = useSelector(teamsInfoSelector);
  const { teamsInfo } = useSelector(teamsSelector);
  const {
    results,
    loading: challengesLoading,
    error: challengesError,
  } = useSelector(teamsChallengesSelector);
  const { history, loadingDelete } = useSelector(teamsDeleteSelector);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [params, setParams] = useState({ page: 1 });

  useEffect(() => {
    const data = {
      query: { page: 1, team: id },
    };
    dispatch(teamChallengesFetch({ data, sort: `&sort=status,-id` }));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (history === 'success') {
      const data = {
        query: { page: 1 },
      };
      push('/teams');
      dispatch(teamsFetch({ data }));
    }

    dispatch(historyTeamsNull());
  }, [dispatch, history, push]);

  const updateTeamHandler = () => {
    dispatch(teamUpdateNull());
    dispatch(teamInfoNull());
    push(`/teams/update-teams/${id}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const deleteTeams = () => {
    dispatch(teamsDeleteFetch({ id }));
  };

  if (error) {
    return <Errors status={error} />;
  }

  const nextBrowserChallengesHandler = (id) => {
    push(`/challenges/challenge-info/${id}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(searchReducer(''));
  };

  const pushHandler = () => {
    dispatch(creatingNullReducer());
    push('/challenges/creatingChallenges');
  };

  const nextHandler = () => {
    setParams((prev) => ({ page: prev + 1 }));
    const data = {
      query: { page: params.page + 1 || 1, team: id },
    };
    dispatch(teamChallengesFetch({ data, sort: `&sort=status,-id` }));
  };

  const backHandler = () => {
    setParams((prev) => ({ page: prev - 1 }));
    const data = {
      query: { page: params.page - 1 || 1, team: id },
    };
    dispatch(teamChallengesFetch({ data, sort: `&sort=status,-id` }));
  };

  const tabs = [
    {
      key: '1',
      label: <p className={b('tab')}>{t('teams.participants')}</p>,
      children: (
        <TableComponent
          isTeams
          filterData={teamsInfo.results?.members_data?.items}
          statusFilterMap={statusAccount}
          stravaFilterMap={stravaStatus}
          placeholderForStatusName={t('home.account_status')}
          searchPlaceholder={t('challenges.search_participants')}
          challengeIds={id}
          usersList={teamsInfo.results?.members_data}
          usersParticipantsSearchFetch={teamInfoFetch}
          usersParticipantsList={teamsInfo.results?.members_data}
          loadingIndicator={loading}
        />
      ),
    },
    {
      key: '2',
      label: <p className={b('tab')}>{t('challenges.name')}</p>,
      children: (
        <>
          {error ? <Errors status={challengesError} /> : null}
          {challengesLoading ? (
            <SkeletonBlock active={challengesLoading} num={20} titleBool />
          ) : (
            <>
              <ShowAllCard
                fileLoading={false}
                results={results?.results}
                options={{}}
                onChange={() => {}}
                category
                requirements
                nextBrowserHandler={nextBrowserChallengesHandler}
              />
              <div className='Challenges__pagination_btn'>
                <Button
                  className='btn_Challenges'
                  type='primary'
                  size='large'
                  disabled={results?.next === null}
                  onClick={() => backHandler()}
                >
                  {t('promotions.back')}
                </Button>
                <Button
                  className='btn_Challenges'
                  type='primary'
                  size='large'
                  disabled={results?.previous === null}
                  onClick={() => nextHandler()}
                >
                  {t('promotions.next')}
                </Button>
              </div>
            </>
          )}

          {!results?.results.length ? (
            <PlugForLists
              pushHandler={pushHandler}
              mainText={t('challenges.no_challenges')}
              subMainText={t('challenges.this_team_has_no_challenges')}
              buttonText={t('challenges.creating_challenges')}
            />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <div className={b()}>
      <div className={b('header')}>
        {!loading && teamsInfo.results ? (
          <>
            <div className={b('info')}>
              <h1 className={b('title')}>{teamsInfo.results?.name}</h1>
              <div className={b('additional_info')}>
                <p className={b('date-info')}>
                  Дата создания:{' '}
                  <span>{dateTimeToFormat(teamsInfo.results?.created_at, dateTimeFormat)}</span>
                </p>
                <p className={b('date-info')}>
                  Организация: <span>{teamsInfo.results?.organization_name} </span>
                </p>
              </div>
            </div>
            <div className={b('buttons')}>
              <Button
                icon={
                  <span className='paddingRight'>
                    <img src={editIcons} alt='editIcons' />
                  </span>
                }
                type='primary'
                style={{
                  width: '100%',
                  height: 44,
                  fontSize: 14,
                  fontWeight: 600,
                  borderRadius: 6,
                }}
                onClick={updateTeamHandler}
              >
                {t('teams.edit_team')}
              </Button>
              <Button
                onClick={() => setIsModalVisible(true)}
                icon={
                  <span className='paddingRight'>
                    <img src={deleteIcons} alt='deleteIcons' />
                  </span>
                }
                className='delete_btn'
                style={{
                  backgroundColor: 'rgba(0, 122, 255, 0.05)',
                  width: '100%',
                  height: 44,
                  color: '#EB4335',
                  fontSize: 14,
                  fontWeight: 600,
                  borderRadius: 6,
                }}
              >
                {t('teams.delete_team')}
              </Button>
              <ModalComponent
                onCancel={() => setIsModalVisible(false)}
                open={isModalVisible}
                width={443}
                height='100%'
                className={b('modal-style')}
              >
                <ModalDelete
                  title={t('teams.are_you_sure_you_want_to_delete_the_team')}
                  text={t('teams.the_team_will_be_permanently_deleted')}
                  noneDeleteHandler={() => setIsModalVisible(false)}
                  yesDeleteHandler={deleteTeams}
                  loading={loadingDelete}
                />
              </ModalComponent>
            </div>
          </>
        ) : (
          <SkeletonTeamInfo />
        )}
      </div>
      <div className={b('tabs')}>
        <Tabs defaultActiveKey='1' items={tabs} />
      </div>
    </div>
  );
};

export default Info;
