import { Popover } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

import notPhoto from 'assets/images/notPhotos.png';
import photoPlug from 'assets/images/photo-plug.png';
import teamsIcons from 'assets/images/statusIcons/Team.svg';
import CardComponent from 'components/CardComponent/CardComponent';
import FormField from 'components/FormField/FormField';
import ShowAllCardStatus from 'components/ShowAllCard/ShowAllCardStatus/ShowAllCardStatus';
import {
  dateEnd,
  dateFormat,
  dateTimeMomentFormat,
  dateTimeToFormat,
} from 'utils/dateFormat/dateFormat';
import localesUtils from 'utils/localesUtils/localesUtils';
import navigatorUserAgent from 'utils/navigatorUserAgent/navigatorUserAgent';
import { resultsKmMeters } from 'utils/resultsKmMeters/resultsKmMeters';

const ResultsCard = ({
  results = [],
  options = {},
  onChange = undefined,
  nextBrowserHandler = false,
  status = false,
  category = false,
  dataDisableEnd = false,
  requirements = false,
  isNews = false,
}) => {
  ResultsCard.propTypes = {
    results: PropTypes.instanceOf(Array).isRequired,
    options: PropTypes.instanceOf(Object).isRequired,
    onChange: PropTypes.func.isRequired,
    nextBrowserHandler: PropTypes.func,
    status: PropTypes.bool,
    category: PropTypes.bool,
    dataDisableEnd: PropTypes.bool,
    requirements: PropTypes.bool,
    isNews: PropTypes.bool,
  };
  const b = bem('ShowAllCard');
  const { t } = useTranslation();
  return results?.map((item) => {
    const content = <span className='content_popover'>{item?.category?.name}</span>;
    return (
      <div data-testid='card_list' key={item.id} className={b('block_card')}>
        {!isNews ? (
          <div className='status_card'>
            <Popover
              style={{
                backgroundColor: 'red',
              }}
              placement='rightTop'
              content={content}
            >
              {category ? (
                <div className='status_cardBlock'>
                  <ShowAllCardStatus icon={item?.category?.icon} name={item?.category?.name} />
                </div>
              ) : null}
            </Popover>
            {status ? (
              <div className={b('block_category_active')}>
                <FormField
                  disabled={dataDisableEnd ? !dateEnd(item.ends_at) : false}
                  loadingUpdate={item.loading}
                  type='selectStatus'
                  options={options}
                  size={20}
                  value={item.status}
                  onChange={onChange}
                  id={item.id}
                />
              </div>
            ) : null}
            {item.team ? (
              <div className={b('team_indicator')}>
                <Popover placement='rightTop' content='HR Challenge'>
                  <img src={teamsIcons} alt='HR Challenge' width={30} />
                </Popover>
              </div>
            ) : null}
          </div>
        ) : null}

        <button
          type='button'
          onClick={() => nextBrowserHandler(item.id)}
          key={item.id}
          className='block_card_text btn_block_style_none'
        >
          <CardComponent
            hoverable
            className={requirements ? '' : 'CardComponent__card-promotions'}
            cover={
              item.images?.length ? (
                <img
                  style={{
                    height: 140,
                  }}
                  alt='example'
                  src={item.images[0]?.image ? item?.images[0].image : notPhoto}
                />
              ) : (
                <img
                  style={{
                    height: 140,
                  }}
                  alt='example'
                  src={photoPlug}
                />
              )
            }
          >
            <div className='block_card d-block'>
              <p className='card_title title_card_hidden'>{isNews ? item.title : item.name}</p>
              <div className='card_text text_card_hidden'>
                {isNews ? item.text : ReactHtmlParser(item.description)}
              </div>
            </div>
            {requirements && !isNews ? (
              <div className={`block_run ${navigatorUserAgent()}_scroll`}>
                {dateEnd(item.starts_at) || dateEnd(item.ends_at) ? (
                  <div className='block_text'>
                    <p className='run_title'>{t('challenges.willStart')}</p>
                    <p className='run_text'>{dateFormat(item.starts_at)}</p>
                  </div>
                ) : null}
                {!dateEnd(item.starts_at) && dateEnd(item.ends_at) ? (
                  <div className='block_text'>
                    <p className='run_title'>{t('challenges.ended')}</p>
                    <p className='run_text'>{dateFormat(item.ends_at)}</p>
                  </div>
                ) : null}
                {!dateEnd(item.starts_at) && !dateEnd(item.ends_at) ? (
                  <div className='block_text'>
                    <p className='run_title'>{t('challenges.ended')}</p>
                    <p className='run_text'>{t('challenges.finished')}</p>
                  </div>
                ) : null}
                {item.requirements?.map((item) => {
                  return (
                    <div key={item.id} className='block_text'>
                      <p className='run_title'>
                        {localesUtils(item.requirement.name_ru, item.requirement.name_en)}
                      </p>
                      <p className='run_text'>
                        {resultsKmMeters(item.unit_amount, item.requirement.measurement_unit)}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {isNews ? (
              <div className='block_run'>
                <div className='block_text'>
                  <p className='run_title'>{t('news.publish_date')}</p>
                  <p className='run_text'>
                    {dateTimeToFormat(item.created_at, dateTimeMomentFormat)}
                  </p>
                </div>
              </div>
            ) : null}
          </CardComponent>
        </button>
      </div>
    );
  });
};

export default ResultsCard;
