import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Divider, Select, Space } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import csvIcon from 'assets/images/icons/csv.svg';
import FormField from 'components/FormField/FormField';
import { userGenderRoles } from 'utils/constants';

const { Option } = Select;

const SearchTable = ({
  isTeams = false,
  searchHandler = undefined,
  searchPlaceholder = '',
  placeholderForStatusName = '',
  onFilterChangeHandler = undefined,
  onChangeRegisterDateHandler = undefined,
  valueFrom = null,
  valueTo = null,
  onChangeTo = null,
  onChangeFrom = undefined,
  ageFrom = null,
  statusFilterMap = [],
  stravaFilterMap = [],
  challengeIds = '',
  exportHandler = undefined,
  exportLoading = false,
  disabledExport = true,
}) => {
  SearchTable.propTypes = {
    isTeams: PropTypes.bool,
    searchHandler: PropTypes.func || undefined,
    searchPlaceholder: PropTypes.string,
    placeholderForStatusName: PropTypes.string,
    onFilterChangeHandler: PropTypes.func,
    onChangeRegisterDateHandler: PropTypes.func,
    valueFrom: PropTypes.number,
    valueTo: PropTypes.number,
    onChangeTo: PropTypes.func,
    ageFrom: PropTypes.number,
    onChangeFrom: PropTypes.func,
    statusFilterMap: PropTypes.instanceOf(Array),
    stravaFilterMap: PropTypes.instanceOf(Array),
    challengeIds: PropTypes.string,
    exportHandler: PropTypes.func,
    exportLoading: PropTypes.bool,
    disabledExport: PropTypes.bool,
  };
  const b = bem('search_table');
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <div className={b('search_input')}>
        <div className='block_flex_search'>
          <div className='search_upper'>
            <AutoComplete
              allowClear
              onChange={searchHandler}
              className='bg_input'
              size='large'
              placeholder={searchPlaceholder}
              prefix={<SearchOutlined />}
            />
            {challengeIds && !isTeams ? (
              <Button
                type='text'
                className={b('export_file')}
                onClick={exportHandler}
                loading={exportLoading}
                disabled={disabledExport}
              >
                <img src={csvIcon} alt={t('challenges.export_csv')} />
                {t('challenges.export_csv')}
              </Button>
            ) : null}
          </div>
          <Divider />
          <div className={b('date_table')}>
            {!isTeams && (
              <>
                <div>
                  <div className='label_text'>{t('challenges.date_of_birth_from')}</div>
                  <div className='age_input'>
                    <Space direction='vertical' size={12}>
                      <FormField
                        type='selectTags'
                        placeholder={t('home.age_from')}
                        value={valueFrom}
                        onChange={onChangeFrom}
                        allowClear
                      />
                    </Space>
                  </div>
                </div>
                <div style={{ marginTop: 33 }}>
                  <p>—</p>
                </div>
                <div>
                  <div className='label_text'>{t('challenges.date_of_birth_to')}</div>
                  <div className='age_input'>
                    <Space direction='vertical' size={12}>
                      <FormField
                        type='selectTags'
                        placeholder={t('home.age_to')}
                        value={valueTo}
                        onChange={onChangeTo}
                        ageFrom={ageFrom}
                        allowClear
                      />
                    </Space>
                  </div>
                </div>
              </>
            )}

            <div>
              <div className='label_text'>{t('challenges.date_of_registration')}</div>
              <div className='date_input'>
                <Space direction='vertical' size={12}>
                  <FormField
                    type='rangePicker'
                    format='DD-MM-YYYY'
                    onChange={onChangeRegisterDateHandler}
                  />
                </Space>
              </div>
            </div>
            {!isTeams && (
              <div className={b('select_table')}>
                <div className='label_text'>{t('home.choose_gender')}</div>
                <div className={b('bg_select')}>
                  <Select
                    className='select_lan'
                    style={{
                      width: 244,
                    }}
                    allowClear
                    placeholder={t('home.choose_gender')}
                    onChange={(e) => onFilterChangeHandler('gender', e)}
                  >
                    {userGenderRoles.map((gender) => {
                      return (
                        <Option key={gender.name} value={gender.value}>
                          {t(`search.${gender.name}`)}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            )}

            {isTeams && (
              <div className={b('select_table')}>
                <div className='label_text'>{t('teams.strava_account')}</div>
                <div className={b('bg_select')}>
                  <Select
                    className='select_lan'
                    style={{
                      width: 244,
                    }}
                    allowClear
                    placeholder={t('teams.strava_account')}
                    onChange={(e) => onFilterChangeHandler('strava_integration_allowed', e)}
                  >
                    {stravaFilterMap.map((status) => {
                      return (
                        <Option key={status.name} value={status.value}>
                          {t(`search.${status.name}`)}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            )}
            <div className={b('select_table')}>
              <div className='label_text'>{placeholderForStatusName}</div>
              <div className={b('bg_select')}>
                <Select
                  className='select_lan'
                  style={{
                    width: 244,
                  }}
                  allowClear
                  placeholder={placeholderForStatusName}
                  onChange={(e) => onFilterChangeHandler('status', e)}
                >
                  {statusFilterMap.map((status) => {
                    return (
                      <Option key={status.name} value={status.value}>
                        {t(`search.${status.name}`)}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTable;
