import PropTypes from 'prop-types';
import React from 'react';

import ReactHtmlParser from 'react-html-parser';

import ShowAllCardStatus from 'components/ShowAllCard/ShowAllCardStatus/ShowAllCardStatus';
import { capitalize } from 'utils/constants';

const SearchCard = ({ item = {}, isChallenge }) => {
  SearchCard.propTypes = {
    item: PropTypes.instanceOf(Object),
    isChallenge: PropTypes.bool,
  };
  return (
    <div className='block_search' key={item.id}>
      <p>{capitalize(item?.name)}</p>
      {isChallenge && (
        <div
          className={`status_challenges width_block_search ${
            item.status === 0 ? 'status_challenges_icon_active' : 'status_challenges_icon_inactive'
          }`}
        >
          <div>
            <ShowAllCardStatus icon={item?.category?.icon} name={item?.category.name} />
          </div>
        </div>
      )}
      {!isChallenge && (
        <div className='text text_card_hidden_promotions'>{ReactHtmlParser(item?.description)}</div>
      )}
    </div>
  );
};

export default SearchCard;
