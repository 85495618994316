import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';

const StatusRun = ({ name = '', category = '' }) => {
  StatusRun.propTypes = {
    name: PropTypes.string,
    category: PropTypes.string,
  };
  const b = bem('StatusRun');
  return (
    <div data-testid='status_run' className={b()}>
      <span className='title'>{name}</span> <br />
      <span className='text'>{category}</span>
    </div>
  );
};

export default StatusRun;
