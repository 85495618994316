import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SearchTable from 'components/TableComponent/SearchTable/SearchTable';
import DrawerProfile from 'components/TableComponent/TableList/DrawerProfile/DrawerProfile';
import TableList from 'components/TableComponent/TableList/TableList';

import { exportParticipantsToCsv } from 'redux/users/usersSlice';
import { deleteEmptyQueryStrings } from 'utils/constants';

import { datePickerFormat } from 'utils/dateFormat/dateFormat';

const TableComponent = ({
  isTeams = false,
  stravaFilterMap = [],
  usersList = undefined,
  searchPlaceholder = '',
  usersParticipantsSearchFetch = undefined,
  filterData = undefined,
  onChangeBirthDateHandler = undefined,
  loadingIndicator = undefined,
  challengeIds = '',
  statusInfo = '',
  placeholderForStatusName = '',
  statusFilterMap = [],
  usersParticipantsList = {},
  exportLoading = false,
  hasSearch = true,
}) => {
  TableComponent.propTypes = {
    isTeams: PropTypes.bool,
    usersList: PropTypes.instanceOf(Object) || undefined,
    searchPlaceholder: PropTypes.string,
    onChangeBirthDateHandler: PropTypes.func,
    usersParticipantsSearchFetch: PropTypes.func,
    filterData: PropTypes.instanceOf(Array),
    usersParticipantsList: PropTypes.instanceOf(Object),
    loadingIndicator: PropTypes.bool,
    statusInfo: PropTypes.string,
    challengeIds: PropTypes.string,
    placeholderForStatusName: PropTypes.string,
    statusFilterMap: PropTypes.instanceOf(Array),
    stravaFilterMap: PropTypes.instanceOf(Array),
    exportLoading: PropTypes.bool,
    hasSearch: PropTypes.bool,
  };
  const dispatch = useDispatch();
  const [usersSearch, setUsersSearch] = useState({ search: '' });
  const [filters, setFilters] = useState({
    page: 1,
    size: 10,
    gender: '',
    status: '',
    registered__from: '',
    registered__to: '',
    age_from: null,
    age_to: null,
    strava_integration_allowed: '',
  });
  const [usersSort, setUsersSort] = useState({
    by_profile_name: '',
    by_profile_email: '',
    by_registration: '',
    by_profile_date_of_birth: '',
    by_application_update: '',
  });
  const [visible, setVisible] = useState(false);
  const totalUsersPages =
    usersParticipantsList && Math.ceil(usersParticipantsList.total / usersParticipantsList.size);

  const columnSorters = {
    first_name: 'by_profile_name',
    email: 'by_profile_email',
    registered_on: 'by_registration',
    date_of_birth: 'by_profile_date_of_birth',
    status: 'by_application_update',
  };

  useEffect(() => {
    const queryObj = {
      challenge_ids: challengeIds,
      page: filters.page,
      size: filters.size,
      gender: filters.gender,
      status: filters.status,
      age_from: filters.age_from,
      age_to: filters.age_to,
      registered__from: filters.registered__from,
      registered__to: filters.registered__to,
      strava_integration_allowed: filters.strava_integration_allowed,
      by_profile_name: usersSort.by_profile_name,
      by_profile_email: usersSort.by_profile_email,
      by_profile_date_of_birth: usersSort.by_profile_date_of_birth,
      by_registration: usersSort.by_registration,
      by_application_update: usersSort.by_application_update,
    };

    if (isTeams) delete queryObj.challenge_ids;
    const validateQuery = deleteEmptyQueryStrings(queryObj);
    const data = {
      query: validateQuery,
    };
    const delay = setTimeout(() => {
      dispatch(usersParticipantsSearchFetch({ data, id: challengeIds }));
    }, 500);
    return () => clearTimeout(delay);
  }, [
    dispatch,
    filters.page,
    filters.size,
    filters.gender,
    filters.status,
    filters.registered__from,
    filters.registered__to,
    filters.strava_integration_allowed,
    usersSort.by_profile_name,
    usersSort.by_profile_email,
    usersSort.by_profile_date_of_birth,
    usersSort.by_registration,
    usersSort.by_application_update,
    filters.age_from,
    filters.age_to,
    challengeIds,
  ]);

  useEffect(() => {
    const queryObj = {
      challenge_ids: challengeIds,
      search: usersSearch.search,
    };

    if (isTeams) delete queryObj.challenge_ids;
    const validateQuery = deleteEmptyQueryStrings(queryObj);
    const data = {
      query: validateQuery,
    };
    const delay = setTimeout(() => {
      if (usersSearch?.search) {
        dispatch(usersParticipantsSearchFetch({ data, id: challengeIds }));
      }
    }, 500);
    return () => clearTimeout(delay);
  }, [dispatch, challengeIds, usersSearch.search]);

  const searchHandler = (e) => {
    const queryObj = {
      challenge_ids: challengeIds,
      search: '',
    };

    if (isTeams) delete queryObj.challenge_ids;
    const validateQuery = deleteEmptyQueryStrings(queryObj);
    const data = {
      query: validateQuery,
    };
    setUsersSearch({ ...usersSearch, search: e?.toLowerCase() });
    if (!e?.length && e !== undefined) {
      dispatch(usersParticipantsSearchFetch({ data, id: challengeIds }));
    }
  };

  const onChangeRegisterDateHandler = (date) => {
    if (date) {
      const from = datePickerFormat(date[0]);
      const to = datePickerFormat(date[1]);
      setFilters({ ...filters, page: 1, registered__from: from, registered__to: to });
    } else {
      setFilters({ ...filters, page: 1, registered__from: '', registered__to: '' });
    }
  };

  const handleTableSortChange = (pagination, filters, sorter) => {
    const order = sorter.order === 'descend' ? 'down' : 'up';
    const initialSort = Object.fromEntries(Object.keys(usersSort).map((key) => [key, '']));
    setUsersSort({
      ...initialSort,
      [columnSorters[sorter.columnKey]]: order,
    });
  };

  const onFilterChangeHandler = (name, value) => {
    if (isNaN(value) || (name === 'strava_integration_allowed' && typeof value !== 'boolean')) {
      setFilters({ ...filters, page: 1, [name]: '' });
    } else {
      setFilters({ ...filters, page: 1, [name]: value });
    }

    if (name === 'strava_integration_allowed') {
      setUsersSort({
        ...usersSort,
        by_application_update: (usersSort.by_application_update = 'up'),
      });
    }
  };

  const handleChange = (value) => {
    setFilters({ ...filters, page: 1, size: value });
  };

  const pagePrevHandler = () => {
    setFilters({ ...filters, page: filters.page - 1 });
  };

  const pageNextHandler = () => {
    setFilters({ ...filters, page: filters.page + 1 });
  };

  const onClose = () => {
    setVisible(false);
  };

  const exportHandler = () => {
    const queryObj = {
      challenge_ids: challengeIds,
      page: filters.page,
      size: filters.size,
      gender: filters.gender,
      status: filters.status,
      age_from: filters.age_from,
      age_to: filters.age_to,
      registered__from: filters.registered__from,
      registered__to: filters.registered__to,
      strava_integration_allowed: filters.strava_integration_allowed,
      by_profile_name: usersSort.by_profile_name,
      by_profile_email: usersSort.by_profile_email,
      by_profile_date_of_birth: usersSort.by_profile_date_of_birth,
      by_registration: usersSort.by_registration,
      by_application_update: usersSort.by_application_update,
      search: usersSearch.search,
    };

    const validateQuery = deleteEmptyQueryStrings(queryObj);
    const data = {
      query: validateQuery,
    };

    dispatch(exportParticipantsToCsv({ data, id: challengeIds }));
  };

  return (
    <div className='container_block'>
      {hasSearch ? (
        <SearchTable
          isTeams={isTeams}
          onFilterChangeHandler={onFilterChangeHandler}
          searchHandler={searchHandler}
          searchPlaceholder={searchPlaceholder}
          onChangeBirthDateHandler={onChangeBirthDateHandler}
          onChangeRegisterDateHandler={onChangeRegisterDateHandler}
          valueFrom={filters.age_from}
          valueTo={filters.age_to}
          ageFrom={filters.age_from}
          onChangeFrom={(value) => {
            setFilters({ ...filters, page: 1, age_from: value });
            if (value >= filters.age_to) {
              setFilters({ ...filters, age_to: null, age_from: value });
            }
          }}
          onChangeTo={(value) => {
            setFilters({ ...filters, page: 1, age_to: value });
            if (value === undefined) {
              setFilters({ ...filters, age_from: null, age_to: null });
            }
          }}
          placeholderForStatusName={placeholderForStatusName}
          statusFilterMap={statusFilterMap}
          stravaFilterMap={stravaFilterMap}
          challengeIds={challengeIds}
          exportHandler={exportHandler}
          exportLoading={exportLoading}
          disabledExport={!filterData?.length}
        />
      ) : null}

      <TableList
        loadingIndicator={loadingIndicator}
        users={filterData}
        usersList={usersList}
        numUsersPagination={filters}
        usersParams={filters}
        inputOnChangeHandler={(e) => setFilters({ ...filters, page: Number(e.target.value) })}
        handleChange={handleChange}
        handleTableSortChange={handleTableSortChange}
        pagePrevHandler={pagePrevHandler}
        pageNextHandler={pageNextHandler}
        totalUsersPages={totalUsersPages}
        statusInfo={statusInfo}
        challengeIds={challengeIds}
        isTeams={isTeams}
      />
      <div>
        <DrawerProfile visible={visible} onClose={onClose} />
      </div>
    </div>
  );
};

export default TableComponent;
