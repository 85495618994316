import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFoundSearch = ({ textSearch = '' }) => {
  NotFoundSearch.propTypes = {
    textSearch: PropTypes.string,
  };
  const b = bem('NotFoundSearch');
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <h3 className={b('title')}>
        {t('search.search_get')} "{textSearch}" <br />
        {t('search.not_found')}
      </h3>
    </div>
  );
};

export default NotFoundSearch;
