import { Form } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import { useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import SurveyFormClipboard from 'components/Fields/SunEditorField/PlainClipboard';

Quill.register('modules/clipboard', SurveyFormClipboard, true);

const SunEditorField = ({
  id = '',
  name = '',
  contents = '',
  marginTop = '',
  dataName = '',
  rules = [],
  placeholder = '',
  label = '',
  onChange = undefined,
}) => {
  SunEditorField.propTypes = {
    id: PropTypes.string,
    rules: PropTypes.instanceOf(Array),
    marginTop: PropTypes.string,
    name: PropTypes.string,
    dataName: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    contents: PropTypes.string,
    onChange: PropTypes.func,
  };
  const quillRef = useRef < ReactQuill > null;
  const b = bem('SunEditorField');

  return (
    <Form.Item
      style={{
        marginTop,
      }}
      id={id}
      label={label}
      rules={rules}
      name={name}
      className={b()}
    >
      <ReactQuill
        ref={quillRef}
        preserveWhitespace={false}
        data-name={dataName}
        theme='snow'
        id={id}
        placeholder={placeholder}
        value={contents}
        onChange={onChange}
        className={b('quill-styles')}
      />
    </Form.Item>
  );
};

export default SunEditorField;
