import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Form, Input, message, Space } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import FormField from 'components/FormField/FormField';
import { messagesError, messagesErrorFetch } from 'components/Messages/Messagetext';
import {
  challengesSelector,
  creatChallengesReducer,
  createAddTeam,
  createRemoveLimit,
  createRemoveTeam,
  dateAddReducer,
  dateNull,
} from 'redux/challengesSlice/challengesSlice';
import {
  errorsValidateNullReducer,
  errorsValidateReducer,
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';

import { teamsFetch, teamsSelector } from 'redux/teams/teamsSlice';
import { deleteEmptyQueryStrings } from 'utils/constants';
import {
  dateMomentFormat,
  datePickerFormat,
  dateTimeFormat,
  dateTimeToFormat,
  sundayDate,
  sundayEnd,
} from 'utils/dateFormat/dateFormat';
import deleteTagHtml from 'utils/deleteTagHtml/deleteTagHtml';
import localesUtils from 'utils/localesUtils/localesUtils';
import localesValidateInput from 'utils/localiesValidateInput/localiesValidateInput';

const CreatingChallenges = () => {
  const b = bem('Creating');
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const { t } = useTranslation();
  const push = useNavigate();
  const { creatChallenges, datePickerNull } = useSelector(challengesSelector);
  const { results, teamsLoading } = useSelector(teamsSelector);
  const dispatch = useDispatch();
  const [teamSearch, setTeamSearch] = useState('');
  const [showTeam, setShowTeam] = useState(!!creatChallenges.team);
  const [teamChosen, setTeamChosen] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (errorsValidate) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.onpopstate = () => {};
        dispatch(modalExitReducer(true));
        dispatch(linksAddReducer(`/challenges`));
      };
    }
  }, [errorsValidate]);

  useEffect(() => {
    if (!showTeam) {
      dispatch(createRemoveTeam());
      setTeamChosen({});
      setTeamSearch('');
    }
  }, [dispatch, showTeam]);

  useEffect(() => {
    dispatch(dateNull(false));
    const query = {
      page: 1,
    };
    dispatch(teamsFetch({ data: { query } }));
  }, []);

  useEffect(() => {
    const queryObj = {
      name: teamSearch,
      page: 1,
    };

    const validateQuery = deleteEmptyQueryStrings(queryObj);
    const data = {
      query: validateQuery,
    };

    const delay = setTimeout(() => {
      dispatch(teamsFetch({ data }));
    }, 300);
    return () => clearTimeout(delay);
  }, [dispatch, teamSearch]);

  const formHandler = (e) => {
    const { target } = e;
    const dataName = target.dataset.name;
    if (dataName) {
      dispatch(creatChallengesReducer({ [dataName]: target.value }));
    }
    if (target.value) {
      dispatch(errorsValidateReducer(true));
    }
  };

  const dateHandler = (date) => {
    if (date) {
      const datePicker = {
        starts_at: datePickerFormat(date[0]),
        ends_at: datePickerFormat(date[1]),
      };
      dispatch(
        dateAddReducer({
          data: datePicker,
        }),
      );
      dispatch(dateNull(false));
      dispatch(errorsValidateReducer(true));
    } else if (date === null) {
      message.error(messagesErrorFetch(t('errors.add_date')));
      dispatch(dateNull(true));
    } else {
      dispatch(dateNull(false));
      const datePicker = {
        starts_at: sundayDate(),
        ends_at: sundayEnd(),
      };
      dispatch(
        dateAddReducer({
          data: datePicker,
        }),
      );
      dispatch(errorsValidateNullReducer());
    }
  };

  const handleChange = (content) => {
    dispatch(errorsValidateReducer(true));
    dispatch(creatChallengesReducer({ description: content }));
  };

  const onFinish = () => {
    if (!deleteTagHtml(creatChallenges.description)) {
      return message.error(messagesErrorFetch(t('errors.enter_description')));
    }

    if (!(creatChallenges.starts_at && creatChallenges.ends_at)) {
      return message.error(messagesError('Заполните дату'));
    }

    if (showTeam && !creatChallenges.team) {
      return message.error(messagesError(t('challenges.select_team')));
    }

    if (!creatChallenges.participants_limit) {
      dispatch(createRemoveLimit());
    }

    push('/challenges/creatingChallenges/challengeConditions');
  };

  const handleSearch = (e) => {
    setTeamSearch(e.target.value);
  };

  const handleTeamSelect = () => {
    setOpen(false);
    dispatch(createAddTeam({ ...teamChosen }));
  };

  const dropdownMenu = () => {
    const menu = (
      <div className={b('dropdown')}>
        <Input
          size='small'
          placeholder={t('challenges.search_team')}
          prefix={teamsLoading ? <LoadingOutlined /> : <SearchOutlined />}
          className={b('search')}
          value={teamSearch}
          onChange={handleSearch}
        />
        <div className={b('team_select')}>
          {!results.items.length ? <p className={b('not-found')}>Команды не найдены</p> : null}
          {results?.items?.map((item) => (
            <div key={item.id} className={b('team_option')}>
              <label htmlFor={item.id}>
                <input
                  type='radio'
                  value={item.id}
                  className={b('radio')}
                  id={item.id}
                  onChange={() =>
                    setTeamChosen({
                      team: item.id,
                      organization: item.organization,
                      team_name: item.name,
                    })
                  }
                  checked={item.id === teamChosen.team}
                />
                <div>
                  <p className={b('team_name')}>{item.name}</p>
                  <p className={b('team_organization')}>{item.organization_name}</p>
                  <div className={b('team_additional')}>
                    <div>
                      <p className={b('additional_subtitle')}>{t('challenges.created_at')}</p>
                      <p className={b('additional_text')}>
                        {dateTimeToFormat(item.created_at, dateTimeFormat)}
                      </p>
                    </div>
                    <div>
                      <p className={b('additional_subtitle')}>
                        {t('challenges.participants_number')}
                      </p>
                      <p className={b('additional_text')}>{item.members.length}</p>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          ))}
        </div>

        <Button
          type='primary'
          style={{ width: '100%', fontWeight: 600, fontSize: '14px' }}
          onClick={handleTeamSelect}
        >
          {t('challenges.select')}
        </Button>
      </div>
    );
    return menu;
  };

  return (
    <div data-testid='elem-creating' className={b()}>
      <BreadcrumbsComponent textEnd={t('challenges.creating_challenges')} />
      <p className={b('title')}>{t('challenges.creating_challenges')}</p>
      <p className={b('text')}>{t('challenges.create_your_challenges')}</p>
      <div>
        <Form
          fields={[
            {
              name: 'name',
              value: creatChallenges.name,
            },
            {
              name: 'participants_limit',
              value: creatChallenges.participants_limit,
            },
            {
              name: 'description',
              value: creatChallenges.description,
            },
          ]}
          name='basic'
          layout='vertical'
          onChange={formHandler}
          onFinish={onFinish}
        >
          <FormField
            type='modificationInput'
            label='challenges.title'
            name='name'
            message={localesUtils(
              'Пожалуйста, введите название задачи',
              'Please input your challenge title',
            )}
            dataName='name'
            placeholder='challenges.title'
          />
          {/* <FormField */}
          {/*  type='number' */}
          {/*  label={t('challenges.challenge_participants_amount')} */}
          {/*  name='participants_limit' */}
          {/*  dataName='participants_limit' */}
          {/*  placeholder={t('challenges.challenge_participants_amount')} */}
          {/*  width='100%' */}
          {/*  max={99999} */}
          {/* /> */}
          {/* <p className={b('text_tag')}>{t('challenges.max_participants_info')}</p> */}
          <Form.Item>
            <Checkbox
              role='checkbox'
              defaultChecked={showTeam}
              style={{ color: '#67697F', marginBottom: 12 }}
              onClick={() => setShowTeam(!showTeam)}
            >
              HR Challenge
            </Checkbox>
            {showTeam ? (
              <div className={b('hr-challenge')}>
                <Dropdown
                  open={open}
                  onOpenChange={(e) => setOpen(e)}
                  dropdownRender={dropdownMenu}
                  trigger={['click']}
                >
                  <button
                    className={b('team')}
                    style={creatChallenges.team ? { color: '#67697F' } : { color: '#bebebe' }}
                    type='button'
                    onClick={(e) => e.preventDefault()}
                  >
                    <Space>
                      {creatChallenges.team_name
                        ? creatChallenges.team_name
                        : t('challenges.select_team')}
                    </Space>
                  </button>
                </Dropdown>
              </div>
            ) : null}
          </Form.Item>
          <FormField
            id='description_id'
            rules={[
              {
                required: true,
                message: localesValidateInput(
                  <span id='promotionsDescription'>{t('errors.enter_description')}</span>,
                  'promotionsDescription',
                  t('errors.enter_description'),
                ),
              },
              {
                max: 1200,
                message: localesValidateInput(
                  <span id='promotionsDescriptionSecond'>
                    {t('errors.description_should_be_less_than_1200_character')}
                  </span>,
                  'promotionsDescriptionSecond',
                  t('errors.description_should_be_less_than_1200_character'),
                ),
              },
            ]}
            label={t('challenges.description')}
            placeholder={t('challenges.description')}
            type='editor'
            name='description'
            onChange={(content) => handleChange(content)}
          />
          <FormField
            type='dateFormRangePicker'
            label='promotions.select_a_date'
            marginTop='-10px'
            creatObj={creatChallenges}
            message='Please input your date picker!'
            dateHandler={dateHandler}
            dateFormat={dateMomentFormat}
            width='100%'
            height='44px'
            borderRadius={8}
          />
          <Form.Item
            className={b('btn_form')}
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button
              data-testid='button-submit'
              disabled={datePickerNull}
              style={{
                width: 129,
                height: 44,
                borderRadius: 8,
              }}
              type='primary'
              htmlType='submit'
            >
              {t('promotions.next')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreatingChallenges;
