import { Button } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';

import vectorErrors from 'assets/images/errors/Vector.png';
import vectorErrors2 from 'assets/images/errors/Vector2.png';
import vectorErrors3 from 'assets/images/errors/Vector3.png';

const ErrorsNumber = ({ status = 0, detail = 'Ошибка на сервере' }) => {
  ErrorsNumber.propTypes = {
    status: PropTypes.number,
    detail: PropTypes.string,
  };
  const b = bem('ErrorsNumber');

  const reloadHandler = () => {
    window.location.reload();
  };
  return (
    <div className={b()}>
      <div className={b('block_width')}>
        <div>
          <div className={b('vector_1')}>
            <img src={vectorErrors} alt='vectorErrors' width={85} height={59} />
          </div>
          <div className={b('number_text')}>{status}</div>
          <div className={b('flex_vector')}>
            <div className='vector_2'>
              <img src={vectorErrors2} alt='vectorErrors2' width={66} height={46} />
            </div>
            <div className='vector_3'>
              <img src={vectorErrors3} alt='vectorErrors3' width={107} height={60} />
            </div>
          </div>
        </div>
        <div className={b('block_text')}>
          <p className='title_block'>{detail}</p>
          <p>Попробуйте перезагрузить страницу или попробовать зайти чуть позже</p>
          <div className='btn'>
            <Button
              onClick={reloadHandler}
              style={{
                width: 159,
                height: 40,
                backgroundColor: '#007AFF',
                color: 'white',
                fontSize: 14,
                fontWeight: 600,
                border: 'none',
                borderRadius: 8,
              }}
            >
              Обновить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorsNumber;
