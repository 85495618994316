const navigatorUserAgent = () => {
  const navigator = window.navigator.userAgent;

  if (navigator.includes('Chrome')) {
    return 'Chrome';
  }
  if (navigator.includes('Safari')) {
    return 'Safari';
  }

  if (navigator.includes('Mozilla')) {
    return 'Mozilla';
  }
  return 'None';
};

export default navigatorUserAgent;
