import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { messagesErrorFetch, messagesSuccess } from 'components/Messages/Messagetext';
import axiosApi from 'utils/axios-api';
import { errorsHandler, successText } from 'utils/errorsHandler/errorsHandler';
import toQueryParams from 'utils/toQueryParams';

const nameSpace = 'cache';

const INITIAL_STATE = {
  updateLoading: false,
  updateError: null,
};

export const cachingFetch = createAsyncThunk(
  `${nameSpace}/cachingFetch`,
  async ({ data = '', challenges = '', promotions = '', starts = '' }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/caching/${query}${challenges}${promotions}${starts}`);
      message.success(messagesSuccess(successText('updateCaching')));
      return {
        ...resp.data,
      };
    } catch (e) {
      message.error(messagesErrorFetch(errorsHandler(e?.response?.data?.update)));
      return rejectWithValue(e?.response?.data);
    }
  },
);

const cachingSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(cachingFetch.pending, (state) => {
      state.updateLoading = true;
      state.updateError = null;
    });
    builder.addCase(cachingFetch.fulfilled, (state) => {
      state.updateLoading = false;
      state.updateError = null;
    });
    builder.addCase(cachingFetch.rejected, (state, { payload }) => {
      state.updateLoading = false;
      state.updateError = payload;
    });
  },
});

export const cachingSelector = (state) => state.cache;
export default cachingSlice.reducer;
