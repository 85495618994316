import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { messagesErrorFetch, messagesSuccess } from 'components/Messages/Messagetext';
import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';
import { sundayDate, sundayEnd } from 'utils/dateFormat/dateFormat';
import { errorsHandler, successText } from 'utils/errorsHandler/errorsHandler';
import toQueryParams from 'utils/toQueryParams';

const nameSpace = 'starts';

const INITIAL_STATE = {
  results: [],
  loading: false,
  errors: false,
  searchStarts: '',
  loadingSearch: false,
  pagination: {
    count: 0,
    previous: null,
    next: '',
  },
  params: {
    page: 1,
  },
  activeInactive: {
    loading: false,
    errors: null,
  },
  resultsInfo: {
    loading: false,
    errors: null,
    resultsInfoStarts: null,
  },
  updateStartsData: {
    images: [],
  },
  updateStarts: {
    loadingUpdate: false,
    errorsErrors: null,
    historyEdit: '',
  },
  updatePutStartLoading: false,
  updatePutStartError: null,
  updateStartsNavigate: false,
  sort: {
    status: {
      id: null,
      title: '',
      url: '',
    },
    categories: {
      id: null,
      title: {
        name_ru: '',
        name_en: '',
      },
      url: '',
    },
  },
  categories: {
    loading: false,
    errors: null,
    results: [],
    categoriesChose: null,
  },
  creatStarts: {
    name: '',
    description: '',
    images: [],
    button_text: '',
    url: '',
    registration_starts: sundayDate(),
    registration_ends: sundayEnd(),
    date: sundayEnd(),
  },
  editStarts: {
    images: [],
    deleted_images: [],
    name: '',
    description: '',
    button_text: '',
    url: '',
    registration_starts: '',
    registration_ends: '',
    date: '',
  },
  startsSuccess: {
    loading: false,
    errors: null,
    success: 'not',
  },
  deleteStarts: {
    errorsDelete: null,
    loadingDelete: false,
    history: '',
  },
  dateValidate: false,
};

export const startsCreateFetch = createAsyncThunk(
  `${nameSpace}/startsCreateFetch`,
  async ({ data }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.post('/starts/', data);
      message.success(messagesSuccess(successText('successAdd')));
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const startsCategoriesFetch = createAsyncThunk(
  `${nameSpace}/startsCategoriesFetch`,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/challenges/categories/`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const startsFetch = createAsyncThunk(
  `${nameSpace}/startsFetch`,
  async ({ data, sort, name = '' }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/starts/${query}${sort}&name=${name}`);
      return {
        ...resp.data,
        name,
      };
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const activeInactiveStartsFetch = createAsyncThunk(
  `${nameSpace}/activeInactiveStartsFetch`,
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.put(`/starts/${payload.id}/`, payload.data);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const startsInfoFetch = createAsyncThunk(
  `${nameSpace}/startsInfoFetch`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/starts/${id}/`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const startUpdate = createAsyncThunk(
  `${nameSpace}/startUpdate`,
  async ({ startId, newData }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.put(`/starts/${startId}/`, newData);
      return resp.data;
    } catch (e) {
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      return rejectWithValue(e?.response?.data);
    }
  },
);

export const startDeleteFetch = createAsyncThunk(
  `${nameSpace}/startDeleteFetch`,
  async ({ id }, { rejectWithValue }) => {
    try {
      await axiosApi.delete(`/starts/${id}/`);
      message.success(messagesSuccess(successText('successDelete')));
      return {
        id,
      };
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

const startsSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  reducers: {
    sortHandlerStartsReducer: (state, action) => {
      state.sort = {
        ...state.sort,
        ...action.payload,
      };
    },
    loadingStartsActiveInActive: (state, action) => {
      const findLoading = state.results.find((item) => item.id === action.payload.id);
      findLoading.loading = true;
    },
    updateStartsHistory: (state) => {
      state.updateStarts.historyEdit = '';
      state.updateStartsData.images = [];
    },
    creatStartsReducer: (state, action) => {
      const keys = Object.keys(action.payload);
      state.creatStarts[keys[0]] = action.payload[keys[0]];
    },
    startsDragHandler: (state, action) => {
      state.creatStarts.images = [...state.creatStarts.images, action.payload];
    },
    deleteStartsPhoto: (state, action) => {
      state.creatStarts.images = state.creatStarts.images.filter(
        (item) => item.id !== action.payload,
      );
    },
    startsPhotoDownload: (state, action) => {
      state.creatStarts.images = [...state.creatStarts.images, action.payload];
    },
    createStartsNull: (state) => {
      state.creatStarts = INITIAL_STATE.creatStarts;
      state.startsSuccess = {
        loading: false,
        errors: null,
        success: 'not',
      };
    },
    editStartsAddReducer: (state, action) => {
      state.updateStartsData.images = action.payload?.images;
      state.editStarts.name = action.payload?.name;
      state.editStarts.description = action.payload?.description;
      state.editStarts.button_text = action.payload?.button_text;
      state.editStarts.registration_starts = action.payload?.registration_starts;
      state.editStarts.registration_ends = action.payload?.registration_ends;
      state.editStarts.date = action.payload?.date;
      state.editStarts.url = action.payload?.url;
      state.editStarts.deleted_images = [];
      state.editStarts.images = [];
    },
    updateStartsDate: (state, action) => {
      state.editStarts.registration_starts = action.payload.data.registration_starts;
      state.editStarts.registration_ends = action.payload.data.registration_ends;
    },
    updateDate: (state, action) => {
      state.editStarts.date = action.payload.data.date;
    },
    startsUpdatePhotoDownload: (state, action) => {
      state.editStarts.images = [...state.editStarts.images, action.payload];
    },
    deleteUpdateStartsPhoto: (state, action) => {
      state.editStarts.images = state.editStarts.images.filter(
        (item) => item.id !== action.payload,
      );
    },
    deletePhotosHttpsStarts: (state, action) => {
      const photosEdit = state.updateStartsData.images.filter((item) => item.id !== action.payload);
      state.editStarts.deleted_images.push(action.payload);
      state.updateStartsData.images = photosEdit;
    },
    deleteImagesNull: (state) => {
      state.editStarts.deleted_images = [];
    },
    editStartsReducer: (state, action) => {
      const keys = Object.keys(action.payload);
      state.editStarts[keys[0]] = action.payload[keys[0]];
    },
    addStartsSearch: (state, action) => {
      state.results = [...state.results, action.payload];
    },
    addPageParamsStarts: (state) => {
      state.params.page += 1;
    },
    minusPageParamsStart: (state) => {
      state.params.page -= 1;
    },
    nullPageParamsStarts: (state) => {
      state.params.page = 1;
    },
    searchTxtStartsReducer: (state) => {
      state.searchStarts = 'success';
    },
    resultsInfoStartNull: (state) => {
      state.resultsInfo.resultsInfoStarts = null;
    },
    historyStartsNull: (state) => {
      state.deleteStarts.history = '';
    },
    dateAddReducerStarts: (state, action) => {
      state.creatStarts.registration_starts = action.payload.data.registration_starts;
      state.creatStarts.registration_ends = action.payload.data.registration_ends;
    },
    addDateStarts: (state, action) => {
      state.creatStarts.date = action.payload.data.date;
    },
    dateValidateReducer: (state, action) => {
      state.dateValidate = action.payload;
    },
    startsChoseReducer: (state, action) => {
      state.categories.categoriesChose = action.payload.data;
    },
    updateStartsNavigateNull: (state) => {
      state.updateStartsNavigate = false;
    },
    clearStarts: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(startsCreateFetch.pending, ({ startsSuccess }) => {
      startsSuccess.loading = true;
      startsSuccess.errors = null;
    });
    builder.addCase(startsCreateFetch.fulfilled, (state, { payload }) => {
      state.results = [payload, ...state.results];
      state.startsInfo = payload;
      state.startsSuccess = {
        ...state.startsSuccess,
        loading: false,
        errors: null,
        success: 'ok',
      };
    });
    builder.addCase(startsCategoriesFetch.pending, (state) => {
      state.categories = {
        ...state.categories,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(startsCategoriesFetch.fulfilled, (state, { payload }) => {
      state.categories = {
        ...state.categories,
        results: payload,
        loading: false,
        errors: null,
      };
    });
    builder.addCase(startsCategoriesFetch.rejected, (state, { payload }) => {
      state.categories = {
        ...state.categories,
        errors: payload,
        loading: false,
      };
    });

    builder.addCase(startsFetch.pending, (state, action) => {
      state.loading = true;
      state.errors = null;
      if (action?.meta.arg.name) {
        state.loadingSearch = true;
      }
    });
    builder.addCase(startsFetch.fulfilled, (state, { payload }) => {
      state.errors = null;
      state.results = payload.results;
      state.searchStarts = '';
      state.pagination = {
        ...state.pagination,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
      state.loading = false;
      if (payload.name) {
        state.loadingSearch = false;
      }
    });
    builder.addCase(startsFetch.rejected, (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
      state.loadingSearch = false;
    });

    builder.addCase(activeInactiveStartsFetch.pending, (state) => {
      state.activeInactive = {
        ...state.activeInactive,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(activeInactiveStartsFetch.fulfilled, (state, { payload }) => {
      const findUpdate =
        state?.results.find((item) => item.id === payload.id) ||
        state?.resultsInfo?.resultsStartsInfo;
      findUpdate.status = payload.status;
      findUpdate.loading = false;
      state.resultsInfo = { ...state.resultsInfo, resultInfoStarts: payload };
      state.activeInactive = {
        ...state.activeInactive,
        loading: false,
        errors: null,
      };
    });
    builder.addCase(activeInactiveStartsFetch.rejected, (state, { payload }) => {
      state.activeInactive = {
        ...state.activeInactive,
        errors: payload,
        loading: false,
      };
    });

    builder.addCase(startsInfoFetch.pending, (state) => {
      state.resultsInfo = {
        ...state.resultsInfo,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(startsInfoFetch.fulfilled, (state, { payload }) => {
      state.resultsInfo = {
        ...state.resultsInfo,
        errors: null,
        resultsInfoStarts: payload,
        loading: false,
      };
    });
    builder.addCase(startsInfoFetch.rejected, (state, { payload }) => {
      state.resultsInfo = {
        ...state.resultsInfo,
        errors: payload,
        loading: false,
      };
    });
    builder.addCase(startUpdate.pending, (state) => {
      state.updatePutStartLoading = true;
      state.updatePutStartError = null;
    });
    builder.addCase(startUpdate.fulfilled, (state, { payload }) => {
      const findUpdate =
        state?.results?.find((item) => item.id === payload.id) ||
        state.resultsInfo.resultsInfoStarts;

      findUpdate.images = payload?.images;
      findUpdate.button_text = payload?.button_text;
      findUpdate.registration_starts = payload?.registration_starts;
      findUpdate.registration_ends = payload?.registration_ends;
      findUpdate.date = payload?.date;
      findUpdate.name = payload?.name;
      findUpdate.description = payload?.description;
      findUpdate.url = payload?.url;
      findUpdate.status = payload?.status;
      findUpdate.loading = false;
      state.resultsInfo.resultsInfoStarts = payload;
      state.resultsInfo.resultsInfoStarts.images = payload.images;
      state.updatePutStartLoading = false;
      state.updatePutStartError = null;
      state.updateStartsNavigate = true;
    });
    builder.addCase(startUpdate.rejected, (state, { payload }) => {
      state.updatePutStartError = payload;
      state.updatePutStartLoading = false;
    });
    builder.addCase(startDeleteFetch.pending, (state) => {
      state.deleteStarts = {
        ...state.deleteStarts,
        loadingDelete: true,
        errorsDelete: null,
      };
    });
    builder.addCase(startDeleteFetch.fulfilled, (state, { payload }) => {
      state.results = state.results.filter((item) => item.id !== Number(payload.id));
      state.deleteStarts = {
        ...state.deleteStarts,
        loadingDelete: false,
        history: 'success',
      };
    });
    builder.addCase(startDeleteFetch.rejected, (state, { payload }) => {
      state.deleteStarts = {
        ...state.deleteStarts,
        errorsDelete: payload,
        loadingDelete: false,
      };
    });
  },
});

export const {
  sortHandlerStartsReducer,
  loadingStartsActiveInActive,
  updateStartsHistory,
  creatStartsReducer,
  startsDragHandler,
  deleteStartsPhoto,
  startsPhotoDownload,
  createStartsNull,
  editStartsAddReducer,
  startsUpdatePhotoDownload,
  deleteUpdateStartsPhoto,
  deletePhotosHttpsStarts,
  deleteImagesNull,
  addStartsSearch,
  editStartsReducer,
  searchTxtStartsReducer,
  addPageParamsStarts,
  minusPageParamsStart,
  nullPageParamsStarts,
  resultsInfoStartNull,
  historyStartsNull,
  dateAddReducerStarts,
  addDateStarts,
  dateValidateReducer,
  startsChoseReducer,
  updateStartsDate,
  updateDate,
  updateStartsNavigateNull,
  clearStarts,
} = startsSlice.actions;
export const startsSelector = (state) => state.starts;
export const startsCreateSelector = (state) => state.starts.creatStarts;
export const startsPaginationSelector = (state) => state.starts.pagination;
export const startsResultsInfoSelector = (state) => state.starts.resultsInfo;
export const startsEditSelector = (state) => state.starts.editStarts;
export const startDeleteSelector = (state) => state.starts.deleteStarts;
export default startsSlice.reducer;
