import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';

const nameSpace = 'common';

const INITIAL_STATE = {
  commonStatistic: {
    common: {},
    loading: false,
    errors: null,
  },
};

export const commonStatisticInfoFetch = createAsyncThunk(
  `${nameSpace}/commonStatisticInfoFetch`,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/common/strava-limits/`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

const commonSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(commonStatisticInfoFetch.pending, (state) => {
      state.commonStatistic = {
        ...state.commonStatistic,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(commonStatisticInfoFetch.fulfilled, (state, { payload }) => {
      state.commonStatistic = {
        ...state.commonStatistic,
        common: payload,
        loading: false,
        errors: null,
      };
    });
    builder.addCase(commonStatisticInfoFetch.rejected, (state, { payload }) => {
      state.commonStatistic = {
        ...state.commonStatistic,
        errors: payload,
        loading: false,
      };
    });
  },
});

export const commonStatisticsSelector = (state) => state.common.commonStatistic;
export default commonSlice.reducer;
