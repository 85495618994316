import { Carousel, Typography } from 'antd';
import bem from 'easy-bem';
import React from 'react';

import 'components/CarouselComponent/_сarouselComponentStyles.scss';
import image1 from 'assets/images/image-1.jpg';
import image2 from 'assets/images/image-2.jpg';
import image3 from 'assets/images/image-3.jpg';
import logo from 'assets/images/logo.svg';
import LocalizationButton from 'components/LocalizationButton/LocalizationButton';

const CarouselComponent = () => {
  const b = bem('CarouselComponent');

  return (
    <div className={b()}>
      <div className='signImg'>
        <LocalizationButton />
        <Carousel autoplay dots={false} className='carouselStyles'>
          <div>
            <img src={image1} alt='image1' />
          </div>
          <div>
            <img src={image2} alt='image2' />
          </div>
          <div>
            <img src={image3} alt='image3' />
          </div>
        </Carousel>
        <div>
          <img src={logo} alt='' className='logoStyles' />
          <Typography.Title className='subtitleStyles' level={3} style={{ margin: 0 }}>
            "
          </Typography.Title>
          <Typography.Title className='titleStyles' level={3} style={{ margin: 0 }}>
            We strive to help people and make the world a better place, because good deeds help life
          </Typography.Title>
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;
