import localesUtils from 'utils/localesUtils/localesUtils';

const nameLocales = (measurementUnit, input) => {
  if (measurementUnit === 0) {
    if (input === 'km') {
      return localesUtils('км', 'km');
    }
    return localesUtils('м', 'm');
  }
  if (measurementUnit === 2) {
    if (input === 'km') {
      return localesUtils('км/ч', 'km/h');
    }
    return localesUtils('м/с', 'm/s');
  }
  if (measurementUnit === 1) {
    return localesUtils('с', 's');
  }
  if (measurementUnit === 3) {
    return localesUtils('ккал', 'kcal');
  }
  if (measurementUnit === 4) {
    return localesUtils('ват', 'wat');
  }
};

export default nameLocales;
