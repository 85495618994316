import { Button, Form, message } from 'antd';
import dayjs from 'dayjs';
import bem from 'easy-bem';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import FormField from 'components/FormField/FormField';
import { messagesError, messagesErrorFetch } from 'components/Messages/Messagetext';
import { errorsValidateReducer } from 'redux/historyNotifications/historyNotifications';
import {
  addDateStarts,
  creatStartsReducer,
  dateAddReducerStarts,
  dateValidateReducer,
  startsCategoriesFetch,
  startsChoseReducer,
  startsSelector,
} from 'redux/startsSlice/startsSlice';
import {
  dateMomentFormat,
  datePickerFormat,
  sundayDate,
  sundayEnd,
} from 'utils/dateFormat/dateFormat';
import localesUtils from 'utils/localesUtils/localesUtils';
import localesValidateInput from 'utils/localiesValidateInput/localiesValidateInput';

const Creating = () => {
  const b = bem('Creating');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categories, creatStarts, dateValidate } = useSelector(startsSelector);
  const push = useNavigate();

  useEffect(() => {
    if (!categories.results.length) {
      dispatch(startsCategoriesFetch());
    }
  }, [dispatch]);

  const onChangeChoose = (value) => {
    const resultsCategories = categories.results?.find((item) => item.id === value);
    dispatch(creatStartsReducer({ category: value }));
    dispatch(startsChoseReducer({ data: resultsCategories }));
    dispatch(errorsValidateReducer(true));
  };

  const formHandler = (e) => {
    const { target } = e;
    const dataName = target.dataset.name;
    dispatch(creatStartsReducer({ [dataName]: target.value }));
    if (target.value) {
      dispatch(errorsValidateReducer(true));
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      const datePicker = {
        date: datePickerFormat(date),
      };
      dispatch(
        addDateStarts({
          data: datePicker,
        }),
      );

      if (date && dayjs(date).isBefore(creatStarts?.registration_ends)) {
        dispatch(dateValidateReducer(true));

        return message.error(messagesError(t('errors.date_should_be_after_register_end')));
      }

      dispatch(dateValidateReducer(false));
      dispatch(errorsValidateReducer(true));
    } else if (date === null) {
      message.error(messagesErrorFetch(t('errors.add_date')));
      dispatch(dateValidateReducer(true));
    } else {
      const datePicker = {
        date: sundayDate(),
      };
      addDateStarts(
        date({
          data: datePicker,
        }),
      );
      dispatch(dateValidateReducer(false));
    }
  };

  const dateHandler = (date) => {
    if (date) {
      const datePicker = {
        registration_starts: datePickerFormat(date[0]),
        registration_ends: datePickerFormat(date[1]),
      };
      dispatch(
        dateAddReducerStarts({
          data: datePicker,
        }),
      );

      if (date && dayjs(date[1]).isAfter(creatStarts?.date)) {
        dispatch(dateValidateReducer(true));

        return message.error(messagesError(t('errors.date_should_be_after_register_end')));
      }

      dispatch(dateValidateReducer(false));
      dispatch(errorsValidateReducer(true));
    } else if (date === null) {
      message.error(messagesErrorFetch(t('errors.add_date')));
      dispatch(dateValidateReducer(true));
    } else {
      const datePicker = {
        registration_starts: sundayDate(),
        registration_ends: sundayEnd(),
      };
      dispatch(
        dateAddReducerStarts({
          data: datePicker,
        }),
      );
      dispatch(dateValidateReducer(false));
    }
  };

  const handleChange = (content) => {
    if (!content.slice(0, 11).includes('<p><br></p>')) {
      dispatch(errorsValidateReducer(true));
      dispatch(creatStartsReducer({ description: content }));
    } else {
      dispatch(creatStartsReducer({ description: '' }));
    }
  };
  const onFinish = () => {
    if (!creatStarts.description) {
      return message.error(messagesErrorFetch(t('errors.enter_description')));
    }

    if (!creatStarts.category) {
      return message.error(messagesErrorFetch(t('errors.enter_category')));
    }

    push('/starts/create/uploadPhotos');
  };

  return (
    <div className={b()} data-testid='starts--form-element'>
      <BreadcrumbsComponent textEnd={t('starts.creating_starts')} />
      <p className={b('title')}>{t('starts.create_title')}</p>
      <p className={b('text')}>{t('starts.create_your_starts')}</p>
      <Form
        fields={[
          {
            name: 'name',
            value: creatStarts.name,
          },
          {
            name: 'description',
            value: creatStarts.description,
          },
          {
            name: 'url',
            value: creatStarts.url,
          },
          {
            name: 'button_text',
            value: creatStarts.button_text,
          },
        ]}
        name='basic'
        layout='vertical'
        onChange={formHandler}
        onFinish={onFinish}
      >
        <FormField
          type='modificationInput'
          label='starts.title_label'
          name='name'
          message={localesUtils(
            'Пожалуйста, введите название старта',
            'Please input your starts title',
          )}
          dataName='name'
          placeholder='starts.title_label'
        />
        <FormField
          id='description_id'
          rules={[
            {
              required: true,
              message: localesValidateInput(
                <span id='promotionsDescription'>{t('errors.enter_description')}</span>,
                'promotionsDescription',
                t('errors.enter_description'),
              ),
            },
            {
              max: 1200,
              message: localesValidateInput(
                <span id='promotionsDescriptionSecond'>
                  {t('errors.description_should_be_less_than_1200_character')}
                </span>,
                'promotionsDescriptionSecond',
                t('errors.description_should_be_less_than_1200_character'),
              ),
            },
          ]}
          label={t('starts.description_label')}
          placeholder={t('starts.description_label')}
          type='editor'
          name='description'
          onChange={(content) => handleChange(content)}
        />
        <FormField
          type='showSearchSelect'
          label={t('starts.category')}
          name='category'
          message='Please input your username!'
          placeholder={t('starts.category')}
          onChangeChoose={onChangeChoose}
          chooseCategory={creatStarts.category}
          disabled={categories.loading}
          options={categories.results}
        />
        <FormField
          type='datePicker'
          label='starts.select_date'
          marginTop='-10px'
          disableDate={false}
          creatObj={creatStarts}
          message='Please input your date picker!'
          dateChangeHandler={handleDateChange}
          dateFormat={dateMomentFormat}
          width='100%'
          height='34px'
          borderRadius={8}
        />
        <FormField
          type='dateFormRangePicker'
          label='starts.register_dates'
          marginTop='-10px'
          disableDate={false}
          creatObj={creatStarts}
          message='Please input your date picker!'
          dateHandler={dateHandler}
          dateFormat={dateMomentFormat}
          width='100%'
          height='34px'
          borderRadius={8}
          startLabel='registration_starts'
          endLabel='registration_ends'
        />
        <FormField
          type='text'
          id='url_id'
          dataName='url'
          className={b('input-form-style input-text-form-style')}
          inputClassName={b('border-input-style')}
          name='url'
          label={t('starts.url_label')}
          placeholder={t('starts.url_label')}
          dependencies={['button_text']}
          textErrorsRu='Поле `Текст для кнопки` должно быть заполнено'
          textErrorsEn='Field `Button text` must be field'
          rules={[
            {
              required: true,
              type: 'url',
              message: localesValidateInput(
                <span id='promotionsButtonText'>{t('errors.this_field_must_be_a_valid_url')}</span>,
                'promotionsButtonText',
                t('errors.this_field_must_be_a_valid_url'),
              ),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const buttonText = getFieldValue(['button_text']);
                if (value !== '' && buttonText === '') {
                  return Promise.reject(new Error(t('errors.button_link_text')));
                }

                if (value.includes(' ')) {
                  return Promise.reject(new Error(t('errors.no_spaces')));
                }
                return Promise.resolve();
              },
            }),
          ]}
        />

        <FormField
          type='text'
          dataName='button_text'
          id='button_text_id'
          className={b('input-form-style')}
          inputClassName={b('border-input-style')}
          label={t('promotions.button_text')}
          placeholder={t('promotions.button_text')}
          name='button_text'
          dependencies={['url']}
          textErrorsRu='Поле `Добавить ссылку` должно быть заполнено'
          textErrorsEn='Field `Add link` must be field'
          rules={[
            {
              required: true,
              max: 20,
              message: localesValidateInput(
                <span id='promotionsButtonText2'>{t('errors.button_error_max_text')}</span>,
                'promotionsButtonText2',
                t('errors.button_error_max_text'),
              ),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const urlLink = getFieldValue(['url']);
                if (value !== '' && urlLink === '') {
                  return Promise.reject(new Error(t('errors.button_error_text')));
                }

                return Promise.resolve();
              },
            }),
          ]}
        />
        <Form.Item
          className={b('btn_form')}
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button
            disabled={dateValidate}
            data-testid='button-submit'
            style={{
              width: 129,
              height: 44,
              borderRadius: 8,
            }}
            type='primary'
            htmlType='submit'
          >
            {t('promotions.next')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Creating;
