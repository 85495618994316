import { Popconfirm } from 'antd';
import bem from 'easy-bem';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Loading from 'components/Loading/Loading';

const ModalFormConditions = ({
  results = {},
  requirement = [],
  cardDelete = undefined,
  kmHandler = undefined,
  meterHandler = undefined,
}) => {
  ModalFormConditions.propTypes = {
    results: PropTypes.instanceOf(Object),
    requirement: PropTypes.instanceOf(Array).isRequired,
    cardDelete: PropTypes.func.isRequired,
    kmHandler: PropTypes.func.isRequired,
    meterHandler: PropTypes.func.isRequired,
  };
  const b = bem('ModalFormConditions');
  const { t } = useTranslation();

  if (!results?.name) {
    return <Loading />;
  }

  return (
    <div className={b()}>
      <p className={b('title')}>{t('challenges.conditions')}</p>
      <div className={b('text')}>
        {t('challenges.create_your_challenges')} {t('challenges.interest')}
      </div>
      <div className={b('block_card')}>
        {results?.applicable_reqs.map((item) => {
          const findRemove = requirement?.find((remove) => remove.id === item.id);
          if (findRemove?.id === item?.id) {
            return (
              <div
                onClick={() => cardDelete(item.id)}
                key={item?.id}
                onKeyDown={cardDelete}
                role='presentation'
              >
                <div className='icons_block_card'>
                  <img
                    src={item.icon}
                    alt={item.name_en}
                    width={20}
                    height={20}
                    className='svg_blue'
                  />
                </div>
                <div className='text_card'>
                  <div className='title_card'>
                    <div className='t_pg'>
                      {i18n?.language === 'ru' ? item.name_ru : item.name_en}
                    </div>
                    <div>
                      <button type='button' className='remove_link btn_none_style'>
                        {t('challenges.remove')}
                      </button>
                    </div>
                  </div>
                  <p>{item?.measurement_unit_display}</p>
                </div>
              </div>
            );
          }
          if (item.measurement_unit === 2 || item.measurement_unit === 0) {
            return (
              <div key={item?.id}>
                <Popconfirm
                  className='block_pop'
                  title={t('challenges.km_and_meters')}
                  okText={item.measurement_unit === 2 ? t('challenges.kmh') : t('challenges.km')}
                  cancelText={
                    item.measurement_unit === 2 ? t('challenges.ms') : t('challenges.meters')
                  }
                  onConfirm={() =>
                    kmHandler({
                      id: item?.id,
                      name: item?.name,
                      name_en: item?.name_en,
                      name_ru: item?.name_ru,
                      requirement: {
                        id: item?.id,
                      },
                      unit_amount: 0,
                      percentage: 0,
                      measurement_unit: item.measurement_unit,
                    })
                  }
                  onCancel={() =>
                    meterHandler({
                      id: item?.id,
                      name: item?.name,
                      name_en: item?.name_en,
                      name_ru: item?.name_ru,
                      requirement: {
                        id: item?.id,
                      },
                      unit_amount: 0,
                      percentage: 0,
                      measurement_unit: item.measurement_unit,
                    })
                  }
                >
                  <div className='icons_block_card'>
                    <img
                      src={item.icon}
                      alt={item.name_en}
                      width={20}
                      height={20}
                      className='svg_blue'
                    />
                  </div>
                  <div className='text_card'>
                    <div className='title_card'>
                      <div className='t_pg'>
                        {i18n?.language === 'ru' ? item.name_ru : item.name_en}
                      </div>
                      <button type='button' className='t_link btn_none_style'>
                        {t('challenges.add')}
                      </button>
                    </div>
                    <p>{item?.measurement_unit_display}</p>
                  </div>
                </Popconfirm>
              </div>
            );
          }

          return (
            <div
              onClick={() =>
                meterHandler({
                  id: item?.id,
                  name: item?.name,
                  name_en: item?.name_en,
                  name_ru: item?.name_ru,
                  requirement: {
                    id: item?.id,
                  },
                  unit_amount: 0,
                  percentage: 0,
                  measurement_unit: item.measurement_unit,
                })
              }
              onKeyDown={meterHandler}
              role='presentation'
              key={item?.id}
            >
              <div className='icons_block_card'>
                <img src={item.icon} alt={item.name_en} width={20} height={20} />
              </div>
              <div className='text_card'>
                <div className='title_card'>
                  <div className='t_pg'>
                    {i18n?.language === 'ru' ? item.name_ru : item.name_en}
                  </div>

                  <button type='button' className='t_link btn_none_style'>
                    {t('challenges.add')}
                  </button>
                </div>
                <p>{item?.measurement_unit_display}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ModalFormConditions;
