import { CaretUpOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import closeIcons from 'assets/images/icons/close.svg';

const DrawerComponents = ({ onClose = undefined, visible = false, title = '' }) => {
  DrawerComponents.propTypes = {
    onClose: PropTypes.func,
    visible: PropTypes.bool,
    title: PropTypes.string,
  };
  const { t } = useTranslation();
  const b = bem('drawer');
  return (
    <Drawer placement='right' closable={false} onClose={onClose} open={visible}>
      <div className={b('main_drawer_block')}>
        <div className={b('title_drawer')}>
          <div className={b('block_static_flex')}>
            <p>{t('home.statistics')}</p>
            <button onClick={onClose} type='button' className='btn_none_style_block'>
              <img src={closeIcons} alt='closeIcons' />
            </button>
          </div>
          <h2>{title}</h2>
        </div>
        {data.map((item, i) => {
          return (
            <div key={item.id || i} className='block_drawer'>
              <p className='text_draw_title'>{item.title}</p>
              <p className='text_draw_events'>Events</p>
              <p className='text_draw_interest'>
                {item.num}
                <span>
                  {item.max}
                  {item.interest}
                </span>
              </p>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
};

const data = [
  {
    title: 'March',
    num: 4,
    max: <CaretUpOutlined />,
    min: null,
    interest: '+50%',
  },
  {
    title: 'March',
    num: 4,
    max: <CaretUpOutlined />,
    min: null,
    interest: '+50%',
  },
  {
    title: 'March',
    num: 4,
    max: <CaretUpOutlined />,
    min: null,
    interest: '+50%',
  },
  {
    title: 'March',
    num: 4,
    max: <CaretUpOutlined />,
    min: null,
    interest: '+50%',
  },
  {
    title: 'March',
    num: 4,
    max: <CaretUpOutlined />,
    min: null,
    interest: '+50%',
  },
  {
    title: 'March',
    num: 4,
    max: <CaretUpOutlined />,
    min: null,
    interest: '+50%',
  },
  {
    title: 'March',
    num: 4,
    max: <CaretUpOutlined />,
    min: null,
    interest: '+50%',
  },
  {
    title: 'March',
    num: 4,
    max: <CaretUpOutlined />,
    min: null,
    interest: '+50%',
  },
  {
    title: 'March',
    num: 4,
    max: <CaretUpOutlined />,
    min: null,
    interest: '+50%',
  },
];

export default DrawerComponents;
