import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const SkeletonImage = ({
  className = '',
  width = 0,
  height = 0,
  borderRadius = 0,
  num = 0,
  marginBottom = 0,
}) => {
  SkeletonImage.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    borderRadius: PropTypes.number,
    num: PropTypes.number,
    marginBottom: PropTypes.number,
  };
  return (
    <>
      {[...Array(Number(num))].map(() => {
        return (
          <div key={Math.floor(new Date().valueOf() * Math.random())}>
            <Skeleton.Image
              active
              className={className}
              style={{
                width,
                height,
                borderRadius,
                marginBottom,
              }}
            />
          </div>
        );
      })}
    </>
  );
};

export default SkeletonImage;
