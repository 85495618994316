import { configureStore } from '@reduxjs/toolkit';

import authReducer from 'redux/auth/authSlice';
import cachingReducer from 'redux/cache/cacheSlice';
import challengesSlice from 'redux/challengesSlice/challengesSlice';
import commonStatisticsSlice from 'redux/common/commonSlice';
import historyNotificationsSlice from 'redux/historyNotifications/historyNotifications';
import homeSlice from 'redux/homeSlice/homeSlice';
import newsSlice from 'redux/news/newsSlice';
import organizationsReducer from 'redux/organizations/organizationsSlice';
import promotionsReducer from 'redux/promotions/promotionsSlice';
import searchSlice from 'redux/search/searchSlice';
import startsSlice from 'redux/startsSlice/startsSlice';
import teamsSlice from 'redux/teams/teamsSlice';
import userReducer from 'redux/user/userSlice';
import usersReducer from 'redux/users/usersSlice';

export default configureStore({
  reducer: {
    cache: cachingReducer,
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    organizations: organizationsReducer,
    promotions: promotionsReducer,
    challenges: challengesSlice,
    historyNotifications: historyNotificationsSlice,
    search: searchSlice,
    home: homeSlice,
    common: commonStatisticsSlice,
    starts: startsSlice,
    teams: teamsSlice,
    news: newsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
