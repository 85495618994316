import Card from 'antd/lib/card/Card';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ButtonsHandlers from 'components/ButtonsHandlers/ButtonsHandlers';
import CardInfo from 'components/CardInfo/CardInfo';
import Errors from 'components/Errors/Errors';
import Loading from 'components/Loading/Loading';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalDelete from 'components/UI/ModalDelete/ModalDelete';
import { searchReducer, searchSelector } from 'redux/search/searchSlice';
import {
  activeInactiveStartsFetch,
  historyStartsNull,
  startDeleteFetch,
  startDeleteSelector,
  startsFetch,
  startsInfoFetch,
  startsResultsInfoSelector,
  startsSelector,
  updateStartsNavigateNull,
} from 'redux/startsSlice/startsSlice';
import { optionsActiveInactive } from 'utils/sortStatus/sortStatus';

const Info = () => {
  const b = bem('Info');
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const push = useNavigate();
  const { searchTerm } = useSelector(searchSelector);
  const { activeInactive, results, sort } = useSelector(startsSelector);
  const { loadingDelete, history } = useSelector(startDeleteSelector);
  const { resultsInfoStarts, loading, errors } = useSelector(startsResultsInfoSelector);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const resultsObj = results.find((item) => item.id === +id) || resultsInfoStarts;

  useEffect(() => {
    if (history === 'success') {
      const data = {
        query: { page: 1 },
      };
      push('/starts');
      dispatch(
        startsFetch({
          data,
          sort: `&sort=status,-id${sort.status.url}${sort.categories.url}`,
          name: searchTerm ? `&name=${searchTerm}` : searchTerm,
        }),
      );
    }

    dispatch(historyStartsNull());
    dispatch(updateStartsNavigateNull());
  }, [dispatch, history, push]);

  useEffect(() => {
    if (!results.length) {
      dispatch(startsInfoFetch({ id }));
    }
  }, [dispatch]);

  const updateHandler = () => {
    dispatch(updateStartsNavigateNull());
    push(`/starts/update-starts/${id}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(searchReducer(''));
  };

  const modalDeleteHandler = () => {
    setIsModalVisible(true);
  };

  const onChange = (value, id) => {
    const statusUpdate = {
      status: value,
    };

    dispatch(
      activeInactiveStartsFetch({
        data: statusUpdate,
        id,
      }),
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deleteStarts = () => {
    dispatch(startDeleteFetch({ id }));
  };

  if (loading) {
    return <Loading />;
  }

  if (errors) {
    return <Errors status={errors} />;
  }
  return (
    <div className={b()}>
      <div>
        <div className={b('card')}>
          <CardInfo resultsInfo={resultsObj} pushHandler='/starts' componentName='starts.name' />
        </div>
        <Card className={b('block_btn')}>
          <ButtonsHandlers
            nameChange='starts'
            updateHandler={updateHandler}
            loadingUpdate={activeInactive.loading}
            resultsInfo={resultsObj?.status}
            options={optionsActiveInactive}
            id={resultsObj?.id}
            modalDeleteHandler={modalDeleteHandler}
            onChangeStatus={onChange}
            textEdit={t('starts.edit')}
            textDelete={t('starts.delete')}
          />
        </Card>
      </div>
      <ModalComponent open={isModalVisible} onCancel={handleCancel} width={443} height='100%'>
        <ModalDelete
          title={t('starts.modal_delete')}
          text={t('starts.modal_delete_text')}
          noneDeleteHandler={handleCancel}
          yesDeleteHandler={deleteStarts}
          loading={loadingDelete}
        />
      </ModalComponent>
    </div>
  );
};

export default Info;
