import localesUtils from 'utils/localesUtils/localesUtils';

export const optionsActiveInactive = [
  {
    id: 1,
    name: 'inactive',
  },
  {
    id: 0,
    name: 'active',
  },
];

export const sortActiveInactive = (open, value) => {
  if (open) {
    if (value === 0) {
      return 'activeOpen';
    }
    return 'inactiveOpen';
  }
  if (value === 0) {
    return 'active';
  }
  return 'inactive';
};

export const textActiveInactive = (status) => {
  if (status === null) {
    return '';
  }
  if (Number(status) === 0) {
    return localesUtils('Активных', 'Active');
  }

  if (Number(status) === 1) {
    return localesUtils('Неактивных', 'Inactive');
  }
  return '';
};
