import { Button } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';

import icons from 'assets/images/scelet.svg';

const NotActiveInactive = ({ message = '', textLink = '', handlerNotPush = undefined }) => {
  NotActiveInactive.propTypes = {
    message: PropTypes.string,
    textLink: PropTypes.string,
    handlerNotPush: PropTypes.func,
  };
  const b = bem('NotActiveInactive');
  return (
    <div className={b()}>
      <div className={b('block')}>
        <div className='block_img'>
          <img src={icons} alt='icons' width={328} height={328} />
        </div>
        <div className={b('block_2')}>
          <div>
            <p>{message}</p>
            <div>
              <Button
                onClick={handlerNotPush}
                style={{
                  backgroundColor: '#007AFF',
                  height: 40,
                  borderRadius: 8,
                  fontWeight: 600,
                  color: 'white',
                }}
              >
                {textLink}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotActiveInactive;
