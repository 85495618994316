import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import MobileNavigation from 'components/MenuNavigation/MobileNavigation/MobileNavigation';
import Navigation from 'components/MenuNavigation/Navigation/Navigation';
import { logoutUser } from 'redux/auth/authSlice';
import { creatingNullReducer, resultsInfoNull } from 'redux/challengesSlice/challengesSlice';
import {
  errorsValidateReducer,
  historyNotificationsSelector,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import { clearState } from 'redux/promotions/promotionsSlice';
import { searchReducer } from 'redux/search/searchSlice';
import { logoutLocalStorage } from 'utils/token';

const MenuNavigation = () => {
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const push = useNavigate();
  const dispatch = useDispatch();

  const logOutHandler = () => {
    if (errorsValidate) {
      setIsModalVisible(true);
    } else {
      logoutLocalStorage();
      dispatch(logoutUser());
      window.location.reload();
      dispatch(searchReducer(''));
    }
  };

  const yesDeleteHandler = () => {
    logoutLocalStorage();
    dispatch(logoutUser());
    dispatch(modalExitReducer(false));
    dispatch(errorsValidateReducer(false));
    dispatch(creatingNullReducer());
    dispatch(clearState());
    dispatch(resultsInfoNull());
    setIsModalVisible(false);
    push('/admin');
    window.onbeforeunload = false;
  };

  const noneDeleteHandler = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Navigation
        logOutHandler={logOutHandler}
        isModalVisible={isModalVisible}
        yesDeleteHandler={yesDeleteHandler}
        noneDeleteHandler={noneDeleteHandler}
      />
      <MobileNavigation
        logOutHandler={logOutHandler}
        isModalVisible={isModalVisible}
        yesDeleteHandler={yesDeleteHandler}
        noneDeleteHandler={noneDeleteHandler}
      />
    </>
  );
};

export default MenuNavigation;
