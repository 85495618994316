export const updateChallengesValidate = (origin, update, createImages) => {
  const originJson = {
    name: origin.name,
    description: origin.description,
    images: origin.images,
    deleted_images: [],
    participants_limit: origin.participants_limit ? origin.participants_limit.toString() : null,
  };
  const updateJson = {
    name: update.name,
    description: update.description,
    images: [...update.images, ...createImages.images],
    deleted_images: update.deleted_images,
    participants_limit: update.participants_limit ? update.participants_limit.toString() : null,
  };
  return (
    JSON.stringify(originJson).replace(/ /g, '') === JSON.stringify(updateJson).replace(/ /g, '')
  );
};

export const updatePromotionsValidate = (origin, update, deleteImages) => {
  const originJson = { ...origin, deleted_images: [] };
  const updateJson = {
    ...origin,
    name: update.name,
    description: update.description,
    starts_at: update.starts_at,
    ends_at: update.ends_at,
    images: [...origin.images, ...update.images],
    deleted_images: deleteImages,
    url: update.url,
    button_text: update.button_text,
  };
  return (
    JSON.stringify(originJson).replace(/ /g, '') === JSON.stringify(updateJson).replace(/ /g, '')
  );
};

export const updateStartsValidate = (origin, update, deleteImages) => {
  const originJson = { ...origin, deleted_images: [] };
  const updateJson = {
    ...origin,
    name: update.name,
    description: update.description,
    images: [...origin.images, ...update.images],
    deleted_images: deleteImages,
    url: update.url,
    date: update.date,
    registration_starts: update.registration_starts,
    registration_ends: update.registration_ends,
    button_text: update.button_text,
  };
  return (
    JSON.stringify(originJson).replace(/ /g, '') === JSON.stringify(updateJson).replace(/ /g, '')
  );
};

export const updateTeamsValidate = (origin, update, deleteMembers) => {
  const originJson = { ...origin, deleted_members: [] };
  const updateJson = {
    ...origin,
    name: update.name,
    deleted_members: deleteMembers,
    members: [...origin.members, ...update.members],
  };

  return (
    JSON.stringify(originJson).replace(/ /g, '') === JSON.stringify(updateJson).replace(/ /g, '')
  );
};

export const updateNewsValidate = (origin, update, deleteImages) => {
  const originJson = { ...origin, deleted_images: [] };
  const updateJson = {
    ...origin,
    title: update.title,
    text: update.text,
    images: [...origin.images, ...update.images],
    deleted_images: deleteImages,
  };
  return (
    JSON.stringify(originJson).replace(/ /g, '') === JSON.stringify(updateJson).replace(/ /g, '')
  );
};
