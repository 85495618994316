import PropTypes from 'prop-types';
import React from 'react';

import DateFormRangePicker from 'components/Fields/DateFormRangePicker/DateFormRangePicker';
import DatePickerField from 'components/Fields/DatePickerField/DatePickerField';
import DefaultField from 'components/Fields/DefaultField';
import Description from 'components/Fields/Description/Description';
import EmailField from 'components/Fields/EmailField/EmailField';
import InputInterest from 'components/Fields/InputInterest/InputInterest';
import ModificationInput from 'components/Fields/ModificationInput/ModificationInput';
import NumberField from 'components/Fields/NumberField/NumberField';
import PasswordField from 'components/Fields/PasswordField/PasswordField';
import RangePickerComponent from 'components/Fields/RangePickerComponent/RangePickerComponent';
import SelectField from 'components/Fields/SelectField/SelectField';
import SelectStatus from 'components/Fields/SelectStatus/SelectStatus';
import SelectTags from 'components/Fields/SelectTags/SelectTags';
import ShowSearchSelect from 'components/Fields/ShowSearchSelect/ShowSearchSelect';
import SunEditorField from 'components/Fields/SunEditorField/SunEditorField';
import TagInput from 'components/Fields/TagInput/TagInput';
import TextareaField from 'components/Fields/TextareaField/TextareaField';

const FormField = ({ type = '', ...props }) => {
  FormField.propTypes = {
    type: PropTypes.string,
  };

  switch (type) {
    case 'email':
      return <EmailField {...props} />;
    case 'number':
      return <NumberField {...props} />;
    case 'password':
      return <PasswordField {...props} />;
    case 'select':
      return <SelectField {...props} />;
    case 'textarea':
      return <TextareaField {...props} />;
    case 'modificationInput':
      return <ModificationInput {...props} />;
    case 'description':
      return <Description {...props} />;
    case 'dateFormRangePicker':
      return <DateFormRangePicker {...props} />;
    case 'datePicker':
      return <DatePickerField {...props} />;
    case 'showSearchSelect':
      return <ShowSearchSelect {...props} />;
    case 'inputInterest':
      return <InputInterest {...props} />;
    case 'selectStatus':
      return <SelectStatus {...props} />;
    case 'tagInput':
      return <TagInput {...props} />;
    case 'editor':
      return <SunEditorField {...props} />;
    case 'rangePicker':
      return <RangePickerComponent {...props} />;
    case 'selectTags': {
      return <SelectTags {...props} />;
    }
    default:
      return <DefaultField {...props} />;
  }
};

export default FormField;
