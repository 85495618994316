import i18n from 'i18next';
import moment from 'moment';

export const dateMomentFormat = 'YYYY-MM-DD';
export const dateTimeFormat = 'DD.MM.YYYY';
export const dateAndTimeMomentFormat = 'DD.MM.YYYY HH:mm:ss';
export const dateTimeMomentFormat = 'DD.MM.YYYY HH:mm';

export const dateTimeToFormat = (date, format) => {
  return moment(date).format(format);
};

export const convertUtcToLocalTime = (date, format) => {
  const utcMoment = moment.utc(date);
  return utcMoment.local().format(format);
};

export const dateFormat = (endDate) => {
  const mEnd = moment(endDate).add(1, 'days');
  const endChallenges = moment(mEnd).fromNow();

  if (i18n?.language === 'ru') {
    moment.locale('ru');
    return endChallenges;
  }

  if (i18n?.language === 'en') {
    moment.locale('en');
    return endChallenges;
  }
};

export const dateEnd = (endDate) => {
  const mStart = moment();
  const mEnd = moment(endDate).add(1, 'days');
  return mStart.isBefore(mEnd);
};

export function datePickerFormat(date = null) {
  const resDate = new Date(date?.$d);
  const mm = resDate.getMonth() + 1;
  const dd = resDate.getDate();
  const yy = resDate.getFullYear();
  return `${yy}-${mm}-${dd}`;
}

export function sundayDate() {
  const resDate = new Date();
  const mm = resDate.getMonth() + 1;
  const dd =
    +resDate.getDate().toString().length === 1 ? `0${resDate.getDate()}` : resDate.getDate();
  const yy = resDate.getFullYear();
  return `${yy}-${mm}-${dd}`;
}

export function sundayEnd() {
  const resDate = new Date(new Date().setDate(new Date().getDate() + 1));
  const mm = resDate.getMonth() + 1;
  const dd =
    +resDate.getDate().toString().length === 1 ? `0${resDate.getDate()}` : resDate.getDate();
  const yy = resDate.getFullYear();

  return `${yy}-${mm}-${dd}`;
}
