import { Button } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalExit = ({
  noneDeleteHandler = undefined,
  yesDeleteHandler = undefined,
  loading = false,
}) => {
  ModalExit.propTypes = {
    noneDeleteHandler: PropTypes.func,
    yesDeleteHandler: PropTypes.func,
    loading: PropTypes.bool,
  };
  const { t } = useTranslation();
  const b = bem('modalEventsDelete');

  return (
    <div data-testid='elem-modal-delete' className={b()}>
      <div>
        <h1>{t('home.data_has_not')}</h1>
        <p>{t('home.are_you_sure')}</p>
        <div className='btn_delete'>
          <Button
            data-testid='elem-not'
            onClick={noneDeleteHandler}
            style={{
              backgroundColor: '#F4F6FF',
              color: '#007AFF',
              marginRight: '12px',
            }}
          >
            {t('home.cancellation')}
          </Button>
          <Button
            loading={loading}
            data-testid='yes-elem'
            onClick={yesDeleteHandler}
            style={{
              backgroundColor: '#007AFF',
              color: '#fff',
            }}
          >
            {t('home.yes')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalExit;
