import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { sortActiveInactive } from 'utils/sortStatus/sortStatus';

const { Option } = Select;

const SelectStatus = ({
  value = undefined,
  onChange = undefined,
  options = [],
  width = '',
  name = '',
  id = undefined,
  loadingUpdate = false,
  open = false,
  disabled = false,
}) => {
  SelectStatus.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    options: PropTypes.instanceOf(Array),
    width: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
    loadingUpdate: PropTypes.bool,
    open: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  const { t } = useTranslation();

  return (
    <div className={sortActiveInactive(open, +value)}>
      <Select
        disabled={disabled}
        loading={loadingUpdate}
        style={{
          fontSize: 14,
          border: 'none',
          width,
        }}
        onChange={(value) => onChange(value, id)}
        value={value}
      >
        {options?.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              {name === 'promotions' ? t(`promotions.${item.name}`) : t(`challenges.${item.name}`)}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectStatus;
