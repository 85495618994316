import Card from 'antd/lib/card/Card';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ButtonsHandlers from 'components/ButtonsHandlers/ButtonsHandlers';
import CardInfo from 'components/CardInfo/CardInfo';
import Errors from 'components/Errors/Errors';
import SkeletonInfo from 'components/SkeletonBlock/SkeletonInfo/SkeletonInfo';
import TableComponent from 'components/TableComponent/TableComponent';
import ModalActiveInactive from 'components/UI/ModalActiveInactive/ModalActiveInactive';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalDelete from 'components/UI/ModalDelete/ModalDelete';
import {
  activeInactiveFetch,
  challengesDeleteFetch,
  challengesDeleteSelector,
  challengesFetch,
  challengesInfoFetch,
  challengesInfoSelector,
  challengesSelector,
  challengesUpdateSelector,
  editAddReducer,
  historyNull,
  removeErrors,
  updateHistory,
} from 'redux/challengesSlice/challengesSlice';
import { homeSelector } from 'redux/homeSlice/homeSlice';
import { searchReducer, searchSelector } from 'redux/search/searchSlice';
import { exportNull, participantsFetch, usersSelector } from 'redux/users/usersSlice';
import { participationStatus } from 'utils/constants';
import { dateEnd } from 'utils/dateFormat/dateFormat';
import { optionsActiveInactive } from 'utils/sortStatus/sortStatus';

const Info = () => {
  const b = bem('Info');
  const { t } = useTranslation();
  const { id } = useParams();
  const push = useNavigate();
  const dispatch = useDispatch();
  const { searchTerm } = useSelector(searchSelector);
  const { results, activeInactive, sort } = useSelector(challengesSelector);
  const { challengesHome } = useSelector(homeSelector);
  const { errors, loading, resultsInfo } = useSelector(challengesInfoSelector);
  const { loadingDelete, history } = useSelector(challengesDeleteSelector);
  const { historyEdit } = useSelector(challengesUpdateSelector);
  const { participants, participantsLoading, participantsError, exportLoading, exportFile } =
    useSelector(usersSelector);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalActiveInactive, setIsModalActiveInactive] = useState(false);
  const [dataActiveInactive, setDataActiveInactive] = useState({
    value: '',
    id: 0,
  });

  useEffect(() => {
    if (exportFile) {
      const blob = new Blob([exportFile], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', `${resultsObj.name} participants.csv`);
      a.click();
    }

    return () => {
      dispatch(exportNull());
    };
  }, [dispatch, exportFile]);

  useEffect(() => {
    if (!results.length && !challengesHome.results.length && !resultsInfo) {
      dispatch(challengesInfoFetch({ id }));
    }
    if (errors) {
      dispatch(removeErrors());
    }
  }, [challengesHome.results.length]);

  useEffect(() => {
    if (history === 'success') {
      const data = {
        query: { page: 1 },
      };
      push('/challenges');
      dispatch(
        challengesFetch({
          data,
          sort: `&sort=status,-id${sort.status.url}${sort.categories.url}`,
          name: searchTerm ? `&name=${searchTerm}` : searchTerm,
        }),
      );
    }

    dispatch(historyNull());
  }, [dispatch, history, push]);

  useEffect(() => {
    dispatch(updateHistory());
  }, [dispatch, historyEdit]);

  useEffect(() => {
    if (activeInactive.errors) {
      setIsModalActiveInactive(false);
    }
  }, [activeInactive]);

  const resultsObj = results.find((item) => item.id === Number(id)) || resultsInfo;

  useEffect(() => {
    const data = {
      query: { page: 1 },
    };
    if (resultsObj) {
      if (Number(resultsObj?.status) === dataActiveInactive.value) {
        setIsModalActiveInactive(false);
        dispatch(
          challengesFetch({
            data,
            sort: `&sort=status,-id${sort.status.url}${sort.categories.url}`,
            name: searchTerm ? `&name=${searchTerm}` : searchTerm,
          }),
        );
      }
    }
  }, [dataActiveInactive.value, resultsObj]);

  const deleteChallenges = () => {
    dispatch(challengesDeleteFetch({ id }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const modalDeleteHandler = () => {
    setIsModalVisible(true);
  };

  const updateHandler = () => {
    const findEdit = results.find((item) => item.id === +id);
    dispatch(editAddReducer(findEdit || resultsInfo));
    push(`/challenges/update-challenge/${id}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(searchReducer(''));
  };

  const handleCancelActiveInactive = () => {
    setIsModalActiveInactive(false);
  };

  const onChange = (value, id) => {
    setIsModalActiveInactive(true);
    setDataActiveInactive({
      value,
      id,
    });
  };

  const yesHandler = () => {
    const statusUpdate = {
      status: dataActiveInactive.value,
    };
    dispatch(
      activeInactiveFetch({
        data: statusUpdate,
        id: dataActiveInactive.id,
      }),
    );
  };

  if (loading) {
    return <SkeletonInfo />;
  }

  if (errors) {
    return <Errors status={errors} />;
  }
  return (
    <div className={b()}>
      <div className={b('ChallengesOpen_block')}>
        <CardInfo
          resultsInfo={resultsObj}
          pushHandler='/challenges'
          componentName='challenges.name'
        />
        <Card className={b('ChallengesOpen_block__card-actions')}>
          <ButtonsHandlers
            endDate={!dateEnd(resultsObj?.ends_at)}
            nameChange='challenges'
            updateHandler={updateHandler}
            loadingUpdate={activeInactive.loading}
            resultsInfo={resultsObj?.status}
            options={optionsActiveInactive}
            id={resultsObj?.id}
            modalDeleteHandler={modalDeleteHandler}
            onChangeStatus={onChange}
            textEdit={t('challenges.edit')}
            textDelete={t('challenges.delete')}
          />
        </Card>
      </div>
      <div className={b('table')}>
        <p>{t('challenges.list_of_participants')}</p>
        {participantsError ? (
          <Errors status={participantsError} />
        ) : (
          <TableComponent
            challengeIds={id}
            usersParticipantsSearchFetch={participantsFetch}
            usersParticipantsList={participants}
            loadingIndicator={participantsLoading}
            filterData={participants?.items}
            usersList={participants}
            searchPlaceholder={t('challenges.search_participants')}
            placeholderForStatusName={t('challenges.participation_status')}
            statusFilterMap={participationStatus}
            statusInfo='participantStatus'
            exportLoading={exportLoading}
          />
        )}
      </div>
      <ModalComponent open={isModalVisible} onCancel={handleCancel} width={443} height='100%'>
        <ModalDelete
          title={t('challenges.modal_delete')}
          text={t('challenges.modal_delete_text')}
          noneDeleteHandler={handleCancel}
          yesDeleteHandler={deleteChallenges}
          loading={loadingDelete}
        />
      </ModalComponent>
      <ModalComponent
        open={isModalActiveInactive}
        onCancel={handleCancelActiveInactive}
        width={440}
        height='100%'
      >
        <ModalActiveInactive
          results={{ ...resultsObj, loading: activeInactive.loading }}
          noneHandler={() => setIsModalActiveInactive(false)}
          yesHandler={yesHandler}
        />
      </ModalComponent>
    </div>
  );
};

export default Info;
