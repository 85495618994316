import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SelectField = ({
  placeholder = '',
  name = '',
  label = '',
  onChange = undefined,
  options = [],
  size = 0,
  defaultValue = 0,
}) => {
  SelectField.propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.instanceOf(Array),
    size: PropTypes.number,
    defaultValue: PropTypes.number,
  };
  const { t } = useTranslation();
  const { Option } = Select;

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: true,
          message: t('errors.choose_organization'),
        },
      ]}
    >
      <Select
        placeholder={placeholder}
        size={size || 'large'}
        defaultValue={defaultValue}
        style={{
          width: 120,
        }}
        onChange={onChange}
      >
        {options?.map((option) => {
          return (
            <Option key={option.id} value={option.id}>
              {t(option.name_ru, { returnObjects: true })}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default SelectField;
