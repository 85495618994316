import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'App.js';
import translationEN from 'locales/translationEN.json';
import translationRU from 'locales/translationRU.json';
import store from 'redux/store';
import initSentry from 'senrty/sentry';
import 'assets/scss/app.scss';

initSentry();

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    ru: { translation: translationRU },
  },
  lng: 'ru',
  fallbackLng: 'ru',
  interpolation: { escapeValue: false },
});

const root = ReactDOM.createRoot(document.getElementById('root') || document.createElement('div'));

root.render(
  <Provider store={store}>
    <BrowserRouter basename='/'>
      <App />
    </BrowserRouter>
  </Provider>,
);
