import { Button } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import noChallenges from 'assets/images/noChallenges.svg';

const PlugForLists = ({
  pushHandler = undefined,
  mainText = '',
  subMainText = '',
  buttonText = '',
}) => {
  PlugForLists.propTypes = {
    pushHandler: PropTypes.func,
    mainText: PropTypes.string,
    subMainText: PropTypes.string,
    buttonText: PropTypes.string,
  };
  const b = bem('NoChallenges');
  const { t } = useTranslation();

  return (
    <div data-testid='not-found-events' className={b()}>
      <div>
        <img src={noChallenges} alt='not found' />
      </div>
      <div className='text_NoChallenges'>
        <h1>{mainText || t('challenges.started')}</h1>
        <p>{subMainText}</p>
        <Button
          onClick={pushHandler}
          style={{
            width: '200px',
            height: '44px',
            marginTop: '30px',
            fontSize: 14,
            fontWeight: 600,
            borderRadius: 8,
          }}
          type='primary'
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default PlugForLists;
