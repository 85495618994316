import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, Input } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import 'components/Fields/PasswordField/_passwordField.scss';
import localesValidateInput from 'utils/localiesValidateInput/localiesValidateInput';

const PasswordField = ({
  id = 0,
  className = '',
  label = '',
  name = '',
  type = '',
  placeholder = '',
  dependencies = '',
}) => {
  PasswordField.propTypes = {
    id: PropTypes.number,
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    dependencies: PropTypes.string,
  };
  const b = bem('PasswordField');
  const { t } = useTranslation();

  const error = t('errors.two_passwords_do_not_match');

  return (
    <div>
      {name === 'confirm_password' ? (
        <Form.Item
          type={type}
          hasFeedback
          className={className || b()}
          name={name}
          dependencies={dependencies}
          rules={[
            {
              required: true,
              message: localesValidateInput(
                <span id='confirm_password'>{t('errors.confirm_password')}</span>,
                'confirm_password',
                t('errors.confirm_password'),
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(error));
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={placeholder}
            style={{ borderRadius: '7px' }}
            iconRender={(visible) => {
              visible ? (
                <EyeTwoTone style={{ fontSize: '19px', color: '#000' }} />
              ) : (
                <EyeInvisibleOutlined style={{ fontSize: '19px', color: '#000' }} />
              );
            }}
          />
        </Form.Item>
      ) : (
        <Form.Item
          type={type}
          id={id}
          className={className || b()}
          label={label}
          name={name}
          hasFeedback
          rules={[
            {
              required: true,
              message: localesValidateInput(
                <span id='enter_password'>{t('errors.enter_password')}</span>,
                'enter_password',
                t('errors.enter_password'),
              ),
            },
            {
              min: 8,
              message: localesValidateInput(
                <span id='password_must_have_minimum_length_of_8'>
                  {t('errors.password_must_have_minimum_length_of_8')}
                </span>,
                'password_must_have_minimum_length_of_8',
                t('errors.password_must_have_minimum_length_of_8'),
              ),
            },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%_*?&])[A-Za-z\d@$!%_*?&]{8,}$/,
              message: localesValidateInput(
                <span id='password_must_contain_lowercase_uppercase_number_special_character'>
                  {t('errors.password_must_contain_lowercase_uppercase_number_special_character')}
                </span>,
                'password_must_contain_lowercase_uppercase_number_special_character',
                t('errors.password_must_contain_lowercase_uppercase_number_special_character'),
              ),
            },
          ]}
        >
          <Input.Password
            placeholder={placeholder}
            style={{ borderRadius: '7px' }}
            iconRender={(visible) => {
              visible ? (
                <EyeTwoTone style={{ fontSize: '19px', color: '#000' }} />
              ) : (
                <EyeInvisibleOutlined style={{ fontSize: '19px', color: '#000' }} />
              );
            }}
          />
        </Form.Item>
      )}
    </div>
  );
};

export default PasswordField;
