import bem from 'easy-bem';
import React from 'react';

import SkeletonButton from 'components/SkeletonBlock/FieldsSkeleton/SkeletonButton';

const SkeletonSearch = () => {
  const b = bem('SkeletonSearch');
  return (
    <div data-testid='search' className={b()}>
      <SkeletonButton num={1} height={40} />
      <div className={b('card')}>
        <SkeletonButton num={8} height={60} width='240px' marginTop={12} />
      </div>
    </div>
  );
};

export default SkeletonSearch;
