import { Button } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalActiveInactive = ({ results = {}, noneHandler = undefined, yesHandler = undefined }) => {
  ModalActiveInactive.propTypes = {
    results: PropTypes.instanceOf(Object),
    noneHandler: PropTypes.func,
    yesHandler: PropTypes.func,
  };
  const { t } = useTranslation();
  const b = bem('ModalActiveInactive');

  return (
    <div data-testid='elem-modal-delete' className={b()}>
      <div>
        <h1 data-testid='title_delete_modal'>
          {t('challenges.active_title')}{' '}
          {results?.status === 1 ? t('challenges.activate') : t('challenges.deactivate')}
        </h1>
        <p className='text_card_hidden'>{results?.name}</p>
        <div className='btn_active_inactive'>
          <Button
            data-testid='elem-not'
            onClick={noneHandler}
            style={{
              backgroundColor: '#F4F6FF',
              color: '#007AFF',
              marginRight: '12px',
            }}
          >
            {t('challenges.leave')}
          </Button>
          <Button
            loading={results?.loading}
            data-testid='yes-elem'
            onClick={yesHandler}
            style={{
              backgroundColor: '#007AFF',
              color: '#fff',
            }}
          >
            {results?.status === 1 ? t('challenges.activate') : t('challenges.deactivate')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalActiveInactive;
