import { ConfigProvider, DatePicker } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import ruRU from 'antd/lib/locale/ru_RU';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

const { RangePicker } = DatePicker;

const RangePickerComponent = ({ onChange = undefined, format = '' }) => {
  RangePickerComponent.propTypes = {
    onChange: PropTypes.func,
    format: PropTypes.string,
  };

  return (
    <ConfigProvider locale={i18n?.language === 'ru' ? ruRU : enUS}>
      <RangePicker bordered={false} format={format} onChange={onChange} />
    </ConfigProvider>
  );
};

export default RangePickerComponent;
