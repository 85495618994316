import bem from 'easy-bem';
import React from 'react';

import SkeletonButton from 'components/SkeletonBlock/FieldsSkeleton/SkeletonButton';

const SkeletonStatic = () => {
  const b = bem('SkeletonStatic');
  return (
    <div className={b()}>
      <SkeletonButton width='260px' height={120} marginTop={20} borderRadius={8} num={2} />
    </div>
  );
};

export default SkeletonStatic;
