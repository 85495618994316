import i18n from 'i18next';

const localesUtils = (ru, en) => {
  if (i18n?.language === 'ru') {
    return ru;
  }

  return en;
};

export default localesUtils;
