import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';

const nameSpace = 'home';

const INITIAL_STATE = {
  challengesHome: {
    results: [],
    loading: false,
    errors: null,
    pagination: {
      count: 0,
      previous: null,
      next: '',
    },
  },
  promotionsHome: {
    results: [],
    loading: false,
    errors: null,
    pagination: {
      count: 0,
      previous: null,
      next: '',
    },
  },
  startsHome: {
    results: [],
    loading: false,
    errors: null,
    pagination: {
      count: 0,
      previous: null,
      next: '',
    },
  },
};

export const challengesHomeFetch = createAsyncThunk(
  `${nameSpace}/challengesHomeFetch`,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/challenges/?page=1&sort=status,-id`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const promotionsHomeFetch = createAsyncThunk(
  `${nameSpace}/promotionsHomeFetch`,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/promotions/?page=1&sort=status,-id`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const startsHomeFetch = createAsyncThunk(
  `${nameSpace}/startsHomeFetch`,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/starts/?page=1&sort=status,-id`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

const challengesSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(challengesHomeFetch.pending, (state) => {
      state.challengesHome = {
        ...state.challengesHome,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(challengesHomeFetch.fulfilled, (state, { payload }) => {
      state.challengesHome = {
        ...state.challengesHome,
        results: payload.results,
        loading: false,
        errors: null,
      };
      state.challengesHome.pagination = {
        ...state.challengesHome.pagination,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    });
    builder.addCase(challengesHomeFetch.rejected, (state, { payload }) => {
      state.challengesHome = {
        ...state.challengesHome,
        loading: true,
        errors: payload,
      };
    });

    builder.addCase(promotionsHomeFetch.pending, (state) => {
      state.promotionsHome = {
        ...state.promotionsHome,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(promotionsHomeFetch.fulfilled, (state, { payload }) => {
      state.promotionsHome = {
        ...state.promotionsHome,
        results: payload.results,
        loading: false,
        errors: null,
      };
      state.promotionsHome.pagination = {
        ...state.promotionsHome.pagination,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    });
    builder.addCase(promotionsHomeFetch.rejected, (state, { payload }) => {
      state.promotionsHome = {
        ...state.promotionsHome,
        loading: true,
        errors: payload,
      };
    });

    builder.addCase(startsHomeFetch.pending, (state) => {
      state.startsHome = {
        ...state.startsHome,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(startsHomeFetch.fulfilled, (state, { payload }) => {
      state.startsHome = {
        ...state.startsHome,
        results: payload.results,
        loading: false,
        errors: null,
      };
      state.startsHome.pagination = {
        ...state.startsHome.pagination,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    });
    builder.addCase(startsHomeFetch.rejected, (state, { payload }) => {
      state.startsHome = {
        ...state.startsHome,
        loading: true,
        errors: payload,
      };
    });
  },
});

export const homeSelector = (state) => state.home;

export default challengesSlice.reducer;
