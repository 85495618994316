import { UnorderedListOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Status from 'components/Status/Status';
import localesUtils from 'utils/localesUtils/localesUtils';

const SortCategory = ({
  categoriesHandler = undefined,
  titleObject = {},
  menu = [],
  loading = false,
}) => {
  SortCategory.propTypes = {
    categoriesHandler: PropTypes.func,
    titleObject: PropTypes.instanceOf(Object),
    menu: PropTypes.instanceOf(Array),
    loading: PropTypes.bool,
  };
  const { t } = useTranslation();
  const items = [
    {
      key: 'vse',
      label: (
        <button type='button' className='status_block_categories'>
          <div className='icons_categories_block'>
            <UnorderedListOutlined
              style={{
                padding: 3,
                color: 'black',
                fontSize: 12,
              }}
            />
          </div>
          <p> {t('challenges.all')} </p>
        </button>
      ),
    },
    ...menu.map((item) => {
      return {
        key: item.id,
        label: (
          <button type='button' className='status_block_categories'>
            <div className='icons_categories_block'>
              <img
                src={item.icon}
                width={18}
                height={18}
                className='black_category'
                alt={item.name_en}
              />
            </div>
            <p>{localesUtils(item.name_ru, item.name_en)}</p>
          </button>
        ),
      };
    }),
  ];
  return (
    <div className='status'>
      {' '}
      <span>{t('challenges.sort_by')}</span>{' '}
      {loading ? (
        <Spin />
      ) : (
        <Status
          items={items}
          onClick={categoriesHandler}
          defaultSelectedKeys={[titleObject.categories.id || 'vse']}
          title='challenges.all_category'
        />
      )}
    </div>
  );
};

export default SortCategory;
