import { Button } from 'antd';
import bem from 'easy-bem';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Errors from 'components/Errors/Errors';
import PlugForLists from 'components/NoChallenge/plugForLists';
import NotActiveInactive from 'components/NotActiveInactive/NotActiveInactive';
import NotFoundSearch from 'components/NotFoundSearch/NotFoundSearch';
import ShowAllCard from 'components/ShowAllCard/ShowAllCard';
import SkeletonBlock from 'components/SkeletonBlock/SkeletonBlock';
import SortBy from 'components/SortBy/SortBy';
import SortCategory from 'components/SortBy/SortCategory/SortCategory';
import { searchSelector } from 'redux/search/searchSlice';
import {
  activeInactiveStartsFetch,
  addPageParamsStarts,
  createStartsNull,
  loadingStartsActiveInActive,
  minusPageParamsStart,
  nullPageParamsStarts,
  sortHandlerStartsReducer,
  startsCategoriesFetch,
  startsFetch,
  startsPaginationSelector,
  startsSelector,
  updateStartsHistory,
} from 'redux/startsSlice/startsSlice';
import localesUtils from 'utils/localesUtils/localesUtils';
import { optionsActiveInactive, textActiveInactive } from 'utils/sortStatus/sortStatus';

const Starts = () => {
  const b = bem('Starts');
  const { sort, categories, results, loading, errors, params } = useSelector(startsSelector);
  const { searchTermStart } = useSelector(searchSelector);
  const { next, previous } = useSelector(startsPaginationSelector);
  const push = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      query: { page: params.page || 1 },
    };
    if (!searchTermStart && !results.length) {
      dispatch(
        startsFetch({
          data,
          sort: `&sort=status,-id${sort.status.url}${sort.categories.url}`,
          name: searchTermStart ? `&name=${searchTermStart}` : searchTermStart,
        }),
      );
    }
  }, [dispatch, searchTermStart]);

  useEffect(() => {
    if (!categories.results.length) {
      dispatch(startsCategoriesFetch());
    }
  }, [dispatch]);

  const pushHandler = () => {
    dispatch(createStartsNull());
    push('/starts/create');
  };

  const statusHandler = ({ key }) => {
    const data = {
      query: { page: params.page },
    };
    if (Number(params.page) >= 1) {
      data.query.page = 1;
      dispatch(nullPageParamsStarts());
    }
    if (Number(key) <= 1) {
      dispatch(
        startsFetch({
          data,
          sort: `&sort=status,-id&status=${key}${sort.categories.url}`,
          name: searchTermStart ? `&name=${searchTermStart}` : searchTermStart,
        }),
      );
      dispatch(
        sortHandlerStartsReducer({
          status: {
            id: key,
            title: Number(key) === 0 ? 'sort.active' : 'sort.inactive',
            url: `&status=${key}`,
          },
        }),
      );
    } else {
      dispatch(
        startsFetch({
          data,
          sort: `&sort=status,-id${sort.categories.url}`,
          name: searchTermStart ? `&name=${searchTermStart}` : searchTermStart,
        }),
      );
      dispatch(
        sortHandlerStartsReducer({
          status: {
            id: '2',
            title: '',
            url: '',
          },
        }),
      );
    }
  };
  const categoriesHandler = ({ key }) => {
    const data = {
      query: { page: params.page },
    };
    if (Number(params.page) >= 1) {
      data.query.page = 1;
      dispatch(nullPageParamsStarts());
    }

    if (categories.results.length) {
      if (key !== 'vse') {
        const titleText = categories.results.find((item) => item.id === +key);
        dispatch(
          startsFetch({
            data,
            sort: `&sort=status,-id${sort.status.url}&category=${key}`,
            name: searchTermStart ? `&name=${searchTermStart}` : searchTermStart,
          }),
        );
        dispatch(
          sortHandlerStartsReducer({
            categories: {
              id: key,
              title: {
                name_ru: `«${titleText.name_ru}»`,
                name_en: `«${titleText.name_en}»`,
              },
              url: `&category=${key}`,
            },
          }),
        );
      } else {
        dispatch(
          startsFetch({
            data,
            sort: `&sort=status,-id${sort.status.url}`,
            name: searchTermStart ? `&name=${searchTermStart}` : searchTermStart,
          }),
        );
        dispatch(
          sortHandlerStartsReducer({
            categories: {
              id: 'vse',
              title: {
                name_ru: '',
                name_en: '',
              },
              url: '',
            },
          }),
        );
      }
    }
  };
  const onChange = (value, id) => {
    const statusUpdate = {
      status: value,
    };
    dispatch(activeInactiveStartsFetch({ data: statusUpdate, id }));
    dispatch(loadingStartsActiveInActive({ id }));
    dispatch(updateStartsHistory());
  };
  const fileHandler = () => {
    dispatch(createStartsNull());
  };
  const nextBrowserStartsHandler = (id) => {
    push(`/starts/starts-info/${id}`);
  };
  const handlerNotPush = () => {
    push('/starts/create');
  };

  const backHandler = () => {
    dispatch(minusPageParamsStart);
    const data = {
      query: { page: params.page - 1 || 1 },
    };
    dispatch(
      startsFetch({
        data,
        sort: `&sort=status,-id${sort.status.url}${sort.categories.url}`,
        name: searchTermStart ? `&name=${searchTermStart}` : searchTermStart,
      }),
    );
  };
  const nextHandler = () => {
    dispatch(addPageParamsStarts());
    const data = {
      query: { page: params.page + 1 || 1 },
    };
    dispatch(
      startsFetch({
        data,
        sort: `&sort=status,-id${sort.status.url}${sort.categories.url}`,
        name: searchTermStart ? `&name=${searchTermStart}` : searchTermStart,
      }),
    );
  };

  if (categories.loading || loading) {
    return <SkeletonBlock active={loading || categories.loading} num={20} titleBool />;
  }

  if (errors || categories.errors) {
    return <Errors status={errors || categories.errors} />;
  }

  if (searchTermStart && !results.length) {
    return (
      <div className={b()}>
        <div className='block_promotions_title'>
          <p className='title_text_v'>
            {t('search.search_starts')} {t(sort.status.title).toLowerCase()}
          </p>
          <div>
            <SortBy statusHandler={statusHandler} titleObject={sort} />
          </div>
        </div>
        <NotFoundSearch textSearch={searchTermStart} />
      </div>
    );
  }

  if (
    (sort.status.title && !results.length) ||
    (sort.categories.title.name_ru && !results.length)
  ) {
    return (
      <div className={b()}>
        <div className={b('title')}>
          <p className='title_text_v'>
            {searchTermStart
              ? `${t('search.search_starts')}${t(sort.status.title)}`
              : `${t(sort.status.title)} ${t('starts.name')} `}
            {sort.categories.title.name_ru ? (
              <span className='text_categories_name'>
                {localesUtils(sort.categories.title.name_ru, sort.categories.title.name_en)}
              </span>
            ) : null}
          </p>
          <div>
            <SortBy
              statusHandler={statusHandler}
              titleObject={sort}
              categoriesHandler={categoriesHandler}
              menuBoolean
            />
            <SortCategory
              menu={categories.results}
              loading={categories.loading}
              categoriesHandler={categoriesHandler}
              titleObject={sort}
            />
          </div>
        </div>
        <div>
          <NotActiveInactive
            message={` ${textActiveInactive(sort.status.id)} ${t('starts.name')} ${localesUtils(
              sort.categories.title.name_ru,
              sort.categories.title.name_en,
            )} ${t('starts.no')}`}
            handlerNotPush={handlerNotPush}
            textLink={t('starts.creating_starts')}
          />
        </div>
      </div>
    );
  }

  if (results.length) {
    return (
      <div className={b()}>
        <div className={b('title')}>
          <h3 className={b('title_name')}>
            {searchTermStart
              ? `${t('search.search_starts')}${t(sort.status.title)}`
              : `${t(sort.status.title)} ${t('starts.name')} `}
            {sort.categories.title.name_ru ? (
              <span className='text_categories_name'>
                {localesUtils(sort.categories.title.name_ru, sort.categories.title.name_en)}
              </span>
            ) : null}
          </h3>
          <div>
            <SortBy statusHandler={statusHandler} titleObject={sort} menuBoolean />
            <SortCategory
              menu={categories.results}
              loading={categories.loading}
              categoriesHandler={categoriesHandler}
              titleObject={sort}
            />
          </div>
        </div>
        <ShowAllCard
          link='/starts/create'
          fileLoading
          results={results || []}
          options={optionsActiveInactive}
          onChange={onChange}
          fileHandler={fileHandler}
          category
          status
          nextBrowserHandler={nextBrowserStartsHandler}
          titleFile={t('starts.create_new_starts')}
          textFile={t('starts.create_your_starts')}
        />
        <div className={b('pagination_btn')}>
          <Button
            className='btn_starts'
            type='primary'
            size='large'
            disabled={previous === null}
            onClick={() => backHandler()}
          >
            {t('promotions.back')}
          </Button>
          <Button
            className='btn_starts'
            type='primary'
            size='large'
            disabled={next === null}
            onClick={() => nextHandler()}
          >
            {t('promotions.next')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <PlugForLists
      pushHandler={pushHandler}
      subMainText={t('starts.starts_starts_photo')}
      buttonText={t('starts.starts_starts')}
    />
  );
};

export default Starts;
