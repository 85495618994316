import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { message } from 'antd';

import { messagesErrorFetch, messagesSuccess } from 'components/Messages/Messagetext';
import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';
import { errorsHandler, successText } from 'utils/errorsHandler/errorsHandler';
import toQueryParams from 'utils/toQueryParams';

const nameSpace = 'news';

const INITIAL_STATE = {
  news: [],
  errors: null,
  loading: false,
  pagination: {
    count: 0,
    previous: null,
    next: '',
  },
  params: {
    page: 1,
  },
  newsInfo: {
    loading: false,
    error: null,
    results: null,
  },
  createNews: {
    title: '',
    text: '',
    images: [],
    users: [],
    teams: [],
  },
  editNews: {
    title: '',
    text: '',
    images: [],
    deleted_images: [],
  },
  updateNewsData: {
    images: [],
    imagesHttps: [],
  },
  deleteNews: {
    errorsDelete: null,
    loadingDelete: false,
    history: '',
  },
  newsSuccess: {
    loading: false,
    errors: null,
    success: 'not',
  },
  updatePutNews: {
    loading: false,
    error: null,
    updatePutNewsNavigate: false,
  },
};

export const newsFetch = createAsyncThunk(
  `${nameSpace}/news-fetch`,
  async ({ data }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/news/${query}`);
      return {
        ...resp.data,
      };
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const newsCreate = createAsyncThunk(
  `${nameSpace}/news-create`,
  async ({ data }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.post('/news/', data);
      message.success(messagesSuccess(successText('successAdd')));
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const newsInfoFetch = createAsyncThunk(
  `${nameSpace}/news-info-fetch`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/news/${id}/`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const newsUpdate = createAsyncThunk(
  `${nameSpace}/news-update`,
  async ({ newsId, newData }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.put(`/news/${newsId}/`, newData);
      return { ...resp.data, id: newsId };
    } catch (e) {
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      return rejectWithValue(e?.response?.data);
    }
  },
);

export const newsDeleteFetch = createAsyncThunk(
  `${nameSpace}/news-delete-fetch`,
  async ({ id }, { rejectWithValue }) => {
    try {
      await axiosApi.delete(`/news/${id}/`);
      message.success(messagesSuccess(successText('successDelete')));
      return {
        id,
      };
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

const newsSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  reducers: {
    createNewsReducer: (state, action) => {
      const keys = Object.keys(action.payload);
      state.createNews[keys[0]] = action.payload[keys[0]];
    },
    newsCreatePhotoDownload: (state, action) => {
      state.createNews.images = [...state.createNews.images, action.payload];
    },
    deleteCreatePhoto: (state, action) => {
      state.createNews.images = state.createNews.images.filter(
        (item) => item.id !== action.payload,
      );
    },
    createNewsAddUser: (state, { payload }) => {
      state.createNews.users = [
        ...state.createNews.users,
        { id: payload.id, email: payload.email },
      ];
    },
    createNewsAddTeam: (state, { payload }) => {
      state.createNews.teams = [...state.createNews.teams, { id: payload.id, name: payload.name }];
    },
    removeUserReducer: (state, { payload }) => {
      state.createNews.users = state.createNews.users.filter((item) => item.id !== payload);
    },
    removeTeamReducer: (state, { payload }) => {
      state.createNews.teams = state.createNews.teams.filter((item) => item.id !== payload);
    },
    editNewsReducer: (state, action) => {
      const keys = Object.keys(action.payload);
      if (state.editNews[keys[0]]) {
        state.editNews[keys[0]] = action.payload[keys[0]];
      }
    },
    editNewsAddReducer: (state, action) => {
      state.updateNewsData.images = action.payload?.images;
      state.updateNewsData.imagesHttps = action.payload?.images;
      state.editNews.title = action.payload?.title;
      state.editNews.text = action.payload?.text;
      state.editNews.deleted_images = [];
      state.editNews.images = [];
    },
    newsUpdatePhotoDownload: (state, action) => {
      state.editNews.images = [...state.editNews.images, action.payload];
    },
    deleteUpdateNewsPhoto: (state, action) => {
      state.editNews.images = state.editNews.images.filter((item) => item.id !== action.payload);
    },
    addPageParamsNews: (state) => {
      state.params.page += 1;
    },
    minusPageParamsNews: (state) => {
      state.params.page -= 1;
    },
    nullPageParamsNews: (state) => {
      state.params.page = 1;
    },
    deleteHistoryNewsNull: (state) => {
      state.deleteNews = INITIAL_STATE.deleteNews;
    },
    clearCreateNews: (state) => {
      state.createNews = INITIAL_STATE.createNews;
      state.newsSuccess = INITIAL_STATE.newsSuccess;
    },
    deletePhotosHttpsNews: (state, action) => {
      const photosEdit = state.updateNewsData.images.filter((item) => item.id !== action.payload);
      state.editNews.deleted_images.push(action.payload);
      state.updateNewsData.images = photosEdit;
    },
    updateNewsNavigateNull: (state) => {
      state.updatePutNews.updatePutNewsNavigate = false;
    },
    clearNews: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(newsFetch.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(newsFetch.fulfilled, (state, { payload }) => {
      state.errors = null;
      state.news = payload.results;
      state.pagination = {
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
      state.loading = false;
    });
    builder.addCase(newsFetch.rejected, (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    });
    builder.addCase(newsInfoFetch.pending, (state) => {
      state.newsInfo.loading = true;
      state.newsInfo.error = null;
    });
    builder.addCase(newsInfoFetch.fulfilled, (state, { payload }) => {
      state.newsInfo.loading = false;
      state.newsInfo.error = null;
      state.newsInfo.results = payload;
    });
    builder.addCase(newsInfoFetch.rejected, (state, { payload }) => {
      state.newsInfo.loading = false;
      state.newsInfo.error = payload;
    });
    builder.addCase(newsDeleteFetch.pending, (state) => {
      state.deleteNews = {
        ...state.deleteNews,
        loadingDelete: true,
        errorsDelete: null,
      };
    });
    builder.addCase(newsDeleteFetch.fulfilled, (state, { payload }) => {
      state.news = state.news.filter((item) => item.id !== Number(payload.id));
      state.deleteNews = {
        ...state.deleteNews,
        loadingDelete: false,
        history: 'success',
      };
    });
    builder.addCase(newsDeleteFetch.rejected, (state, { payload }) => {
      state.deleteNews = {
        ...state.deleteNews,
        errorsDelete: payload,
        loadingDelete: false,
      };
    });
    builder.addCase(newsCreate.pending, (state) => {
      state.newsSuccess.loading = true;
      state.newsSuccess.errors = null;
    });
    builder.addCase(newsCreate.fulfilled, (state, { payload }) => {
      state.newsSuccess = {
        loading: false,
        errors: null,
        success: 'success',
      };
      state.news = [payload, ...state.news];
      state.newsInfo.results = payload;
    });
    builder.addCase(newsCreate.rejected, (state, { payload }) => {
      state.newsSuccess.loading = false;
      state.newsSuccess.error = payload;
    });
    builder.addCase(newsUpdate.pending, (state) => {
      state.updatePutNews.loading = true;
      state.updatePutNews.error = null;
    });
    builder.addCase(newsUpdate.fulfilled, (state, { payload }) => {
      const findUpdate =
        state.news.find((item) => item.id === Number(payload.id)) || state.newsInfo.results;
      findUpdate.images = payload?.images;
      findUpdate.title = payload?.title;
      findUpdate.text = payload?.text;
      findUpdate.loading = false;
      state.newsInfo.results = payload;
      state.newsInfo.results.images = payload.images;
      state.updatePutNews = {
        loading: false,
        error: null,
        updatePutNewsNavigate: true,
      };
    });
    builder.addCase(newsUpdate.rejected, (state, { payload }) => {
      state.updatePutNews.loading = false;
      state.updatePutNews.error = payload;
    });
  },
});

export const {
  createNewsReducer,
  newsCreatePhotoDownload,
  deleteCreatePhoto,
  createNewsAddUser,
  createNewsAddTeam,
  removeUserReducer,
  removeTeamReducer,
  editNewsReducer,
  editNewsAddReducer,
  newsUpdatePhotoDownload,
  deleteUpdateNewsPhoto,
  addPageParamsNews,
  minusPageParamsNews,
  nullPageParamsNews,
  deleteHistoryNewsNull,
  clearCreateNews,
  deletePhotosHttpsNews,
  updateNewsNavigateNull,
  clearNews,
} = newsSlice.actions;
export const newsSelector = (state) => state.news;
export const newsInfoSelector = (state) => state.news.newsInfo;
export const deleteNewsSelector = (state) => state.news.deleteNews;
export const updatePutNews = (state) => state.news.updatePutNews;
export default newsSlice.reducer;
