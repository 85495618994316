import { Modal } from 'antd';
import PropTypes from 'prop-types';

const ModalComponent = ({
  open = false,
  onCancel = undefined,
  onOk = undefined,
  children = undefined,
  width = 0,
  height = '',
  className = '',
  closable = true,
  centered = false,
}) => {
  ModalComponent.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    children: PropTypes.element,
    width: PropTypes.number,
    height: PropTypes.string,
    className: PropTypes.string,
    closable: PropTypes.bool,
    centered: PropTypes.bool,
  };
  return (
    <Modal
      footer={null}
      width={width}
      height={height}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      className={className}
      closable={closable}
      centered={centered}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
