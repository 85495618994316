import { CloseOutlined, LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Form, message, Spin, Tooltip } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router';

import csvIcon from 'assets/images/icons/csv.svg';
import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import { messagesError, messagesErrorFetch } from 'components/Messages/Messagetext';
import {
  errorsValidateReducer,
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';

import {
  addMemberReducer,
  createTeamFetch,
  deleteInvalidMember,
  emailsSelector,
  fetchMembersEmails,
  removeMemberReducer,
  teamsSelector,
} from 'redux/teams/teamsSlice';
import { usersFetch, usersSelector } from 'redux/users/usersSlice';
import { deleteEmptyQueryStrings } from 'utils/constants';

const Creating = () => {
  const b = bem('Creating');
  const { t } = useTranslation();
  const push = useNavigate();
  const { users, usersLoading } = useSelector(usersSelector);
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const dispatch = useDispatch();
  const { createTeam, teamsSuccess, teamsInfo } = useSelector(teamsSelector);
  const { loading, success, invalidMembers } = useSelector(emailsSelector);
  const [usersSearch, setUsersSearch] = useState({ search: '' });
  const [value, setValue] = useState(null);
  const [filename, setFilename] = useState(null);

  useEffect(() => {
    if (teamsSuccess.success !== 'not') {
      if (teamsInfo?.results) {
        push(`/teams/teams-info/${teamsInfo?.results.id}/`);
      }
    }
  }, [teamsSuccess]);

  useEffect(() => {
    if (
      !errorsValidate &&
      (createTeam.name || createTeam.organization || createTeam.members.length)
    ) {
      dispatch(errorsValidateReducer(true));
    }

    if (errorsValidate) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.onpopstate = () => {};
        dispatch(modalExitReducer(true));
        dispatch(linksAddReducer(`/teams`));
      };
    }
  }, [errorsValidate]);

  useEffect(() => {
    const queryObj = {
      search: usersSearch.search,
    };

    const validateQuery = deleteEmptyQueryStrings(queryObj);
    const data = {
      query: validateQuery,
    };
    if (usersSearch?.search) {
      dispatch(usersFetch({ data }));
    }
  }, [dispatch, usersSearch.search]);

  const handleSearch = (e) => {
    setValue(e);
    setUsersSearch({ ...usersSearch, search: e?.toLowerCase() });
  };

  const handleMemberSelect = (user) => {
    if (createTeam.members.find((e) => e.member_id === user.id)) {
      return message.error(messagesErrorFetch(t('errors.this_user_already_added')));
    }
    setValue(null);
    dispatch(addMemberReducer({ id: user.id, email: user.email }));
    dispatch(errorsValidateReducer(true));
  };

  const deleteMemberHandler = (id) => {
    dispatch(removeMemberReducer({ id }));
  };

  const onFinish = () => {
    if (createTeam.members.length < 1) {
      return message.error(messagesError(t('errors.add_at_least_one_member')));
    }

    if (invalidMembers.length > 0) {
      return message.error(messagesError(t('teams.delete_unregistered_users')));
    }

    dispatch(createTeamFetch({ data: { ...createTeam } }));
  };

  const fileSaveHandler = (e) => {
    const { files } = e.target;
    setFilename(files[0].name);

    const obj = {
      user_emails: files[0],
    };

    const formData = new FormData();
    for (const name in obj) {
      if (name) {
        formData.append(name, obj[name]);
      }
    }
    dispatch(fetchMembersEmails({ data: formData }));
  };

  return (
    <div className={b()} data-testid='select-members'>
      <div className='breadcrumbs'>
        <BreadcrumbsComponent textEnd={t('teams.creating_team')} />
        <p className={b('title')}>{t('teams.creating_team')}</p>
        <p className={b('text')}>{t('teams.create_your_teams')}</p>
        <div className={b('upload-file')}>
          <label className='btn_file text_select_none' htmlFor='upload-file'>
            <img src={csvIcon} alt={t('teams.import_csv')} />
            <input
              onChange={fileSaveHandler}
              type='file'
              name='file'
              id='upload-file'
              multiple
              accept='text/csv'
            />
            {t('teams.import_csv')}
          </label>
        </div>
        {filename ? (
          <div className={b('clipped-file')}>
            {loading ? (
              <LoadingOutlined style={{ color: '#1677ff' }} />
            ) : (
              <PaperClipOutlined
                style={success !== 'not' ? { color: '#1677ff' } : { color: '#ff6a6b' }}
              />
            )}
            <span className={b(`filename ${success === 'not' && !loading ? 'invalid-file' : ''}`)}>
              {filename}
            </span>
          </div>
        ) : null}

        <Form name='basic' layout='vertical' style={{ marginTop: '10px' }}>
          <Form.Item label={t('teams.add_participant')} data-testid='autocomplete-users'>
            <AutoComplete
              loading={usersLoading}
              value={value}
              style={{ width: '100%' }}
              options={users?.items?.map((user) => ({ key: user.id, value: user.email, user }))}
              filterOption={(inputValue, option) =>
                option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }
              onSearch={handleSearch}
              onSelect={(e, option) => handleMemberSelect(option.user)}
              placeholder={t('teams.input_email')}
            />
          </Form.Item>

          {loading ? (
            <div className={b('participants_loading')}>
              <Spin size='large' />
            </div>
          ) : (
            <div className={b('participants_block')}>
              {createTeam.members.map((member) => (
                <p className={b('participant')} key={member.member_id} data-testid='added-member'>
                  {member.email}
                  <CloseOutlined
                    style={{ cursor: 'pointer', fontSize: '14px' }}
                    onClick={() => deleteMemberHandler(member.member_id)}
                  />
                </p>
              ))}
              {invalidMembers?.map((member) => (
                <Tooltip
                  title='Не найден в списке Nomad пользователей'
                  key={member.id}
                  placement='bottom'
                  overlayInnerStyle={{ fontSize: '12px', width: '255px' }}
                >
                  <p className={b('participant invalid')}>
                    {member.email}
                    <CloseOutlined
                      style={{ cursor: 'pointer', fontSize: '14px', color: '#FF4B55' }}
                      onClick={() => dispatch(deleteInvalidMember({ id: member.id }))}
                    />
                  </p>
                </Tooltip>
              ))}
            </div>
          )}

          <div className={b('block_btn')}>
            <Button
              onClick={() => push('/teams/create')}
              style={{
                width: 129,
                height: 44,
                backgroundColor: 'rgba(0, 122, 255, 0.1)',
                border: 'none',
                color: '#200E32',
                borderRadius: 8,
              }}
            >
              {t('promotions.back')}
            </Button>
            <div className={b('text_page')}>
              <span>
                <span className='text_page_title'>{t('promotions.page')} 2</span>
                <br />
                <span className='text_page_text'>2 {t('challenges.from')} 2</span>
              </span>
            </div>
            <Button
              disabled={
                !createTeam.members.length ||
                !createTeam.name ||
                !createTeam.organization ||
                teamsSuccess.loading
              }
              loading={teamsSuccess.loading}
              style={{
                width: 129,
                height: 44,
                backgroundColor:
                  !createTeam.members.length || !createTeam.name || !createTeam.organization
                    ? '#89BAEF'
                    : '#007AFF',
                color: '#ffffff',
                borderRadius: 8,
              }}
              onClick={onFinish}
            >
              {t('promotions.finish')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Creating;
