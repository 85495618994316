import { Button } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';

import editIcons from 'assets/images/icons/Edit.svg';
import FormField from 'components/FormField/FormField';

const ButtonsHandlers = ({
  name = '',
  modalDeleteHandler = undefined,
  options = [],
  resultsInfo = undefined,
  onChangeStatus = undefined,
  loadingUpdate = false,
  updateHandler = undefined,
  textEdit = '',
  textDelete = '',
  id = undefined,
  endDate = false,
  hasActiveInactive = true,
}) => {
  ButtonsHandlers.propTypes = {
    modalDeleteHandler: PropTypes.func,
    options: PropTypes.instanceOf(Array),
    resultsInfo: PropTypes.number,
    onChangeStatus: PropTypes.func,
    loadingUpdate: PropTypes.bool,
    updateHandler: PropTypes.func,
    textEdit: PropTypes.string,
    textDelete: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
    endDate: PropTypes.bool,
    hasActiveInactive: PropTypes.bool,
  };
  const b = bem('ButtonsHandlers');
  return (
    <div className={b()}>
      {hasActiveInactive ? (
        <FormField
          disabled={endDate}
          name={name}
          open
          style={{
            width: '100%',
          }}
          loadingUpdate={loadingUpdate}
          type='selectStatus'
          options={options}
          size={20}
          value={resultsInfo}
          onChange={onChangeStatus}
          width='100%'
          id={id}
        />
      ) : null}

      <Button
        onClick={updateHandler}
        icon={
          <span className='paddingRight'>
            <img src={editIcons} alt='editIcons' />
          </span>
        }
        type='primary'
        style={{
          width: '100%',
          height: 44,
          fontSize: 14,
          fontWeight: 600,
          borderRadius: 6,
        }}
      >
        {textEdit}
      </Button>

      <Button
        onClick={modalDeleteHandler}
        className='delete_btn'
        style={{
          backgroundColor: 'rgba(0, 122, 255, 0.05)',
          width: '100%',
          height: 44,
          color: '#EB4335',
          fontSize: 14,
          fontWeight: 600,
          borderRadius: 6,
        }}
      >
        {textDelete}
      </Button>
    </div>
  );
};

export default ButtonsHandlers;
