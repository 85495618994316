import bem from 'easy-bem';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router';

import Errors from 'components/Errors/Errors';
import SkeletonButton from 'components/SkeletonBlock/FieldsSkeleton/SkeletonButton';
import TeamsTable from 'components/TeamsTable/TeamsTable';
import TeamsItemComponent from 'containers/Teams/TeamsItemComponent/TeamsItemComponent';

import { creatingNullReducer, teamsFetch, teamsSelector } from 'redux/teams/teamsSlice';

const Teams = () => {
  const b = bem('Teams');
  const { results, teamsLoading, teamsError } = useSelector(teamsSelector);
  const dispatch = useDispatch();
  const push = useNavigate();

  useEffect(() => {
    if (!results) {
      const data = {
        query: { page: 1 },
      };
      dispatch(teamsFetch({ data }));
    }
  }, [dispatch]);

  const pushHandler = () => {
    dispatch(creatingNullReducer());
    push('/teams/create');
  };

  if (teamsError) {
    return <Errors status={teamsError} />;
  }

  return (
    <div className={b()}>
      {!teamsLoading ? (
        <TeamsItemComponent
          pushHandler={pushHandler}
          teamsTotal={results?.teams_total}
          membersTotal={results?.unique_members_total}
        />
      ) : (
        <SkeletonButton height={158} marginBottom={20} num={1} borderRadius={12} />
      )}
      <TeamsTable teamsList={results?.items} teamsSearchFetch={teamsFetch} />
    </div>
  );
};

export default Teams;
