import PropTypes from 'prop-types';
import React from 'react';

import ErrorsNumber from 'components/Errors/ErrorsNumber/ErrorsNumber';
import PageNotFound from 'components/Errors/PageNotFound/PageNotFound';

const Errors = ({ status = {} }) => {
  Errors.propTypes = {
    status: PropTypes.instanceOf(Object),
  };
  if (status?.status === 404) {
    return <PageNotFound />;
  }
  if (status.status) {
    return (
      <ErrorsNumber
        status={status?.status}
        detail={status?.error?.detail || 'Что то пошло не так!'}
      />
    );
  }

  return (
    <ErrorsNumber
      status={status?.status}
      detail={status?.error?.detail || 'Что то пошло не так!'}
    />
  );
};

export default Errors;
