import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const SkeletonButton = ({
  width = '100%',
  height = 0,
  borderRadius = 0,
  num = 0,
  marginTop = 0,
  marginBottom = 0,
}) => {
  SkeletonButton.propTypes = {
    width: PropTypes.string,
    height: PropTypes.number,
    borderRadius: PropTypes.number,
    num: PropTypes.number,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
  };
  return (
    <>
      {[...Array(Number(num))].map(() => {
        return (
          <div data-testid='loading' key={Math.floor(new Date().valueOf() * Math.random())}>
            <Skeleton.Button
              size='small'
              active
              style={{
                width,
                height,
                borderRadius,
                marginTop,
                marginBottom,
              }}
            />
          </div>
        );
      })}
    </>
  );
};

export default SkeletonButton;
