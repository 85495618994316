import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ModificationInput = ({
  label = '',
  name = '',
  message = '',
  dataName = '',
  placeholder = '',
  onClick = undefined,
  rules = [],
  maxLength = 120,
}) => {
  ModificationInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    message: PropTypes.string,
    dataName: PropTypes.string,
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    rules: PropTypes.instanceOf(Array),
    maxLength: PropTypes.number,
  };
  const { t } = useTranslation();

  useEffect(() => {
    const lanDom = document.querySelector('#lan');
    if (lanDom) {
      lanDom.innerHTML = message;
    }
  }, [message]);

  return (
    <Form.Item
      label={t(label)}
      name={name}
      rules={[
        ...rules,
        {
          required: true,
          message: <span id='lan'>{message}</span>,
        },
      ]}
    >
      <Input
        maxLength={maxLength}
        onClick={onClick}
        data-name={dataName}
        placeholder={t(placeholder)}
      />
    </Form.Item>
  );
};

export default ModificationInput;
