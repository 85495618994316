import { Skeleton } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React from 'react';

const SkeletonBlock = ({ active = false, num = 0, titleBool = false }) => {
  SkeletonBlock.propTypes = {
    active: PropTypes.bool,
    num: PropTypes.number,
    titleBool: PropTypes.bool,
  };
  const b = bem('SkeletonBlock');
  const styleCss = {
    width: 256,
    height: 140,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  };
  return (
    <div>
      {titleBool ? (
        <div className='title_skeleton'>
          <Skeleton.Button
            size='small'
            active={active}
            style={{
              width: '100%',
            }}
          />
        </div>
      ) : null}
      <div data-testid='loading' className={b()}>
        {[...Array(Number(num))].map(() => {
          return (
            <div key={Math.floor(new Date().valueOf() * Math.random())}>
              <div>
                <Skeleton.Image active={active} className={b('skeleton_img')} style={styleCss} />
              </div>
              <div className={b('block_text_skeleton')}>
                <Skeleton active={active} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SkeletonBlock;
