import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DrawerComponents from 'components/DrawerComponents/DrawerComponents';
import SkeletonStatic from 'components/SkeletonBlock/SkeletonStatic/SkeletonStatic';

const StatisticItemComponent = ({ loading = false, items = [], commonInfo = {} }) => {
  StatisticItemComponent.propTypes = {
    loading: PropTypes.bool,
    items: PropTypes.instanceOf(Array).isRequired,
    commonInfo: PropTypes.instanceOf(Object).isRequired,
  };
  const { t } = useTranslation();
  const b = bem('StatisticItemComponent');
  const [visible, setVisible] = useState(false);
  const [title] = useState('');
  const [cardId] = useState(null);

  const showDrawer = () => {
    setVisible(!visible);
  };

  if (loading) {
    return <SkeletonStatic />;
  }

  return (
    <div className='block_flex'>
      {items?.map((item, i) => {
        return (
          <button
            type='button'
            data-testid='elem-static'
            key={item.id ? item.id : i}
            className={`${b()} btn_none_style_block`}
          >
            <div
              className={b(`block_static ${cardId === i && !!visible && 'active_block_static'}`)}
            >
              <p>{t(`home.${item.title}`)}</p>
              <div className='text_static'>
                <div className='summa'>
                  <div className='text_sum'>
                    {item.title === 'total_number_of_strava_requests'
                      ? commonInfo?.daily_used
                      : commonInfo?.quarter_used}{' '}
                    /
                  </div>
                  <div className='sumRen'>
                    {item.title === 'total_number_of_strava_requests'
                      ? commonInfo?.daily_limit
                      : commonInfo?.quarter_limit}
                  </div>
                </div>
              </div>
            </div>
          </button>
        );
      })}

      <DrawerComponents onClose={showDrawer} visible={visible} title={title} />
    </div>
  );
};

export default StatisticItemComponent;
