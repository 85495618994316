import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Popover, Progress } from 'antd';
import bem from 'easy-bem';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { messagesErrorFetch } from 'components/Messages/Messagetext';
import { summaRequirementAdd } from 'redux/challengesSlice/challengesSlice';

const ProgressComponents = ({ requirement = [], summaRequirement = 0, chooseCategory = 0 }) => {
  ProgressComponents.propTypes = {
    requirement: PropTypes.instanceOf(Array),
    summaRequirement: PropTypes.number,
    chooseCategory: PropTypes.number,
  };
  const { t } = useTranslation();
  const b = bem('ProgressComponents');
  const dispatch = useDispatch();
  let summa = 0;

  useEffect(() => {
    summa = 0;
    const summaArray = requirement.map((item) => item.percentage);
    summaArray.forEach((item) => {
      summa += item;
    });
    dispatch(summaRequirementAdd({ summa }));
  }, [requirement]);

  useEffect(() => {
    if (summaRequirement > 99) {
      const inter = document.querySelector('.ant-progress-circle .ant-progress-text');
      const domInterest = document.querySelector(
        '.ProgressComponents .ant-progress-inner:not(.ant-progress-circle-gradient)' +
          ' .ant-progress-circle-path',
      );
      inter.innerHTML = `${summaRequirement}%`;
      domInterest.style.stroke = '#FB4400';
    }

    if (summaRequirement === 100) {
      const domInterest = document.querySelector(
        '.ProgressComponents .ant-progress-inner:not(.ant-progress-circle-gradient)' +
          ' .ant-progress-circle-path',
      );
      domInterest.style.stroke = '#47CE6D';
    }

    if (summaRequirement > 100) {
      message.error(
        messagesErrorFetch(
          i18n?.language === 'ru'
            ? 'Наберите 100% и заполните шкалу'
            : 'Dial 100% and fill the scale',
        ),
      );
    }

    if (summaRequirement < 100) {
      const domInterest = document.querySelector(
        '.ProgressComponents .ant-progress-inner:not(.ant-progress-circle-gradient)' +
          ' .ant-progress-circle-path',
      );
      domInterest.style.stroke = '#FB4400';
    }
  }, [summaRequirement]);

  const content = <div className='text_confirm'>{t('challenges.text_interest')}</div>;
  const renderProgress = () => {
    if (requirement.length > 3) {
      return (
        <div className='block_bottom'>
          <div
            className='icons_checkMark block_text_size'
            style={{
              backgroundColor: '#FF4B55',
            }}
          >
            <CloseOutlined
              style={{
                color: '#ffffff',
                fontSize: 10,
              }}
            />
          </div>
          <div className='blockCheckMark_text line_height_block'>
            {t('challenges.only_3_challenge')}
          </div>
        </div>
      );
    }

    return (
      <div className='block_bottom'>
        <div
          className='icons_checkMark block_text_size'
          style={{
            backgroundColor: requirement.length > 4 || requirement.length ? '#47CE6D' : '#ffff',
            border: '1px solid #47CE6D',
          }}
        >
          <CheckOutlined
            style={{
              fontSize: '10px',
              color: requirement.length > 4 || requirement.length ? '#ffff' : '#47CE6D',
            }}
          />
        </div>
        <div className='blockCheckMark_text line_height_block'>
          {t('challenges.only_3_challenge')}
        </div>
      </div>
    );
  };

  const renderSumma = () => {
    if (summaRequirement === 100) {
      return (
        <div>
          <div
            className='icons_checkMark'
            style={{
              backgroundColor: '#47CE6D',
              border: '1px solid #47CE6D',
            }}
          >
            <CheckOutlined
              style={{
                fontSize: '10px',
                color: '#ffffff',
              }}
            />
          </div>
          <div className='blockCheckMark_text'>{t('challenges.interest100')}</div>
        </div>
      );
    }

    if (summaRequirement > 100) {
      return (
        <div>
          <div
            className='icons_checkMark'
            style={{
              backgroundColor: '#FF4B55',
            }}
          >
            <CloseOutlined
              style={{
                color: '#ffffff',
                fontSize: 10,
              }}
            />
          </div>
          <div className='blockCheckMark_text'>
            {t('challenges.interest100')}
            <Popover content={content} className='text_warning'>
              <ExclamationCircleOutlined
                style={{
                  paddingLeft: 5,
                }}
              />
            </Popover>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div
          className='icons_checkMark'
          style={{
            border: '1px solid #47CE6D',
          }}
        >
          <CheckOutlined
            style={{
              fontSize: '10px',
              color: '#47CE6D',
            }}
          />
        </div>
        <div className='blockCheckMark_text'>
          {t('challenges.interest100')}
          <Popover content={content}>
            <ExclamationCircleOutlined
              style={{
                paddingLeft: 5,
              }}
            />
          </Popover>
        </div>
      </div>
    );
  };

  return (
    <div className={b()}>
      <div className={b('progress')}>
        <Progress status='normal' type='circle' percent={summaRequirement} />
      </div>

      <div className={b('text')}>
        <p className='title'> {t('challenges.fill_in_the_scale')}</p>
        <p className='text_progress'>{t('challenges.interest')}</p>
      </div>
      <div className={b('checkMark')}>
        <div>
          <div
            className='icons_checkMark'
            style={{
              backgroundColor: chooseCategory ? '#47CE6D' : '#ffff',
              border: '1px solid #47CE6D',
            }}
          >
            <CheckOutlined
              style={{
                fontSize: '10px',
                color: chooseCategory ? '#ffffff' : '#47CE6D',
              }}
            />
          </div>

          <div className='blockCheckMark_text'>{t('challenges.category')}</div>
        </div>
        <div>
          <div
            className='icons_checkMark'
            style={{
              backgroundColor: requirement.length ? '#47CE6D' : '#ffff',
              border: '1px solid #47CE6D',
            }}
          >
            <CheckOutlined
              style={{
                fontSize: '10px',
                color: requirement.length ? '#ffff' : '#47CE6D',
              }}
            />
          </div>
          <div className='blockCheckMark_text'>{t('challenges.create_conditions')}</div>
        </div>
        {renderProgress()}
        {renderSumma()}
      </div>
    </div>
  );
};

export default ProgressComponents;
