import bem from 'easy-bem';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Errors from 'components/Errors/Errors';
import SkeletonSearch from 'components/SkeletonBlock/SkeletonSearch/SkeletonSearch';
import SearchCard from 'containers/SearchResults/SearchCard';
import { addChallengesSearch } from 'redux/challengesSlice/challengesSlice';
import { addPromotionsSearch } from 'redux/promotions/promotionsSlice';
import {
  searchSelector,
  searchTermPromoReducer,
  searchTermReducer,
  searchTermStartReducer,
  searchTextNotReducer,
} from 'redux/search/searchSlice';
import { addStartsSearch } from 'redux/startsSlice/startsSlice';
import { setSearchReducer } from 'redux/teams/teamsSlice';
import { checkSpaces, optionsCard } from 'utils/constants';

const SearchResults = () => {
  const b = bem('SearchResults');
  const { t } = useTranslation();
  const { challengesSearch, promotionsSearch, startsSearch, teamsSearch, search } =
    useSelector(searchSelector);
  const dispatch = useDispatch();
  const push = useNavigate();

  useEffect(() => {
    if (
      !checkSpaces(search) &&
      (challengesSearch.results.length ||
        promotionsSearch.results.length ||
        startsSearch.results.length ||
        teamsSearch.results.length)
    ) {
      dispatch(searchTextNotReducer());
    }
  }, [search]);

  const cardHandlerChallenges = (item) => {
    push(`/challenges/challenge-info/${item.id}`);
    dispatch(addChallengesSearch(item));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const cardHandlerPromotions = (item) => {
    push(`/promotions/promotion-info/${item.id}`);
    dispatch(addPromotionsSearch(item));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const cardHandlerStarts = (item) => {
    push(`/starts/starts-info/${item.id}`);
    dispatch(addStartsSearch(item));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const cardHandlerTeams = (item) => {
    push(`/teams/teams-info/${item.id}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const clickChallengesHandler = () => {
    dispatch(searchTermReducer(search));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const clickPromotionsHandler = () => {
    dispatch(searchTermPromoReducer(search));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const clickStartsHandler = () => {
    dispatch(searchTermStartReducer(search));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const clickTeamsHandler = () => {
    dispatch(setSearchReducer(search));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (
    challengesSearch.loading ||
    promotionsSearch.loading ||
    startsSearch.loading ||
    teamsSearch.loading
  ) {
    return <SkeletonSearch />;
  }

  if (
    challengesSearch.errors ||
    promotionsSearch.errors ||
    startsSearch.errors ||
    teamsSearch.errors
  ) {
    return (
      <Errors
        status={
          challengesSearch.errors ||
          promotionsSearch.errors ||
          startsSearch.errors ||
          teamsSearch.errors
        }
      />
    );
  }

  if (
    !challengesSearch.count &&
    !promotionsSearch.count &&
    !startsSearch.count &&
    !teamsSearch.count
  ) {
    return (
      <div>
        <h3>
          {t('search.search_get')} "{search}" <br /> {t('search.not_found')}
        </h3>
        <p className='not_text'>{t('search.text_not')}</p>
      </div>
    );
  }
  return (
    <div className={b()}>
      <p className={b('title')}>
        {t('search.search_get')} "{search}" <br /> {t('search.found')}{' '}
        {challengesSearch.count + promotionsSearch.count + startsSearch.count + teamsSearch.count}{' '}
        {t('search.results')}
      </p>
      {challengesSearch.results.length ? (
        <div>
          <p className='tag'>{t('challenges.name')}</p>
          {optionsCard(3, challengesSearch.results)?.map((item) => {
            return (
              <button
                data-testid='elem_search'
                onClick={() => cardHandlerChallenges(item)}
                type='button'
                key={item.id}
                className='line btn_none_style_block_card'
              >
                <SearchCard isChallenge item={item} />
              </button>
            );
          })}
          <Link onClick={clickChallengesHandler} className='link_card' to='/challenges'>
            {t('search.watch_all_result')} ({challengesSearch.count})
          </Link>
        </div>
      ) : null}

      {promotionsSearch.results.length ? (
        <div className='promotions_card_top'>
          <p className='tag'>{t('promotions.name')}</p>
          {optionsCard(3, promotionsSearch.results)?.map((item) => {
            return (
              <button
                onClick={() => cardHandlerPromotions(item)}
                type='button'
                data-testid='promotions_search'
                key={item.id}
                className='line btn_none_style_block_card'
              >
                <SearchCard item={item} />
              </button>
            );
          })}
          <Link onClick={clickPromotionsHandler} to='/promotions' className='link_card'>
            {t('search.watch_all_result')} ({promotionsSearch.count})
          </Link>
        </div>
      ) : null}

      {startsSearch.results.length ? (
        <div className='promotions_card_top'>
          <p className='tag'>{t('starts.name')}</p>
          {optionsCard(3, startsSearch.results)?.map((item) => {
            return (
              <button
                onClick={() => cardHandlerStarts(item)}
                type='button'
                data-testid='starts_search'
                key={item.id}
                className='line btn_none_style_block_card'
              >
                <SearchCard item={item} />
              </button>
            );
          })}
          <Link onClick={clickStartsHandler} to='/starts' className='link_card'>
            {t('search.watch_all_result')} ({startsSearch.count})
          </Link>
        </div>
      ) : null}

      {teamsSearch.results.length ? (
        <div className='promotions_card_top'>
          <p className='tag'>{t('teams.title')}</p>
          {optionsCard(3, teamsSearch.results)?.map((item) => {
            return (
              <button
                onClick={() => cardHandlerTeams(item)}
                type='button'
                data-testid='teams_search'
                key={item.id}
                className='line btn_none_style_block_card'
              >
                <SearchCard item={item} />
              </button>
            );
          })}
          <Link onClick={clickTeamsHandler} to='/teams' className='link_card'>
            {t('search.watch_all_result')} ({teamsSearch.count})
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default SearchResults;
