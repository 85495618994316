import { Spin } from 'antd';
import bem from 'easy-bem';
import React from 'react';

const Loading = () => {
  const b = bem('Loading');

  return (
    <div data-testid='loading' className={b()}>
      <Spin />
    </div>
  );
};

export default Loading;
