import { Button, Form, message } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import DownloadPhoto from 'components/DownloadPhoto/DownloadPhoto';
import Errors from 'components/Errors/Errors';
import FormField from 'components/FormField/FormField';
import { messagesError, messagesSuccess } from 'components/Messages/Messagetext';
import SkeletonUpdate from 'components/SkeletonBlock/SkeletonUpdate/SkeletonUpdate';
import { authSelector } from 'redux/auth/authSlice';
import {
  challengesInfoFetch,
  challengesInfoSelector,
  challengesSelector,
  challengesUpdatePhotoDownload,
  challengesUpdateSelector,
  deletePhotosHttpsChallenges,
  deleteUpdateChallengesPhoto,
  deleteUpdateNull,
  editChallengesReducer,
  editChallengesSelector,
  updateChallengesFetch,
} from 'redux/challengesSlice/challengesSlice';
import {
  errorsValidateNullReducer,
  errorsValidateReducer,
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import { checkHandlerFor, filesValidateImg } from 'utils/constants';
import { dateMomentFormat } from 'utils/dateFormat/dateFormat';
import deleteTagHtml from 'utils/deleteTagHtml/deleteTagHtml';
import filesValidate from 'utils/filesValidate/filesValidate';
import localesUtils from 'utils/localesUtils/localesUtils';
import localesValidateInput from 'utils/localiesValidateInput/localiesValidateInput';
import { updateChallengesValidate } from 'utils/updateValidate/updateValidate';

const Update = () => {
  const b = bem('Update');
  const { user } = useSelector(authSelector);
  const { updateChallengesData, editChallenges, results } = useSelector(challengesSelector);
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const { loading, errors, resultsInfo } = useSelector(challengesInfoSelector);
  const { images } = useSelector(editChallengesSelector);
  const { loadingUpdate, historyEdit } = useSelector(challengesUpdateSelector);
  const [validateForm, setValidateForm] = useState(false);
  const push = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [drag, setDrag] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (errorsValidate) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.onpopstate = () => {};
        dispatch(modalExitReducer(true));
        dispatch(linksAddReducer(`/challenges/challenge-info/${id}`));
      };
    }
  }, [errorsValidate]);

  useEffect(() => {
    if (!results.length && !resultsInfo) {
      dispatch(challengesInfoFetch({ id }));
    }
    dispatch(deleteUpdateNull());
  }, []);

  useEffect(() => {
    if (historyEdit === 'push') {
      push(`/challenges/challenge-info/${id}`);
      message.success(messagesSuccess(t('challenges.save_challenges')));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [historyEdit]);

  useEffect(() => {
    const resultsUpdate = results.find((item) => item.id === Number(id)) || resultsInfo;
    if (resultsUpdate) {
      const validate = updateChallengesValidate(
        resultsUpdate,
        editChallenges,
        updateChallengesData,
      );
      setValidateForm(validate);
      if (validate) {
        dispatch(errorsValidateNullReducer());
      } else {
        dispatch(errorsValidateReducer(true));
      }
    }
  }, [editChallenges, updateChallengesData]);

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];
    const ret = filesValidate(files, images, updateChallengesData.images);
    checkHandlerFor(challengesUpdatePhotoDownload, ret);
    setDrag(false);
  };

  const deletePhotoHandler = (id) => {
    dispatch(deleteUpdateChallengesPhoto(id));
  };

  const deletePhotoHttps = (id) => {
    dispatch(deletePhotosHttpsChallenges(id));
  };

  const dragHandler = (e) => {
    e.preventDefault();
    if (updateChallengesData?.images.length <= 5) {
      setDrag(true);
    }
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const photosSaveHandler = (e) => {
    const { files } = e.target;
    if (filesValidateImg(files).length) {
      const ret = filesValidate(files, images, updateChallengesData.images);
      checkHandlerFor(challengesUpdatePhotoDownload, ret);
    }
  };

  const formHandler = (e) => {
    const { target } = e;
    const dataName = target.dataset.name;
    dispatch(editChallengesReducer({ [dataName]: target.value }));
  };

  const handleChange = (content) => {
    dispatch(editChallengesReducer({ description: content }));
  };
  const saveHandler = () => {
    if ([...images, ...updateChallengesData.images].length < 1) {
      message.error(messagesError(t('errors.photos')));
    } else {
      const editObj = {
        ...editChallenges,
      };

      if (!editObj.participants_limit) {
        editObj.participants_limit = '';
      }
      delete editObj.images;
      delete editObj.deleted_images;
      if (user?.organization?.id) {
        editObj.organization = user?.organization.id;
      }
      updateChallengesData.images.forEach((item, i) => {
        editObj[`images[${i}]image`] = item;
      });

      if (editChallenges.deleted_images?.length) {
        editChallenges.deleted_images.forEach((item, i) => {
          editObj[`deleted_images[${i}]`] = item;
        });
      }
      const formData = new FormData();
      for (const key in editObj) {
        if (key) {
          formData.append(key, editObj[key]);
        }
      }
      dispatch(updateChallengesFetch({ data: formData, id }));
    }
  };

  const cancelHandler = () => {
    if (errorsValidate) {
      dispatch(modalExitReducer(true));
      dispatch(linksAddReducer(`/challenges/challenge-info/${id}`));
    } else {
      push(`/challenges/challenge-info/${id}`);
    }
  };

  const examination = () => {
    const limit = parseInt(editChallenges.participants_limit);

    if (
      editChallenges.participants_limit &&
      (!/^\d+$/.test(editChallenges.participants_limit.toString()) ||
        isNaN(limit) ||
        Number(limit) < 1 ||
        Number(limit) > 99999)
    ) {
      return true;
    }

    if (!editChallenges.name || !editChallenges.description) {
      return true;
    }
    return !editChallenges.images.length && !updateChallengesData.images.length;
  };

  const onClick = (e) => {
    e.target.select();
  };

  if (loading) {
    return <SkeletonUpdate />;
  }

  if (errors) {
    return <Errors status={errors} />;
  }
  return (
    <div className={b()}>
      <BreadcrumbsComponent
        linkProps
        textEnd={t('challenges.editing_challenge')}
        componentName='challenges.name'
        pushHandler='/challenges'
      />
      <p className={b('title')}>{t('challenges.title_update')}</p>
      <p className={b('text')}>{t('challenges.text_update')}</p>
      <DownloadPhoto
        photos={[...images, ...updateChallengesData.images]}
        drag={drag}
        onDropHandler={onDropHandler}
        deletePhotoHandler={deletePhotoHandler}
        deletePhotoHttps={deletePhotoHttps}
        dragHandler={dragHandler}
        dragLeaveHandler={dragLeaveHandler}
        photosSaveHandler={photosSaveHandler}
        title={false}
      />
      <div className='CreatingChallengesForm'>
        <Form
          fields={[
            {
              name: 'name',
              value: editChallenges?.name,
            },
            {
              name: 'description',
              value: editChallenges?.description,
            },
            {
              name: 'participants_limit',
              value: editChallenges?.participants_limit,
            },
          ]}
          onFinish={saveHandler}
          name='basic'
          layout='vertical'
          onChange={formHandler}
        >
          <FormField
            type='modificationInput'
            label='challenges.editChallenges'
            name='name'
            message={localesUtils(
              'Пожалуйста, введите название челленджа',
              'Please input your challenge title',
            )}
            dataName='name'
            placeholder='challenges.title'
            onClick={onClick}
          />
          {/* <FormField */}
          {/*  type='number' */}
          {/*  label={t('challenges.challenge_participants_amount')} */}
          {/*  name='participants_limit' */}
          {/*  dataName='participants_limit' */}
          {/*  placeholder={t('challenges.challenge_participants_amount')} */}
          {/*  width='100%' */}
          {/*  max={99999} */}
          {/* /> */}
          {/* <p className={b('text_tag')}>{t('challenges.max_participants_info')}</p> */}
          <FormField
            id='description_id'
            rules={[
              {
                required: true,
                message: localesValidateInput(
                  <span id='promotionsDescription'>{t('errors.enter_description')}</span>,
                  'promotionsDescription',
                  t('errors.enter_description'),
                ),
              },
              {
                max: 1200,
                message: localesValidateInput(
                  <span id='promotionsDescriptionSecond'>
                    {t('errors.description_should_be_less_than_1200_character')}
                  </span>,
                  'promotionsDescriptionSecond',
                  t('errors.description_should_be_less_than_1200_character'),
                ),
              },
            ]}
            label={t('challenges.editDescription')}
            type='editor'
            marginTop='10px'
            name='description'
            dataName='description'
            maxLength={2400}
            contents={resultsInfo?.description}
            onChange={(content) => handleChange(content)}
          />
          <FormField
            disabled
            type='dateFormRangePicker'
            label='challenges.select_a_date'
            marginTop='-10px'
            creatObj={editChallenges}
            message='Please input your date picker!'
            dateFormat={dateMomentFormat}
            width='100%'
            height='44px'
            borderRadius={8}
          />
        </Form>

        <div className={b('btn_edit')}>
          <Button
            onClick={cancelHandler}
            style={{
              width: 129,
              height: 44,
              backgroundColor: 'rgba(0, 122, 255, 0.1)',
              borderRadius: 8,
              color: '#200E32',
              marginRight: 16,
              border: 'none',
            }}
          >
            {t('challenges.cancel')}
          </Button>
          <Button
            disabled={
              examination() ||
              validateForm ||
              !deleteTagHtml(editChallenges.description) ||
              editChallenges.description.length > 1200 ||
              editChallenges.participants_limit > 99999
            }
            loading={loadingUpdate}
            onClick={saveHandler}
            style={{
              width: 129,
              height: 44,
              backgroundColor:
                examination() ||
                validateForm ||
                !deleteTagHtml(editChallenges.description) ||
                editChallenges.description.length > 1200 ||
                editChallenges.participants_limit > 99999
                  ? 'rgb(137, 186, 239)'
                  : '#007AFF',
              borderRadius: 8,
              color: 'white',
              border: 'none',
            }}
          >
            {t('challenges.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Update;
