import { Button, Col, Form, message, Row, Typography } from 'antd';
import bem from 'easy-bem';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Lock from 'assets/images/Lock.png';
import CarouselComponent from 'components/CarouselComponent/CarouselComponent';
import CustomButton from 'components/CustomButton/Button';
import FormField from 'components/FormField/FormField';
import { authSelector, loginUser } from 'redux/auth/authSlice';
import 'containers/SignIn/_signIn.scss';
import { userLocalStorage } from 'utils/token';

const { Title } = Typography;

const SignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const b = bem('SignIn');
  const { loading, commonError, user, success } = useSelector(authSelector);
  const history = useNavigate();

  const onFinish = (values) => {
    dispatch(loginUser(values));
  };

  const pushToMainPage = () => {
    if (success && user && userLocalStorage) {
      message.success(t('navigation.successful_authorization'));
      history('/admin');
    }
  };

  useEffect(() => {
    pushToMainPage();
  }, [user, success]);

  useEffect(() => {
    if (commonError) {
      message.success({
        content: `${commonError}`,
        className: 'messageStyles',
        icon: <img src={`${Lock}`} alt='Lock' className='lockStyles' />,
      });
    }
  }, [commonError]);

  return (
    <Row justify='space-between' data-testid='sign-in' className={b()}>
      <Col
        className='mainColumnStyles'
        xs={{ span: 12, offset: 6 }}
        lg={{ span: 6, offset: 2 }}
        md={{ span: 12, offset: 6 }}
      >
        <Title data-testid='sign_in_test'>{t('login.sign_in')}</Title>

        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className='login-form'
          autoComplete='off'
          layout='vertical'
        >
          <FormField
            data-testid='email_id_login'
            type='email'
            id='email_id'
            inputClassName={b('username')}
            label={t('login.email')}
            name='email'
            placeholder={t('login.your_email_or_phone_number')}
          />

          <FormField
            data-testid='password_id'
            className='username'
            type='password'
            label={t('login.password')}
            name='password'
            placeholder={t('login.your_password')}
          />

          {loading ? (
            <Button
              type='primary'
              loading
              style={{ width: '100%', borderRadius: 8 }}
              className='login-form-button buttonStyle'
            >
              {t('login.sign_in')}
            </Button>
          ) : (
            <CustomButton
              type='primary'
              htmlType='submit'
              style={{ width: '100%', borderRadius: 8 }}
              className='login-form-button buttonStyle'
              text={t('login.sign_in')}
            />
          )}
        </Form>
      </Col>
      <Col xs={{ span: 24, offset: 0 }} lg={{ span: 13, offset: 0 }} md={{ span: 24, offset: 0 }}>
        <CarouselComponent />
      </Col>
    </Row>
  );
};

export default SignIn;
