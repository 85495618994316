import { CloseOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const PhotosAdd = ({
  photosHttp = '',
  photosCreate = {},
  id = 0,
  deletePhoto = undefined,
  deletePhotoHttps = undefined,
}) => {
  PhotosAdd.propTypes = {
    photosHttp: PropTypes.string,
    photosCreate: PropTypes.instanceOf(Object),
    id: PropTypes.number,
    deletePhoto: PropTypes.func.isRequired,
    deletePhotoHttps: PropTypes.func,
  };
  const b = bem('PhotosAdd');
  const [images, setImages] = useState('');
  useEffect(() => {
    if (!photosHttp) {
      setImages(window.URL.createObjectURL(photosCreate));
    }
  }, [photosCreate]);
  if (photosHttp || images) {
    return (
      <div data-testid='photos-elem' key={id} className={b()}>
        {photosHttp ? (
          <div
            data-testid='image'
            className='image_download'
            style={{
              backgroundImage: `url(${photosHttp})`,
            }}
          >
            <button className='delete_icons_btn' type='button' onClick={() => deletePhotoHttps(id)}>
              <CloseOutlined
                style={{
                  color: '#ffffff',
                  fontSize: 12,
                  fontWeight: 600,
                }}
              />
            </button>
          </div>
        ) : null}
        {images ? (
          <div
            className='image_download'
            style={{
              backgroundImage: `url(${images})`,
            }}
          >
            <button
              className='delete_icons_btn'
              type='button'
              onClick={() => deletePhoto(photosCreate?.id)}
            >
              <CloseOutlined
                style={{
                  color: '#ffffff',
                }}
              />
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  return <div key={id}>Not Found photos</div>;
};

export default PhotosAdd;
