import localesUtils from 'utils/localesUtils/localesUtils';

export const resultsKmMeters = (sum, measurementUnit) => {
  const res = sum.toString().slice(0, sum.length - 3);

  if (measurementUnit === 2) {
    return `${sum} ${localesUtils('м/c', 'm/s')}`;
  }

  if (measurementUnit === 1) {
    return `${sum} ${localesUtils('с', 's')}`;
  }

  if (measurementUnit === 3) {
    return `${sum} ${localesUtils('ккал', 'kcal')}`;
  }

  if (measurementUnit === 4) {
    return `${sum} ${localesUtils('ват', 'wat')}`;
  }
  if (res.length < 4) {
    return `${Number(res).toFixed()} ${localesUtils('м', 'm')}`;
  }
  const summa = Number(res) / 1000;
  return `${summa.toFixed(1)} ${localesUtils('км', 'km')}`;
};

export const numberKmFunction = (measurementUnit, unitKm, id) => {
  if (measurementUnit === 2) {
    const metersKmh = unitKm / 3600;
    const unitKmFixed = unitKm / 1000;
    return {
      id,
      unit_amount: Number(metersKmh).toFixed(2),
      unitKmDefault: Number(unitKmFixed).toFixed(2),
    };
  }
  if (unitKm.toString().includes('.')) {
    return {
      id,
      unit_amount: Number(unitKm).toFixed(2),
      unitKmDefault: (unitKm / 1000).toFixed(2),
    };
  }
  return { id, unit_amount: unitKm, unitKmDefault: unitKm / 1000 };
};

export const numberMetersFunction = (id, unitAmount) => {
  if (unitAmount) {
    if (unitAmount.toString().includes('.')) {
      return {
        id,
        unit_amount: Number(unitAmount).toFixed(2),
        unitKmDefault: Number(unitAmount).toFixed(2),
      };
    }
    return { id, unit_amount: unitAmount, unitKmDefault: unitAmount };
  }
  return { id, unit_amount: 0, unitKmDefault: 0 };
};
