import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { messagesErrorFetch, messagesSuccess } from 'components/Messages/Messagetext';
import axiosApi from 'utils/axios-api';
import { defaultError } from 'utils/config';
import { sundayDate, sundayEnd } from 'utils/dateFormat/dateFormat';
import { errorsHandler, successText } from 'utils/errorsHandler/errorsHandler';
import localesUtils from 'utils/localesUtils/localesUtils';
import toQueryParams from 'utils/toQueryParams';

const nameSpace = 'challenges';

const INITIAL_STATE = {
  results: [],
  status: null,
  loading: false,
  errors: null,
  searchClick: true,
  loadingSearch: false,
  params: {
    page: 1,
  },
  pagination: {
    count: 0,
    previous: null,
    next: '',
  },
  resultsChallengesInfo: {
    loading: false,
    errors: null,
    resultsInfo: null,
  },
  updateChallengesData: {
    images: [],
  },
  editChallenges: {
    images: [],
    deleted_images: [],
    name: '',
    description: '',
    starts_at: '',
    ends_at: '',
    participants_limit: null,
  },
  categories: {
    loading: false,
    errors: null,
    results: [],
    categoriesChose: null,
  },
  deleteChallenges: {
    loadingDelete: false,
    errorsDelete: null,
    history: '',
  },
  createChallengesFetch: {
    loading: false,
    errors: null,
    history: '',
  },
  creatChallenges: {
    name: '',
    description: '',
    starts_at: sundayDate(),
    ends_at: sundayEnd(),
    category: 0,
    images: [],
    requirements: [],
    participants_limit: '',
  },
  updateChallenges: {
    loadingUpdate: false,
    errorsErrors: null,
    historyEdit: '',
  },
  summaRequirement: 0,
  datePickerNull: false,
  sort: {
    status: {
      id: null,
      title: '',
      url: '',
    },
    categories: {
      id: null,
      title: {
        name_ru: '',
        name_en: '',
      },
      url: '',
    },
  },
  validateUnitAmount: [],
  activeInactive: {
    loading: false,
    errors: null,
  },
  searchTxt: '',
};

export const challengesFetch = createAsyncThunk(
  `${nameSpace}/challengesFetch`,
  async ({ data, sort, name = '' }, { rejectWithValue }) => {
    try {
      let query = '';
      if (data?.query) {
        query = toQueryParams(data.query);
      }
      const resp = await axiosApi.get(`/challenges/${query}${sort}${name}`);
      return {
        ...resp.data,
        name,
      };
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const createChallengesFetch = createAsyncThunk(
  `${nameSpace}/createChallengesFetch`,
  async ({ data }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.post(`/challenges/`, data);
      message.success(messagesSuccess(successText('successAdd')));
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));

      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const challengesInfoFetch = createAsyncThunk(
  `${nameSpace}/challengesInfoFetch`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/challenges/${id}/`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const challengesDeleteFetch = createAsyncThunk(
  `${nameSpace}/challengesDeleteFetch`,
  async ({ id }, { rejectWithValue }) => {
    try {
      await axiosApi.delete(`/challenges/${id}/`);
      message.success(messagesSuccess(successText('successDelete')));
      return {
        id,
      };
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const challengesCategoriesFetch = createAsyncThunk(
  `${nameSpace}/challengesCategoriesFetch`,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/challenges/categories/`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

export const updateChallengesFetch = createAsyncThunk(
  `${nameSpace}/updateChallengesFetch`,
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.put(`/challenges/${id}/`, data);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

export const activeInactiveFetch = createAsyncThunk(
  `${nameSpace}/activeInactiveFetch`,
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.put(`/challenges/${payload.id}/`, payload.data);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      if (e?.response.status === 400) {
        message.error(
          messagesErrorFetch(
            localesUtils(
              'Вы не можете активировать завершенное испытание!',
              'You cannot activate completed challenge!',
            ),
          ),
        );
      } else {
        message.error(messagesErrorFetch(errorsHandler(e?.response?.status)));
      }
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue(error);
    }
  },
);

const challengesSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  reducers: {
    creatChallengesReducer: (state, action) => {
      const keys = Object.keys(action.payload);
      state.creatChallenges[keys[0]] = action.payload[keys[0]];
    },
    dateAddReducer: (state, action) => {
      state.creatChallenges.starts_at = action.payload.data.starts_at;
      state.creatChallenges.ends_at = action.payload.data.ends_at;
    },
    challengesDragHandler: (state, action) => {
      state.creatChallenges.images = [...state.creatChallenges.images, action.payload];
    },
    deleteChallengesPhoto: (state, action) => {
      state.creatChallenges.images = state.creatChallenges.images.filter(
        (item) => item.id !== action.payload,
      );
    },
    challengesPhotoDownload: (state, action) => {
      state.creatChallenges.images = [...state.creatChallenges.images, action.payload];
    },
    challengesChoseReducer: (state, action) => {
      state.creatChallenges.requirements = [];
      state.categories.categoriesChose = action.payload.data;
    },
    requirementAdd: (state, action) => {
      state.creatChallenges.requirements.push(action.payload.card);
    },
    requirementRemove: (state, action) => {
      state.creatChallenges.requirements = state.creatChallenges.requirements.filter(
        (item) => item.id !== action.payload.id,
      );
    },
    requirementInterestChange: (state, action) => {
      const requirementUnit = state.creatChallenges.requirements?.find(
        (item) => item.id === action.payload.id,
      );
      requirementUnit.percentage = action.payload.interest;
    },

    requirementUnitChange: (state, action) => {
      const requirementUnit = state.creatChallenges.requirements?.find(
        (item) => item.id === action.payload.id,
      );
      requirementUnit.unit_amount = action.payload.unit_amount;
    },
    summaRequirementAdd: (state, action) => {
      state.summaRequirement = action.payload.summa;
    },
    removeErrors: (state) => {
      state.resultsChallengesInfo.errors = null;
    },
    historyNull: (state) => {
      state.deleteChallenges.history = '';
      state.createChallengesFetch.history = '';
    },
    creatingNullReducer: (state) => {
      state.creatChallenges = {
        name: '',
        description: '',
        starts_at: sundayDate(),
        ends_at: sundayEnd(),
        category: 0,
        images: [],
        requirements: [],
      };
      state.createChallengesFetch.history = '';
    },
    deleteUpdateChallengesPhoto: (state, action) => {
      state.updateChallengesData.images = state.updateChallengesData.images.filter(
        (item) => item.id !== action.payload,
      );
    },
    deletePhotosHttpsChallenges: (state, action) => {
      const photosEdit = state.editChallenges.images.filter((item) => item.id !== action.payload);
      state.editChallenges.deleted_images.push(action.payload);
      state.editChallenges.images = photosEdit;
    },
    challengesUpdatePhotoDownload: (state, action) => {
      state.updateChallengesData.images = [...state.updateChallengesData.images, action.payload];
    },
    editChallengesReducer: (state, action) => {
      const keys = Object.keys(action.payload);
      state.editChallenges[keys[0]] = action.payload[keys[0]];
    },
    updateHistory: (state) => {
      state.updateChallenges.historyEdit = '';
      state.updateChallengesData.images = [];
    },
    editAddReducer: (state, action) => {
      state.editChallenges.images = action.payload.images;
      state.editChallenges.name = action.payload.name;
      state.editChallenges.description = action.payload?.description;
      state.editChallenges.starts_at = action.payload?.starts_at;
      state.editChallenges.ends_at = action.payload?.ends_at;
      state.editChallenges.deleted_images = [];
      state.editChallenges.participants_limit = action.payload?.participants_limit;
    },
    dateNull: (state, action) => {
      state.datePickerNull = action.payload;
    },
    sortHandlerReducer: (state, action) => {
      state.sort = {
        ...state.sort,
        ...action.payload,
      };
    },
    nullSort: (state) => {
      state.sort = {
        status: {
          id: null,
          title: '',
          url: '',
        },
        categories: {
          id: null,
          title: {
            name_ru: '',
            name_en: '',
          },
          url: '',
        },
      };
    },
    resultsInfoNull: (state) => {
      state.resultsChallengesInfo.resultsInfo = null;
    },
    requirementUnitChangeKm: (state, action) => {
      const requirementUnit = state.creatChallenges.requirements?.find(
        (item) => item.id === action.payload.id,
      );
      requirementUnit.unit_amount = action.payload.unit_amount;
      requirementUnit.unitKmDefault = action.payload.unitKmDefault;
    },
    validateUnitAmountReducer: (state, action) => {
      state.validateUnitAmount.push(action.payload);
    },
    validateUnitAmountReducerDelete: (state, action) => {
      state.validateUnitAmount = state.validateUnitAmount.filter((item) => item !== action.payload);
    },
    validateUnitArrayNull: (state) => {
      state.validateUnitAmount = [];
    },
    loadingChallengesActiveInActive: (state, action) => {
      const findLoading = state.results.find((item) => item.id === action.payload.id);
      findLoading.loading = true;
    },
    deleteUpdateNull: (state) => {
      state.editChallenges.deleted_images = [];
    },
    addChallengesSearch: (state, action) => {
      state.results = [...state.results, action.payload];
    },
    addResultsChallenges: (state, action) => {
      state.results = action.payload.results;
      state.pagination = {
        ...state.pagination,
        count: action.payload.pagination.count,
        next: action.payload.pagination.next,
        previous: action.payload.pagination.previous,
      };
      state.sort = {
        status: {
          id: null,
          title: '',
          url: '',
        },
        categories: {
          id: null,
          title: {
            name_ru: '',
            name_en: '',
          },
          url: '',
        },
      };
    },
    clearResultsChallenges: (state) => {
      state.results = [];
    },
    loadingSearchChallenges: (state) => {
      state.loading = true;
    },
    addResultsInfo: (state, action) => {
      state.resultsChallengesInfo.resultsInfo = action.payload;
    },
    addPageParams: (state) => {
      state.params.page += 1;
    },
    minusPageParams: (state) => {
      state.params.page -= 1;
    },
    nullPageParams: (state) => {
      state.params.page = 1;
    },
    activeErrorsLoader: (state, action) => {
      const findLoader = state.results.find((item) => Number(item.id) === Number(action.payload));
      if (findLoader) {
        findLoader.loading = false;
      }
      state.activeInactive.errors = null;
    },
    searchTxtReducer: (state) => {
      state.searchTxt = 'success';
    },
    createAddTeam: (state, { payload }) => {
      state.creatChallenges.organization = payload.organization;
      state.creatChallenges.team = payload.team;
      state.creatChallenges.team_name = payload.team_name;
    },
    createRemoveTeam: (state) => {
      const createObj = { ...state.creatChallenges };
      delete createObj.team;
      delete createObj.team_name;
      delete createObj.organization;
      state.creatChallenges = { ...createObj };
    },
    createRemoveLimit: (state) => {
      const createObj = { ...state.creatChallenges };
      delete createObj.participants_limit;
      state.creatChallenges = { ...createObj };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(challengesFetch.pending, (state, action) => {
      state.loading = true;
      state.errors = null;
      if (action?.meta.arg.name) {
        state.loadingSearch = true;
      }
    });
    builder.addCase(challengesFetch.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.errors = null;
      state.results = payload.results;
      state.searchTxt = '';
      state.pagination = {
        ...state.pagination,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
      if (payload.name) {
        state.loadingSearch = false;
      }
    });
    builder.addCase(challengesFetch.rejected, (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
      state.loadingSearch = false;
      state.searchHandler = '';
      state.searchTxt = '';
    });

    builder.addCase(challengesInfoFetch.pending, (state) => {
      state.resultsChallengesInfo = {
        ...state.resultsChallengesInfo,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(challengesInfoFetch.fulfilled, (state, { payload }) => {
      state.resultsChallengesInfo = {
        ...state.resultsChallengesInfo,
        errors: null,
        resultsInfo: payload,
        loading: false,
      };
      state.editChallenges = {
        ...state.editChallenges,
        images: payload.images,
        name: payload.name,
        description: payload.description,
        starts_at: payload.starts_at,
        ends_at: payload.ends_at,
        participants_limit: payload.participants_limit,
      };
    });
    builder.addCase(challengesInfoFetch.rejected, (state, { payload }) => {
      state.resultsChallengesInfo = {
        ...state.resultsChallengesInfo,
        errors: payload,
        loading: false,
      };
    });

    builder.addCase(challengesCategoriesFetch.pending, (state) => {
      state.categories = {
        ...state.categories,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(challengesCategoriesFetch.fulfilled, (state, { payload }) => {
      state.categories = {
        ...state.categories,
        results: payload,
        loading: false,
        errors: null,
      };
    });
    builder.addCase(challengesCategoriesFetch.rejected, (state, { payload }) => {
      state.categories = {
        ...state.categories,
        errors: payload,
        loading: false,
      };
    });
    builder.addCase(createChallengesFetch.pending, (state) => {
      state.createChallengesFetch = {
        ...state.createChallengesFetch,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(createChallengesFetch.fulfilled, (state, { payload }) => {
      state.resultsChallengesInfo = {
        ...state.resultsChallengesInfo,
        resultsInfo: payload,
      };
      state.createChallengesFetch = {
        ...state.createChallengesFetch,
        loading: false,
        errors: null,
        history: 'success',
      };
      state.results = [payload, ...state.results];
    });
    builder.addCase(createChallengesFetch.rejected, (state, { payload }) => {
      state.createChallengesFetch = {
        ...state.createChallengesFetch,
        errors: payload,
        loading: false,
      };
    });
    builder.addCase(challengesDeleteFetch.pending, (state) => {
      state.deleteChallenges = {
        ...state.deleteChallenges,
        loadingDelete: true,
        errorsDelete: null,
      };
    });
    builder.addCase(challengesDeleteFetch.fulfilled, (state, { payload }) => {
      state.results = state.results.filter((item) => item.id !== Number(payload.id));
      state.deleteChallenges = {
        ...state.deleteChallenges,
        loadingDelete: false,
        history: 'success',
      };
    });
    builder.addCase(challengesDeleteFetch.rejected, (state, { payload }) => {
      state.deleteChallenges = {
        ...state.deleteChallenges,
        errorsDelete: payload,
        loadingDelete: false,
      };
    });
    builder.addCase(updateChallengesFetch.pending, (state) => {
      state.updateChallenges = {
        ...state.updateChallenges,
        loadingUpdate: true,
        errorsErrors: null,
      };
    });
    builder.addCase(updateChallengesFetch.fulfilled, (state, { payload }) => {
      const findUpdate =
        state?.results.find((item) => item.id === payload.id) ||
        state?.resultsChallengesInfo.resultsInfo;
      findUpdate.status = payload.status;
      findUpdate.images = payload.images;
      findUpdate.name = payload.name;
      findUpdate.description = payload.description;
      findUpdate.participants_limit = payload.participants_limit;
      findUpdate.loading = false;
      state.resultsInfo = payload;
      state.updateChallenges = {
        ...state.updateChallenges,
        loadingUpdate: false,
        errorsErrors: null,
        historyEdit: 'push',
      };
    });
    builder.addCase(updateChallengesFetch.rejected, (state, { payload }) => {
      state.updateChallenges = {
        ...state.updateChallenges,
        errorsErrors: payload,
        loadingUpdate: false,
      };
    });
    builder.addCase(activeInactiveFetch.pending, (state) => {
      state.activeInactive = {
        ...state.activeInactive,
        loading: true,
        errors: null,
      };
    });
    builder.addCase(activeInactiveFetch.fulfilled, (state, { payload }) => {
      const findUpdate =
        state?.results.find((item) => item.id === payload.id) ||
        state?.resultsChallengesInfo.resultsInfo;
      findUpdate.status = payload.status;
      findUpdate.starts_at = payload.starts_at;
      findUpdate.ends_at = payload.ends_at;
      findUpdate.loading = false;
      state.resultsInfo = payload;
      state.activeInactive = {
        ...state.activeInactive,
        loading: false,
        errors: null,
      };
    });
    builder.addCase(activeInactiveFetch.rejected, (state, { payload }) => {
      state.activeInactive = {
        ...state.activeInactive,
        errors: payload,
        loading: false,
      };
    });
  },
});

export const {
  dateAddReducer,
  creatChallengesReducer,
  challengesDragHandler,
  deleteChallengesPhoto,
  challengesPhotoDownload,
  challengesChoseReducer,
  requirementAdd,
  requirementRemove,
  requirementInterestChange,
  requirementUnitChange,
  summaRequirementAdd,
  removeErrors,
  historyNull,
  creatingNullReducer,
  deleteUpdateChallengesPhoto,
  deletePhotosHttpsChallenges,
  challengesUpdatePhotoDownload,
  editChallengesReducer,
  updateHistory,
  editAddReducer,
  dateNull,
  sortHandlerReducer,
  nullSort,
  resultsInfoNull,
  requirementUnitChangeKm,
  validateUnitAmountReducer,
  validateUnitAmountReducerDelete,
  loadingChallengesActiveInActive,
  deleteUpdateNull,
  validateUnitArrayNull,
  addChallengesSearch,
  addResultsChallenges,
  clearResultsChallenges,
  loadingSearchChallenges,
  addResultsInfo,
  addPageParams,
  minusPageParams,
  nullPageParams,
  activeErrorsLoader,
  searchTxtReducer,
  createAddTeam,
  createRemoveTeam,
  createRemoveLimit,
} = challengesSlice.actions;

export const challengesSelector = (state) => state.challenges;
export const challengesInfoSelector = (state) => state.challenges.resultsChallengesInfo;
export const challengesCreateDataSelector = (state) => state.challenges.creatChallenges;
export const challengesCategoriesSelector = (state) => state.challenges.categories;
export const createChallengesData = (state) => state.challenges.createChallengesFetch;
export const challengesDeleteSelector = (state) => state.challenges.deleteChallenges;
export const paginationSelector = (state) => state.challenges.pagination;
export const challengesUpdateSelector = (state) => state.challenges.updateChallenges;
export const editChallengesSelector = (state) => state.challenges.editChallenges;
export default challengesSlice.reducer;
