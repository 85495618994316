import { Card } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import ButtonsHandlers from 'components/ButtonsHandlers/ButtonsHandlers';
import CardInfo from 'components/CardInfo/CardInfo';
import Errors from 'components/Errors/Errors';
import SkeletonInfo from 'components/SkeletonBlock/SkeletonInfo/SkeletonInfo';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalDelete from 'components/UI/ModalDelete/ModalDelete';
import { homeSelector } from 'redux/homeSlice/homeSlice';
import {
  deleteHistoryPromotion,
  promotionDelete,
  promotionFetchInfo,
  promotionsActiveInactive,
  promotionsFetch,
  promotionsInfoErrorsNull,
  promotionsSelector,
  updatePromotionsNavigateNull,
} from 'redux/promotions/promotionsSlice';
import { searchReducer, searchSelector } from 'redux/search/searchSlice';
import { optionsActiveInactive } from 'utils/sortStatus/sortStatus';

const Info = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    promotions,
    promotionInfo,
    deletePromotionLoading,
    deletePromotionSuccess,
    activeInactive,
    getPromotionFetchError,
    sort,
    getPromotionFetchLoading,
  } = useSelector(promotionsSelector);
  const { promotionsHome } = useSelector(homeSelector);
  const { searchTermPromo } = useSelector(searchSelector);
  const [statusPromotions, setStatusPromotions] = useState({
    id: null,
    status: '',
  });
  const { t } = useTranslation();
  const b = bem('Info');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const resultsObj = promotions.find((item) => item.id === +id) || promotionInfo;

  useEffect(() => {
    if (!promotions.length && !promotionsHome.results.length && !promotionInfo) {
      dispatch(promotionFetchInfo({ id }));
    }
    dispatch(promotionsInfoErrorsNull());
    dispatch(updatePromotionsNavigateNull());
  }, [promotions.length]);

  useEffect(() => {
    const dataPage = {
      query: { page: 1 },
    };
    const findPromotions =
      promotions.find((item) => Number(item.id) === Number(statusPromotions.id)) || promotionInfo;
    if (findPromotions) {
      if (Number(findPromotions.status) === Number(statusPromotions.status)) {
        dispatch(
          promotionsFetch({
            data: dataPage,
            sort: `&sort=status,-id${sort.status.url}`,
            name: searchTermPromo ? `&name=${searchTermPromo}` : searchTermPromo,
          }),
        );
      }
    }
  }, [statusPromotions, activeInactive]);

  useEffect(() => {
    if (deletePromotionSuccess === 'push') {
      const data = {
        query: { page: 1 },
      };
      navigate('/promotions/');
      dispatch(deleteHistoryPromotion());
      dispatch(
        promotionsFetch({
          data,
          sort: `&sort=status,-id${sort.status.url}`,
          name: searchTermPromo ? `&name=${searchTermPromo}` : searchTermPromo,
        }),
      );
    }
  }, [deletePromotionSuccess]);

  const onChange = (value, promotionId) => {
    const data = {
      name: 'promotionInfo',
      promotionId,
      newData: { status: value },
    };

    dispatch(promotionsActiveInactive(data));

    setStatusPromotions({
      id: promotionId,
      status: value,
    });
  };

  const updateHandler = () => {
    dispatch(updatePromotionsNavigateNull());
    navigate(`/promotions/update-promotion/${id}/`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(searchReducer(''));
  };

  const deletePromotionHandler = (id) => {
    if (deletePromotionLoading) {
      setIsModalVisible(false);
    }
    dispatch(promotionDelete(id));
  };

  if (getPromotionFetchLoading) {
    return <SkeletonInfo />;
  }

  if (getPromotionFetchError) {
    return <Errors status={getPromotionFetchError} />;
  }
  return (
    <>
      <div className={b('promotion-info-style-calc')}>
        <CardInfo
          resultsInfo={resultsObj}
          pushHandler='/promotions/'
          componentName='promotions.name'
        />
        <Card className={b('promotion-info')}>
          <ButtonsHandlers
            name='promotions'
            loadingUpdate={activeInactive.loading}
            options={optionsActiveInactive}
            resultsInfo={resultsObj?.status}
            onChangeStatus={onChange}
            id={resultsObj?.id}
            updateHandler={updateHandler}
            textEdit={t('promotions.edit_promotion')}
            textDelete={t('promotions.delete_promotion')}
            modalDeleteHandler={() => setIsModalVisible(true)}
          />
        </Card>
      </div>
      <ModalComponent
        onCancel={() => setIsModalVisible(false)}
        open={isModalVisible}
        width={443}
        height='100%'
        className={b('modal-style')}
      >
        <ModalDelete
          title={t('promotions.are_you_sure_you_want_to_delete_the_promotion')}
          text={t('promotions.the_promotion_will_be_permanently_deleted')}
          yesDeleteHandler={() => deletePromotionHandler(resultsObj?.id)}
          noneDeleteHandler={() => setIsModalVisible(false)}
          loading={deletePromotionLoading}
        />
      </ModalComponent>
    </>
  );
};

export default Info;
