import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

import photoPlug from 'assets/images/photo-plug.png';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import StatusRun from 'components/CardInfo/StatusRun/StatusRun';
import { dateEnd, dateFormat } from 'utils/dateFormat/dateFormat';
import localesUtils from 'utils/localesUtils/localesUtils';
import navigatorUserAgent from 'utils/navigatorUserAgent/navigatorUserAgent';
import { resultsKmMeters } from 'utils/resultsKmMeters/resultsKmMeters';

const CardInfo = ({ resultsInfo = {}, pushHandler = '', componentName = '' }) => {
  CardInfo.propTypes = {
    resultsInfo: PropTypes.instanceOf(Object),
    pushHandler: PropTypes.string,
    componentName: PropTypes.string,
  };
  const b = bem('CardInfo');
  const scroll = React.createRef();
  const [images, setImages] = useState(null);
  const [str, setStr] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setImages(resultsInfo?.images[0]);
  }, []);

  const nextHandler = () => {
    const scrollRef = scroll.current;
    scrollRef.scrollTop = 0;
  };

  const endHandler = () => {
    const scrollRef = scroll.current;
    scrollRef.scrollTop = scrollRef.scrollHeight;
  };

  return (
    <div className={b()}>
      <div>
        <BreadcrumbsComponent
          linkProps
          homeLink='main'
          textEnd={resultsInfo?.name || resultsInfo?.title}
          pushHandler={pushHandler}
          componentName={componentName}
        />
      </div>
      <p className={b('title_card')}>
        {resultsInfo?.name ? resultsInfo?.name : resultsInfo?.title}
      </p>
      <div className={b('images_group')}>
        {images?.image && (
          <Image.PreviewGroup>
            <div>
              <Image
                data-testid='image_home'
                width={416}
                height={267}
                style={{
                  borderRadius: 6,
                }}
                src={images?.image ? images?.image : photoPlug}
              />
            </div>
            {resultsInfo?.images?.length > 1 && (
              <div className='images_overflow'>
                <button type='button' onClick={nextHandler} className='block_start'>
                  <CaretUpOutlined
                    style={{
                      color: 'white',
                    }}
                  />
                </button>
                <div className={`block_scroll ${navigatorUserAgent()}_block_scroll`} ref={scroll}>
                  {resultsInfo?.images
                    ?.filter((item) => item.id !== images?.id)
                    .map((item) => {
                      return (
                        <div data-testid='image' key={item.id}>
                          <Image
                            width={128}
                            height={81}
                            style={{
                              borderRadius: 6,
                            }}
                            src={item.image}
                          />
                        </div>
                      );
                    })}
                </div>
                <button type='button' onClick={endHandler} className='block_end'>
                  <CaretDownOutlined
                    style={{
                      color: 'white',
                    }}
                  />
                </button>
              </div>
            )}
          </Image.PreviewGroup>
        )}
      </div>
      <div className={resultsInfo?.requirements ? b('status_run') : ''}>
        {resultsInfo?.requirements ? (
          <>
            <StatusRun
              name={t('challenges.categories')}
              category={localesUtils(
                resultsInfo?.category?.name_ru || resultsInfo?.category?.name,
                resultsInfo?.category?.name_en || resultsInfo?.category?.name,
              )}
            />
            {dateEnd(resultsInfo.starts_at) || dateEnd(resultsInfo.ends_at) ? (
              <StatusRun
                name={t('challenges.willStart')}
                category={dateFormat(resultsInfo.starts_at)}
              />
            ) : null}
            {!dateEnd(resultsInfo.starts_at) && dateEnd(resultsInfo.ends_at) ? (
              <StatusRun name={t('challenges.ended')} category={dateFormat(resultsInfo.ends_at)} />
            ) : null}
            {!dateEnd(resultsInfo.starts_at) && !dateEnd(resultsInfo.ends_at) ? (
              <StatusRun name={t('challenges.ended')} category={t('challenges.finished')} />
            ) : null}
            {resultsInfo?.requirements.map((item) => {
              return (
                <div key={item.id}>
                  <StatusRun
                    name={localesUtils(item?.requirement?.name_ru, item.requirement.name_en)}
                    category={resultsKmMeters(
                      item?.unit_amount,
                      item?.requirement?.measurement_unit,
                    )}
                  />
                </div>
              );
            })}
          </>
        ) : null}
      </div>
      <div className={b('challengesInfo_text')}>
        {resultsInfo?.description?.length < 400 || resultsInfo?.text?.length < 400 ? (
          ReactHtmlParser(resultsInfo?.description || resultsInfo.text)
        ) : (
          <div className='show-more-styles'>
            {str
              ? ReactHtmlParser(
                  resultsInfo?.description ? resultsInfo.description : resultsInfo.text,
                )
              : ReactHtmlParser(
                  `${
                    resultsInfo?.description
                      ? resultsInfo?.description?.slice(0, 400)
                      : resultsInfo?.text.slice(0, 400)
                  }...`,
                )}
            <Button className='show-more-button' type='link' onClick={() => setStr(!str)}>
              {!str ? t('promotions.show_more') : t('promotions.hide')}
            </Button>
          </div>
        )}
      </div>
      {!resultsInfo?.requirements && resultsInfo?.url ? (
        <Button
          href={resultsInfo?.url}
          type='link'
          target='_blank'
          style={{
            width: '100%',
            borderRadius: 8,
            backgroundColor: 'rgba(0, 122, 255, 0.05)',
            marginTop: 30,
            marginBottom: 10,
          }}
        >
          {resultsInfo?.button_text}
        </Button>
      ) : null}
    </div>
  );
};

export default CardInfo;
