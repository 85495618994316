import { CommentOutlined } from '@ant-design/icons';
import { ConfigProvider, Form, Popover, Radio, Table } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomizeRenderEmpty from 'components/CustomizeRenderEmpty/CustomizeRenderEmpty';
import PaginationComponents from 'components/PaginationComponents/PaginationComponents';
import DrawerProfile from 'components/TableComponent/TableList/DrawerProfile/DrawerProfile';
import { teamInfoNull } from 'redux/teams/teamsSlice';
import { usersSelector } from 'redux/users/usersSlice';
import {
  challengeUserStatusRoles,
  iconsTable,
  userGenderDisplay,
  userStatusRoles,
} from 'utils/constants';
import {
  convertUtcToLocalTime,
  dateAndTimeMomentFormat,
  dateTimeFormat,
  dateTimeToFormat,
} from 'utils/dateFormat/dateFormat';

const TableList = ({
  users = undefined,
  usersList = undefined,
  numUsersPagination = undefined,
  usersParams = undefined,
  inputOnChangeHandler = undefined,
  handleChange = undefined,
  pagePrevHandler = undefined,
  pageNextHandler = undefined,
  handleTableSortChange = undefined,
  totalUsersPages = undefined,
  loadingIndicator = undefined,
  statusInfo = '',
  challengeIds = '',
  isTeams = false,
}) => {
  TableList.propTypes = {
    users: PropTypes.instanceOf(Object) || undefined,
    usersList: PropTypes.instanceOf(Object) || undefined,
    numUsersPagination: PropTypes.instanceOf(Object) || undefined,
    usersParams: PropTypes.instanceOf(Object) || undefined,
    inputOnChangeHandler: PropTypes.func,
    handleChange: PropTypes.func,
    pagePrevHandler: PropTypes.func,
    pageNextHandler: PropTypes.func,
    handleTableSortChange: PropTypes.func,
    totalUsersPages: PropTypes.number,
    loadingIndicator: PropTypes.bool,
    statusInfo: PropTypes.string,
    challengeIds: PropTypes.string,
    isTeams: PropTypes.bool,
  };
  const [visible, setVisible] = useState(false);
  const [recordData, setRecordData] = useState(null);
  const [size, setSize] = useState('large');
  const dispatch = useDispatch();
  const { userComments } = useSelector(usersSelector);
  const b = bem('TableList');
  const { t } = useTranslation();

  const columns = [
    {
      key: 'first_name',
      title: <div className='block_text_table'>{t('challenges.participant')}</div>,
      dataIndex: 'first_name',
      fixed: 'left',
      sorter: true,
      width: '15%',
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div className='table_list_user'>
          <span>
            {record.first_name} {record.last_name}
          </span>
          {userComments && Object.keys(userComments).includes(record.id.toString()) ? (
            <Popover
              className='table_list_popover'
              content={`${userComments[record.id]}`}
              placement='topLeft'
            >
              <CommentOutlined />
            </Popover>
          ) : null}
        </div>
      ),
    },
    {
      key: 'email',
      title: <div className='block_text_table'>{t('challenges.email')}</div>,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      dataIndex: 'email',
      width: '15%',
    },
    {
      key: 'phone',
      title: t('challenges.phone'),
      dataIndex: 'phone',
      width: '10%',
    },
    {
      key: 'date_of_birth',
      title: <div className='block_text_table'>{t('challenges.date_of_birth')}</div>,
      dataIndex: 'date_of_birth',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      width: '10%',
      render: (text, record) => (
        <span>{dateTimeToFormat(record.date_of_birth, dateTimeFormat)}</span>
      ),
    },
    {
      title: t('home.gender'),
      dataIndex: 'gender',
      key: 'gender',
      ellipsis: true,
      width: '10%',
      render: (text, record) => <span>{t(`home.${userGenderDisplay[record.gender]}`)}</span>,
    },
    {
      key: statusInfo === 'participantStatus' ? 'registered' : 'registered_on',
      title: <div className='block_text_table'>{t('challenges.date_of_registration')}</div>,
      dataIndex: statusInfo === 'participantStatus' ? 'registered' : 'registered_on',
      sorter: true,
      width: '10%',
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>
          {dateTimeToFormat(
            statusInfo === 'participantStatus' ? record.registered : record.registered_on,
            dateTimeFormat,
          )}
        </span>
      ),
    },
    {
      key: 'strava_account',
      title: t('challenges.strava'),
      dataIndex: 'strava_account',
      width: '10%',
      render: (text, record) => (
        <span>{record.strv_integration_allowed ? t('home.linked') : t('home.not_linked')}</span>
      ),
    },
    {
      title: t('home.statuses'),
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      sorter: !!challengeIds && !isTeams,
      sortDirections: challengeIds && !isTeams ? ['descend', 'ascend'] : null,
      width: '10%',
      render: (text, record) => {
        return statusInfo === 'participantStatus' ? (
          <span>
            {challengeUserStatusRoles[record.status].tag}{' '}
            {t(`challenges.${challengeUserStatusRoles[record.status].text}`)}
          </span>
        ) : (
          <span>
            {userStatusRoles[record.status].tag} {t(`home.${userStatusRoles[record.status].text}`)}
          </span>
        );
      },
    },
  ];

  if (challengeIds && !isTeams) {
    columns.splice(7, 0, {
      key: 'updated',
      title: t('challenges.completion_time'),
      dataIndex: 'updated',
      width: '12%',
      render: (text, record) => (
        <span>
          {record.updated && record.status === 2
            ? convertUtcToLocalTime(record.updated, dateAndTimeMomentFormat)
            : null}
        </span>
      ),
    });
  }

  if (usersList?.teams) {
    columns.push({
      key: 'teams',
      title: t('teams.title'),
      dataIndex: 'teams',
      render: (text, record) => {
        const teams = usersList.teams[record.id];
        if (teams?.length) {
          return (
            <div>
              {teams.map((team) => (
                <p key={team.team_id}>
                  <Link
                    to={`/teams/teams-info/${team.team_id}`}
                    onClick={() => dispatch(teamInfoNull())}
                  >
                    {team.name}
                  </Link>
                </p>
              ))}
            </div>
          );
        }

        return null;
      },
    });
  }

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const tableProps = {
    size,
  };

  const showDrawer = (record) => {
    setRecordData(record);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className={b()}>
      <div className={b('title')}>
        <p>{t('home.list_of_additions')}</p>
        <div>
          <Form.Item>
            <Radio.Group value={size} onChange={handleSizeChange}>
              <Radio.Button
                style={{
                  border: 'none',
                }}
                value='large'
              >
                <span className={size}>{iconsTable.large(size)}</span>
              </Radio.Button>
              <Radio.Button
                style={{
                  border: 'none',
                }}
                value='middle'
              >
                <span className={size}>{iconsTable.middle(size)}</span>
              </Radio.Button>
              <Radio.Button
                style={{
                  border: 'none',
                }}
                value='small'
              >
                <span className={size}>{iconsTable.small(size)}</span>
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <ConfigProvider renderEmpty={() => CustomizeRenderEmpty}>
        <Table
          loading={loadingIndicator}
          {...tableProps}
          style={{ cursor: 'pointer' }}
          bordered
          columns={columns}
          pagination={false}
          dataSource={users}
          rowKey={(record) => record.id}
          scroll={{
            x: 950,
          }}
          onChange={handleTableSortChange}
          onRow={(record) => ({
            onClick: () => {
              showDrawer(record);
            },
          })}
        />
      </ConfigProvider>
      <PaginationComponents
        usersList={usersList}
        numUsersPagination={numUsersPagination}
        usersParams={usersParams}
        inputOnChangeHandler={inputOnChangeHandler}
        handleChange={handleChange}
        pagePrevHandler={pagePrevHandler}
        pageNextHandler={pageNextHandler}
        totalUsersPages={totalUsersPages}
      />
      <DrawerProfile open={visible} recordData={recordData} onClose={onClose} />
    </div>
  );
};

export default TableList;
