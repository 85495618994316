import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const Description = ({
  label = '',
  name = '',
  marginTop = '',
  message = '',
  dataName = '',
  placeholder = '',
  maxLength = 0,
  onClick = undefined,
}) => {
  Description.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    marginTop: PropTypes.string,
    message: PropTypes.string,
    dataName: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    onClick: PropTypes.func,
  };
  const { t } = useTranslation();
  useEffect(() => {
    const descriptionDom = document.querySelector('#description');
    if (descriptionDom) {
      descriptionDom.innerHTML = message;
    }
  }, [message]);

  return (
    <Form.Item
      label={t(label)}
      name={name}
      style={{
        marginTop,
      }}
      rules={[
        {
          required: true,
          message: <span id='description'>{message}</span>,
        },
      ]}
    >
      <TextArea
        onClick={onClick}
        data-name={dataName}
        placeholder={t(placeholder)}
        showCount
        maxLength={maxLength}
        rows={4}
      />
    </Form.Item>
  );
};

export default Description;
