import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosApi from 'utils/axios-api';

import { defaultError } from 'utils/config';

const nameSpace = 'organizations';

const INITIAL_STATE = {
  organizations: null,
  organizationsLoading: false,
  organizationsError: null,
  organizationsCategories: null,
  getOrganizationsCategoriesListErrors: null,
  getOrganizationsCategoriesListSuccess: false,
};

export const getOrganizationsCategoriesList = createAsyncThunk(
  `${nameSpace}/getOrganizationsCategoriesList`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get('/organizations/categories/');
      return response.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  },
);

export const organizationsListFetch = createAsyncThunk(
  `${nameSpace}/organizationsListFetch`,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axiosApi.get(`/organizations/`);
      return resp.data;
    } catch (e) {
      let error = e?.response?.data;
      const status = e?.response.status;
      if (!e.response) {
        error = defaultError;
      }
      return rejectWithValue({
        error,
        status,
      });
    }
  },
);

const organizationsSlice = createSlice({
  name: nameSpace,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getOrganizationsCategoriesList.pending, (state) => {
      state.getOrganizationsCategoriesListErrors = null;
      state.getOrganizationsCategoriesListSuccess = false;
    });
    builder.addCase(getOrganizationsCategoriesList.fulfilled, (state, { payload }) => {
      state.getOrganizationsCategoriesListErrors = false;
      state.getOrganizationsCategoriesListSuccess = true;
      state.organizationsCategories = payload;
    });
    builder.addCase(getOrganizationsCategoriesList.rejected, (state, { payload }) => {
      state.getOrganizationsCategoriesListErrors = payload;
      state.getOrganizationsCategoriesListSuccess = false;
    });

    builder.addCase(organizationsListFetch.pending, (state) => {
      state.organizationsLoading = false;
      state.organizationsError = null;
    });
    builder.addCase(organizationsListFetch.fulfilled, (state, { payload }) => {
      state.organizationsLoading = false;
      state.organizations = payload;
    });
    builder.addCase(organizationsListFetch.rejected, (state, { payload }) => {
      state.organizationsError = payload;
      state.organizationsLoading = false;
    });
  },
});

export const organizationsSelector = (state) => state.organizations;
export default organizationsSlice.reducer;
