import { Button, Checkbox, Form, message, Typography } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import FormField from 'components/FormField/FormField';
import { messagesErrorFetch } from 'components/Messages/Messagetext';
import {
  errorsValidateReducer,
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import {
  createButtonUrlNull,
  dateAddReducerPromotions,
  dateValidateReducer,
  promotionsSelector,
  setPromotionData,
} from 'redux/promotions/promotionsSlice';
import {
  dateMomentFormat,
  datePickerFormat,
  sundayDate,
  sundayEnd,
} from 'utils/dateFormat/dateFormat';
import deleteTagHtml from 'utils/deleteTagHtml/deleteTagHtml';
import localesValidateInput from 'utils/localiesValidateInput/localiesValidateInput';

const { Title } = Typography;

const Creating = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const b = bem('Creating');
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const { createPromotionData, dateValidate } = useSelector(promotionsSelector);
  const [form] = Form.useForm();
  const push = useNavigate();
  const [showLink, setShowLink] = useState(!!createPromotionData.url);
  useEffect(() => {
    if (errorsValidate) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.onpopstate = () => {};
        dispatch(modalExitReducer(true));
        dispatch(linksAddReducer(`/promotions/`));
      };
    }
  }, [errorsValidate]);

  const onChangeCheckboxHandler = (e) => {
    setShowLink(e.target.checked);
    if (!e.target.checked) {
      dispatch(createButtonUrlNull());
    }
  };

  const dateHandler = (date) => {
    if (date) {
      const datePicker = {
        starts_at: datePickerFormat(date[0]),
        ends_at: datePickerFormat(date[1]),
      };
      dispatch(
        dateAddReducerPromotions({
          data: datePicker,
        }),
      );
      dispatch(dateValidateReducer(false));
      dispatch(errorsValidateReducer(true));
    } else if (date === null) {
      message.error(messagesErrorFetch(t('errors.add_date')));
      dispatch(dateValidateReducer(true));
    } else {
      const datePicker = {
        starts_at: sundayDate(),
        ends_at: sundayEnd(),
      };
      dispatch(
        dateAddReducerPromotions({
          data: datePicker,
        }),
      );
      dispatch(dateValidateReducer(false));
    }
  };

  const formHandler = (e) => {
    const { target } = e;
    if (target.value) {
      dispatch(errorsValidateReducer(true));
    }
  };

  const handleChange = (content) => {
    dispatch(errorsValidateReducer(true));
    dispatch(setPromotionData({ description: content }));
  };

  const onFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
    };
    if (!deleteTagHtml(createPromotionData.description)) {
      message.error(messagesErrorFetch(t('errors.enter_description')));
    } else {
      delete values['range-picker'];
      dispatch(setPromotionData(values));
      push('/promotions/create-promotion/UploadPhoto');
    }
  };
  let linkForm;

  if (showLink) {
    linkForm = (
      <div className={b('link-fields-styles')}>
        <FormField
          type='text'
          id='url_id'
          className={b('input-form-style input-text-form-style')}
          inputClassName={b('border-input-style')}
          name='url'
          dependencies={['button_text']}
          textErrorsRu='Поле `Текст для кнопки` должно быть заполнено'
          textErrorsEn='Field `Button text` must be field'
          rules={[
            {
              required: true,
              type: 'url',
              message: localesValidateInput(
                <span id='promotionsButtonText'>{t('errors.this_field_must_be_a_valid_url')}</span>,
                'promotionsButtonText',
                t('errors.this_field_must_be_a_valid_url'),
              ),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const buttonText = getFieldValue(['button_text']);
                if (value !== '' && buttonText === '') {
                  return Promise.reject(new Error(t('errors.button_link_text')));
                }

                if (value.includes(' ')) {
                  return Promise.reject(new Error(t('errors.no_spaces')));
                }
                return Promise.resolve();
              },
            }),
            {
              validator: (_, value) =>
                !value.includes(' ')
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('errors.spaces'))),
            },
          ]}
        />

        <FormField
          type='text'
          id='button_text_id'
          className={b('input-form-style')}
          inputClassName={b('border-input-style')}
          label={t('promotions.button_text')}
          name='button_text'
          dependencies={['url']}
          textErrorsRu='Поле `Добавить ссылку` должно быть заполнено'
          textErrorsEn='Field `Add link` must be field'
          rules={[
            {
              required: true,
              max: 20,
              message: localesValidateInput(
                <span id='promotionsButtonText2'>{t('errors.button_error_max_text')}</span>,
                'promotionsButtonText2',
                t('errors.button_error_max_text'),
              ),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const urlLink = getFieldValue(['url']);
                if (value !== '' && urlLink === '') {
                  return Promise.reject(new Error(t('errors.button_error_text')));
                }

                if (value.includes(' ')) {
                  return Promise.reject(new Error(t('errors.no_spaces')));
                }
                return Promise.resolve();
              },
            }),
            {
              validator: (_, value) =>
                !value.includes(' ')
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('errors.spaces'))),
            },
          ]}
        />
      </div>
    );
  }

  return (
    <div className={b()} data-testid='promotion--form-element'>
      <div className='breadcrumbs'>
        <BreadcrumbsComponent textEnd={t('promotions.creating_promotion')} />
      </div>
      <div className={b('promotion-stage-one-style')}>
        <Title
          data-testid='promotion-form-title'
          strong
          level={3}
          style={{ margin: 0, lineHeight: 'normal' }}
        >
          {t('promotions.creating_promotions')}
        </Title>
        <Title disabled level={5} style={{ margin: '6px 0', fontWeight: 'normal' }}>
          {t('promotions.create_your_promotions')}
        </Title>

        <Form
          form={form}
          initialValues={createPromotionData}
          onFinish={onFinish}
          onChange={formHandler}
          className='login-form'
          layout='vertical'
        >
          <FormField
            rules={[
              {
                required: true,
                message: localesValidateInput(
                  <span id='promotionsName'>{t('errors.enter_organization_name')}</span>,
                  'promotionsName',
                  t('errors.enter_organization_name'),
                ),
              },
              {
                max: 50,
                message: localesValidateInput(
                  <span id='promotionsNameSecond'>
                    {t('errors.title_should_be_less_than_50_character')}
                  </span>,
                  'promotionsNameSecond',
                  t('errors.title_should_be_less_than_50_character'),
                ),
              },
            ]}
            type='text'
            id='text_id'
            className={b('input-form-style')}
            inputClassName={b('border-input-style')}
            label={t('promotions.promotion_title')}
            name='name'
            placeholder={t('promotions.promotion_title')}
          />
          <FormField
            id='description_id'
            rules={[
              {
                required: true,
                message: localesValidateInput(
                  <span id='promotionsDescription'>{t('errors.enter_description')}</span>,
                  'promotionsDescription',
                  t('errors.enter_description'),
                ),
              },
              {
                max: 1200,
                message: localesValidateInput(
                  <span id='promotionsDescriptionSecond'>
                    {t('errors.description_should_be_less_than_1200_character')}
                  </span>,
                  'promotionsDescriptionSecond',
                  t('errors.description_should_be_less_than_1200_character'),
                ),
              },
            ]}
            label={t('promotions.promotion_description')}
            type='editor'
            name='description'
            onChange={(content) => handleChange(content)}
          />
          <FormField
            type='dateFormRangePicker'
            label='promotions.select_a_date'
            marginTop='-10px'
            disableDate={false}
            creatObj={createPromotionData}
            message='Please input your date picker!'
            dateHandler={dateHandler}
            dateFormat={dateMomentFormat}
            width='100%'
            height='34px'
            borderRadius={8}
          />
          <Form.Item>
            <Checkbox
              role='checkbox'
              defaultChecked={showLink}
              style={{ color: '#67697F', marginBottom: 12 }}
              onClick={onChangeCheckboxHandler}
            >
              {t('promotions.add_link')}
            </Checkbox>
            {linkForm}
          </Form.Item>
          <Form.Item
            className={b('btn_form')}
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button
              data-testid='button-submit'
              disabled={dateValidate}
              style={{
                width: 129,
                height: 44,
                borderRadius: 8,
              }}
              type='primary'
              htmlType='submit'
            >
              {t('promotions.next')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Creating;
