import { Button, Card, Checkbox, Form, message, Typography } from 'antd';
import bem from 'easy-bem';
import i18n from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import DownloadPhoto from 'components/DownloadPhoto/DownloadPhoto';
import Errors from 'components/Errors/Errors';
import FormField from 'components/FormField/FormField';
import {
  messagesError,
  messagesErrorFetch,
  messagesSuccess,
} from 'components/Messages/Messagetext';
import SkeletonUpdate from 'components/SkeletonBlock/SkeletonUpdate/SkeletonUpdate';
import {
  errorsValidateNullReducer,
  errorsValidateReducer,
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import {
  dateValidateReducer,
  deleteImagesNull,
  deletePhotosHttps,
  deleteUpdatePromotionPhoto,
  editAddPromotion,
  imagesNPromotionClear,
  promotionFetchInfo,
  promotionsCheckboxHandler,
  promotionsEditDataSelector,
  promotionsGetHandler,
  promotionsSelector,
  promotionUpdate,
  promotionUpdatePhotoDownload,
  updatePromotionsDate,
} from 'redux/promotions/promotionsSlice';
import { checkHandlerFor, filesValidateImg } from 'utils/constants';
import {
  dateMomentFormat,
  datePickerFormat,
  sundayDate,
  sundayEnd,
} from 'utils/dateFormat/dateFormat';
import deleteTagHtml from 'utils/deleteTagHtml/deleteTagHtml';
import { successText } from 'utils/errorsHandler/errorsHandler';
import filesValidate from 'utils/filesValidate/filesValidate';
import localesValidateInput from 'utils/localiesValidateInput/localiesValidateInput';
import { updatePromotionsValidate } from 'utils/updateValidate/updateValidate';

const { Title } = Typography;

const Update = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const b = bem('Update');
  const {
    promotions,
    promotionInfo,
    updatePromotionData,
    updatePutPromotionLoading,
    getPromotionFetchLoading,
    updatePromotionsNavigate,
    getPromotionFetchError,
  } = useSelector(promotionsSelector);
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const resultsObj = promotions.find((item) => item.id === +id) || promotionInfo;
  const { images } = useSelector(promotionsEditDataSelector);
  const { dateValidate, editPromotion } = useSelector(promotionsSelector);
  const [form] = Form.useForm();
  const [showLink, setShowLink] = useState(false);
  const [drag, setDrag] = useState(false);
  const [validateUpdate, setValidateUpdate] = useState(false);

  useEffect(() => {
    if (errorsValidate) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.onpopstate = () => {};
        dispatch(modalExitReducer(true));
        dispatch(linksAddReducer(`/promotions/promotion-info/${id}/`));
      };
    }
  }, [errorsValidate]);

  useEffect(() => {
    dispatch(deleteImagesNull());
  }, []);

  useEffect(() => {
    if (!promotions.length) {
      dispatch(promotionFetchInfo({ id }));
    }
  }, [dispatch, promotions.length, id]);

  useEffect(() => {
    if (resultsObj) {
      if (resultsObj.url) {
        setShowLink(true);
      } else {
        setShowLink(false);
      }
    }
  }, [resultsObj]);

  useEffect(() => {
    dispatch(imagesNPromotionClear());
  }, [dispatch, resultsObj?.url]);

  useEffect(() => {
    if (resultsObj) {
      form.setFieldsValue({
        name: resultsObj?.name,
        description: resultsObj?.description,
        'range-picker': [
          resultsObj?.starts_at ? moment(resultsObj?.starts_at, dateMomentFormat) : null,
          resultsObj?.ends_at ? moment(resultsObj?.ends_at, dateMomentFormat) : null,
        ],
        url: resultsObj?.url,
        button_text: resultsObj?.button_text,
      });
    }
  }, [form, resultsObj]);

  useEffect(() => {
    if (resultsObj) {
      dispatch(editAddPromotion(resultsObj));
    }
  }, [dispatch, resultsObj]);

  useEffect(() => {
    if (updatePromotionsNavigate) {
      history(`/promotions/promotion-info/${id}/`);
      message.success(messagesSuccess(successText('updatePromotions')));
    }
  }, [updatePromotionsNavigate]);

  useEffect(() => {
    if (resultsObj) {
      const resultsValidate = updatePromotionsValidate(
        resultsObj,
        updatePromotionData,
        editPromotion.deleted_images,
      );
      setValidateUpdate(resultsValidate);
      if (resultsValidate) {
        dispatch(errorsValidateNullReducer());
      } else {
        dispatch(errorsValidateReducer(true));
      }
    }
  }, [updatePromotionData, editPromotion.deleted_images]);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    dispatch(promotionsGetHandler({ [name]: value }));
  };

  const handleChange = (content) => {
    dispatch(promotionsGetHandler({ description: content }));
  };

  const onChangeCheckboxHandler = (e) => {
    setShowLink(e.target.checked);
    form.setFieldsValue({
      url: '',
      button_text: '',
    });

    if (!e.target.checked) {
      dispatch(promotionsCheckboxHandler());
    }
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];
    dispatch(errorsValidateReducer(true));
    const ret = filesValidate(files, images, updatePromotionData.images);
    checkHandlerFor(promotionUpdatePhotoDownload, ret);
    setDrag(false);
  };

  const deletePhotoHandler = (id) => {
    dispatch(deleteUpdatePromotionPhoto(id));
  };

  const deletePhotoHttps = (id) => {
    dispatch(deletePhotosHttps(id));
  };

  const dragHandler = (e) => {
    e.preventDefault();
    if (updatePromotionData?.images.length <= 5) {
      setDrag(true);
    }
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const photosSaveHandler = (e) => {
    const { files } = e.target;

    if (filesValidateImg(files).length) {
      const ret = filesValidate(files, images, updatePromotionData.images);
      checkHandlerFor(promotionUpdatePhotoDownload, ret);
    }
  };

  const handleSelect = (e) => {
    e.target.select();
  };

  let linkForm;

  if (showLink) {
    linkForm = (
      <div className={b('link-fields-styles')}>
        <FormField
          type='text'
          id='url_id'
          className={b('input-form-style input-text-form-style')}
          inputClassName={b('border-input-style')}
          name='url'
          onChange={inputChangeHandler}
          onClick={(e) => handleSelect(e)}
          dependencies={['button_text']}
          textErrorsRu='Поле `Текст для кнопки` должно быть заполнено'
          textErrorsEn='Field `Button text` must be field'
          rules={[
            {
              type: 'url',
              message: localesValidateInput(
                <span id='promotionsButtonText'>{t('errors.this_field_must_be_a_valid_url')}</span>,
                'promotionsButtonText',
                t('errors.this_field_must_be_a_valid_url'),
              ),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const buttonText = getFieldValue(['button_text']);
                if (value !== '' && buttonText === '') {
                  return Promise.reject(new Error(t('errors.button_link_text')));
                }

                if (value.includes(' ')) {
                  return Promise.reject(new Error(t('errors.no_spaces')));
                }

                return Promise.resolve();
              },
            }),
          ]}
        />

        <FormField
          type='text'
          id='button_text_id'
          className={b('input-form-style')}
          inputClassName={b('border-input-style')}
          label={t('promotions.button_text')}
          name='button_text'
          onChange={inputChangeHandler}
          onClick={(e) => handleSelect(e)}
          dependencies={['url']}
          textErrorsRu='Поле `Добавить ссылку` должно быть заполнено'
          textErrorsEn='Field `Add link` must be field'
          rules={[
            {
              max: 20,
              message: localesValidateInput(
                <span id='promotionsButtonText2'>{t('errors.button_error_max_text')}</span>,
                'promotionsButtonText2',
                t('errors.button_error_max_text'),
              ),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const urlLink = getFieldValue(['url']);
                if (value !== '' && urlLink === '') {
                  return Promise.reject(new Error(t('errors.button_error_text')));
                }

                if (value.includes(' ')) {
                  return Promise.reject(new Error(t('errors.no_spaces')));
                }

                return Promise.resolve();
              },
            }),
          ]}
        />
      </div>
    );
  }

  const dateHandler = (date) => {
    if (date) {
      const datePicker = {
        starts_at: datePickerFormat(date[0]),
        ends_at: datePickerFormat(date[1]),
      };
      dispatch(
        updatePromotionsDate({
          data: datePicker,
        }),
      );
      dispatch(dateValidateReducer(false));
    } else if (date === null) {
      message.error(messagesErrorFetch(i18n?.language === 'ru' ? 'Выберите дату' : 'Add date'));
      dispatch(dateValidateReducer(true));
    } else {
      const datePicker = {
        starts_at: sundayDate(),
        ends_at: sundayEnd(),
      };
      dispatch(
        updatePromotionsDate({
          data: datePicker,
        }),
      );
      dispatch(dateValidateReducer(false));
    }
  };

  const onFinish = () => {
    if ([...images, ...updatePromotionData.images].length < 1) {
      message.error(messagesError(t('errors.download_no_more_than_two_photos')));
    } else {
      const obj = {
        ...updatePromotionData,
      };
      delete obj.images;

      if (updatePromotionData?.images.length) {
        updatePromotionData.images.forEach((item, i) => {
          obj[`images[${i}]image`] = item;
        });
      }

      if (editPromotion.deleted_images?.length) {
        editPromotion.deleted_images.forEach((item, i) => {
          obj[`deleted_images[${i}]`] = item;
        });
      }

      if (!updatePromotionData.url) {
        delete obj.url;
      }

      if (!updatePromotionData.button_text) {
        delete obj.button_text;
      }

      const formData = new FormData();
      for (const name in obj) {
        if (name) {
          formData.append(name, obj[name]);
        }
      }

      const data = {
        promotionId: id,
        newData: formData,
      };
      dispatch(promotionUpdate(data));
    }
  };

  const onFinishFailed = (errorInfo) => {
    return errorInfo;
  };

  const cancelHandler = () => {
    if (errorsValidate) {
      dispatch(modalExitReducer(true));
      dispatch(linksAddReducer(`/promotions/promotion-info/${id}/`));
    } else {
      history(`/promotions/promotion-info/${id}/`);
    }
  };

  if (getPromotionFetchLoading) {
    return <SkeletonUpdate />;
  }

  if (getPromotionFetchError) {
    return <Errors status={getPromotionFetchError} />;
  }

  function validateCheckbox() {
    if (updatePromotionData.url && updatePromotionData.button_text) {
      return false;
    }
    return showLink;
  }

  return (
    <div className={b('update-style-calc')}>
      <Form
        form={form}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        initialValues={resultsObj}
        className='login-form'
        layout='vertical'
      >
        <Card bordered className={b('update-promotion-form-images')}>
          <BreadcrumbsComponent
            linkProps
            textEnd={t('promotions.editing_promotion')}
            pushHandler='/promotions/'
            componentName='promotions.name'
          />
          <Title
            data-testid='update-promotion-form-title'
            strong
            level={3}
            style={{ margin: 0, lineHeight: 'normal' }}
          >
            {t('promotions.edit_promotion')}
          </Title>
          <Title disabled level={5} style={{ margin: '5px 0 15px 0', fontWeight: 'normal' }}>
            {t('promotions.editing_promotion')}
          </Title>

          <DownloadPhoto
            photos={[...images, ...updatePromotionData.images]}
            drag={drag}
            onDropHandler={onDropHandler}
            deletePhotoHandler={deletePhotoHandler}
            deletePhotoHttps={deletePhotoHttps}
            dragHandler={dragHandler}
            dragLeaveHandler={dragLeaveHandler}
            photosSaveHandler={photosSaveHandler}
          />
          <div data-testid='promotion--form-element'>
            <div className={b('promotion-stage-one-style')}>
              <FormField
                rules={[
                  {
                    required: true,
                    message: localesValidateInput(
                      <span id='promotionsName'>{t('errors.enter_organization_name')}</span>,
                      'promotionsName',
                      t('errors.enter_organization_name'),
                    ),
                  },
                  {
                    max: 100,
                    message: localesValidateInput(
                      <span id='promotionsNameSecond'>
                        {t('errors.title_should_be_less_than_50_character')}
                      </span>,
                      'promotionsNameSecond',
                      t('errors.title_should_be_less_than_50_character'),
                    ),
                  },
                ]}
                type='text'
                id='text_id'
                className={b('input-form-style')}
                inputClassName={b('border-input-style')}
                label={t('promotions.promotion_title')}
                name='name'
                placeholder={t('promotions.promotion_title')}
                onChange={inputChangeHandler}
                onClick={(e) => handleSelect(e)}
              />

              <FormField
                id='description_id'
                rules={[
                  {
                    required: true,
                    message: localesValidateInput(
                      <span id='promotionsDescription'>{t('errors.enter_description')}</span>,
                      'promotionsDescription',
                      t('errors.enter_description'),
                    ),
                  },
                  {
                    max: 1200,
                    message: localesValidateInput(
                      <span id='promotionsDescriptionSecond'>
                        {t('errors.description_should_be_less_than_1200_character')}
                      </span>,
                      'promotionsDescriptionSecond',
                      t('errors.description_should_be_less_than_1200_character'),
                    ),
                  },
                ]}
                label={t('promotions.promotion_description')}
                type='editor'
                name='description'
                contents={resultsObj?.description}
                onChange={(content) => handleChange(content)}
              />

              <FormField
                type='dateFormRangePicker'
                label='promotions.select_a_date'
                marginTop='-10px'
                disableDate={false}
                creatObj={resultsObj || updatePromotionData}
                message='Please input your date picker!'
                dateHandler={dateHandler}
                dateFormat={dateMomentFormat}
                width='100%'
                height='34px'
                borderRadius={8}
              />

              <Form.Item>
                <Checkbox
                  role='checkbox'
                  defaultChecked={!!resultsObj?.url}
                  style={{ color: '#67697F' }}
                  onClick={onChangeCheckboxHandler}
                >
                  {t('promotions.add_link')}
                </Checkbox>
                {linkForm}
              </Form.Item>

              <div className={b('button-save')}>
                <Button className={b('button-update button-cancel')} onClick={cancelHandler}>
                  {t('promotions.cancel')}
                </Button>

                <Button
                  loading={updatePutPromotionLoading}
                  disabled={
                    ![...images, ...updatePromotionData.images].length ||
                    dateValidate ||
                    validateCheckbox() ||
                    validateUpdate ||
                    !deleteTagHtml(updatePromotionData.description)
                  }
                  style={{
                    backgroundColor:
                      ![...images, ...updatePromotionData.images].length ||
                      dateValidate ||
                      validateCheckbox() ||
                      !deleteTagHtml(updatePromotionData.description) ||
                      validateUpdate
                        ? '#89BAEF'
                        : '#007AFF',
                  }}
                  data-testid='button-update-submit'
                  onClick={() => {
                    form.submit();
                  }}
                  type='primary'
                  className={b('button-update button-save')}
                >
                  {t('promotions.save')}
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default Update;
