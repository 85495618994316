import { Select } from 'antd';
import bem from 'easy-bem';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';

import English from 'assets/images/icons/English.svg';
import Russian from 'assets/images/icons/Russian.svg';

import 'components/LocalizationButton/_localizationButton.scss';

const languages = [
  {
    name_en: 'English',
    name: 'Eng',
    code: 'en',
    img: English,
  },
  {
    name_en: 'Russian',
    name: 'Rus',
    code: 'ru',
    img: Russian,
  },
];

const LocalizationButton = () => {
  const b = bem('LocalizationButton');
  const [lang, setLang] = useState('ru');
  const { Option } = Select;

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion.winWidth]);

  const handleChange = (value) => {
    i18n.changeLanguage(value);
    setLang(value);
  };

  return (
    <div className={b()}>
      <div className='localizationButtonStyle'>
        <Select
          className={windowDimenion.winWidth < 600 && 'select-option-styles'}
          size='large'
          value={lang}
          defaultValue={lang}
          style={{
            width: 140,
          }}
          onChange={handleChange}
        >
          {languages?.map((option) => {
            return windowDimenion.winWidth >= 600 ? (
              <Option key={option.code} value={option.code}>
                <img style={{ paddingRight: '5px' }} alt={option.name} src={option.img} />
                {option.name_en}
              </Option>
            ) : (
              <Option key={option.code} value={option.code}>
                <img alt={option.name} src={option.img} />
              </Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default LocalizationButton;
