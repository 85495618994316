import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Route, Routes } from 'react-router-dom';

import AppRouter from 'appRouter/AppRouter';

import 'antd/dist/reset.css';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalExit from 'components/UI/ModalExit/ModalExit';
import SignIn from 'containers/SignIn/SignIn';
import Template from 'containers/Template/Template';
import { authSelector, checkForTokens, logoutUser } from 'redux/auth/authSlice';
import { creatingNullReducer } from 'redux/challengesSlice/challengesSlice';
import {
  errorsValidateReducer,
  historyNotificationsSelector,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import { clearStarts } from 'redux/startsSlice/startsSlice';
import { clearTeams } from 'redux/teams/teamsSlice';
import { getCookie } from 'utils/addCookies/addCookies';
import { logoutLocalStorage, userLocalStorage } from 'utils/token';

const App = () => {
  const { success, user, tokens } = useSelector(authSelector);
  const { modalExitBoll, links } = useSelector(historyNotificationsSelector);
  const dispatch = useDispatch();
  const push = useNavigate();
  useEffect(() => {
    const tokensLocal = userLocalStorage(getCookie('refresh'));
    if (tokensLocal) {
      dispatch(checkForTokens(tokensLocal));
    } else {
      logoutLocalStorage();
    }
  }, [dispatch]);
  useEffect(() => {
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);

  const listener = ({ key, newValue }) => {
    if (key === 'users') {
      if (newValue === '{"user":null,"token":null}' || newValue === null) {
        dispatch(logoutUser());
        logoutLocalStorage();
      } else {
        const tokensCopy = JSON.parse(newValue);
        tokensCopy.token.refresh = getCookie('refresh');
        dispatch(checkForTokens(tokensCopy));
      }
    }
  };

  const yesDeleteHandler = () => {
    dispatch(modalExitReducer(false));
    dispatch(errorsValidateReducer(false));
    dispatch(creatingNullReducer());
    dispatch(clearStarts());
    dispatch(clearTeams());
    push(links);
    window.onbeforeunload = false;
  };

  const noneDeleteHandler = () => {
    dispatch(modalExitReducer(false));
  };

  const onCancel = () => {
    dispatch(modalExitReducer(false));
  };

  return (
    <div>
      {success && user && tokens ? (
        <AppRouter />
      ) : (
        <Routes>
          {success && user && tokens ? null : <Route path='*' element={<SignIn />} />}
          <Route path='/' element={<Template />} />
        </Routes>
      )}
      <ModalComponent open={modalExitBoll} onCancel={onCancel} width={440} height='100%'>
        <ModalExit yesDeleteHandler={yesDeleteHandler} noneDeleteHandler={noneDeleteHandler} />
      </ModalComponent>
    </div>
  );
};

export default App;
